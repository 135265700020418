import { CelebrationDTO } from '../../dtos/celebrations.dto';
import {
  CelebrationAPIResponse,
  CelebrationsListAPIResponse,
} from '../../types/responses/celebrations.responses';

export const celebrationDTOFromAPIResponse = (
  item: CelebrationAPIResponse
): CelebrationDTO => ({
  content: item.content,
  createdAt: item.created_at,
  districts: item.districts,
  egroweCoachlogId: item.egrowe_coachlog_id,
  endorser: item.endorser,
  endorserUserId: item.endorser_user_id,
  id: item.id,
  includesYou: item.includesYou,
  isBrightspot: item.is_brightspot,
  isDeleted: item.is_deleted,
  schools: item.schools,
  textContent: item.text_content,
  title: item.title,
  updatedAt: item.updated_at,
  users: item.users,
});

export const celebrationListDTOFromAPIResponse = (
  response: CelebrationsListAPIResponse
): CelebrationDTO[] =>
  response.items.map((item) => celebrationDTOFromAPIResponse(item));
