import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import {
  LibraryShelfItemModelDTO,
  LibraryShelfModelDTO,
  ResourceFiltersDTO,
} from '../../dtos/resources.dto';

@Injectable({ providedIn: 'root' })
export class ResourcesBookmarkService {
  constructor(private apiService: APICoreService, private http: HttpClient) {}

  getAllFavoriteShelves() {
    return this.apiService.getRequest('elearn/favorite-shelves');
  }

  getMyShelves(active_ref_table: string, active_ref_id: number) {
    return this.apiService.getRequest('elearn/favorite-shelves', {
      active_ref_table,
      active_ref_id,
    });
  }

  getChildShelf(
    active_ref_table: string,
    active_ref_id: number,
    shelfId: number
  ) {
    return this.apiService.getRequest(`elearn/favorite-shelves/${shelfId}`, {
      active_ref_table,
      active_ref_id,
    });
  }

  updateShelfTitle(shelfId: number, title: string) {
    return this.apiService.putRequest(`elearn/favorite-shelves/${shelfId}`, {
      title,
    });
  }

  addFavoriteShelf(shelfId: number, shelfItem: LibraryShelfItemModelDTO) {
    return this.apiService.postRequest(
      `elearn/favorite-shelves/${shelfId}/items`,
      shelfItem
    );
  }

  removeFavoriteShelf(shelfId: number, itemType: string, itemId: number) {
    return this.apiService.deleteRequest(
      `elearn/favorite-shelves/${shelfId}/${itemType}/${itemId}`
    );
  }

  updateResourceStatus(id: number) {
    return this.apiService.getRequest(`elearn/resources/${id}`);
  }

  getOnlineCourses() {
    return this.apiService.getRequest('elearn/vpl-courses');
  }

  // ELEARN
  getCreatedByYou() {
    return this.apiService.getRequest('elearn/favorite-shelves/created-by-me');
  }

  getMyShelfs() {
    return this.apiService.getRequest('elearn/favorite-shelves');
  }

  getShelf(shelfId: number | string) {
    return this.apiService.getRequest(`elearn/favorite-shelves/${shelfId}`);
  }

  createShelf(newShelf: LibraryShelfModelDTO) {
    return this.apiService.postRequest('elearn/favorite-shelves', newShelf);
  }

  deleteShelf(shelfId: number) {
    return this.apiService.deleteRequest(`elearn/favorite-shelves/${shelfId}`);
  }

  getShelfItems(shelfId: number | string, resourceFilters: ResourceFiltersDTO) {
    return this.apiService.getRequest(
      `elearn/favorite-shelves/${shelfId}/items`,
      resourceFilters
    );
  }

  updateShelfInfo(shelfId: number, newTitle: string, newDescription: string) {
    return this.apiService.putRequest(`elearn/favorite-shelves/${shelfId}`, {
      title: newTitle,
      description: newDescription,
    });
  }

  postStarShelf(id: number) {
    return this.apiService.postRequest(`favorite/shelf/${id}`, {});
  }

  deleteStarShelf(id: number) {
    return this.apiService.deleteRequest(`favorite/shelf/${id}`);
  }

  sortShelves(id: number, direction: string, refId: number) {
    return this.apiService.putRequest(
      `elearn/favorite-shelves/${id}/sort/${direction}/${refId}`,
      {}
    );
  }

  // ECREATE
  getUnitCreatedByYou() {
    return this.apiService.getRequest('ecreate/favorite-shelves/created-by-me');
  }

  getMyUnitShelfs() {
    return this.apiService.getRequest('ecreate/favorite-shelves');
  }

  getUnitShelf(shelfId: number) {
    return this.apiService.getRequest(`ecreate/favorite-shelves/${shelfId}`);
  }

  createUnitShelf(newShelf: LibraryShelfModelDTO) {
    return this.apiService.postRequest('ecreate/favorite-shelves', newShelf);
  }

  deleteUnitShelf(shelfId: number) {
    return this.apiService.deleteRequest(`ecreate/favorite-shelves/${shelfId}`);
  }

  updateUnitShelfInfo(
    shelfId: number,
    newTitle: string,
    newDescription: string
  ) {
    return this.apiService.putRequest(`ecreate/favorite-shelves/${shelfId}`, {
      title: newTitle,
      description: newDescription,
    });
  }

  postStarUnitShelf(id: number) {
    return this.apiService.postRequest(`favorite/shelf/${id}`, {
      ref_table: 'ecreate_favorite_shelf',
    });
  }

  deleteStarUnitShelf(id: number) {
    return this.apiService.deleteRequest(
      `favorite/shelf/${id}?ref_table=ecreate_favorite_shelf`
    );
  }

  sortUnitShelves(id: number, direction: string, refId: number) {
    return this.apiService.putRequest(
      `ecreate/favorite-shelves/${id}/sort/${direction}/${refId}`,
      {}
    );
  }
}
