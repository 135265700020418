<div class="me-3">
  <div
    class="progress-bar level-{{ progress }}"
    [attr.aria-description]="description">
    <div
      class="progress-bar-block"
      [ngClass]="{ highlighted: highlightedBlockIndex.includes(1) }"></div>
    <div
      class="progress-bar-block"
      [ngClass]="{ highlighted: highlightedBlockIndex.includes(2) }"></div>
    <div
      class="progress-bar-block"
      [ngClass]="{ highlighted: highlightedBlockIndex.includes(3) }"></div>
    <div
      class="progress-bar-block"
      [ngClass]="{ highlighted: highlightedBlockIndex.includes(4) }"></div>
    <ng-container *ngIf="goalLevel">
      <img
        src="/assets/bullseye.svg"
        class="bullseye level-{{ goalLevel }}"
        alt="Bullseye"
        role="img" />
    </ng-container>
    <div *ngIf="progress && progress > 0" class="dot-marker">
      <i class="bi bi-check"></i>
    </div>
    <div class="goal-marker lvl2" [class.complete]="progress && progress >= 2">
      <img
        class="img-fluid"
        aria-label="level 2 completed"
        [src]="competencyIcon" />
    </div>
    <div class="goal-marker lvl3" [class.complete]="progress && progress >= 3">
      <img
        class="img-fluid"
        aria-label="level 3 completed"
        [src]="competencyIcon" />
    </div>
    <div class="goal-marker lvl4" [class.complete]="progress && progress >= 4">
      <img
        class="img-fluid"
        aria-label="level 4 completed"
        [src]="competencyIcon" />
    </div>
  </div>
</div>
