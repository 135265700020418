<div class="container">
  <div class="d-flex justify-content-between mb-3">
    <h1>Coaching Sessions</h1>
    <div class="align-self-center"></div>
  </div>
  <div class="card border-0 p-3 mb-12">
    <div class="row justify-content-end">
      <div class="col">
        <button
          type="button"
          *ngIf="this.user && canCreateLog"
          class="btn btn-primary float-end"
          (click)="createSessionParentModal.openModal()">
          New Session
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex flex-row align-items-center">
          <app-applied-filters
            *ngIf="showAppliedFilters"
            (filterRemoved)="removeFilter($event)"
            (filterListRemoved)="removeFilterFromList($event)"
            [coachlogSearchOptions]="
              coachlogSearchOptions
            "></app-applied-filters>
          <button
            type="button"
            class="btn btn-link mb-2"
            (click)="showFilterModal()">
            Edit Filters
          </button>
        </div>
        <hr class="border border-primary border-2" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ng-container *ngIf="!loading; else isLoading">
          <ng-container *ngIf="sessions.length > 0; else noSessions">
            <app-coaching-session-list
              [sessions]="sessions"></app-coaching-session-list>
          </ng-container>
          <ng-template #noSessions>
            <div
              class="no-sessions w-100 text-center justify-content-center"
              style="background-color: #fff; height: 400px">
              No Sessions Found
            </div>
          </ng-template>
        </ng-container>
        <ng-template #isLoading>
          <div class="w-100" style="background-color: #fff; height: 700px">
            <app-loader></app-loader>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <app-coaching-session-filter-modal
    *ngIf="displayFilterModal"
    (setFilters)="setFilters($event)"
    (modalClose)="closeFilterModal()"
    [initialFilters]="coachingSessionFilters"
    #coachingSessionFilterModal />
  <app-create-session-modal #createSessionParentModal />
</div>
