import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { ResourceAccessModel, ResourceDTO } from '../../../dtos/resources.dto';
import { ResourceService } from '../../../services/resources/resource.service';

@Component({
  selector: 'resource-access-rules',
  templateUrl: './resource-access-rules.component.html',
  styleUrls: ['./resource-access-rules.component.scss'],
})
export class ResourceAccessRulesComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() canManage: boolean;

  subs: Subscription[] = [];

  currentUser: UserDTO;

  currentResource: ResourceDTO | null;

  resourceAccessList: ResourceAccessModel[] | null = [];

  newAccessItem = {} as ResourceAccessModel;

  showAddAccessModal: boolean;

  accessScopeFor: string;

  constructor(private resourceService: ResourceService) {}

  ngOnInit() {
    this.subs.push(
      this.resourceService.resourceAccess.subscribe((accessList) => {
        this.resourceAccessList = accessList;
        this.newAccessItem.elearn_resource_id =
          this.resourceService.currentResourceId;

        if (this.resourceAccessList?.length) {
          if (
            this.resourceAccessList[0].access_rule === 'view' ||
            this.resourceAccessList[0].access_rule === 'edit' ||
            this.resourceAccessList.length > 1
          ) {
            this.resourceService.updateIsPublished(true);
          } else {
            this.resourceService.updateIsPublished(false);
          }
        }
      })
    );

    this.subs.push(
      this.resourceService.currentResource.subscribe((resource) => {
        this.currentResource = resource;
      })
    );

    this.getResourceAccess();
  }

  getResourceAccess() {
    this.resourceAccessList = [];
    this.resourceService.getResourceAccess();
  }

  ngOnChanges() {
    this.getResourceAccess();
  }

  startAddAccess(access_scope: string) {
    this.newAccessItem.access_scope = access_scope;
    this.newAccessItem.access_rule = 'view';
    this.showAddAccessModal = true;
  }

  hideAddAccess() {
    this.showAddAccessModal = false;
  }

  addAccess() {
    this.resourceService.addAccess(this.newAccessItem);
    this.showAddAccessModal = false;
    this.newAccessItem.access_scope = null;
    this.newAccessItem.access_scopes_id = null;
    this.newAccessItem.access_rule = 'view';
  }

  updateAccess(accessItem: ResourceAccessModel) {
    this.resourceService.updateAccess(accessItem);
  }

  removeAccess(accessItem: ResourceAccessModel) {
    this.resourceService.deleteAccess(accessItem);
  }

  setScopeId(selection: UserDTO | DistrictDTO | SchoolDTO | null) {
    if (selection?.id) {
      this.newAccessItem.access_scopes_id = selection.id;
    }
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
