import {
  Component,
  ViewChild,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { map } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { CoacheeListService } from '../../services/coachee-list/coachee-list.service';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-assign-coachee',
  templateUrl: './assign-coachee.component.html',
  styleUrls: ['./assign-coachee.component.scss'],
})
export class AssignCoacheeComponent implements OnInit {
  @Output() coacheeEvent = new EventEmitter<boolean>();

  @ViewChild('assignCoacheeModal')
  assignCoacheeModal: ModalComponent;

  user$ = select(UserState.getUser);

  currentUser: User | null;

  selectedUser: UserDTO | null;

  submitting = false;

  constructor(private coacheeListService: CoacheeListService) {}

  ngOnInit(): void {
    this.user$
      .pipe(
        map((user) => {
          this.currentUser = user;
        })
      )
      .subscribe();
  }

  cancel() {
    this.selectedUser = null;
    this.assignCoacheeModal.close();
  }

  chooseUser(user: UserDTO | null) {
    if (user) {
      this.selectedUser = user;
    }
  }

  onSubmit() {
    if (this.currentUser && this.selectedUser) {
      this.submitting = true;
      this.coacheeListService
        .assignCoachee(this.selectedUser.id, this.currentUser.id)
        .subscribe((res) => {
          if (res) {
            this.selectedUser = null;
            this.coacheeEvent.next(true);
            this.submitting = false;
            this.cancel();
          }
        });
    }
  }
}
