import { AUTH_GUARDED_ROUTE } from 'src/app/common/utilities/route.helpers';
import { PrivateRoute, PublicRoute } from 'src/app/common/types/routes';
import { LoginComponent } from '../pages/login/login.component';
import { PageNotFoundComponent } from '../pages/404/404.component';
import { ForgotPasswordComponent } from '../pages/forgot-password/forgot-password.component';
import { WelcomeComponent } from '../pages/welcome/welcome.component';
import { PrivacyPolicyComponent } from '../pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from '../pages/terms/terms.component';
import { DataSharingComponent } from '../pages/data-sharing/data-sharing.component';
import { CleverLoginComponent } from '../pages/clever-login/clever-login.component';

export const publicRoutes: Array<PublicRoute | PrivateRoute> = [
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'login',
    title: 'Login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    title: 'Forgot Password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'clever-auth',
    title: 'Clever Login',
    component: CleverLoginComponent,
  },
  {
    path: 'welcome',
    title: 'Welcome',
    component: WelcomeComponent,
  },
  {
    path: 'legal/privacy-policy',
    title: 'Privacy Policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'legal/terms',
    title: 'Terms',
    component: TermsComponent,
  },
  {
    path: 'legal/data-sharing',
    title: 'Data Sharing Agreement',
    component: DataSharingComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];
