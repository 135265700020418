import {
  EvidenceSummaryAttachmentResponse,
  PusherEvidenceAssessmentResponse,
} from 'src/app/common/types/responses/responses';
import {
  EvidenceAssessmentDTO,
  EvidenceAttachmentDTO,
  EvidenceDTO,
  EvidenceExtendedDTO,
  EvidenceType,
} from '../../dtos/attendee-rubric.dto';
import {
  CoacheeEvidenceDTO,
  CoacheeEvidenceWillDTO,
} from '../../dtos/coachee-evidence';
import { FileDTO } from '../../dtos/file.dto';
import { FileIcon } from '../../enums/file-icons.enum';
import {
  CreateEvidencePayload,
  EvidencePayload,
} from '../../types/payloads/evidence.payload';
import {
  CoacheeEvidenceAPIResponse,
  CoacheeEvidenceWillAPIResponse,
} from '../../types/responses/coachee-evidence';
import {
  EvidenceAPIResponse,
  EvidenceAssessmentAPIResponse,
  EvidenceAttachmentAPIResponse,
} from '../../types/responses/coaching-log.responses';
import { userDTOFromAPIResponse } from './user.translators';
import { videoDTOFromAPIResponse } from './video.translators';

export const evidencePayloadFromDTO = (
  evidence: EvidenceDTO,
  attachments?: FileDTO[]
): EvidencePayload => ({
  title: evidence.title,
  narrative: evidence.narrative,
  type: evidence.type.toString(),
  added_from: evidence.addedFrom,
  content:
    evidence.type === 'video' && attachments && attachments[0].videoData
      ? attachments[0].videoData.esuiteLink
      : '',
  user_id: evidence.userId,
  egrowe_coachlog_id: evidence.coachlogId,
  evidence_location_list: [
    {
      egrowe_rubric_id: evidence.rubricId,
      egrowe_standard_id: evidence.competencyId,
      egrowe_indicator_group_id: evidence.strandId,
      level: evidence.level,
      egrowe_coachlog_option_id: evidence.coachlogOptionId,
    },
  ],
});

export const createEvidencePayloadFromDTO = (
  evidence: EvidenceDTO,
  attachments?: FileDTO[]
): CreateEvidencePayload => ({
  ...evidencePayloadFromDTO(evidence, attachments),
  attachment_file_ids: attachments
    ? attachments.map((attachment) => attachment.id)
    : evidence.evidenceAttachments.map((attachment) => attachment.id),
});

export const evidenceAssessmentDTOFromAPIResponse = (
  response: EvidenceAssessmentAPIResponse
): EvidenceAssessmentDTO => ({
  id: response.id,
  evidenceId: response.egrowe_evidence_id,
  assessorUserId: response.assessor_user_id,
  title: response.title,
  content: response.content,
  approved: response.approved,
  createdAt: response.created_at,
  updatedAt: response.updated_at,
  assessor: response.assessor,
});

export const evidenceAttachmentDTOFromAPIResponse = (
  response: EvidenceAttachmentAPIResponse
): EvidenceAttachmentDTO => ({
  id: response.id,
  type: response.type,
  relationalId: response.relational_id,
  title: response.title,
  description: response.description,
  filePath: response.file_path,
  fileType: response.file_type,
  createdAt: response.created_at,
  updatedAt: response.updated_at,
  userId: response.user_id,
});

export const evidenceDTOFromAPIResponse = (
  response: EvidenceAPIResponse
): EvidenceExtendedDTO => ({
  id: response.id,
  title: response.title,
  content: response.content,
  type: response.type as EvidenceType,
  userId: response.user_id,
  level: response.level,
  districtId: response.district_id,
  createdAt: response.created_at,
  updatedAt: response.updated_at,
  rubricId: response.egrowe_rubric_id,
  competencyId: response.egrowe_standard_id,
  isDeleted: response.is_deleted === 1,
  narrative: response.narrative,
  strandId: response.egrowe_indicator_group_id,
  coachlogId: response.egrowe_coachlog_id,
  nearestCoachlogId: response.nearest_coachlog_id,
  coachlogOptionId: response.egrowe_coachlog_option_id,
  addedFrom: response.added_from,
  videos:
    response.videos && response.videos?.length > 0
      ? response.videos.map((video) => videoDTOFromAPIResponse(video))
      : [],
  evidenceAttachments: response.evidenceAttachments.map((evidenceAttachment) =>
    evidenceAttachmentDTOFromAPIResponse(evidenceAttachment)
  ),
  assessments: response.assessments.map((assessment) =>
    evidenceAssessmentDTOFromAPIResponse(assessment)
  ),
});

export const fileDTOFromEvidenceAttachment = (
  attachment: EvidenceAttachmentDTO
): FileDTO => ({
  id: attachment.id,
  name: attachment.title,
  iconClass: FileIcon[attachment.fileType as keyof typeof FileIcon],
  location: attachment.filePath,
});

export const fileDTOFromEvidenceSummaryAttachmentResponse = (
  attachment: EvidenceSummaryAttachmentResponse
): FileDTO => ({
  id: attachment.id,
  name: attachment.title,
  iconClass: FileIcon[attachment.file_type as keyof typeof FileIcon],
  location: attachment.file_path,
});

const coacheeEvidenceWillDTOFromAPIResponse = (
  item: CoacheeEvidenceWillAPIResponse
): CoacheeEvidenceWillDTO => ({
  egroweStandardId: item.egrowe_standard_id,
  egroweRubricId: item.egrowe_rubric_id,
  egroweIndicatorGroupId: item.egrowe_indicator_group_id,
  egroweCoachlogId: item.egrowe_coachlog_id,
  standard: item.standard,
  strand: item.strand,
  level: item.level,
  indicator: item.indicator,
  option: item.option,
  will: item.will,
  resources: item.resources,
  evidence: item.evidence.map((evidence) =>
    evidenceDTOFromAPIResponse(evidence)
  ),
  isGoal: item.is_goal,
  forSmartlog: item.forSmartlog,
  optionId: item.optionId,
});

const coacheeEvidenceDTOFromAPIResponse = (
  item: CoacheeEvidenceAPIResponse
): CoacheeEvidenceDTO => ({
  user: userDTOFromAPIResponse(item.user),
  lastLog: {
    id: item.lastlog.id,
    title: item.lastlog.title,
  },
  wills: item.wills.map((will) => coacheeEvidenceWillDTOFromAPIResponse(will)),
});

export const coacheeEvidenceListDTOFromAPIResponse = (response: {
  items: CoacheeEvidenceAPIResponse[];
}): CoacheeEvidenceDTO[] =>
  response.items.map((item) => coacheeEvidenceDTOFromAPIResponse(item));

export const evidenceAssessmentDTOFromPusherEvidenceAssessmentResponse = (
  response: PusherEvidenceAssessmentResponse
): EvidenceAssessmentDTO => ({
  id: response.id,
  evidenceId: response.egrowe_evidence_id,
  assessorUserId: response.assessorUserId,
  title: response.title,
  content: response.content,
  approved: Boolean(response.approved),
  createdAt: response.createdAt,
  updatedAt: response.updatedAt,
  assessor: response.assessor,
});
