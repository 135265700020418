import { Injectable } from '@angular/core';
import { Observable, Subject, debounceTime, map, switchMap } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import {
  UserDtoApiResponse,
  UserSearchResponse,
} from 'src/app/common/types/responses/responses';

@Injectable()
export class UserSearchService {
  items: Observable<UserDTO[]>;

  itemsFull: Observable<UserDtoApiResponse>;

  currentResponse: UserSearchResponse;

  private term: Subject<string> = new Subject<string>();

  private termFull: Subject<string> = new Subject<string>();

  per_page = 20;

  page = 1;

  district_id = 0;

  sort_by = '';

  sort_by_desc = false;

  district_ids: number[] = [];

  user_cohort_tag = '';

  constructor(private apiService: APICoreService) {
    this.items = this.term.pipe(
      debounceTime(250),

      switchMap((term: string) => this.findUsers(term, this.district_ids))
    );

    this.itemsFull = this.termFull.pipe(
      debounceTime(250),
      switchMap((term: string) =>
        this.getUsersFull(
          term,
          this.district_ids,
          this.user_cohort_tag,
          this.per_page,
          this.page,
          this.sort_by,
          this.sort_by_desc
        )
      )
    );
  }

  search(term: string, district_ids: number[] = []) {
    this.district_ids = district_ids;

    /**
     * Set the value which the search stream will work.
     */
    return this.term.next(term);
  }

  searchFull(
    term: string,
    district_ids: number[] = [],
    user_cohort_tag = '',
    per_page = 20,
    page = 1,
    sort_by = '',
    sort_by_desc = false
  ) {
    /**
     * Set the value which the search stream will work.
     */
    this.per_page = per_page;
    this.page = page;
    this.sort_by = sort_by;
    this.sort_by_desc = sort_by_desc;
    this.district_ids = district_ids;
    this.user_cohort_tag = user_cohort_tag;

    return this.termFull.next(term);
  }

  complete(): void {
    this.term.complete();
    this.termFull.complete();
  }

  public findUsers(
    search = '',
    district_ids: number[] = [],
    user_cohort_tag = '',
    per_page = 20,
    page = 1
  ): Observable<UserDTO[]> {
    const params = {
      search,
      district_ids,
      user_cohort_tag,
      page,
      per_page,
    };

    return this.apiService.getRequest('users', params).pipe(
      map((response) => {
        this.currentResponse = {
          users: response.items,
          _meta: response._meta,
          _links: response._links,
        };
        return response.items;
      })
    );
  }

  public getUsersFull(
    search = '',
    district_ids: number[] = [],
    user_cohort_tag = '',
    per_page = 20,
    page = 1,
    sort_by = '',
    sort_by_desc = false
  ): Observable<UserDtoApiResponse> {
    const params = {
      search,
      page,
      per_page,
      sort_by,
      sort_by_desc,
      district_ids,
      user_cohort_tag,
    };

    return this.apiService.getRequest('users', params).pipe(
      map((response: UserDtoApiResponse) => {
        this.currentResponse = {
          users: response.items,
          _meta: response._meta,
          _links: response._links,
        };
        return response;
      })
    );
  }

  getUser(userId: number) {
    return this.apiService
      .getRequest(`users/${userId}`)
      .pipe(map((res) => res.item));
  }
}
