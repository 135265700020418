import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { userDTOFromAPIResponse } from 'src/app/private/shared/helpers/translators/user.translators';
import { APICoreService } from '../../services/api-core/api-core.service';
import { SingleApiResponse } from '../../types/responses/responses';
import { CreateUserPayloadDTO, UserDTO } from './user.dto';

@Injectable({ providedIn: 'root' })
export class UserService {
  cachedUsers: UserDTO[] = [];

  constructor(private apiService: APICoreService) {}

  fetchUser(): Observable<SingleApiResponse | null> {
    return this.apiService.getRequest('user/me');
  }

  fetchUserById(userId: number): Observable<SingleApiResponse | null> {
    return this.apiService.getRequest(`users/${userId}`);
  }

  updateUserNote(coacheeId: number, notesObject: object): Observable<boolean> {
    return this.apiService.postRequest(
      `growelab/coachees/${coacheeId}/notes`,
      notesObject
    );
  }

  createUser(newUser: CreateUserPayloadDTO): Observable<UserDTO> {
    return this.apiService.postRequest('growelab/user', newUser).pipe(
      map((res) => userDTOFromAPIResponse(res.item)),
      catchError((err) => {
        throw err;
      })
    );
  }

  addUserToCache(userToAdd: UserDTO) {
    const index = this.cachedUsers.findIndex(
      (user) => user.id === userToAdd.id
    );
    if (index !== -1) {
      this.cachedUsers[index] = userToAdd;
    } else {
      this.cachedUsers.push(userToAdd);
    }
  }

  getCachedUser(userId: number) {
    return this.cachedUsers.find((user) => user.id === userId);
  }
}
