<div class="position-relative">
  <video
    #videoPlayer
    class="video-js vjs-big-play-centered vjs-show-big-play-button-on-pause w-100"
    controls
    playsinline
    preload="auto">
    <source
      #videoSource
      type="video/mp4"
      *ngIf="video"
      [src]="video.vimeoLink || getEsuiteVideoLink()" />
    <source
      type="video/ogg"
      *ngIf="video"
      [src]="video.vimeoLink || getEsuiteVideoLink()" />
    <source
      type="video/quicktime"
      *ngIf="video"
      [src]="video.vimeoLink || getEsuiteVideoLink()" />
    <source
      type="video/webm"
      *ngIf="video"
      [src]="video.vimeoLink || getEsuiteVideoLink()" />
    <p class="vjs-no-js">
      To view this video please enable JavaScript, and consider upgrading to a
      web browser that
      <a href="https://videojs.com/html5-video-support/" target="_blank"
        >supports HTML5 video</a
      >
    </p>
  </video>
</div>
