import { Component, Input } from '@angular/core';
import { StrandDTO } from 'src/app/private/shared/dtos/attendee-rubric.dto';

@Component({
  selector: 'app-leveled-competency-strand',
  templateUrl: './leveled-competency-strand.component.html',
  styleUrls: ['./leveled-competency-strand.component.scss'],
})
export class LeveledCompetencyStrandComponent {
  @Input() competencyId: number;

  @Input() strand: StrandDTO;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() logId: number;

  @Input() level1name = 'Conventional Culture';

  @Input() level2name = 'Collaborative Culture';

  @Input() level3name = 'Synergistic Culture';

  @Input() level4name = 'Innovative Culture';

  public isCollapsed = false;

  goalLevel = 0;
}
