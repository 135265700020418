<div class="container">
  <div class="d-flex justify-content-between mb-3">
    <h1>Coaching Sessions</h1>
    <div class="align-self-center"></div>
  </div>
  <div class="card border-0">
    <div class="card-body p-3">
      <h2>Summary</h2>
      <ng-container *ngIf="sessionData; else loadingTemplate">
        <ng-container *ngIf="hasPresentAttendee; else noAttendeeTemplate">
          <div class="row mb-3">
            <div class="col-6">
              <a
                href="javascript:void(0)"
                (click)="navigatePreviousScreen()"
                class="text-decoration-none text-muted">
                < Back to Observation Form
              </a>
            </div>
            <div class="col-6 text-end">
              <div *ngIf="sessionData && sessionData.attendees.length > 1">
                <label class="mb-2" [id]="'attendee' + sessionData.id"
                  >Attendee:</label
                >
                <ng-select
                  [labelForId]="'attendee' + sessionData.id"
                  [items]="sessionData.attendees"
                  [searchable]="false"
                  [clearable]="false"
                  bindLabel="fullName"
                  placeholder="Choose attendee"
                  (ngModelChange)="attendeeSelected($event)"
                  [(ngModel)]="selectedAttendee">
                </ng-select>
              </div>
            </div>
          </div>
          <ng-container *ngIf="selectedForm">
            <app-form-view
              #formView
              [showTitle]="false"
              [submissionStatus]="SubmissionStatuses.SUBMITTED"
              [submissionData]="currentSubmission"
              [inputDisabled]="true"
              [form]="selectedForm"></app-form-view>
          </ng-container>
        </ng-container>
        <ng-template #noAttendeeTemplate>
          <p>
            <ngb-alert [dismissible]="false">
              <strong>No Present Attendees</strong> At least 1 attendee must be
              marked present.
              <a
                routerLink="/coaching/log/{{ sessionId }}/info"
                class="text-decoration-none text-muted"
                ><br />
                &lt; back
              </a>
            </ngb-alert>
          </p>
        </ng-template>
      </ng-container>
      <ng-template #loadingTemplate>
        <app-loader></app-loader>
      </ng-template>
    </div>
  </div>
</div>
