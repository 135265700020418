import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthService } from 'src/app/common/services/auth/auth.service';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { SidebarRoute } from 'src/app/common/types/routes';
import {
  canAccessByRole,
  checkRouteEnvironment,
} from 'src/app/common/utilities/check-route';
import { sidebarRoutes } from '../../routes/sidebar.routes';
import { ImpersonateModalComponent } from '../../shared/components/modals/impersonate-modal/impersonate.modal.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Output() linkClicked: EventEmitter<unknown> = new EventEmitter();

  @ViewChild('impersonateModal') impersonateModal: ImpersonateModalComponent;

  isImpersonatingUser = false;

  Roles = UserRole;

  user: User | null = null;

  sidebarItems = sidebarRoutes;

  constructor(private store: Store, private authService: AuthService) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
    this.isImpersonatingUser = this.store.selectSnapshot(
      (state) => state.user.impersonated
    );
  }

  linkSelected() {
    this.linkClicked.emit();
  }

  unimpersonate() {
    this.authService.stopImpersonatingUser();
  }

  shouldShowRoute = (route: SidebarRoute): boolean => {
    if (checkRouteEnvironment(route.path || '') === false) {
      return false;
    }
    return canAccessByRole(route, this.user);
  };

  logout() {
    this.authService.logout();
  }
}
