<app-modal
  #createSessionModal
  (isDismissing)="closeModal()"
  [modalConfig]="{
    titleText: 'New Session',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div>
    <label class="form-label mt-0" for="coachlog-type">Log Type</label>
    <app-log-type-select
      id="coachlog-type"
      [clearAfterSelection]="false"
      [chosenLogType]="coachlogItemsPayload.logType"
      [fieldInvalid]="formSubmitted && !coachlogItemsPayload.logType"
      (selectedChosenLogType)="updateSessionLogType($event)" />
  </div>
  <ng-container *ngIf="coachlogItemsPayload.logType">
    <div>
      <label class="form-label" for="coachlog-title">Title</label>
      <input
        type="text"
        class="form-control"
        id="coachlog-title"
        aria-label="Log Title"
        placeholder="Log Title"
        [(ngModel)]="coachlogItemsPayload.title"
        [style.border]="
          formSubmitted && !coachlogItemsPayload.title ? '1px solid red' : ''
        "
        [style.display]="'inline-block'" />
    </div>
    <div>
      <label class="form-label" for="session-datetime">Date & Time</label>
      <app-datepicker
        id="session-datetime"
        (dateSelectedUnixTimestamp)="updateDateTime($event)"
        [initialDateUnixTimestamp]="currentDateTime"
        [timepicker]="true" />
    </div>
    <div>
      <label class="form-label" for="session-duration">Duration</label>
      <ng-select
        [labelForId]="'session-duration'"
        [(ngModel)]="duration"
        [items]="availableDurations"
        (change)="setDuration($event)"
        [clearable]="false"
        [searchable]="false"
        bindLabel="display"
        bindValue="value">
      </ng-select>
    </div>
    <div class="container px-0 mt-1">
      <div class="row">
        <div class="col-3 form-label mt-1">Coachee(s):</div>
        <div class="col py-0 mt-0">
          <ng-container *ngFor="let attendee of attendeeUserProfiles">
            <app-new-coaching-attendee
              [user]="attendee"
              (removedAttendee)="deleteAttendee(attendee)" />
          </ng-container>
          <ng-container
            *ngIf="
              coachlogItemsPayload.logType.name !==
                'Observation and Feedback' ||
              (coachlogItemsPayload.logType.name ===
                'Observation and Feedback' &&
                attendeeUserProfiles &&
                attendeeUserProfiles.length < 1)
            ">
            <div style="margin-top: 0.5rem">Add coachee</div>
            <app-user-select
              [clearAfterSelection]="true"
              (selectedUser)="addSessionCoachee($event)" />
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-3 form-label mt-1">Shadower(s):</div>
        <div class="col">
          <ng-container *ngFor="let shadower of coachlogItemsPayload.shadowers">
            <app-new-coaching-shadower
              [user]="shadower"
              (removedShadower)="deleteShadower(shadower)" />
          </ng-container>
          <div class="fs-6" style="margin-top: 0.5rem">Add shadower</div>
          <app-user-select
            [clearAfterSelection]="true"
            (selectedUser)="addSessionShadower($event)" />
        </div>
      </div>
    </div>
  </ng-container>
  <div footerContent>
    <ng-container *ngIf="serverError.length > 0">
      <ngb-alert [dismissible]="false" [type]="'danger'">
        <strong>Error</strong> {{ serverError }}
      </ngb-alert>
    </ng-container>
    <button
      [disabled]="isLoading"
      type="button"
      class="btn btn-md btn-primary"
      (click)="createCoachingLog()">
      <ng-container *ngIf="isLoading">
        <div class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </ng-container>
      Schedule
    </button>
  </div>
</app-modal>
