import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CohortDTO } from 'src/app/common/dtos/cohort.dto';
import { Subject } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { UserDtoApiResponse } from 'src/app/common/types/responses/responses';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { Grade } from 'src/app/common/dtos/grade.dto';
import { UserSearchService } from '../../services/user-search/user-search.service';
import {
  SortEvent,
  SortableHeader,
} from '../../directives/sortable-header.directive';
import { CohortService } from '../../services/cohort/cohort.service';

@Component({
  selector: 'app-cohort-users',
  templateUrl: './cohort-users.component.html',
  styleUrls: ['./cohort-users.component.scss'],
})
export class CohortUsersComponent implements OnInit {
  @Input() cohort: CohortDTO | null;

  @Input() district: DistrictDTO;

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  searchTermUpdate = new Subject<string>();

  isLoading = true;

  loadingIcons = true;

  districtCode: string;

  userList: UserDTO[];

  usersToRemove: number[] = [];

  searchTerm = '';

  numPerPage = 20;

  listMeta: PaginationDTO;

  response: UserDtoApiResponse;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortIconTemp: any = {
    name: 'gray',
    email: 'gray',
    school: 'gray',
    cohort: 'gray',
  };

  sortBy = '';

  sortByDesc = false;

  column = '';

  direction = '';

  loading = false;

  constructor(
    private userSearchService: UserSearchService,
    private cohortService: CohortService
  ) {}

  ngOnInit(): void {
    if (this.district && this.cohort) {
      this.userSearchService.itemsFull.subscribe((res) => {
        this.userList = res.items;
        this.userList = this.userList.map((item) => {
          const item2 = item;
          item2.isSelected = false;
          const grades: Grade[] = [
            {
              id: 1,
              title: '1st',
            },
            {
              id: 2,
              title: '2nd',
            },
          ];
          item2.grades = grades;
          return item2;
        });
        this.listMeta = res._meta;
        this.isLoading = false;
        setTimeout(() => {
          this.updateColumns();
        }, 350);
      });
      this.getUsers('', 1, this.cohort.tag);
    }
  }

  getUsers(term = '', currentPage = 1, userCohortTag = '') {
    const districtIds: number[] = [];
    if (this.district) {
      districtIds.push(this.district.id);
    }

    this.userSearchService.searchFull(
      term,
      districtIds,
      userCohortTag,
      this.numPerPage,
      currentPage,
      this.sortBy,
      this.sortByDesc
    );
  }

  updateSelectedUsers() {
    this.usersToRemove = [];
    this.userList.forEach((user) => {
      if (user.isSelected) {
        this.usersToRemove.push(user.id);
      }
    });
  }

  removeUsersFromCohort() {
    if (this.userList && this.cohort) {
      if (this.usersToRemove.length) {
        this.cohortService
          .removeUsersFromCohort(this.cohort.tag, this.usersToRemove)
          .subscribe(() => {
            this.getUsers('', 1, this.cohort?.tag);
          });
      }
    }
  }

  addUserToCohort(users: UserDTO[] | null) {
    if (users?.length && this.cohort) {
      const usersToAdd: number[] = [];
      users.forEach((item) => {
        usersToAdd.push(item.id);
      });
      if (usersToAdd.length) {
        this.cohortService
          .addUsersToCohort(this.cohort?.tag, usersToAdd)
          .subscribe(() => {
            this.getUsers('', 1, this.cohort?.tag);
          });
      }
    }
  }

  onSort({ column, direction }: SortEvent) {
    this.sortBy = column;
    this.sortByDesc = false;
    if (direction === 'asc') {
      this.sortByDesc = true;
    }

    this.column = column;
    this.direction = direction;

    this.loadingIcons = true;
    this.getUsers('', 1, this.cohort?.tag);
  }

  updateColumns() {
    this.headers.forEach((header) => {
      if (header.sortable !== this.column) {
        header.direction = '';
        this.sortIconTemp[header.sortable] = 'gray';
      } else {
        this.sortIconTemp[header.sortable] = 'none';
      }
    });

    if (this.direction === '' || this.column === '') {
      this.sortIconTemp[this.column] = 'gray';
    }
    this.loadingIcons = false;
  }

  paginationChange() {
    this.getUsers('', this.listMeta.currentPage, this.cohort?.tag);
  }
}
