<app-modal
  #modal
  [modalConfig]="{
          headerClass: ['modal-header'],
          customFooter: true,
          options: { size: 'xl' },
        }"
  (isDismissing)="resetVideo()">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="modal.dismiss()">
    <i class="bi bi-x-lg"></i>
  </button>
  <app-video-player
    [isPlaying]="isPlaying"
    [shouldRestart]="shouldRestart"
    [video]="video"></app-video-player>
  <div class="d-flex justify-content-between align-items-center mt-3">
    <p class="h3 fs-3">{{ video.title }}</p>
    <app-video-menu-actions
      [videoId]="video.id.toString()"></app-video-menu-actions>
  </div>
  <p class="text-muted">
    {{ video.viewsCount }} views ·
    {{ video.createdAt | formatDistanceToNow }} ago
  </p>
  <p>{{ video.description }}</p>
  <div *ngIf="video.user" class="d-flex mt-2 mb-0">
    <app-avatar [profile]="video.user.profile" [avatarSize]="'small'" />
    <p class="ms-2">
      {{ video.user.profile.first_name }}
      {{ video.user.profile.last_name }}
    </p>
  </div>
</app-modal>
