<label class="visually-hidden" [id]="modalLabelId"
  >{{ modalLabel
  }}<label>
    <ng-template #modal>
      <div [ngClass]="config.headerClass">
        <h5 class="modal-title">{{ config.titleText }}</h5>
        <ng-content select="[headerContent]"></ng-content>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <ng-container *ngIf="config.customFooter">
          <ng-content select="[footerContent]"></ng-content>
        </ng-container>
        <ng-container *ngIf="!config.customFooter">
          <button
            type="button"
            [ngClass]="config.closeButton.classes"
            (click)="close()"
            *ngIf="!config.hideCloseButton">
            {{ config.closeButton.label }}
          </button>
        </ng-container>
      </div>
    </ng-template>
  </label></label
>
