import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, map } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { AvailableTagsResponse } from 'src/app/common/types/responses/responses';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { TagDTO } from '../../../dtos/tags.dto';
import { ResourceService } from '../../../services/resources/resource.service';
import { ModalComponent } from '../../modals/modal/modal.component';

@Component({
  selector: 'app-resource-create-modal',
  templateUrl: './resource-create-modal.component.html',
  styleUrls: ['./resource-create-modal.component.scss'],
})
export class ResourceCreateModalComponent implements OnInit {
  @ViewChild('createResourceModal')
  createResourceModal: ModalComponent;

  createResourceForm: FormGroup;

  user$ = select(UserState.getUser);

  user: User | null;

  submitted = false;

  subs: Subscription[] = [];

  currentUser: UserDTO;

  canCreate = false;

  tagsLoading = false;

  availTags: string[];

  constructor(
    private resourceService: ResourceService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.user$
      .pipe(
        map((user) => {
          this.user = user;
          if (this.user) {
            this.user.roles.forEach((role) => {
              if (role === 'ELEARN_RESOURCE_CREATOR') {
                this.canCreate = true;
              }
            });
          }
        })
      )
      .subscribe();

    this.getAvailableTags();

    this.buildForm();
  }

  closeModal() {
    this.createResourceModal.close();
  }

  openModal() {
    this.createResourceModal.open();
  }

  buildForm() {
    this.createResourceForm = this.formBuilder.group({
      type: ['research', Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      tags: [[]],
    });
  }

  getAvailableTags() {
    this.tagsLoading = true;
    this.resourceService
      .getAvailTags()
      .subscribe((response: AvailableTagsResponse) => {
        this.availTags = response.items.map((tag: TagDTO) => tag.tag);
        this.tagsLoading = false;
      });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.createResourceForm.invalid) {
      this.createResource();
    }
  }

  cancel() {
    this.resetForm();
    this.closeModal();
  }

  resetForm() {
    this.submitted = false;

    this.createResourceForm.reset();
    this.createResourceForm.controls['type'].setValue('research');
  }

  createResource() {
    this.resourceService
      .createResource(this.createResourceForm.value)
      .subscribe((res) => {
        this.resetForm();
        this.closeModal();
        window.open(`/resources/${res.item.slug}`, '_blank');
      });
  }
}
