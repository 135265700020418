import { Component, Input } from '@angular/core';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { BadgeDataDTO } from '../../dtos/badge.dto';

@Component({
  selector: 'app-badge-graphic',
  templateUrl: './badge-graphic.component.html',
  styleUrls: ['./badge-graphic.component.scss'],
})
export class BadgeGraphicComponent {
  @Input() badge: BadgeDataDTO;

  @Input() size: 'xs' | 'sm' | 'lg' = 'lg';

  apiUrl: string;

  constructor() {
    this.apiUrl = EnvironmentService.apiUrl();
  }
}
