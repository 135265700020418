import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { TagSearchService } from '../../../services/tags/tags.service';
import { TagDTO } from '../../../dtos/tags.dto';

@Component({
  selector: 'app-select-tags',
  templateUrl: './select-tags.component.html',
  styleUrls: ['./select-tags.component.scss'],
})
export class SelectTagsComponent implements OnInit {
  tagList$: Observable<TagDTO[]>;

  isLoading = false;

  searchInput$ = new Subject<string>();

  selectedTag: TagDTO | null = null;

  labelId = uuidv4();

  @Input() placeholder = '';

  @Input() clearAfterSelect = false;

  @Input() label = 'Search Tags';

  @Output() afterSelection: EventEmitter<TagDTO> = new EventEmitter<TagDTO>();

  trackByFn = SelectTagsComponent.trackByFn;

  constructor(private tagService: TagSearchService) {}

  ngOnInit() {
    this.loadTags();
  }

  public static trackByFn(item: TagDTO) {
    return item.id;
  }

  private loadTags() {
    this.tagList$ = concat(
      of([]), // default items
      this.searchInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => {
          this.isLoading = true;
        }),
        switchMap((term) =>
          this.tagService.performSearch(term).pipe(
            catchError(() => of([])), // empty list on error
            tap((results) => {
              this.isLoading = false;
              return results;
            })
          )
        )
      )
    );
  }

  changedSelection(selectedTag: TagDTO) {
    this.afterSelection.emit(selectedTag);
    if (this.clearAfterSelect) {
      setTimeout(() => {
        this.selectedTag = null;
      });
    }
  }
}
