import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { CoachingLogResourceService } from '../../../services/coaching-log/coaching-log-resource.service';
import { ResourceLeveledListItemDTO } from '../../../dtos/resources.dto';

@Component({
  selector: 'app-resource-leveled',
  templateUrl: './resource-leveled.component.html',
  styleUrls: ['./resource-leveled.component.scss'],
})
export class ResourceLeveledComponent implements OnInit {
  @Input() resource: ResourceLeveledListItemDTO;

  @Input() level: number;

  @Input() limitDescriptionLength = true;

  @Input() showRemovalIcon = false;

  @Output() signalSelection: EventEmitter<number> = new EventEmitter();

  description: string;

  constructor(private coachlogResourceService: CoachingLogResourceService) {}

  ngOnInit() {
    this.description = this.limitDescriptionLength
      ? `${this.resource.description.substring(0, 70)} ...`
      : this.resource.description;
  }

  addItem() {
    this.coachlogResourceService.addLeveledResource(this.resource);
    this.signalSelection.next(this.resource.id);
  }
}
