import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { FileUploadResponse } from 'src/app/common/types/responses/responses';

import { FileDTO } from '../../dtos/file.dto';
import { FileIcon } from '../../enums/file-icons.enum';

export const fileUploadToFileDTO = (
  fileUpload: FileUploadResponse
): FileDTO => ({
  id: fileUpload.item.id,
  name: fileUpload.item.title,
  iconClass: FileIcon[fileUpload.item.file_type as keyof typeof FileIcon],
  location: fileUpload.location || fileUpload.item.file_path,
});

export const videoDTOToFileDTO = (video: VideoDTO): FileDTO => ({
  id: video.id,
  name: video.title || '',
  iconClass: FileIcon.video,
  location: video.vimeoLink || video.esuiteLink,
  videoData: video,
});
