import { Component, OnInit, ViewChild } from '@angular/core';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { ThoughtsService } from '../../../services/thoughts/thoughts.service';
import {
  CreateThoughtDTO,
  ThoughtDTO,
} from '../../../state/thought/Thought.dto';
import { Thought } from '../../../state/thought/Thought.model';
import {
  ModalComponent,
  ModalConfig,
} from '../../modals/modal/modal.component';
import {
  WysiwygEditorComponent,
  WysiwygEditorConfig,
} from '../../wysiwyg-editor/wysiwyg-editor.component';

@Component({
  selector: 'app-card-ideas',
  templateUrl: './card-ideas.component.html',
  styleUrls: ['./card-ideas.component.scss'],
})
export class CardIdeasComponent implements OnInit {
  isCollapsed = this.cardStorageService.getCollapsedState('thoughtsideas');

  isLoaded = false;

  isEmpty = true;

  thoughts: Thought[] = [];

  @ViewChild('ideasModal') public ideasModal: ModalComponent;

  @ViewChild('wysiwyg') public editor: WysiwygEditorComponent;

  constructor(
    private cardStorageService: CardStorageService,
    private thoughtsService: ThoughtsService
  ) {}

  editorConfig: WysiwygEditorConfig = {
    initialContent: '',
    editId: '',
  };

  modalConfig: ModalConfig = {
    customFooter: true,
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    windowClass: 'card-ideas-modal',
  };

  ngOnInit(): void {
    /*
    Subscribing to the observable updates the local thoughts array when the thoughts$
    observable emits a new value. You can also just use thoughts on the frontend
    without the async pipe
    */
    this.isLoaded = this.thoughtsService.thoughtsLoaded;
    this.thoughtsService.loadThoughts();
    this.thoughtsService.thoughtsListOutput.subscribe((thoughtsList) => {
      this.thoughts = thoughtsList;
      this.isLoaded = true;
    });
  }

  checkIfEmpty() {
    if (this.editor) {
      if (this.editor.editorContent === '') {
        this.isEmpty = true;
        return true;
      }
      this.isEmpty = false;
      return false;
    }
    this.isEmpty = true;
    return true;
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'thoughtsideas',
      this.isCollapsed
    );
  }

  deleteThought(thoughtId: string) {
    this.thoughtsService.removeThought(thoughtId);
  }

  saveThought() {
    if (this.editorConfig.editId) {
      const thought: ThoughtDTO = {
        id: this.editorConfig.editId,
        content: this.editor.editorContent,
      };
      this.thoughtsService.updateThought(thought);
    } else if (this.editor.editorContent) {
      const thought: CreateThoughtDTO = {
        content: this.editor.editorContent,
      };
      this.thoughtsService.addThought(thought);
    }
  }

  editThought(id: string) {
    const thought = this.thoughts.find((t) => t.id === id);
    if (thought) {
      this.editor.editorContent = thought.content;
      this.editorConfig.editId = id;
      this.ideasModal.config.titleText = 'Edit Thought';
      this.ideasModal.open();
    }
  }

  newThought() {
    this.ideasModal.config.titleText = 'Add Thought';
    this.editor.editorContent = '';
    this.editorConfig.editId = '';
    this.ideasModal.open();
  }
}
