import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-video-menu-actions',
  templateUrl: './video-menu-actions.component.html',
  styleUrls: ['./video-menu-actions.component.scss'],
})
export class VideoMenuActionsComponent {
  @Input() videoId: string;

  @Input() rectangularVariation: boolean;

  iseSuiteAdmin: boolean;

  text: string;

  types: any = [
    {
      type: 'thumbnail',
      text: 'Copy thumbnail embed link',
    },
    {
      type: 'mobile',
      text: 'Copy mobile embed link',
    },
    {
      type: 'video-only',
      text: 'Copy video embed link',
    },
  ];

  constructor(private clipboard: Clipboard) {}

  shareVideo() {
    const text = `${window.location.protocol}//${window.location.host}/resources/video-library?video=${this.videoId}`;
    this.clipboard.copy(text);
  }

  copyEmbedCode(type: 'mobile' | 'video-only' | 'thumbnail') {
    this.rectangularVariation = false;
    let smallSize = 0;
    let width = 366;
    let height = 364;

    if (type === 'mobile') {
      this.rectangularVariation = true;
      smallSize = 1;
      width = 174;
      height = width * 0.51;
      width *= 2;
    }

    this.text = `
        <iframe src="${
          'https://esuite.engage2learn.org/' +
          `esuite-video/${
            this.videoId
          }?smallVariation=${smallSize}&rectangularVariation=${Number(
            this.rectangularVariation
          )}&type=${type}&embedded=${1}&buttonType=filled`
        }"
        style="border:none;overflow:hidden;width:${`${
          width + 3
        }px`};height:${`${height}px`}"
      ></iframe>`;

    if (type === 'video-only') {
      this.text = `<iframe
          class="video-thumbnail-embedded"
          src="${
            'https://esuite.engage2learn.org/' +
            `esuite-video/${this.videoId}?type=video-only` +
            `&embedded=${1}`
          }"
          style="max-width:1280px;width:100%;min-height:100px;height:717px;border:none;overflow:hidden;"
        ></iframe>`;
    }

    this.clipboard.copy(this.text);
  }
}
