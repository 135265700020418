import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { ResourceFiltersDTO } from '../../shared/dtos/resources.dto';
import { ResourcesSearchService } from '../../shared/services/resources/resources-search.service';
import { defaultSearchFilterFreeze } from '../../shared/services/resources/resource.helpers';

@Component({
  selector: 'app-resources-page',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit {
  counts = 0;

  searchFilters: ResourceFiltersDTO | null = null;

  constructor(
    private router: Router,
    private apicoreService: APICoreService,
    private resourceService: ResourcesSearchService
  ) {}

  ngOnInit() {
    this.getCounts();
  }

  onSearchFieldKeydown(event: { key: string }, searchTerm: string) {
    if (event.key === 'Enter') {
      this.redirectSearch(searchTerm);
    }
  }

  redirectSearch(searchTerm: string) {
    this.resourceService.setFilters({
      ...defaultSearchFilterFreeze(),
      search: searchTerm,
    });
    this.router.navigate(['resources/search']);
  }

  getCounts() {
    this.apicoreService
      .getRequest(`public-stats/eplc-resources`)
      .subscribe((results) => {
        this.counts = results.total_resources;
      });
  }
}
