<div class="card border-0">
  <ng-template [ngIf]="!isLoading" [ngIfElse]="placeHolder">
    <div class="card-header pt-3">
      <h2 class="card-title float-start" (click)="triggerCollapse()">
        Growth Snapshot
        <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
      </h2>
      <div class="card-tools float-end">
        <!--<a>
          See All
          <i class="ps-1 bi bi-box-arrow-up-right"></i>
        </a>-->
      </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <ng-container *ngIf="user$ | async as user">
        <ng-container
          *ngFor="let lastCompetency of last5 | slice : 0 : 3; let i = index">
          <div class="card mb-2 mx-3">
            <app-competencies-summary
              [isSelectable]="false"
              [competencyId]="lastCompetency"
              [userId]="user.id"></app-competencies-summary>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
  <ng-template #placeHolder>
    <div class="card-body">
      <span class="card-title placeholder-glow">
        <span class="placeholder col-6"></span>
      </span>
      <p class="card-text placeholder-glow">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-8"></span>
      </p>
    </div>
  </ng-template>
</div>
