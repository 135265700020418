import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { LogTypeSelectDto } from 'src/app/private/shared/components/log-type-select/log-type-select.dto';
import { UserDTO } from '../state/user/user.dto';
import { dateTimeFromNgbDate } from '../utilities/date-helpers';
import { CoachlogSearchPayloadItemsDTO } from './coachlog-search-payload.dto';
import { CompetencyDTO } from './competency.dto';
import { SchoolDTO } from './school.dto';

export interface CoachingSessionFilterDTO {
  coacheeList: UserDTO[] | null;
  coachList: UserDTO[] | null;
  schoolList: SchoolDTO[] | null;
  competencyList: CompetencyDTO[] | null;
  logType: LogTypeSelectDto | null;
  fromDate: NgbDate;
  toDate: NgbDate;
}

export const setDefaultCoachingSessionFilterDateRange = (): {
  toDate: NgbDate;
  fromDate: NgbDate;
} => {
  const toDate = DateTime.now().set({ hour: 23, minute: 59, second: 59 });
  const fromDate = toDate.minus({ days: 7 });
  return {
    toDate: new NgbDate(toDate.year, toDate.month, toDate.day),
    fromDate: new NgbDate(fromDate.year, fromDate.month, fromDate.day),
  };
};

export const emptyCoachingSessionFilters = (): CoachingSessionFilterDTO => {
  const date = setDefaultCoachingSessionFilterDateRange();
  return {
    coacheeList: null,
    coachList: null,
    schoolList: null,
    competencyList: null,
    logType: null,
    fromDate: date.fromDate,
    toDate: date.toDate,
  };
};

export const coachingSessionFilterDTOToCoachlogSearchPayloadItemsDTO = (
  filters: CoachingSessionFilterDTO
): CoachlogSearchPayloadItemsDTO => ({
  keywords: '',
  coach_items: filters.coachList ?? [],
  school_items: filters.schoolList ?? [],
  competency_items: filters.competencyList ?? [],
  logtype_item: filters.logType ?? undefined,
  from_date: dateTimeFromNgbDate(filters.fromDate).toSeconds(),
  to_date: dateTimeFromNgbDate(filters.toDate)
    .set({ hour: 23, minute: 59, second: 59 })
    .toSeconds(),
  coachee_items: filters.coacheeList ?? [],
  per_page: 1000,
  sort_order: 'desc',
  ownonly: 1,
});
