import { UploadType } from '../../enums/upload-type.enum';
import { EvidenceFileService } from './evidence.file.service';
import { FileService } from './file.service';
import { VideoFileService } from './video.file.service';
import { LogFileService } from './log.file.service';

export const FileServiceFactory: {
  [key in UploadType]: typeof FileService;
} = {
  [UploadType.USER_UPLOAD]: FileService,
  // This will need to be updated when resources are dealt with
  [UploadType.RESOURCE_ATTACHMENT]: FileService,
  [UploadType.EVIDENCE]: EvidenceFileService,
  [UploadType.VIDEO]: VideoFileService,
  [UploadType.LOG]: LogFileService,
};
