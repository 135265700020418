/* eslint-disable max-classes-per-file */
import {
  EvidencePusherResponse,
  PusherAttachmentsResponse,
  PusherEvidenceAssessmentResponse,
} from 'src/app/common/types/responses/responses';

import {
  AssessmentDTO,
  CompetencyListItemDTO,
  EvidenceAttachmentDTO,
  EvidenceDTO,
  GoalDTO,
  OptionDTO,
  WillDTO,
} from '../../dtos/attendee-rubric.dto';
import { CoachlogPusherMessageDTO } from '../../dtos/coachlog-pusher-message.dto';
import { ResourceListItemDTO } from '../../dtos/resources.dto';
import {
  IndicatorAssessmentPayload,
  PusherCompetencyAdded,
  PusherCompetencyDeleted,
} from '../../services/coaching-log/coaching-log-service.dto';
import {
  CoachingSessionAttendeesAPIResponse,
  CoachingSessionShadowerAPIResponse,
} from '../../types/responses/coaching-log.responses';

export class FetchSessionData {
  static readonly type = '[Coach Log] Get Session Data';

  constructor(public payload: number) {}
}

export class FetchAttendeeRubrics {
  static readonly type = '[Coach Log] Get Attendee Rubrics';

  constructor(public payload: number) {}
}

export class UpdateGoal {
  static readonly type = '[Coach Log] Set Goal';

  constructor(public payload: GoalDTO) {}
}

export class AddOption {
  static readonly type = '[Coach Log] Add Option';

  constructor(public payload: OptionDTO) {}
}

export class RemoveOption {
  static readonly type = '[Coach Log] Remove Option';

  constructor(public payload: number) {}
}

export class UpdateOption {
  static readonly type = '[Coach Log] Update Option';

  constructor(public payload: OptionDTO) {}
}

export class AddResourceToOption {
  static readonly type = '[Coach Log] Add Resource to Option';

  constructor(
    public payload: { optionId: number; resource: ResourceListItemDTO }
  ) {}
}

export class RemoveResourceFromOption {
  static readonly type = '[Coach Log] Remove Resource from Option';

  constructor(public payload: { optionId: number; resourceId: number }) {}
}

export class AddWill {
  static readonly type = '[Coach Log] Add Will';

  constructor(public payload: WillDTO) {}
}

export class RemoveWill {
  static readonly type = '[Coach Log] Remove Will';

  constructor(public payload: { willId: number; userId: number }) {}
}

export class UpdateWill {
  static readonly type = '[Coach Log] Update Will';

  constructor(public payload: WillDTO) {}
}

export class CreateIndicatorAssessment {
  static readonly type = '[Coach Log] Create Indicator Assessment';

  constructor(public payload: IndicatorAssessmentPayload) {}
}

export class RemoveIndicatorAssessment {
  static readonly type = '[Coach Log] Removes Indicator Assessment';

  constructor(public assessmentId: number) {}
}

export class AddIndicatorAssessment {
  static readonly type = '[Coach Log] Adds an Indicator Assessment';

  constructor(public payload: AssessmentDTO) {}
}

export class RemoveCompetency {
  static readonly type = '[Coach Log] Removes Competency';

  constructor(public payload: PusherCompetencyDeleted) {}
}

export class AddCompetencies {
  static readonly type = '[Coach Log] Adds Available Competencies';

  constructor(public payload: [CompetencyListItemDTO[], boolean]) {}
}

export class UpdateCompetency {
  static readonly type = '[Coach Log] Update Competency Selection';

  constructor(public payload: PusherCompetencyAdded) {}
}
export class UpdateLog {
  static readonly type = '[Coach Log] Update Log';

  constructor(public payload: CoachlogPusherMessageDTO) {}
}

export class DeleteAttendee {
  static readonly type = '[Coach Log] Remove Attendee';

  constructor(public payload: { id: number; coachlogId: number }) {}
}

export class UpdateAttendee {
  static readonly type = '[Coach Log] Update Attendee';

  constructor(public payload: CoachingSessionAttendeesAPIResponse) {}
}

export class UpdateShadower {
  static readonly type = '[Coach Log] Update Shadower';

  constructor(public payload: CoachingSessionShadowerAPIResponse) {}
}

export class DeleteShadower {
  static readonly type = '[Coach Log] Remove Shadower';

  constructor(public payload: { id: number; egrowe_coachlog_id: number }) {}
}

export class ResetLogDefaults {
  static readonly type = '[Coach Log] Reset Defaults';
}
export class UpdateNotes {
  static readonly type = '[Coach Log] Update Notes';

  constructor(public payload: { notes: string; coachlogId: number }) {}
}

export class AddEvidence {
  static readonly type = '[Coach Log] Add Evidence';

  constructor(public payload: EvidenceDTO) {}
}

export class DeleteEvidence {
  static readonly type = '[Coach Log] Delete Evidence';

  constructor(public payload: EvidencePusherResponse) {}
}

export class UpdateEvidence {
  static readonly type = '[Coach Log] Update Evidence';

  constructor(public payload: EvidenceDTO) {}
}

export class UpdateAssesment {
  static readonly type = '[Coach Log] Update Assessment';

  constructor(public payload: PusherEvidenceAssessmentResponse) {}
}

export class DeleteLogAttachments {
  static readonly type = '[Coach Log] Update Log Attachments';

  constructor(
    public payload: { message: PusherAttachmentsResponse; coachlogId: number }
  ) {}
}

export class AddLogAttachments {
  static readonly type = '[Coach Log] Update Log Attachments';

  constructor(public payload: EvidenceAttachmentDTO) {}
}
