import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { Store } from '@ngxs/store';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  ModalComponent,
  ModalConfig,
} from '../../modals/modal/modal.component';
import {
  FetchAttendeeRubrics,
  FetchSessionData,
} from '../../../state/coaching-log/coaching-log.actions';
import { CoachingLogState } from '../../../state/coaching-log/coaching-log.state';
import {
  AttendeeRubricDTO,
  CompetencyDTO,
} from '../../../dtos/attendee-rubric.dto';

interface CompetencyDictionaryItem {
  rubricId: number;
  userId: number;
  competency: CompetencyDTO;
}

@Component({
  selector: 'app-leveled-competency-modal',
  templateUrl: './leveled-competency-modal.component.html',
  styleUrls: ['./leveled-competency-modal.component.scss'],
})
export class LeveledCompetencyModalComponent implements AfterViewInit, OnInit {
  @ViewChild('internalModal') internalModal: ModalComponent;

  @ViewChild('inner') inner: ElementRef<HTMLDivElement>;

  attendeeRubrics$ = select(CoachingLogState.getAttendeeRubricsData);

  @Input() coachlogId: number;

  @Input() competencyId: number;

  @Input() userId: number;

  rubricData: AttendeeRubricDTO[] = [];

  competencyDictionary: CompetencyDictionaryItem[] = [];

  private isOpen = false;

  public modalShowing: BehaviorSubject<boolean> = new BehaviorSubject(false);

  modalConfig: ModalConfig = {
    headerClass: ['visually-hidden'],
    hideDismissButton: true,
    hideCloseButton: true,
    options: {
      size: 'fullscreen',
    },
  };

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (this.coachlogId && this.coachlogId !== 0) {
      this.store.dispatch(new FetchSessionData(this.coachlogId));
      this.store.dispatch(new FetchAttendeeRubrics(this.coachlogId));
      this.attendeeRubrics$
        .pipe(map((filterFn) => filterFn(this.coachlogId)))
        .subscribe((data) => {
          // This causes an infinite loop if you don't check if the data has changed
          if (data && data !== this.rubricData) {
            this.rubricData = data;
            const chosenRubrics = this.userId
              ? data.filter(
                  (attendeeRubric) => attendeeRubric.userId === this.userId
                )
              : data;
            if (this.competencyId) {
              chosenRubrics.forEach((attendeeRubric) =>
                attendeeRubric.rubrics.forEach((rubric) =>
                  rubric.competencyGroups.forEach((competencyGroup) =>
                    competencyGroup.competencies.forEach((competency) => {
                      if (competency.id === this.competencyId) {
                        this.competencyDictionary = [
                          {
                            userId: attendeeRubric.userId,
                            rubricId: rubric.id,
                            competency,
                          },
                        ];
                      }
                    })
                  )
                )
              );
            } else {
              this.competencyDictionary = chosenRubrics.flatMap(
                (attendeeRubric) =>
                  attendeeRubric.rubrics.flatMap((rubric) =>
                    rubric.competencyGroups.flatMap((competencyGroup) =>
                      competencyGroup.competencies.map((competency) => ({
                        userId: attendeeRubric.userId,
                        rubricId: rubric.id,
                        competency,
                      }))
                    )
                  )
              );
            }
          }
        });
    }
  }

  ngAfterViewInit() {
    this.internalModal.isDismissing.subscribe(() => {
      this.modalShowing.next(false);
      this.competencyDictionary = [];
    });

    this.modalShowing.subscribe((shown) => {
      if (shown) {
        if (!this.isOpen) {
          this.internalModal.open();
          this.isOpen = true;
        }
      } else if (this.isOpen) {
        this.internalModal.close();
        this.isOpen = false;
      }
    });
  }
}
