<div
  role="button"
  class="bookmark"
  container="body"
  aria-label="bookmark-icon-button"
  [ngbPopover]="contentTemplate"
  #p="ngbPopover"
  [popoverTitle]="titleTemplate"
  popoverClass="popover-lg"
  [autoClose]="'outside'"
  (click)="getMyShelfs()">
  <i
    class="bi"
    [ngClass]="{
      'bookmark-icon': true,
      'bi-bookmark-star-fill': itemOnAShelf >= 0,
      'bi-bookmark-star': itemOnAShelf < 0
    }"></i>
</div>

<ng-template #titleTemplate>
  <div class="bookmark-title">Add to Folder</div>
</ng-template>

<ng-template #contentTemplate>
  <div>
    <ng-container *ngIf="myShelves; else loadingTemplate">
      <ng-container *ngIf="emptyShelf">
        <ngb-alert [type]="'info'">
          No folders have been created yet
        </ngb-alert>
      </ng-container>
      <ng-container *ngIf="childShelf; else default">
        <ng-container *ngIf="!childLoading; else loadingTemplate">
          <div class="child-wrapper">
            <div
              role="button"
              aria-label="Back"
              class="return"
              (click)="
                childShelf.parent_shelf_id
                  ? getChildShelf(childShelf.parent_shelf_id)
                  : removeChildShelf()
              ">
              <i class="bi bi-arrow-left-circle child-shelf-button"></i>
              Back
            </div>
            <div class="child-content">
              <div class="child-shelf-wrapper d-flex justify-content-between">
                <ng-container *ngIf="childShelf.childShelves">
                  <ng-container *ngIf="childShelf.childShelves.length > 0">
                    <ng-container
                      *ngFor="
                        let shelf of childShelf.childShelves;
                        let i = index
                      ">
                      <input
                        aria-label="child-shelf-checkbox"
                        class="form-check-input me-1"
                        type="checkbox"
                        [(ngModel)]="shelf.is_active"
                        (ngModelChange)="toggleFavorite(shelf)" />
                      <ng-container *ngIf="shelf.numbShelves; else text">
                        <div
                          role="button"
                          title="Open shelf"
                          aria-label="Open shelf"
                          class="shelf-link"
                          (click)="getChildShelf(shelf.id)">
                          {{ shelf.title }}
                          <i
                            class="bi bi-arrow-right-circle child-shelf-button"></i>
                        </div>
                      </ng-container>
                      <ng-template #text>
                        {{ shelf.title }}
                      </ng-template>
                      <div
                        role="button"
                        title="Go to shelf"
                        aria-label="Go to shelf"
                        class="ms-auto">
                        <i
                          class="bi bi-eye eye-icon"
                          [id]="'shelfItem' + i"
                          (click)="gotoShelf(shelf)"></i>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #default>
        <ng-container *ngIf="myShelves.length > 0">
          <ng-container *ngFor="let shelf of myShelves; let i = index">
            <div class="d-flex">
              <input
                aria-label="shelf-checkbox"
                class="form-check-input me-1"
                type="checkbox"
                [(ngModel)]="shelf.is_active"
                (ngModelChange)="toggleFavorite(shelf)" />
              <ng-container *ngIf="shelf.numbShelves > 0; else text">
                <div
                  role="button"
                  title="Open shelf"
                  aria-label="Open shelf"
                  class="shelf-link"
                  (click)="getChildShelf(shelf.id)">
                  {{ shelf.title }}
                  <i class="bi bi-arrow-right-circle child-shelf-button"></i>
                </div>
              </ng-container>
              <ng-template #text>
                <div>
                  {{ shelf.title }}
                </div>
              </ng-template>
              <div
                role="button"
                title="Go to shelf"
                aria-label="Go to shelf"
                class="ms-auto">
                <i
                  class="bi bi-eye eye-icon"
                  [id]="'shelfItem' + i"
                  (click)="gotoShelf(shelf)"></i>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template #loadingTemplate>
      <app-loader></app-loader>
    </ng-template>
  </div>
  <ng-container *ngIf="myShelves && !nameShelf && !savingNewShelf">
    <div class="mt-5">
      <button
        aria-label="Add to New Folder"
        class="btn btn-link btn-sm p-0"
        (click)="nameShelf = true">
        <i class="bi bi-folder-plus"></i> Add to New Folder
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="nameShelf">
    <div class="mt-5">
      <div class="d-flex">
        <div class="input-group input-group-sm">
          <input
            aria-label="new-shelf-title-input-field"
            id="newShelfTitle"
            class="form-control"
            type="text"
            [(ngModel)]="newShelf.title"
            placeholder="Enter new folder name" />
        </div>
        <button
          aria-label="Create Folder"
          class="btn btn-primary btn-sm create-shelf-btn ms-1"
          (click)="createShelf()"
          [disabled]="newShelf.title.length < 1">
          Create Folder
        </button>
      </div>
    </div>
  </ng-container>
  <div *ngIf="savingNewShelf">
    <i class="bi bi-arrow-clockwise"></i> Creating folder...
  </div>

  <div class="app-hr-thin"></div>
  <div style="text-align: right">
    <button
      class="btn btn-primary btn-sm"
      (click)="p.close(); removeChildShelf()">
      Done
    </button>
  </div>
</ng-template>
