import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Channel } from 'pusher-js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  EvidenceDTO,
  EvidenceType,
  LevelDTO,
  OptionDTO,
} from 'src/app/private/shared/dtos/attendee-rubric.dto';
import { FileDTO } from 'src/app/private/shared/dtos/file.dto';
import { UploadType } from 'src/app/private/shared/enums/upload-type.enum';
import { VideoReferenceObjectType } from 'src/app/private/shared/enums/video-reference-object-type.enum';
import { fileDTOFromEvidenceAttachment } from 'src/app/private/shared/helpers/translators/evidence.translators';
import { EvidenceService } from 'src/app/private/shared/services/evidence/evidence.service';
import { EvidenceFileService } from 'src/app/private/shared/services/file/evidence.file.service';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';
import { EvidencePayload } from 'src/app/private/shared/types/payloads/evidence.payload';

import {
  FileUploadComponent,
  UploadStyles,
} from '../../../file-management/file-upload/file-upload.component';
import {
  ModalComponent,
  ModalConfig,
} from '../../../modals/modal/modal.component';
import { evidenceModalTypes } from './evidence-modal.helpers';

@Component({
  selector: 'app-leveled-competency-strand-level-evidence',
  templateUrl: './leveled-competency-strand-level-evidence.component.html',
  styleUrls: ['./leveled-competency-strand-level-evidence.component.scss'],
})
export class LeveledCompetencyStrandLevelEvidenceComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('evidenceModal') evidenceModal: ModalComponent;

  @ViewChild('uploadComponent') uploadComponent: FileUploadComponent;

  @ViewChild('optionSelect') optionSelect: NgSelectComponent;

  @Input() levelData: LevelDTO;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() level: number;

  @Input() lastCard: boolean;

  @Input() strandId: number;

  @Input() competencyId: number;

  @Input() coachlogId: number;

  disableVideoRemoval = false;

  optionList: OptionDTO[] = [];

  optionData$ = select(CoachingLogState.getOptions);

  evidenceData$ = select(CoachingLogState.getEvidence);

  modalFileList: FileDTO[] = [];

  modalConfig: ModalConfig;

  uploadTypes = UploadType;

  uploadType: UploadType | null;

  uploadStyle = UploadStyles.EVIDENCE;

  uploadedFiles: FileDTO[] = [];

  selectedOptionId = 0;

  numberOfFilesUploaded = 0;

  evidenceModalTypes = evidenceModalTypes;

  newEvidence = false;

  disableEvidenceSubmit = new BehaviorSubject<boolean>(false);

  currentEvidence: EvidenceDTO;

  evidenceList: EvidenceDTO[] = [];

  evidenceByOption: { [key: number]: EvidenceDTO[] } = {};

  subscriptions: Subscription[] = [];

  payload: EvidencePayload;

  uploadList: EventEmitter<FileDTO> = new EventEmitter();

  userChannel: Channel;

  constructor(private evidenceService: EvidenceService) {}

  ngOnInit(): void {
    this.modalConfig = {
      headerClass: [
        `bg-level-${this.level}`,
        'text-white',
        'text-center',
        'modal-header',
      ],
      titleText: '',
      options: {
        size: 'lg',
      },
      customFooter: true,
    };
    this.resetCurrentEvidence();
    this.evidenceData$
      .pipe(
        map((filterFn) =>
          filterFn(this.userId, this.strandId, this.level, this.coachlogId)
        )
      )
      .subscribe((newData) => {
        this.evidenceList.forEach((evidence) => {
          const currentIdx = newData.findIndex(
            (newEvidence) => newEvidence.id === evidence.id
          );
          if (currentIdx === -1) {
            this.removedEvidence(evidence);
          }
        });
        newData.forEach((evidence) => {
          this.updatedEvidence(evidence);
        });
      });
    this.optionData$
      .pipe(
        map((filterFn) => filterFn(this.strandId, this.level, this.coachlogId))
      )
      .subscribe((newData) => {
        this.optionList = newData
          .filter((option) => option.will)
          .sort((a, b) => {
            if (a.content > b.content) {
              return 1;
            }
            if (a.content < b.content) {
              return -1;
            }
            return 0;
          });
      });
  }

  ngAfterViewInit(): void {
    this.evidenceModal.isClosing.subscribe(() => {
      this.currentEvidence.coachlogOptionId = this.selectedOptionId;
      if (this.newEvidence) {
        this.currentEvidence = {
          ...this.currentEvidence,
          coachlogOptionId: this.optionSelect.selectedValues[0].id,
        };
        this.createEvidence();
      } else {
        this.updateEvidence();
      }
    });
  }

  resetCurrentEvidence(): void {
    this.currentEvidence = {
      id: 0,
      title: '',
      narrative: '',
      type: EvidenceType.NARRATIVE,
      userId: this.userId,
      rubricId: this.rubricId,
      level: this.level,
      competencyId: this.competencyId,
      coachlogId: this.coachlogId,
      coachlogOptionId: this.optionList[0]?.id || 0,
      strandId: this.strandId,
      addedFrom: 'coachlog',
      evidenceAttachments: [],
      assessments: [],
    };
    this.uploadType = null;
  }

  fileUploaded() {
    if (
      this.uploadType === UploadType.VIDEO &&
      this.uploadedFiles.length === 1 &&
      this.newEvidence === false
    ) {
      this.evidenceService.addVideo(
        {
          objectId: this.currentEvidence.id,
          videoId: this.uploadedFiles[0].id,
          objectType: VideoReferenceObjectType.EVIDENCE,
        },
        this.currentEvidence
      );
    }
  }

  updatedEvidence(evidence: EvidenceDTO) {
    let oldOptionId: number | null = null;
    if (
      this.evidenceList.filter((haystack) => haystack.id === evidence.id)
        .length === 0
    ) {
      this.evidenceList.push(evidence);
    } else {
      this.evidenceList = this.evidenceList.map((haystack) => {
        if (haystack.id === evidence.id) {
          oldOptionId = haystack.coachlogOptionId;
          return evidence;
        }
        return haystack;
      });
    }
    // optionId can change without deleting evidence
    if (oldOptionId && oldOptionId !== evidence.coachlogOptionId) {
      this.removeEvidenceFromByOptionsList(evidence.id, oldOptionId);
    }
    if (!this.evidenceByOption[evidence.coachlogOptionId]) {
      // if option doesn't have any evidence
      this.evidenceByOption[evidence.coachlogOptionId] = [evidence];
    } else if (
      // if evidence doesn't already exist
      this.evidenceByOption[evidence.coachlogOptionId].filter(
        (haystack) => haystack.id === evidence.id
      ).length === 0
    ) {
      this.evidenceByOption[evidence.coachlogOptionId].push(evidence);
    } else {
      // update existing evidence
      this.evidenceByOption[evidence.coachlogOptionId] = this.evidenceByOption[
        evidence.coachlogOptionId
      ].map((haystack) => {
        if (haystack.id === evidence.id) {
          if (this.currentEvidence.id === evidence.id) {
            this.currentEvidence = evidence;
          }
          return evidence;
        }
        return haystack;
      });
    }
    this.evidenceByOption[evidence.coachlogOptionId] = this.evidenceByOption[
      evidence.coachlogOptionId
    ].sort((a, b) =>
      // eslint-disable-next-line no-nested-ternary
      a.title.toLowerCase() < b.title.toLowerCase()
        ? -1
        : a.title.toLowerCase() > b.title.toLowerCase()
        ? 1
        : 0
    );
  }

  removeEvidenceFromByOptionsList(evidenceId: number, optionId: number) {
    this.evidenceByOption[optionId] = this.evidenceByOption[optionId].filter(
      (e) => e.id !== evidenceId
    );
    if (this.evidenceByOption[optionId].length === 0) {
      delete this.evidenceByOption[optionId];
    }
  }

  removedEvidence(evidence: EvidenceDTO) {
    this.evidenceList = this.evidenceList.filter((e) => e.id !== evidence.id);
    this.removeEvidenceFromByOptionsList(
      evidence.id,
      evidence.coachlogOptionId
    );
  }

  removeVideo() {
    this.evidenceService.removeVideo(this.currentEvidence);
    this.disableVideoRemoval = true;
  }

  createEvidence(): void {
    this.evidenceService.addEvidence(this.currentEvidence, this.uploadedFiles);
  }

  updateEvidence(): void {
    this.evidenceService.updateEvidence(this.currentEvidence);
  }

  addEvidence(): void {
    this.resetCurrentEvidence();
    this.newEvidence = true;
    this.selectedOptionId = this.optionList[0].id;
    this.evidenceModal.config.titleText = 'Add Evidence';
    this.uploadedFiles.length = 0;
    this.evidenceModal.open();
  }

  editEvidence(id: number): void {
    this.disableVideoRemoval = false;
    this.newEvidence = false;
    this.evidenceModal.config.titleText = 'Edit Evidence';
    this.currentEvidence = JSON.parse(
      JSON.stringify(this.evidenceList.find((e) => e.id === id))
    ) as EvidenceDTO;
    this.uploadType =
      this.currentEvidence.type === EvidenceType.VIDEO
        ? UploadType.VIDEO
        : UploadType.EVIDENCE;
    this.selectedOptionId = this.currentEvidence.coachlogOptionId;
    this.uploadedFiles.length = 0;
    if (this.uploadComponent && this.uploadType === UploadType.EVIDENCE) {
      (this.uploadComponent.fileService as EvidenceFileService).setEvidenceId(
        this.currentEvidence.id
      );
    }
    if (this.currentEvidence.evidenceAttachments.length > 0) {
      this.currentEvidence.evidenceAttachments.forEach((e) =>
        this.uploadedFiles.push(fileDTOFromEvidenceAttachment(e))
      );
    }
    this.evidenceModal.open();
  }

  changeEvidenceType() {
    this.currentEvidence.type =
      this.uploadType === UploadType.VIDEO
        ? EvidenceType.VIDEO
        : EvidenceType.NARRATIVE;
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  getOptionData(optionId: string): OptionDTO {
    return this.optionList.find(
      (option) => option.id === parseInt(optionId)
    ) as OptionDTO;
  }

  closeModalOnly() {
    this.evidenceModal.dismiss();
  }

  updateNarrative(narrative: string) {
    this.currentEvidence.narrative = narrative;
  }
}
