import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';

import { UserRole } from '../../state/user/role/user-role';
import { checkRouteEnvironment } from '../../utilities/check-route';

@Injectable({ providedIn: 'root' })
export class RouteGuard implements CanActivate {
  user: User | null = null;

  constructor(public store: Store, private router: Router) {}

  /* eslint-disable-next-line */
  canActivate(next: any, state: any): boolean {
    if (checkRouteEnvironment(next.url[0].path) === false) {
      // should eventually catch the "not authorized" page
      this.router.navigate(['/dashboard']);
      return false;
    }
    this.user = this.store.selectSnapshot(UserState.getUser);
    if (next.url.length === 1 && next.url[0].path === 'dashboard') {
      return this.navigateToUserTypeDashboard();
    }
    if (next.url.length === 1 && next.url[0].path === 'settings') {
      return this.navigateToUserDistrictSettings();
    }
    if (
      next.url.length > 2 &&
      next.url.length === 3 &&
      next.url[1].path === 'log' &&
      (next.url[0].path === 'observation' || next.url[0].path === 'coaching')
    ) {
      //
      this.router.navigate([
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        ...next.url.map((section: any) => section.path),
        'info',
      ]);
    }
    return true;
  }

  navigateToUserTypeDashboard() {
    if (this.user) {
      this.router.navigate([`/dashboard/${this.user.type}`]);
      return false;
    }
    this.router.navigate(['/login']);
    return false;
  }

  navigateToUserDistrictSettings() {
    if (this.user && !this.user?.roles.includes(UserRole.E2L_EMPLOYEE)) {
      this.router.navigate([
        `/settings/districts/${this.user.district?.districtCode}`,
      ]);
      return false;
    }
    return true;
  }
}
