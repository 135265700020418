<div class="container container-main">
  <app-dashboard-header :dashboardType="leader" />
  <div class="row gy-3">
    <div class="col-lg-8">
      <div class="row gy-3 mb-3">
        <div class="col">
          <div class="shadow h-100">
            <app-card-leader-glance
              [badgeParams]="yearToDateBadgeParams"
              [sessionParams]="yearToDateSessionParams"
              [obvservationParams]="yearToDateObservationParams"
              [coachedParams]="peopleCoachedParams"
              [campusParams]="yearToDateCampusParams"
              [giParams]="yearToDateGIParams"
              [user]="user" />
          </div>
        </div>
      </div>
      <div class="row gy-3 mb-3">
        <div class="col">
          <app-card-report
            [requestParams]="competencyBadgesParams"
            [chartType]="chartTypes.HorizontalBar"
            [xNameGap]="20"
            cardTitle="Competency Badges"
            xAxisLabel="Badge Count" />
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="row gy-3 mb-3">
        <div class="col"><app-card-todos /></div>
      </div>
      <div class="row gy-3">
        <div class="col"><app-card-ideas /></div>
      </div>
    </div>
  </div>
</div>
