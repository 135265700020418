import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import {
  Field,
  FieldData,
  FieldType,
  FieldWithChoices,
  FormIndicatorDTO,
} from 'src/app/private/shared/dtos/forms.dto';
import { isChoiceField } from 'src/app/private/shared/helpers/forms.utiltiies';

import { AbstractViewFieldComponent } from '../base/abstract-view-field';
import { AbstractViewFieldChoicesComponent } from '../base/abstract-view-field-choices';

@Component({
  selector: 'app-form-view-field',
  templateUrl: './form-view-field.component.html',
  styleUrls: ['./form-view-field.component.scss'],
})
export class FormViewFieldComponent implements OnInit {
  @ViewChild('fieldView') fieldView:
    | AbstractViewFieldComponent
    | AbstractViewFieldChoicesComponent;

  @Input() fieldDefinition: Field;

  @Input() inputDisabled = false;

  @Input() inputData: FieldData | null;

  fieldDefs: Field;

  fieldTypes = FieldType;

  fieldWithChoicesDefs: FieldWithChoices;

  @Output() data = new ReplaySubject<FieldData>();

  /* eslint-disable-next-line class-methods-use-this */
  deserializeStrings(fieldData: string | undefined): string[] | null {
    return fieldData ? JSON.parse(fieldData) : null;
  }

  /* eslint-disable-next-line class-methods-use-this */
  deserializeIndicators(
    fieldData: string | undefined
  ): FormIndicatorDTO[] | null {
    return fieldData ? JSON.parse(fieldData) : null;
  }

  ngOnInit(): void {
    if (isChoiceField(this.fieldDefinition.type)) {
      this.fieldWithChoicesDefs = this.fieldDefinition as FieldWithChoices;
    } else {
      this.fieldDefs = this.fieldDefinition as Field;
    }
  }
}
