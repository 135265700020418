import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import {
  FieldType,
  FieldWithChoices,
  MultipleChoiceFieldMap,
} from '../../../../dtos/forms.dto';
import { FormEditFieldOptionsComponent } from './options/form-edit-field-options.component';
import { FormEditFieldLabelComponent } from './label/form-edit-field-label.component';

@Component({
  template: '',
})
export abstract class FormFieldChoicesAbstractComponent
  implements AfterViewInit
{
  id = `label${uuidv4()}`;

  multipleChoiceOptions = MultipleChoiceFieldMap;

  @ViewChild('options') options: FormEditFieldOptionsComponent;

  @ViewChild('label') label: FormEditFieldLabelComponent;

  @Input() data: FieldWithChoices;

  @Output() dataChange = new EventEmitter<FieldWithChoices>();

  ngAfterViewInit() {
    this.options.dataChange.subscribe((data) => {
      this.data.choices = [...data];
      this.pushChange();
    });

    this.label.dataChange.subscribe((data) => {
      this.data.label = data;
      this.pushChange();
    });
  }

  typeChange(type: { label: string; value: FieldType }) {
    this.data.type = type.value;
    this.pushChange();
  }

  pushChange() {
    this.dataChange.emit(this.data);
  }
}
