import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldWithChoices } from 'src/app/private/shared/dtos/forms.dto';
import { v4 as uuidv4 } from 'uuid';

@Component({
  template: '',
})
export abstract class AbstractViewFieldChoicesComponent {
  @Input() fieldDefinition: FieldWithChoices;

  @Output() value = new EventEmitter<string>();

  id = uuidv4();

  selection: string;
}
