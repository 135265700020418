import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NewCoachingSessionUserDto } from '../../dtos/coaching-session.dto';

@Component({
  selector: 'app-new-coaching-shadower',
  templateUrl: './new-coaching-shadower.component.html',
  styleUrls: ['./new-coaching-shadower.component.scss'],
})
export class NewCoachingShadowerComponent {
  @Input() user: NewCoachingSessionUserDto;

  @Output() removedShadower: EventEmitter<boolean> = new EventEmitter();

  deleteShadower() {
    this.removedShadower.emit(true);
  }
}
