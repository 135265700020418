import { Component, Input, OnInit } from '@angular/core';
import { FileDTO } from 'src/app/private/shared/dtos/file.dto';
import {
  Field,
  FieldType,
  FieldWithChoices,
} from 'src/app/private/shared/dtos/forms.dto';

@Component({
  selector: 'app-form-view-no-input',
  templateUrl: './view-no-input.component.html',
  styleUrls: ['./view-no-input.component.scss'],
})
export class FormViewNoInputComponent implements OnInit {
  FieldTypes = FieldType;

  @Input() fieldDefinition: Field;

  @Input() fieldWithChoicesDefinition: FieldWithChoices;

  @Input() value?: string;

  filesList: FileDTO[] = [];

  ngOnInit() {
    if (
      this.fieldDefinition &&
      this.fieldDefinition.type &&
      this.fieldDefinition.type === FieldType.MEDIA
    ) {
      this.filesList = JSON.parse(this.value as string);
    }
  }

  getOutputValue() {
    if (this.fieldWithChoicesDefinition.type === FieldType.CHECKBOX) {
      const values: string[] = JSON.parse(this.value as string);
      return this.fieldWithChoicesDefinition.choices
        .filter((choice) => values.includes(choice.value))
        .map((choice) => choice.label)
        .join(', ');
    }
    return this.fieldWithChoicesDefinition.choices.find(
      (choice) => choice.value === this.value
    )?.label;
  }
}
