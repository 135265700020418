import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { CompetencyDTO } from 'src/app/common/dtos/competency.dto';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { FileDTO } from '../../dtos/file.dto';
import { CelebrationsService } from '../../services/celebrations/celebrations.service';
import { ConfettiService } from '../../services/confetti/confetti.service';
import { CelebrationAttachmentPayload } from '../../types/payloads/celebrations.payloads';
import { CompetencySelectComponent } from '../competency-select/competency-select.component';
import { UploadStyles } from '../file-management/file-upload/file-upload.component';
import { ModalComponent } from '../modals/modal/modal.component';
import { WysiwygEditorComponent } from '../wysiwyg-editor/wysiwyg-editor.component';

@Component({
  selector: 'app-celebration-modal',
  templateUrl: './celebration-modal.component.html',
  styleUrls: ['./celebration-modal.component.scss'],
})
export class CelebrationModalComponent {
  @ViewChild('celebrationModal')
  celebrationModal: ModalComponent;

  @ViewChild('competencySelect') competencySelect: CompetencySelectComponent;

  @ViewChild('wysiwyg') public editor: WysiwygEditorComponent;

  @Output() createCelebrationEvent = new EventEmitter();

  celebrationId: number;

  users: UserDTO[] = [];

  attachments: FileDTO[] = [];

  competencyList: CompetencyDTO[] = [];

  uploadStyle = UploadStyles.SIMPLE;

  constructor(
    private celebrationsService: CelebrationsService,
    private confettiService: ConfettiService
  ) {}

  closeModal() {
    this.celebrationModal.close();
  }

  openModal() {
    this.celebrationModal.open();
  }

  addChosenUser(chosenUser: UserDTO | null) {
    if (chosenUser) {
      if (!this.users.some((user) => user.id === chosenUser.id)) {
        this.users.push(chosenUser);
      }
    }
  }

  deleteChosenUser(chosenUser: UserDTO | null) {
    if (chosenUser) {
      this.users.some((user, index) => {
        if (user.id === chosenUser.id) {
          this.users.splice(index, 1);
        }
        return null;
      });
    }
  }

  updateCompetency(competency: CompetencyDTO | null) {
    if (competency) this.competencyList.push(competency);
  }

  updateCompetencyList(competencyList: CompetencyDTO[]) {
    this.competencyList = competencyList;
  }

  createCelebration() {
    if (this.users.length > 0 && this.editor.editorContent) {
      const ids = this.users.map((user) => user.id);
      this.celebrationsService
        .createCelebration(ids, this.editor.editorContent)
        .subscribe((res) => {
          this.celebrationId = res.item.id;
          this.uploadCompetencies();
          this.uploadAttachments();
          this.closeModal();
          this.clearFields();
          this.createCelebrationEvent.emit();
          this.confettiService.showConfetti();
        });
    }
  }

  uploadCompetencies() {
    if (this.celebrationId && this.competencyList.length > 0) {
      this.competencyList.forEach((competency) => {
        this.celebrationsService
          .updateCelebrationCompetencies(
            this.celebrationId,
            competency.rubric_id
          )
          .subscribe();
      });
    }
  }

  uploadAttachments() {
    if (this.celebrationId && this.attachments.length > 0) {
      this.attachments.forEach((attachment) => {
        const payload: CelebrationAttachmentPayload = {
          endorsement_id: this.celebrationId,
          attachment_id: attachment.id,
        };
        this.celebrationsService
          .updateCelebrationAttachments(payload)
          .subscribe();
      });
    }
  }

  updateAttachments(file: FileDTO) {
    this.attachments.push(file);
  }

  clearFields() {
    this.users = [];
    this.editor.editorContent = '';
    this.competencyList = [];
    this.competencySelect.reset();
    this.attachments = [];
  }
}
