import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProfileDTO } from 'src/app/common/dtos/profile.dto';
import { getTextColor } from 'src/app/common/utilities/contrast-checker';
import { UserService } from 'src/app/common/state/user/user.service';
import { AvatarDTO } from './avatar.dto';
import { avatarDTOFromProfile } from './avatar.translators';
import { userDTOFromAPIResponse } from '../../helpers/translators/user.translators';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() profile: ProfileDTO;

  @Input() avatarData: AvatarDTO;

  @Input() userId: number;

  @Input() hideTooltip = false;

  @ViewChild('element', { static: false }) element: ElementRef;

  @Input() avatarSize:
    | 'small'
    | 'medium'
    | 'medium-large'
    | 'large'
    | 'avatar-side-nav';

  initials: string;

  initialsColor: string;

  photoUrl: string;

  constructor(private userService: UserService) {}

  getAvatarData(): void {
    if (this.avatarData.photoUrl) {
      this.photoUrl = this.avatarData.photoUrl;
    } else this.getInitials();
  }

  getInitials(): void {
    let newString = '';
    newString += this.avatarData.firstName[0] + this.avatarData.lastName[0];
    this.initials = newString;
    this.initialsColor = getTextColor(this.avatarData.color);
  }

  ngOnInit(): void {
    if (this.profile) {
      this.avatarData = avatarDTOFromProfile(this.profile);
      this.getAvatarData();
    }
    if ((!this.profile || !this.avatarData) && this.userId) {
      const cachedUser = this.userService.getCachedUser(this.userId);
      if (cachedUser) {
        this.avatarData = avatarDTOFromProfile(cachedUser.profile);
      } else {
        this.userService.fetchUserById(this.userId).subscribe((res) => {
          if (res) {
            const userDTO = userDTOFromAPIResponse(res.item);
            this.userService.addUserToCache(userDTO);
            this.avatarData = avatarDTOFromProfile(userDTO.profile);
          }
        });
      }
    }
  }
}
