<div class="container">
  <div class="d-flex justify-content-between mb-3">
    <h1>Style and Component Library</h1>
    <div class="align-self-center">
      <button class="btn btn-link" (click)="defaultModal.open()">
        <i class="bi bi-plus-circle me-1"></i>New default modal
      </button>
      <app-modal
        #defaultModal
        [modalConfig]="{ titleText: 'Default Modal Title' }">
        Default modal contents go here
      </app-modal>
      <button class="btn btn-link" (click)="customModal.open()">
        <i class="bi bi-plus-circle me-1"></i>New custom modal
      </button>
      <app-modal
        #customModal
        [modalConfig]="{
          titleText: 'Custom Modal',
          headerClass: ['modal-header', 'bg-primary', 'text-white'],
          windowClass: 'custom-class',
          customFooter: true
        }">
        <button
          class="bg-transparent border-0"
          title="Close"
          aria-label="close"
          headerContent
          (click)="customModal.close()">
          <i class="bi bi-x-lg text-white"></i>
        </button>
        This modal has custom header classes, header content, custom window
        class, and button text
        <div footerContent>
          <button
            (click)="customModal.close()"
            type="button"
            class="btn btn-md btn-primary">
            Custom footer button
          </button>
        </div>
      </app-modal>
    </div>
  </div>
  <div id="in-page-links" class="my-3">
    <a
      [routerLink]="'.'"
      fragment="editors"
      aria-label="Link to this section: Editors"
      >Editors</a
    >
    &mdash;
    <a
      [routerLink]="'.'"
      fragment="buttons"
      aria-label="Link to this section: Buttons"
      >Buttons</a
    >
    &mdash;
    <a
      [routerLink]="'.'"
      fragment="forms"
      aria-label="Link to this section: Forms"
      >Forms</a
    >
    &mdash;
    <a
      [routerLink]="'.'"
      fragment="dropdowns"
      aria-label="Link to this section: Dropdowns"
      >Dropdowns</a
    >
    &mdash;
    <a
      [routerLink]="'.'"
      fragment="headers"
      aria-label="Link to this section: Headers"
      >Headers</a
    >
    &mdash;
    <a
      [routerLink]="'.'"
      fragment="colors"
      aria-label="Link to this section: Colors"
      >Colors</a
    >
    &mdash;
    <a
      [routerLink]="'.'"
      fragment="tables"
      aria-label="Link to this section: Tables"
      >Tables</a
    >
    &mdash;
    <a
      [routerLink]="'.'"
      fragment="files"
      aria-label="Link to this section: Files"
      >Files</a
    >
  </div>
  <div class="row gy-3">
    <!-- FORM COLUMN -->
    <div class="col-md-6">
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 id="editors" class="fs-3">
            <a href="style-library#editors">Editors (WYSIWYG)</a>
          </h2>
          <div class="my-3">Showing tabs and editor type components</div>
          <nav ngbNav #nav="ngbNav" class="nav-tabs mt-2">
            <ng-container ngbNavItem>
              <a ngbNavLink class="nav-link"> None </a>
              <ng-template ngbNavContent>
                <app-wysiwyg-editor [version]="'none'" />
              </ng-template>
            </ng-container>
            <ng-container ngbNavItem>
              <a ngbNavLink class="nav-link"> Mini </a>
              <ng-template ngbNavContent>
                <app-wysiwyg-editor
                  [version]="'mini'"
                  [config]="{
                  useCodox: true,
                }" />
              </ng-template>
            </ng-container>
            <ng-container ngbNavItem>
              <a ngbNavLink class="nav-link"> Full </a>
              <ng-template ngbNavContent>
                <app-wysiwyg-editor
                  [version]="'full'"
                  [config]="{
                  useCodox: true,
                }" />
              </ng-template>
            </ng-container>
          </nav>
          <div [ngbNavOutlet]="nav" class="mt-2 p-2"></div>
        </div>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 id="forms" class="card-title fs-3">
            <a href="style-library#forms">Forms</a>
          </h2>
          <div class="row">
            <div class="col">
              <label for="first-name" class="form-label">First Name</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="first-name"
                  aria-label="First Name" />
              </div>
            </div>
            <div class="col">
              <label for="last-name" class="form-label">Last Name</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="last-name"
                  aria-label="Last Name" />
              </div>
            </div>
          </div>
          <label for="email" class="form-label">E-mail</label>
          <div>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                id="email"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="basic-addon1" />
              <span class="input-group-text" id="basic-addon1"
                >@example.com</span
              >
            </div>
          </div>
          <label for="textarea" class="form-label">Tell Us Your 'Why'</label>
          <div>
            <div class="input-group">
              <textarea
                class="form-control"
                id="textarea"
                placeholder="This is an example textarea"
                aria-label="Example textarea"></textarea>
            </div>
            <div class="form-text">
              Example help text goes outside the input group.
            </div>
          </div>
          <div>
            <label for="sample-datepicker" class="form-label"
              >Pick a date</label
            >
            <div class="input-group datepicker-popup" style="max-width: 350px">
              <input
                class="form-control"
                placeholder="yyyy-mm-dd"
                id="sample-datepicker"
                [firstDayOfWeek]="0"
                ngbDatepicker
                placement="bottom-end top-end"
                aria-label="Enter date"
                #d="ngbDatepicker" />
              <button
                class="btn color-tertiary btn-outline-secondary bi bi-calendar3"
                (click)="d.toggle()"
                aria-label="Date picker"
                type="button"></button>
            </div>
            <div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1" />
                <label class="form-check-label" for="flexRadioDefault1">
                  Default radio
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked />
                <label class="form-check-label" for="flexRadioDefault2">
                  Default checked radio
                </label>
              </div>
            </div>
            <div class="d-flex flex-row-reverse mt-3">
              <button type="button" class="btn btn-primary px-3 mx-1">
                Submit
              </button>
              <button type="button" class="btn btn-secondary px-3 mx-1">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- BUTTONS -->
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 id="buttons" class="card-title fs-3">Buttons</h2>
          <div>Examples of buttons (with popovers, modals, and confetti)</div>
          <h3 class="fs-5 mt-3">
            Primary Button
            <i
              class="bi bi-info-circle-fill mx-1 fs-6"
              ngbTooltip="These buttons showcase popover tools"></i>
          </h3>
          <button
            type="button"
            class="btn btn-primary"
            placement="right"
            ngbPopover="Popover content"
            popoverTitle="Basic Popover Title">
            Basic Popover
          </button>
          <h3 class="fs-5 mt-3">Secondary Button</h3>
          <button
            type="button"
            class="btn btn-secondary"
            placement="right"
            container="body"
            [autoClose]="'outside'"
            [ngbPopover]="customPopoverContent"
            popoverTitle="Custom Popover Title"
            #p="ngbPopover">
            Custom Popover
          </button>
          <h3 class="fs-5 mt-3">Text Button</h3>
          <button
            type="button"
            class="btn"
            placement="right"
            container="body"
            [autoClose]="'outside'"
            [ngbPopover]="deletePopoverContent"
            popoverTitle="Confirm Deletion"
            #p="ngbPopover">
            Delete Popover
          </button>
          <h3 class="fs-5 mt-3">Link Button (Text/Icon)</h3>
          <button class="btn btn-link" (click)="customModal.open()">
            <i class="bi bi-plus-circle me-1"></i>New custom modal
          </button>
          <h3 class="fs-5 mt-3">
            Button Group
            <i
              class="bi bi-info-circle-fill mx-1 fs-6"
              ngbTooltip="These buttons showcase confetti tools"></i>
          </h3>
          <div class="btn-group btn-group-sm me-5" role="group">
            <input
              [checked]="confettiLocation === 'top'"
              type="radio"
              class="btn-check"
              id="top-radio"
              (click)="setConfettiLocation('top')" />
            <label class="btn btn-outline-primary" for="top-radio">Top</label>
            <input
              [checked]="confettiLocation === 'sides'"
              type="radio"
              class="btn-check"
              id="sides-radio"
              (click)="setConfettiLocation('sides')" />
            <label class="btn btn-outline-primary" for="sides-radio"
              >Sides</label
            >
            <input
              [checked]="confettiLocation === 'bottom'"
              type="radio"
              class="btn-check"
              id="bottom-radio"
              (click)="setConfettiLocation('bottom')" />
            <label class="btn btn-outline-primary" for="bottom-radio"
              >Bottom</label
            >
          </div>
          <h3 class="fs-5 mt-3">Large/Next Button</h3>
          <button
            type="button"
            class="btn btn-primary btn-next"
            [disabled]="particlesVisible"
            (click)="cueConfetti()">
            Cue Confetti
          </button>
        </div>
        <ng-template #customPopoverContent>
          <div class="p-2">
            Here is some popover content with a small close button below
          </div>
          <div class="p-2">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              (click)="p.close()">
              Close Popover
            </button>
          </div>
        </ng-template>
        <ng-template #deletePopoverContent>
          <div>Permanently delete this item?</div>
          <div class="app-hr-thin"></div>
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-danger px-3 m-1"
              (click)="p.close()">
              Delete
            </button>
          </div>
        </ng-template>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 id="dropdowns" class="card-title fs-3">
            <a href="style-library#dropdowns">Dropdowns</a>
          </h2>
          <div class="my-3">
            <app-user-select [clearAfterSelection]="false" />
          </div>
          <div class="my-3">
            <app-select-resource
              [placeholder]="'Search for Resources'"></app-select-resource>
          </div>
        </div>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 id="datepickers" class="card-title fs-3">
            <a href="style-library#datepickers">Datepickers</a>
          </h2>
          <div class="mt-3 mb-3">
            <div class="form-text">
              Should start at logged-in user's district's TZ equivalent of
              2021-05-02 @ 5 p.m. PST
            </div>
            <app-datepicker
              (dateSelectedUnixTimestamp)="
                selectedDate.next($event.time / 1000);
                selectedTimezone.next($event.timezone)
              "
              [initialDateUnixTimestamp]="1620000000"
              [timepicker]="true"></app-datepicker>

            <div class="d-flex p-3 m-3 border-success bg-neutral rounded-3">
              <div>
                <label class="form-label" for="timeOutput"
                  >Selected time in your computer's TZ</label
                >
                <input
                  id="timeOutput"
                  type="text"
                  [value]="
                    (selectedDate$ | async) || 0
                      | showDateTimezone : undefined : undefined : false
                  " />
              </div>
              <div>
                <label class="form-label" for="tzOutput"
                  >Selected timezone</label
                >
                <input
                  id="tzOutput"
                  type="text"
                  [value]="selectedTimezone$ | async" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 class="card-title fs-3">Competency-in-a-box (modal)</h2>
          <div class="input-group">
            <input
              type="text"
              class="form-control mb-2"
              placeholder="Enter valid log ID here (button gets competencies from log)"
              (keyup)="setCompetencyModalId($event)" />
            <div class="input-group-append">
              <button
                title="Get competency IDs from log"
                class="btn btn-primary"
                (click)="getCompetencyLogData()">
                <i class="bi bi-plus-circle"></i>
              </button>
            </div>
          </div>
          <div *ngIf="competencyModalCompetencyIds.length > 0">
            <p>
              <strong>User IDs: {{ competencyModalUserIds.join(", ") }}</strong>
            </p>
            <p><strong>Available competencies:</strong></p>
            <ul>
              <li *ngFor="let competencyId of competencyModalCompetencyIds">
                {{ competencyId }}
              </li>
            </ul>
          </div>
          <input
            type="text"
            class="form-control mb-2"
            placeholder="Enter valid User ID from the log here"
            (keyup)="setCompetencyModalUserId($event)" />
          <input
            type="text"
            class="form-control mb-2"
            placeholder="Enter valid competency ID from the log here"
            (keyup)="setCompetencyModalCompetencyId($event)" />
          <button (click)="toggleCompetencyModal()" class="btn btn-primary">
            Open the above in a modal
          </button>
        </div>
        <app-leveled-competency-modal
          #competencyModal></app-leveled-competency-modal>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 class="card-title fs-3">Pusher</h2>
          <p>
            <strong>Channel status: </strong>
            {{ channel.subscribed ? "Connected" : "Disconnected" }}
          </p>
          <ng-template [ngIf]="channel.subscribed">
            <p><strong>Attendees</strong></p>
            <p class="d-flex">
              <app-avatar
                avatarSize="medium"
                *ngFor="let attendee of attendees"
                [avatarData]="attendee"></app-avatar>
            </p>
          </ng-template>
        </div>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 id="reports" class="card-title fs-3">Reports</h2>
          <app-card-report
            cardTitle="People Coached Trend - Bar"
            [chartType]="chartTypes.Bar"
            yAxisLabel="Coachee Count"
            [requestParams]="{
              dataset: 'fct_coaching_session',
              measure: {
                type: 'count',
                field: 'log_id',
              },
              filters: [],
            }"></app-card-report>
          <app-card-report
            cardTitle="People Coached Trend - Custom Label Spacing - Horizontal Bar"
            [chartType]="chartTypes.HorizontalBar"
            xAxisLabel="Coachee Count"
            [xNameGap]="40"
            [requestParams]="{
              dataset: 'fct_coaching_session',
              measure: {
                type: 'count',
                field: 'log_id',
              },
              filters: [],
            }"></app-card-report>
          <div class="mt-3"></div>
          <app-card-report
            cardTitle="People Coached Trend - Custom Sized Grid - Line"
            [chartType]="chartTypes.Line"
            yAxisLabel="Coachee Count"
            [gridDimensions]="{
              top: '50px',
              left: '50px',
              right: '50px',
              bottom: '50px',
            }"
            [requestParams]="{
              dataset: 'fct_coaching_session',
              measure: {
                type: 'count',
                field: 'log_id',
              },
              filters: [],
            }"></app-card-report>
          <app-card-report
            cardTitle="People Coached Trend - Custom Color - Area"
            chartColor="#430089"
            [chartType]="chartTypes.Area"
            yAxisLabel="Coachee Count"
            [requestParams]="{
              dataset: 'fct_coaching_session',
              measure: {
                type: 'count',
                field: 'log_id',
              },
              filters: [],
            }"></app-card-report>
        </div>
      </div>
    </div>
    <!-- DESIGN COLUMNS -->
    <div class="col-md-6">
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 id="colors" class="card-title fs-3">
            <a href="style-library#colors">Colors</a>
          </h2>
          <div class="container text-center">
            <div class="row">
              <div class="col">
                <div class="bg-primary p-3"></div>
                <div>Primary</div>
              </div>
              <div class="col">
                <div class="bg-secondary p-3"></div>
                <div>Secondary</div>
              </div>
              <div class="col">
                <div class="bg-tertiary p-3"></div>
                <div>Tertiary</div>
              </div>
              <div class="col">
                <div class="bg-neutral p-3"></div>
                <div>Neutral</div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="bg-level-1 p-3"></div>
                <div>Level 1</div>
              </div>
              <div class="col">
                <div class="bg-level-2 p-3"></div>
                <div>Level 2</div>
              </div>
              <div class="col">
                <div class="bg-level-3 p-3"></div>
                <div>Level 3</div>
              </div>
              <div class="col">
                <div class="bg-level-4 p-3"></div>
                <div>Level 4</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 id="headers" class="card-title fs-3">
            <a href="style-library#headers">Headers</a>
          </h2>
          <h1>h1 Heading</h1>
          <h2>h2 Heading</h2>
          <h3>h3 Heading</h3>
          <h4>h4 Heading</h4>
          <h5>h5 Heading</h5>
          <h6>h6 Heading</h6>
        </div>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 id="fonts" class="card-title fs-3">
            <a href="style-library#fonts"> Fonts (weights 2.6rem = 26px) </a>
          </h2>
          <div style="font-size: 2.6rem">
            <div style="font-weight: 900">Greycliff-CF / font-weight: 900</div>
            <div style="font-weight: 800">Greycliff-CF / font-weight: 800</div>
            <div style="font-weight: 700">Greycliff-CF / font-weight: 700</div>
            <div style="font-weight: 600">Greycliff-CF / font-weight: 600</div>
            <div style="font-weight: 500">Greycliff-CF / font-weight: 500</div>
            <div style="font-weight: 400">Greycliff-CF / font-weight: 400</div>
            <div style="font-weight: 300">Greycliff-CF / font-weight: 300</div>
            <div style="font-weight: 200">Greycliff-CF / font-weight: 200</div>
            <div style="font-weight: 100">Greycliff-CF / font-weight: 100</div>
          </div>
        </div>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 id="paragraphs" class="card-title fs-3">
            <a href="style-library#paragraphs">Paragraphs</a>
          </h2>
          <p>
            Incididunt consectetur eiusmod dolore ullamco sit pariatur deserunt
            nisi magna pariatur. Qui adipisicing anim enim pariatur fugiat nisi
            nulla tempor culpa laboris eu in. Id in velit reprehenderit sunt
            aute fugiat ea non. Excepteur occaecat veniam aliqua fugiat sint
            reprehenderit nisi excepteur pariatur nisi occaecat. Excepteur
            excepteur nostrud voluptate consectetur sunt eiusmod culpa deserunt
            in eiusmod minim. Nisi veniam quis velit id officia cupidatat.
          </p>
          <p>
            Non consectetur sint anim consequat sit aliqua sint eu consectetur
            enim nulla mollit. Ut nulla consequat eiusmod cillum velit ad
            nostrud labore commodo magna. Deserunt officia pariatur officia
            consectetur aute ea nostrud.
          </p>
        </div>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 id="tables" class="card-title fs-3">
            <a href="style-library#tables">Tables</a>
          </h2>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">First</th>
                <th scope="col">Last</th>
                <th scope="col">Handle</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <td>3</td>
                <td colspan="2">Larry the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card border-0 mb-3">
        <app-competencies-summary
          [isSelectable]="true"
          [userId]="2"
          [competencyId]="25"
          (isSelectedEvent)="
            handleCompetencySummaryEvent()
          "></app-competencies-summary>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 id="files" class="card-title fs-3">File list</h2>
          <small class="d-block mb-2"
            >FPO, buttons won't work properly. To test functionality, use upload
            below</small
          >
          <div class="row">
            <app-file-list [fileList]="mockFiles"></app-file-list>
          </div>
        </div>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 class="card-title fs-3">Upload/file list</h2>
          <div class="row">
            <app-file-list [uploadList]="fileUploads"></app-file-list>
          </div>
          <div class="row">
            <app-file-upload (outputFiles)="handleFileUpload($event)" />
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div id="celebrations-kpi" class="card border-0 text-center p-3">
          <app-kpi-controller
            [kpiTitle]="'Average Sessions per Coachee'"
            [primaryRequestParams]="primaryAverageParams"
            [secondaryRequestParams]="
              secondaryAverageParams
            "></app-kpi-controller>
          <div class="fs-5 fw-800">Average KPI Card</div>
        </div>
      </div>
      <div class="mb-3">
        <app-card-kpi
          kpiName="Sessions in April"
          measure="'count'"
          [fetchRequestParams]="aprilSessionParams"></app-card-kpi>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <h2 class="card-title fs-3">Badges</h2>
          <div class="fw-500 fs-19 mb-3">Size 'lg' - default</div>
          <div class="row">
            <app-badge-graphic [badge]="strandBadge.badges[0]" />
            <app-badge-graphic [badge]="competencyBadge.badges[0]" />
          </div>
          <hr />
          <div class="fw-500 fs-19 mb-3">Size 'sm'</div>
          <div class="row">
            <app-badge-graphic [badge]="strandBadge.badges[0]" [size]="'sm'" />
            <app-badge-graphic
              [badge]="competencyBadge.badges[0]"
              [size]="'sm'" />
          </div>
        </div>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body">
          <app-action-plan></app-action-plan>
        </div>
      </div>
    </div>
  </div>
</div>
