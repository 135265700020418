import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LibraryShelfModelDTO } from '../../../dtos/resources.dto';

@Component({
  selector: 'app-shelf-item',
  templateUrl: './shelf-item.component.html',
  styleUrls: ['./shelf-item.component.scss'],
})
export class ShelfItemComponent {
  @Input() shelf: LibraryShelfModelDTO;

  @Input() multiShelves: boolean;

  @Output() shelfClicked: EventEmitter<boolean> = new EventEmitter();

  @Output() copyClicked: EventEmitter<number> = new EventEmitter();

  @Output() starClicked: EventEmitter<boolean> = new EventEmitter();

  shelfClickedHandler() {
    this.shelfClicked.emit(true);
  }

  copyClickedHandler() {
    this.copyClicked.emit(this.shelf.id);
  }

  starClickedHandler() {
    this.starClicked.emit(true);
  }
}
