import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Observable, Subscription } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  checkIfCoach,
  checkIfShadower,
  checkPresentAttendee,
} from 'src/app/common/utilities/session-helpers';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';

import { UserState } from '../../../common/state/user/user.state';
import { LeveledCompetencyModalComponent } from '../../shared/components/competency/leved-competency-modal/leveled-competency-modal.component';
import { DatepickerOutput } from '../../shared/components/datepicker/datepicker.component';
import { CompetencyDTO } from '../../shared/dtos/attendee-rubric.dto';
import {
  CoachingSessionAttendeeDTO,
  CoachingSessionDTO,
} from '../../shared/dtos/coaching-session.dto';
import { checkSessionDataType } from '../../shared/helpers/coachee-log.utilities';
import { CoachingLogService } from '../../shared/services/coaching-log/coaching-log.service';
import { FetchSessionData } from '../../shared/state/coaching-log/coaching-log.actions';
import { CoachingLogState } from '../../shared/state/coaching-log/coaching-log.state';
import {
  AbsentReasonsAPIResponse,
  CoachlogTypesAPIResponse,
} from '../../shared/types/responses/coaching-log.responses';

@Component({
  selector: 'app-coaching-log-info-page',
  templateUrl: './coaching-log-info-page.component.html',
  styleUrls: ['./coaching-log-info-page.component.scss'],
})
export class CoachingLogInfoPageComponent implements OnInit, OnDestroy {
  @ViewChild('competencyModal')
  competencyModal: LeveledCompetencyModalComponent;

  sessionId: number;

  sessionData$ = select(CoachingLogState.getSessionData);

  competenciesLoading = true;

  user$ = select(UserState.getUser);

  allCompetencies: CompetencyDTO[] = [];

  competencyData$ = select(CoachingLogState.getCompetenciesByLog);

  competencyData: CompetencyDTO[] = [];

  lastSessionCompetencies: {
    userId: number;
    competencies: CompetencyDTO[];
  }[] = [];

  sessionData: CoachingSessionDTO | null;

  selectedAttendee: UserDTO | null;

  sessionAttendees: UserDTO[] = [];

  subs: Subscription[] = [];

  selectedLogId: number | null;

  attendeeSelectApiCalls: Subscription | null;

  coachlogTypesList: CoachlogTypesAPIResponse[];

  absentReasonsList: AbsentReasonsAPIResponse[];

  isCoach: boolean;

  isShadower: boolean;

  selectedTypeId: number;

  logStart: number;

  logEnd: number;

  logDuration = 1800;

  timezone: TimezoneLinkBack;

  latestLogId: number;

  durationList = [
    { value: 900, label: '15 minutes' },
    { value: 1800, label: '30 minutes' },
    { value: 2700, label: '45 minutes' },
    { value: 3600, label: '1 hour' },
  ];

  hasPresentAttendee = false;

  constructor(
    private route: ActivatedRoute,
    public coachingService: CoachingLogService,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((url) => {
      this.sessionId = parseInt(url['logId']);
      this.store.dispatch(new FetchSessionData(this.sessionId));
    });

    this.sessionData$
      .pipe(map((filterFn) => filterFn(this.sessionId)))
      .pipe(
        map((sessionData) => {
          if (sessionData) {
            this.sessionData = sessionData;
            checkSessionDataType(sessionData, 'coaching', this.router);

            this.isCoach = checkIfCoach(this.sessionData);
            this.isShadower = checkIfShadower(this.sessionData);
            this.hasPresentAttendee = checkPresentAttendee(this.sessionData);

            if (this.sessionData.egroweCoachlogTypeId) {
              this.selectedTypeId = this.sessionData.egroweCoachlogTypeId;
            }

            this.logStart = this.sessionData.startDatetime;
            this.logEnd = this.sessionData.endDatetime;
            const seconds = this.logEnd - this.logStart;
            const roundedSeconds = Math.round(seconds / 900) * 900;
            /* eslint-disable no-nested-ternary */
            this.logDuration =
              roundedSeconds !== 0
                ? roundedSeconds > 3600
                  ? 3600
                  : roundedSeconds
                : 900;
            this.timezone = this.sessionData.timezone as TimezoneLinkBack;

            if (this.sessionData?.attendees?.length) {
              this.sessionAttendees = this.sessionData.attendees.map(
                (attendee) => attendee.user
              );

              if (this.sessionAttendees?.length) {
                const first = this.sessionAttendees[0];
                this.selectedAttendee = first;
              }

              if (this.selectedAttendee?.id !== undefined) {
                this.setCompetencyList(this.selectedAttendee?.id);
              }
            } else {
              this.selectedAttendee = null;
            }
          }
        })
      )
      .subscribe();

    this.subs.push(
      this.coachingService.getCoachlogTypes().subscribe((response) => {
        this.coachlogTypesList = response.items;
        // temp to restrict log types
        this.coachlogTypesList = this.coachlogTypesList.filter(
          (logType) => logType.title === 'Coaching Conversation'
        );
      })
    );

    this.subs.push(
      this.coachingService.getAbsentReasons().subscribe((response) => {
        this.absentReasonsList = response.items;
      })
    );
  }

  onItemChange($event: UserDTO) {
    this.setCompetencyList($event.id);
  }

  onCheckboxChange($event: Event): void {
    const target = $event.target as HTMLInputElement | null;
    const coachlogUpdates = {
      is_private: false,
    };

    if (target) {
      if (target.checked === true) {
        coachlogUpdates.is_private = true;
      }
    }

    if (this.sessionData) {
      this.coachingService.updateCoachingLog(
        this.sessionData?.id,
        coachlogUpdates
      );
    }
  }

  setCompetencyList(attendeeId: number) {
    this.competenciesLoading = true;

    if (
      this.sessionData?.attendees &&
      this.sessionData.attendees.length > 0 &&
      this.sessionData.startDatetime
    ) {
      if (this.attendeeSelectApiCalls) {
        this.attendeeSelectApiCalls.unsubscribe();
      }
      this.attendeeSelectApiCalls = this.getPreviousLogId(
        attendeeId,
        this.sessionData?.startDatetime as number,
        this.selectedTypeId
      ).subscribe((logId) => {
        if (logId) {
          this.getCompetencyList(logId, attendeeId);
        } else {
          this.lastSessionCompetencies = [];
          this.competenciesLoading = false;
        }
      });
    }
  }

  getCompetencyList(logId: number, userId: number) {
    this.attendeeSelectApiCalls = this.coachingService
      .getAttendeeRubrics(logId)
      .subscribe((res) => {
        const newCompetencies = res?.decomposedByUser[userId]?.competencies;

        this.lastSessionCompetencies = [
          {
            competencies: newCompetencies,
            userId,
          },
        ];
        this.competenciesLoading = false;
      });
  }

  getPreviousLogId(
    attendeeId: number,
    sessionDate: number,
    logType: number
  ): Observable<number | null> {
    sessionDate -= 1;
    return this.coachingService
      .getPreviousLogs(attendeeId, sessionDate, logType)
      .pipe(
        map((logs) => {
          if (logs && logs.length > 0) {
            this.latestLogId = logs[0].id;
            return this.latestLogId;
          }
          return null;
        })
      );
  }

  selectLog(logId: number) {
    this.store.dispatch(new FetchSessionData(logId));
    this.selectedLogId = logId;
  }

  addCoachee(newCoachee: UserDTO | null) {
    if (newCoachee) {
      this.coachingService.addAttendee(newCoachee.id, this.sessionData?.id);
    }
  }

  removeCoachee(coachee: CoachingSessionAttendeeDTO) {
    if (this.sessionData) {
      this.sessionData.attendees = this.sessionData.attendees.filter(
        (attendee) => attendee.id !== coachee.id
      );
    }
  }

  addShadower(newShadower: UserDTO | null) {
    if (newShadower) {
      const isCoachee = this.sessionData?.attendees.find(
        (item) => item.userId === newShadower.id
      );
      if (!isCoachee) {
        this.coachingService.addShadower(newShadower.id, this.sessionData?.id);
      }
    }
  }

  saveTitle() {
    if (this.sessionData) {
      const coachlogUpdates = { title: this.sessionData?.title };
      this.coachingService.updateCoachingLog(
        this.sessionData?.id,
        coachlogUpdates
      );
    }
  }

  saveDateTime() {
    if (this.sessionData) {
      this.logEnd = this.logStart + this.logDuration;
      this.coachingService.updateCoachingLog(this.sessionData.id, {
        start_datetime: this.logStart,
        end_datetime: this.logEnd,
        timezone: this.timezone,
      });
    }
  }

  updateDateTime(timeUpdate: DatepickerOutput) {
    this.logStart = timeUpdate.time / 1000;
    this.timezone = timeUpdate.timezone;
    this.saveDateTime();
  }

  updateDuration(duration: number) {
    this.logDuration = duration;
    this.saveDateTime();
  }

  navigateNextScreen() {
    if (this.hasPresentAttendee) {
      this.router.navigate([
        `/coaching/log/${this.sessionData?.id}/competencies`,
      ]);
    }
  }

  toggleCardModal(userId: number, competencyId: number) {
    this.competencyModal.coachlogId = this.latestLogId;
    this.competencyModal.competencyId = competencyId;
    this.competencyModal.userId = userId;
    this.competencyModal.ngOnInit();
    this.competencyModal.modalShowing.next(
      !this.competencyModal.modalShowing.value
    );
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
