import { UserType } from 'src/app/common/state/user/type/user-type';
import { PrivateRoute } from 'src/app/common/types/routes';
import { CoacheeListPageComponent } from 'src/app/private/pages/coachee-list-page/coachee-list-page.component';

import { UserRole } from 'src/app/common/state/user/role/user-role';
import {
  AUTH_GUARDED_ROUTE,
  AUTH_GUARDED_ROUTE_OPERATOR,
  AUTH_ROUTE_GUARDED_ROUTE,
  AUTH_ROUTE_GUARDED_ROUTE_E2L_EMPLOYEE,
  AUTH_ROUTE_GUARDED_ROUTE_E2L_EMPLOYEE_COACHES,
  AUTH_ROUTE_GUARDED_ROUTE_OPERATOR,
} from '../../common/utilities/route.helpers';
import { AddDistrictComponent } from '../pages/add-district/add-district.component';
import { CoacheeEvidencePageComponent } from '../pages/coachee-evidence-page/coachee-evidence-page.component';
import { CoachingLogInfoPageComponent } from '../pages/coaching-log-info-page/coaching-log-info-page.component';
import { CoachingLogPageComponent } from '../pages/coaching-log-page/coaching-log-page.component';
import { CohortsPageComponent } from '../pages/cohorts-page/cohorts-page.component';
import { CoachDashboardComponent } from '../pages/dashboard/coach-dashboard/coach-dashboard.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { LeaderDashboardComponent } from '../pages/dashboard/leader-dashboard/leader-dashboard.component';
import { LearnerDashboardComponent } from '../pages/dashboard/learner-dashboard/learner-dashboard.component';
import { EditDistrictComponent } from '../pages/edit-district/edit-district.component';
import { CreateFormPageComponent } from '../pages/forms/create-form-page/create-form-page.component';
import { EditFormPageComponent } from '../pages/forms/edit-form-page/edit-form-page.component';
import { FormsListPageComponent } from '../pages/forms/forms-list-page/forms-list-page.component';
import { MyResourcesPageComponent } from '../pages/my-resources-page/my-resources-page.component';
import { ObservationFormPageComponent } from '../pages/observation/observation-form-page/observation-form-page.component';
import { ObservationInfoPageComponent } from '../pages/observation/observation-info-page/observation-info-page.component';
import { ObservationSummaryPageComponent } from '../pages/observation/observation-summary-page/observation-summary-page.component';
import { PermissionDeniedPageComponent } from '../pages/permission-denied/permission-denied.component';
import { ReportingPageComponent } from '../pages/reporting-page/reporting-page.component';
import { ResourceViewPageComponent } from '../pages/resource-view-page/resource-view-page.component';
import { ResourcesOnlineCoursesComponent } from '../pages/resources-online-courses/resources-online-courses.component';
import { ResourcesSearchComponent } from '../pages/resources-search/resources-search.component';
import { ResourcesVideoLibraryComponent } from '../pages/resources-video-library/resources-video-library.component';
import { ResourcesComponent } from '../pages/resources/resources.component';
import { RostersPageComponent } from '../pages/rosters-page/rosters-page.component';
import { UserSettingsPageComponent } from '../pages/user-settings/user-settings.component';
import { ShelfComponent } from '../shared/components/resources/shelf/shelf.component';
import { GeneralLogPageComponent } from '../pages/general/general-log-page/general-log-page.component';
import { GeneralLogInfoPageComponent } from '../pages/general/general-log-info-page/general-log-info-page.component';

export const privateRoutes: PrivateRoute[] = [
  {
    ...AUTH_GUARDED_ROUTE,
    path: '',
    component: CoachDashboardComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'permission-denied',
    title: 'Permission Denied',
    component: PermissionDeniedPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: `dashboard/${UserType.COACH}`,
    title: 'Coach Dashboard',
    component: CoachDashboardComponent,
    data: {
      roles: [UserRole.COACH],
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: `dashboard/${UserType.LEADER}`,
    title: 'Leader Dashboard',
    component: LeaderDashboardComponent,
    data: {
      roles: [
        UserRole.DISTRICT_ADMIN,
        UserRole.SCHOOL_ADMIN,
        UserRole.OPERATOR,
      ],
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: `dashboard/${UserType.LEARNER}`,
    title: 'Learner Dashboard',
    component: LearnerDashboardComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'dashboard/coachee-list',
    title: 'Coachee List',
    component: CoacheeListPageComponent,
    data: {
      roles: [UserRole.COACH],
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'dashboard/coachee-evidence',
    title: 'Coachee Evidence',
    component: CoacheeEvidencePageComponent,
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'coaching/log/:logId',
    title: 'Coaching Session redirect',
    component: DashboardComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'coaching/log/:logId/info',
    title: 'Coaching Session Info',
    component: CoachingLogInfoPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'coaching/log/:logId/competencies',
    title: 'Coaching Session Competencies',
    component: CoachingLogPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'coaching/log/:logId/growth',
    title: 'Coaching Session Coaching',
    component: CoachingLogPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'coaching/log/:logId/summary',
    title: 'Coaching Session Summary',
    component: CoachingLogPageComponent,
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'observation/log/:logId',
    title: 'Observation Form redirect',
    component: DashboardComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'observation/log/:logId/info',
    title: 'Observation Session Info',
    component: ObservationInfoPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'observation/log/:logId/form',
    title: 'Observation Session Form',
    component: ObservationFormPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'observation/log/:logId/summary',
    title: 'Observation Session Summary',
    component: ObservationSummaryPageComponent,
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'general/log/:logId',
    title: 'Observation Form redirect',
    component: DashboardComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'general/log/:logId/info',
    title: 'General Session Info',
    component: GeneralLogInfoPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'general/log/:logId/notes',
    title: 'General Session Form',
    component: GeneralLogPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources',
    title: 'Resources',
    component: ResourcesSearchComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/my',
    title: 'My Resources',
    component: MyResourcesPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/my/shelf/:shelfId',
    title: 'My Resources Shelf',
    component: ShelfComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/my/shelf/created-by-you',
    title: 'My Resources Shelf',
    component: ShelfComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/search',
    title: 'Search Results',
    component: ResourcesSearchComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/video-library',
    title: 'Video Library',
    component: ResourcesVideoLibraryComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/communities',
    title: 'Community',
    component: ResourcesComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/courses',
    title: 'Online Courses',
    component: ResourcesOnlineCoursesComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/:resourceId',
    title: 'Resource',
    component: ResourceViewPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/communities/:communityId',
    title: 'Community Post',
    component: ResourcesComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/shelves/:userId/shelf/:shelfId',
    title: 'Public Shelf',
    component: ResourcesComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'settings/user',
    title: 'User Settings',
    component: UserSettingsPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode',
    title: 'Edit District',
    component: EditDistrictComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/new-district',
    title: 'Add a District',
    component: AddDistrictComponent,
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE_E2L_EMPLOYEE_COACHES,
    path: 'coachee-evidence',
    title: 'Coachee Evidence',
    component: CoacheeEvidencePageComponent,
  },

  {
    ...AUTH_ROUTE_GUARDED_ROUTE_E2L_EMPLOYEE,
    path: 'settings/forms/:formId',
    title: 'Form templates',
    component: EditFormPageComponent,
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/rosters',
    title: 'Rosters',
    component: RostersPageComponent,
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/forms',
    title: 'Forms',
    component: FormsListPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/forms/create',
    title: 'New Form',
    component: CreateFormPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/forms/:formId',
    title: 'Edit Form',
    component: EditFormPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/rosters',
    title: 'Rosters',
    component: RostersPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/cohorts',
    title: 'Edit Form',
    component: CohortsPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'reports',
    title: 'Reports',
    component: ReportingPageComponent,
  },
];
