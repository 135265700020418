<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-container *ngIf="isMulti; else singleModel">
  <div style="clear: both; height: 4px"></div>
  <ng-select
    [labelForId]="labelId"
    (change)="logTypeSelected($event); select.blur()"
    [items]="logTypeList"
    bindLabel="id"
    [multiple]="isMulti"
    typeToSearchText="Please enter 2 or more characters"
    [typeahead]="searchInput$"
    [loading]="searchLoading"
    [(ngModel)]="selectedLogTypeList"
    (remove)="remove()"
    [style.border]="fieldInvalid ? '1px solid red' : ''"
    #select>
    <ng-template ng-multi-label-tmp>
      <span style="display: block" class="ng-placeholder"></span>
    </ng-template>
    <ng-template
      ng-option-tmp
      let-item="item"
      let-index="index"
      let-search="term">
      <app-log-type-select-label [logType]="item" />
    </ng-template>
  </ng-select>
  <ng-container *ngIf="selectedLogTypeList.length">
    <ng-container *ngFor="let eachLogType of selectedLogTypeList">
      <span class="badge badge-item" (click)="removeLogType(eachLogType)"
        >{{ eachLogType.name }}&nbsp;<i class="bi bi-x"></i> </span
      >&nbsp;
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #singleModel>
  <ng-select
    [labelForId]="labelId"
    (change)="logTypeSelected($event); select.blur()"
    [items]="logTypeList"
    bindLabel="id"
    typeToSearchText="Please enter 2 or more characters"
    [typeahead]="searchInput$"
    [loading]="searchLoading"
    [(ngModel)]="selectedLogType"
    (clear)="remove()"
    [style.border]="fieldInvalid ? '1px solid red' : ''"
    #select>
    <ng-template ng-label-tmp let-item="item">
      <app-log-type-select-label [logType]="item" />
    </ng-template>
    <ng-template
      ng-option-tmp
      let-item="item"
      let-index="index"
      let-search="term">
      <app-log-type-select-label [logType]="item" />
    </ng-template>
  </ng-select>
</ng-template>
