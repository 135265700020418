<div class="container">
  <div class="row mt-3">
    <div class="col-12">
      <button
        *ngIf="strand"
        type="button"
        class="btn btn-link text-decoration-none strand-title"
        (click)="isCollapsed = !isCollapsed">
        {{ strand.title }}
        <span
          ><i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i
        ></span>
      </button>
    </div>
  </div>
  <div *ngIf="strand">
    <div class="row">
      <div class="col-12" *ngIf="strand && competencyId">
        <app-goal-slider
          [userId]="userId"
          [coachlogId]="logId"
          [competencyId]="competencyId"
          [strandId]="strand.id"></app-goal-slider>
      </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div class="container p-0">
        <div class="row g-lg-2 g-xxl-3 row-cols-lg-4">
          <div class="col-lg mb-3">
            <app-leveled-competency-strand-level
              [level]="1"
              [levelName]="level1name"
              [rubricId]="rubricId"
              [competencyId]="competencyId"
              [strandId]="strand.id"
              [userId]="userId"
              [logId]="logId"></app-leveled-competency-strand-level>
          </div>
          <div class="col-lg mb-3">
            <app-leveled-competency-strand-level
              [level]="2"
              [levelName]="level2name"
              [rubricId]="rubricId"
              [competencyId]="competencyId"
              [strandId]="strand.id"
              [userId]="userId"
              [logId]="logId"></app-leveled-competency-strand-level>
          </div>
          <div class="col-lg mb-3">
            <app-leveled-competency-strand-level
              [level]="3"
              [levelName]="level3name"
              [rubricId]="rubricId"
              [competencyId]="competencyId"
              [strandId]="strand.id"
              [userId]="userId"
              [logId]="logId"></app-leveled-competency-strand-level>
          </div>
          <div class="col-lg mb-3">
            <app-leveled-competency-strand-level
              [level]="4"
              [levelName]="level4name"
              [rubricId]="rubricId"
              [competencyId]="competencyId"
              [strandId]="strand.id"
              [userId]="userId"
              [logId]="logId"></app-leveled-competency-strand-level>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
