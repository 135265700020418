<ng-container *ngIf="shelf">
  <div class="shelf-item d-flex align-items-center justify-content-between">
    <div class="ps-2">
      <div
        (click)="shelfClickedHandler()"
        routerLink="/resources/my/shelf/{{ shelf.id }}"
        class="d-flex align-items-center shelf-title">
        <i class="bi bi-folder me-1"></i>
        {{ shelf.title }}
      </div>
      <div *ngIf="shelf.description" class="shelf-description py-1">
        {{ shelf.description }}
      </div>
    </div>
    <div class="resources ms-auto pe-2">
      <ng-container *ngIf="shelf.numbShelves && shelf.numbShelves > 0">
        {{ shelf.numbShelves }}
        <ng-container [ngPlural]="shelf.numbShelves">
          <ng-template ngPluralCase="=1">Folder</ng-template>
          <ng-template ngPluralCase="other">Folders</ng-template>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="shelf.numbShelves > 0 && shelf.numbItems > 0">
        ,
      </ng-container>
      <ng-container *ngIf="shelf.numbItems && shelf.numbItems > 0">
        {{ shelf.numbItems }}
        <ng-container [ngPlural]="shelf.numbItems">
          <ng-template ngPluralCase="=1">Resource</ng-template>
          <ng-template ngPluralCase="other">Resources</ng-template>
        </ng-container>
      </ng-container>
    </div>
    <div class="actions d-flex">
      <!-- <div aria-label="share" role="button" class="p-1">
        <i class="bi bi-share mx-2" (click)="copyClickedHandler()"></i>
      </div> -->
      <div aria-label="favorite" role="button" class="p-1 mx-2">
        <i
          [ngClass]="shelf.usersFavorite ? 'bi bi-star-fill' : 'bi bi-star'"
          (click)="starClickedHandler()"></i>
      </div>
    </div>
  </div>
</ng-container>
