import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UserService } from 'src/app/common/state/user/user.service';
import {
  CreateUserPayloadDTO,
  UserDTO,
} from 'src/app/common/state/user/user.dto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import { SchoolSearchService } from '../../services/school-search/school-search.service';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  styleUrls: ['./create-user-modal.component.scss'],
})
export class CreateUserModalComponent
  implements AfterViewInit, OnChanges, OnInit
{
  @Input() showModal = false;

  @Input() districtId = 0;

  @Output() showModalChange = new EventEmitter();

  formSubmitted = false;

  isLoading = false;

  serverError = '';

  createdUser: UserDTO | null;

  isOpen = false;

  modalShowing: BehaviorSubject<boolean> = new BehaviorSubject(false);

  schoolList: SchoolDTO[];

  form: FormGroup;

  @ViewChild('createUserModal') createUserModal: ModalComponent;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private schoolService: SchoolSearchService
  ) {
    this.clearForm();
  }

  ngOnInit(): void {
    // load the school list
    this.schoolService
      .getDistrictSchools(this.districtId)
      .subscribe((results) => {
        this.schoolList = results;
        if (
          this.schoolList.length === 1 &&
          this.schoolList[0].is_default === 1
        ) {
          this.form.controls['school_id'].setValue(this.schoolList[0].id);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showModal'].currentValue) {
      this.modalShowing.next(true);
    }
    this.clearForm();
  }

  ngAfterViewInit() {
    this.createUserModal.isDismissing.subscribe(() => {
      this.modalShowing.next(false);
    });

    this.modalShowing.subscribe((shown) => {
      if (shown) {
        if (!this.isOpen) {
          this.createUserModal.open();
          this.isOpen = true;
        }
      } else if (this.isOpen) {
        this.createUserModal.close();
        this.isOpen = false;
      }
    });
  }

  clearForm() {
    let defaultSchool = 0;
    if (
      this.schoolList &&
      this.schoolList.length === 1 &&
      this.schoolList[0].is_default === 1
    ) {
      defaultSchool = this.schoolList[0].id;
    }

    this.form = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      title: ['', Validators.required],
      password: ['', Validators.required],
      school_id: [defaultSchool],
      district_id: this.districtId,
      coachee_type: ['Teacher', Validators.required],
    });
  }

  get f() {
    return this.form.controls;
  }

  closeModal() {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
    this.modalShowing.next(false);
    this.clearForm();
    this.formSubmitted = false;
    this.createdUser = null;
    this.isLoading = false;
    this.serverError = '';
    if (this.createUserModal) {
      this.createUserModal.close();
    }
  }

  createUser(): boolean {
    this.formSubmitted = true;
    this.createdUser = null;

    if (this.form.invalid) {
      return false;
    }

    this.isLoading = true;

    const payload: CreateUserPayloadDTO = {
      first_name: this.f['first_name'].value,
      last_name: this.f['last_name'].value,
      title: this.f['title'].value,
      email: this.f['email'].value,
      district_id: this.f['district_id'].value,
      school_id: this.f['school_id'].value,
      coachee_type: this.f['coachee_type'].value,
      password: this.f['password'].value,
    };

    this.userService.createUser(payload).subscribe({
      error: (error) => {
        this.isLoading = false;
        this.serverError = error.error.message;
      },
      next: (newUser) => {
        this.isLoading = false;
        this.serverError = '';
        this.clearForm();
        this.createdUser = newUser;
      },
    });
    return true;
  }
}
