<div>
  <span class="filters-label">Applied Filters:</span>&nbsp; &nbsp;
  <div
    class="p-2 badge badge-item"
    *ngIf="coachlogSearchOptions.from_date && coachlogSearchOptions.to_date">
    <span class="bold-text"><i class="bi bi-table"></i> Dates: </span
    >{{
      coachlogSearchOptions.from_date
        | showDateTimezone : "MMM d, yyyy" : undefined : false
    }}
    -
    {{
      coachlogSearchOptions.to_date
        | showDateTimezone : "MMM d, yyyy" : undefined : false
    }}
  </div>
  <div
    class="p-2 badge badge-item"
    *ngIf="coachlogSearchOptions.district_item"
    (click)="removeFilter('district_item')">
    <span class="bold-text"><i class="bi bi-geo-alt"></i> District: </span
    ><app-district-name
      [districtId]="coachlogSearchOptions.district_item.id" />&nbsp;
    <i class="bi bi-x"></i>
  </div>
  <ng-container *ngFor="let school of coachlogSearchOptions.school_items">
    <div
      class="p-2 badge badge-item"
      (click)="removeFilterFromList('schools', school)">
      <span class="bold-text"><i class="bi bi-geo-alt"></i> School: </span>
      <app-school-name [schoolId]="school.id" />&nbsp; <i class="bi bi-x"></i>
    </div>
  </ng-container>
  <div
    class="p-2 badge badge-item"
    *ngIf="coachlogSearchOptions.logtype_item"
    (click)="removeFilter('logtype')">
    <span class="bold-text"><i class="bi bi-collection"></i> Log Type: </span
    ><app-log-type-name
      [logTypeId]="coachlogSearchOptions.logtype_item.id" />&nbsp;
    <i class="bi bi-x"></i>
  </div>

  <ng-container
    *ngFor="let competency of coachlogSearchOptions.competency_items">
    <div
      class="p-2 badge badge-item"
      (click)="removeFilterFromList('competency_items', competency)">
      <span class="bold-text"
        ><i class="bi bi-bi-person"></i> Competency:
      </span>
      <app-competency-name [competencyId]="competency.id" />&nbsp;
      <i class="bi bi-x"></i>
    </div>
  </ng-container>

  <ng-container *ngFor="let coach of coachlogSearchOptions.coach_items">
    <div
      class="p-2 badge badge-item"
      (click)="removeFilterFromList('coach_items', coach)">
      <span class="bold-text"><i class="bi bi-bi-person"></i> Coach: </span>
      <app-users-name [userProfile]="coach.profile" />&nbsp;
      <i class="bi bi-x"></i>
    </div>
  </ng-container>

  <ng-container *ngFor="let coachee of coachlogSearchOptions.coachee_items">
    <div
      class="p-2 badge badge-item"
      (click)="removeFilterFromList('coachee_items', coachee)">
      <span class="bold-text"><i class="bi bi-bi-person"></i> Coachee: </span>
      <app-users-name [userProfile]="coachee.profile" />&nbsp;
      <i class="bi bi-x"></i>
    </div>
  </ng-container>
</div>
