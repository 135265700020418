import { Injectable } from '@angular/core';
import {
  KpiReportFilter,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private apiService: APICoreService) {}

  getLeaderGlanceData(
    requestParams: ReportRequestParams,
    userId: number,
    schoolYearId: number
  ) {
    const districtFilter = requestParams.filters.find(
      (filter: KpiReportFilter) => filter.codename === 'district'
    );

    if (!districtFilter) {
      requestParams.filters.push({
        codename: 'district',
        title: 'District',
        value: [userId.toString()],
        displayName: 'District',
      });
    }

    const schoolYearFilter = requestParams.filters.find(
      (filter: KpiReportFilter) => filter.codename === 'school_year'
    );

    if (!schoolYearFilter) {
      requestParams.filters.push({
        codename: 'school_year',
        title: 'School Year',
        value: [schoolYearId.toString()],
        displayName: 'School Year',
      });
    }

    return this.apiService.getRequest('reporting/custom', requestParams);
  }
}
