<div class="card border-0">
  <ng-container *ngIf="sessions && !isLoading; else placeHolder">
    <div class="card-header pt-3">
      <h2
        id="upcoming-sessions-title"
        class="card-title float-start"
        (click)="triggerCollapse()">
        Upcoming Sessions
        <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill ml-5"></i>
      </h2>
      <div class="card-tools float-end">
        <ng-container *ngIf="user$ | async as user">
          <i
            class="bi bi-plus-circle fs-6 tool"
            [style.cursor]="'pointer'"
            *ngIf="user.isCoach"
            ngbTooltip="Schedule a new session"
            (click)="createSessionParentModal.openModal()"></i>
        </ng-container>
      </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div
        class="card-body pt-0 overflow-auto"
        tabindex="0"
        role="group"
        aria-labelledby="upcoming-sessions-title"
        style="height: 18rem">
        <ng-container *ngIf="sessions && sessions.length > 0; else noSessions">
          <div class="session-wrapper">
            <ng-container *ngFor="let session of sessions">
              <div
                class="session-item d-flex flex-row flex-nowrap align-items-center justify-content-between py-1">
                <div class="session-item__details">
                  <div class="title">
                    <a [routerLink]="getSessionLink(session)" target="_blank"
                      >{{ session.title }}
                      <i class="bi bi-box-arrow-up-right"></i
                    ></a>
                  </div>
                  <div *ngIf="session.startDatetime" class="start-date">
                    {{ session.startDatetime | showDateTimezone }}
                  </div>
                </div>
                <div
                  class="session-item__coachees d-flex flex-nowrap align-items-center">
                  <ng-container
                    *ngFor="
                      let attendee of session.attendees | slice : 0 : 2;
                      let i = index
                    ">
                    <app-avatar
                      style="margin-left: -5px"
                      [profile]="attendee.user.profile"
                      [avatarSize]="'small'">
                    </app-avatar>
                  </ng-container>
                  <ng-container *ngIf="session.attendees.length - 2 > 0">
                    <div class="ms-1">+{{ session.attendees.length - 2 }}</div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #noSessions>
          <div
            class="no-sessions d-flex justify-content-center align-items-center">
            No upcoming sessions scheduled
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-template #placeHolder>
    <div class="card-header pt-3">
      <div class="card-title placeholder-glow">
        <span class="placeholder col-5"></span>
      </div>
    </div>
    <div class="card-body pt-0" style="height: 18rem">
      <div class="card-text placeholder-glow">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
      <div class="card-text placeholder-glow mt-2">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
    </div>
  </ng-template>

  <app-create-session-modal #createSessionParentModal />
</div>
