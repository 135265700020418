<div class="container container-main">
  <app-dashboard-header
    *ngIf="user"
    [dashboardType]="user.type"
    [showSwitcher]="false"
    [showAddCoachee]="true"
    (coacheeEvent)="coacheesUpdated()"></app-dashboard-header>
  <div class="card border-0 p-3 mt-3 overflow-y table-container">
    <div
      class="tool position-absolute top-0 start-100 translate-middle rounded-circle fs-3"
      style="background-color: white; line-height: 1rem">
      <i
        [style.cursor]="'pointer'"
        routerLink="/dashboard"
        ngbTooltip="Close"
        class="bi bi-x-circle color-primary"></i>
    </div>
    <div
      class="table table-toolbar d-flex justify-content-between align-items-center">
      <div class="coachee-search table-search">
        <input
          type="search"
          class="form-control ms-1"
          placeholder="Filter coachees"
          aria-label="Search"
          (input)="searchCoachees($event)" />
      </div>
      <div class="me-3 ms-auto">
        <button
          placement="bottom"
          aria-label="Schedule session for coachees"
          (click)="createSessionParentModal.openModal()"
          class="btn next-session-icon border-0 {{ calendarIconStatus }}">
          <i
            class="bi bi-calendar"
            ngbTooltip="Schedule session for coachees"></i>
        </button>
      </div>
    </div>
    <ng-container *ngIf="!isLoading; else loadingTemplate">
      <table *ngIf="tableData" class="table">
        <thead>
          <tr class="align-middle">
            <th class="text-center">
              <input
                (change)="handleCheckAll()"
                [(ngModel)]="masterSelected"
                type="checkbox"
                class="form-check-input"
                aria-label="Select all coachees" />
            </th>
            <th scope="col" sortable="name" (sort)="onSort($event)">
              Coachees ({{ totalCoachees }})
              <i class="bi bi-caret-down {{ sortIconTemp.name }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="badges"
              class="sortable-header text-center d-none d-md-table-cell">
              Badges
              <i class="bi bi-caret-down {{ sortIconTemp.badges }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="celebrations"
              class="sortable-header text-center d-none d-md-table-cell">
              Celebrations
              <i class="bi bi-caret-down {{ sortIconTemp.celebrations }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="sessions"
              class="sortable-header text-center d-none d-md-table-cell">
              Sessions
              <i class="bi bi-caret-down {{ sortIconTemp.sessions }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="classroomVisits"
              class="sortable-header sortable-header-large text-center d-none d-md-table-cell">
              Classroom Visits
              <i
                class="bi bi-caret-down {{ sortIconTemp.classroomVisits }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="nextSession"
              class="sortable-header text-center">
              Next Session
              <i class="bi bi-caret-down {{ sortIconTemp.nextSession }}"></i>
            </th>
            <th class="notes-header d-none d-md-table-cell">Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let coachee of tableData"
            style="border-bottom: 1px solid #78858e5a"
            class="align-middle">
            <td class="text-center">
              <input
                (change)="onCheckboxChange($event)"
                [(ngModel)]="coachee.isSelected"
                class="form-check-input"
                type="checkbox"
                id="{{ 'checkbox' + coachee.id }}"
                value="{{ coachee.id }}"
                aria-label="select" />
            </td>
            <td>
              <label
                for="{{ 'checkbox' + coachee.id }}"
                style="height: 6rem"
                class="d-flex justify-content-start align-items-center">
                <app-avatar
                  class="me-2"
                  [profile]="coachee.profile"
                  [avatarSize]="'medium'">
                </app-avatar>
                {{ coachee.profile.first_name }}
                {{ coachee.profile.last_name }}
              </label>
            </td>
            <td class="text-center d-none d-md-table-cell">
              <span class="stat-number">{{ coachee.badgeCount }}</span>
            </td>
            <td class="text-center d-none d-md-table-cell">
              <span class="stat-number">{{ coachee.celebrationsCount }}</span>
            </td>
            <td class="text-center d-none d-md-table-cell">
              <span class="stat-number">{{ coachee.sessionCount }}</span>
            </td>
            <td class="text-center d-none d-md-table-cell">
              <span class="stat-number">{{ coachee.classroomvisitCount }}</span>
            </td>
            <td class="text-center">
              <span class="next-session-date">
                <a
                  href="coaching/log/{{ coachee.nextSessionId }}/info"
                  target="_blank"
                  *ngIf="coachee.nextSessionId && coachee.nextSessionDate">
                  {{ coachee.nextSessionDate * 1000 | date : "M/d" }}
                  <i
                    class="bi bi-box-arrow-up-right ms-2"
                    role="img"
                    aria-label="Go to next session"></i>
                </a>
              </span>
              <span class="text-muted" *ngIf="!coachee.nextSessionId"
                >&mdash;</span
              >
            </td>
            <td class="notes notes-body d-none d-md-table-cell">
              <app-coachee-notes [coachee]="coachee"></app-coachee-notes>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-template #loadingTemplate>
      <app-loader></app-loader>
    </ng-template>
  </div>
  <app-create-session-modal
    #createSessionParentModal
    [attendeeUserProfiles]="attendeeUserProfiles" />
</div>
