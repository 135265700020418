import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Observable, Subscription } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  checkIfCoach,
  checkIfShadower,
  checkPresentAttendee,
} from 'src/app/common/utilities/session-helpers';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import { DatepickerOutput } from 'src/app/private/shared/components/datepicker/datepicker.component';
import {
  CoachingSessionAttendeeDTO,
  CoachingSessionDTO,
} from 'src/app/private/shared/dtos/coaching-session.dto';
import { checkSessionDataType } from 'src/app/private/shared/helpers/coachee-log.utilities';
import { CoachingLogService } from 'src/app/private/shared/services/coaching-log/coaching-log.service';
import { FetchSessionData } from 'src/app/private/shared/state/coaching-log/coaching-log.actions';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';
import {
  AbsentReasonsAPIResponse,
  CoachlogTypesAPIResponse,
} from 'src/app/private/shared/types/responses/coaching-log.responses';

@Component({
  selector: 'app-observation-info-page',
  templateUrl: './observation-info-page.component.html',
  styleUrls: ['./observation-info-page.component.scss'],
})
export class ObservationInfoPageComponent implements OnInit, OnDestroy {
  sessionId: number;

  sessionData$ = select(CoachingLogState.getSessionData);

  user$ = select(UserState.getUser);

  sessionData: CoachingSessionDTO | null;

  selectedAttendee: UserDTO | null;

  sessionAttendees: UserDTO[] | null;

  subs: Subscription[] = [];

  selectedLogId: number | null;

  attendeeSelectApiCalls: Subscription | null;

  coachlogTypesList: CoachlogTypesAPIResponse[];

  absentReasonsList: AbsentReasonsAPIResponse[];

  isCoach: boolean;

  isShadower: boolean;

  selectedTypeId: number;

  logStart: number;

  logEnd: number;

  logDuration = 1800;

  timezone: TimezoneLinkBack;

  latestLogId: number;

  durationList = [
    { value: 900, label: '15 minutes' },
    { value: 1800, label: '30 minutes' },
    { value: 2700, label: '45 minutes' },
    { value: 3600, label: '1 hour' },
  ];

  hasPresentAttendee = false;

  constructor(
    private route: ActivatedRoute,
    public coachingService: CoachingLogService,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((url) => {
      this.sessionId = parseInt(url['logId']);
      this.store.dispatch(new FetchSessionData(this.sessionId));
    });

    this.sessionData$
      .pipe(map((filterFn) => filterFn(this.sessionId)))
      .pipe(
        map((sessionData) => {
          if (sessionData) {
            this.sessionData = sessionData;

            checkSessionDataType(sessionData, 'observation', this.router);

            this.isCoach = checkIfCoach(this.sessionData);
            this.isShadower = checkIfShadower(this.sessionData);
            this.hasPresentAttendee = checkPresentAttendee(this.sessionData);

            if (this.sessionData.egroweCoachlogTypeId) {
              this.selectedTypeId = this.sessionData.egroweCoachlogTypeId;
            }

            this.logStart = this.sessionData.startDatetime;
            this.logEnd = this.sessionData.endDatetime;
            const seconds = this.logEnd - this.logStart;
            const roundedSeconds = Math.round(seconds / 900) * 900;
            /* eslint-disable no-nested-ternary */
            this.logDuration =
              roundedSeconds !== 0
                ? roundedSeconds > 3600
                  ? 3600
                  : roundedSeconds
                : 900;
            this.timezone = this.sessionData.timezone as TimezoneLinkBack;

            if (!this.selectedAttendee && this.sessionData?.attendees?.length) {
              if (!this.sessionAttendees) {
                this.sessionAttendees = [];
              }
              this.sessionAttendees = this.sessionData.attendees.map(
                (attendee) => attendee.user
              );

              if (this.sessionAttendees?.length) {
                const first = this.sessionAttendees[0];
                this.selectedAttendee = first;
              }
            }
          }
        })
      )
      .subscribe();

    this.subs.push(
      this.coachingService.getCoachlogTypes().subscribe((response) => {
        this.coachlogTypesList = response.items;
        // temp to restrict log types
        this.coachlogTypesList = this.coachlogTypesList.filter(
          (logType) => logType.title === 'Coaching Conversation'
        );
      })
    );

    this.subs.push(
      this.coachingService.getAbsentReasons().subscribe((response) => {
        this.absentReasonsList = response.items;
      })
    );
  }

  getPreviousLogId(
    attendeeId: number,
    sessionDate: number,
    logType: number
  ): Observable<number | null> {
    sessionDate -= 1;
    return this.coachingService
      .getPreviousLogs(attendeeId, sessionDate, logType)
      .pipe(
        map((logs) => {
          if (logs && logs.length > 0) {
            this.latestLogId = logs[0].id;
            return this.latestLogId;
          }
          return null;
        })
      );
  }

  selectLog(logId: number) {
    this.store.dispatch(new FetchSessionData(logId));
    this.selectedLogId = logId;
  }

  addCoachee(newCoachee: UserDTO | null) {
    if (newCoachee) {
      this.coachingService.addAttendee(newCoachee.id, this.sessionData?.id);
    }
  }

  removeCoachee(coachee: CoachingSessionAttendeeDTO) {
    if (this.sessionData) {
      this.sessionData.attendees = this.sessionData.attendees.filter(
        (attendee) => attendee.id !== coachee.id
      );
    }
  }

  addShadower(newShadower: UserDTO | null) {
    if (newShadower) {
      const isCoachee = this.sessionData?.attendees.find(
        (item) => item.userId === newShadower.id
      );
      if (!isCoachee) {
        this.coachingService.addShadower(newShadower.id, this.sessionData?.id);
      }
    }
  }

  saveTitle() {
    if (this.sessionData) {
      const coachlogUpdates = { title: this.sessionData?.title };
      this.coachingService.updateCoachingLog(
        this.sessionData?.id,
        coachlogUpdates
      );
    }
  }

  saveDateTime() {
    if (this.sessionData) {
      this.logEnd = this.logStart + this.logDuration;
      this.coachingService.updateCoachingLog(this.sessionData.id, {
        start_datetime: this.logStart,
        end_datetime: this.logEnd,
        timezone: this.timezone,
      });
    }
  }

  updateDateTime(timeUpdate: DatepickerOutput) {
    this.logStart = timeUpdate.time / 1000;
    this.timezone = timeUpdate.timezone;
    this.saveDateTime();
  }

  updateDuration(duration: number) {
    this.logDuration = duration;
    this.saveDateTime();
  }

  navigateNextScreen() {
    if (this.hasPresentAttendee) {
      this.router.navigate([`/observation/log/${this.sessionData?.id}/form`]);
    }
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
