import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { EChartsOption } from 'echarts';
import {
  KpiReportFilter,
  ReportApiResponse,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { map } from 'rxjs';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';

import { CURRENT_SCHOOL_YEAR } from '../../../enums/reports.enum';
import { ChartType } from '../../../enums/chart-type.enum';
import { ChartGrid, chartOptionsHelper } from './chart-options-helper';

@Component({
  selector: 'app-report-controller',
  templateUrl: './report-controller.component.html',
  styleUrls: ['./report-controller.component.scss'],
})
export class ReportControllerComponent
  implements AfterViewInit, OnInit, OnChanges
{
  @Input() reportTitle = '';

  @Input() chartType: ChartType = ChartType.Bar;

  @Input() cardHeight: string;

  @Input() xAxisLabel = '';

  @Input() yAxisLabel = '';

  @Input() requestParams: ReportRequestParams;

  @Input() percentage: boolean;

  @Input() yNameGap = 25;

  @Input() xNameGap = 30;

  @Input() gridDimensions: ChartGrid = {
    top: '10px',
    left: '23px',
    right: '15px',
    bottom: '25px',
  };

  @Input() chartColor: string;

  @Output() errorMessage = new EventEmitter<string>();

  apiResponse: ReportApiResponse;

  chartOptions: EChartsOption;

  isLoading = true;

  user$ = select(UserState.getUser);

  user: User | null = null;

  eChartType: ChartType.Bar | ChartType.Line;

  constructor(private apiService: APICoreService) {}

  ngAfterViewInit() {
    this.user$
      .pipe(
        map((user) => {
          if (user) {
            this.user = user;
            this.getDashboardData();
          }
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    if (this.chartType === ChartType.Area) {
      this.eChartType = ChartType.Line;
    } else if (this.chartType === ChartType.HorizontalBar) {
      this.eChartType = ChartType.Bar;
    } else if (
      this.chartType === ChartType.Bar ||
      this.chartType === ChartType.Line
    ) {
      this.eChartType = this.chartType;
    }
  }

  ngOnChanges(): void {
    this.getDashboardData();
  }

  checkDefaultParams(params: ReportRequestParams) {
    // bail if params are undefined
    if (typeof params === 'undefined') {
      return params;
    }

    const districtFilter = params.filters.find(
      (filter: KpiReportFilter) => filter.codename === 'district'
    );
    if (!districtFilter && this.user && this.user.district) {
      params.filters.push({
        codename: 'district',
        title: 'District',
        value: [this.user.district.id.toString()],
        displayName: 'District',
      });
    }

    const schoolYearFilter = params.filters.find(
      (filter: KpiReportFilter) => filter.codename === 'school_year'
    );
    if (!schoolYearFilter) {
      params.filters.push({
        codename: 'school_year',
        title: 'School Year',
        value: [CURRENT_SCHOOL_YEAR],
        displayName: 'School Year',
      });
    }
    if (!params.dimension) {
      params.dimension = 'month_of_school_year';
    }
    return params;
  }

  getDashboardData(): void {
    this.requestParams = this.checkDefaultParams(this.requestParams);
    this.isLoading = true;
    this.apiService
      .getRequest('reporting/custom', this.requestParams)
      .subscribe((value) => {
        this.apiResponse = value;
        this.handleResponse();
        this.isLoading = false;
        if (value.valid === false) {
          const errorMessage = value.error_messages[0];
          this.errorMessage.emit(errorMessage);
        }
      });
  }

  handleResponse() {
    if (this.apiResponse.datasets.length !== 0) {
      this.chartOptions = chartOptionsHelper(this.chartType, {
        title: {
          text: this.reportTitle,
        },
        grid: this.gridDimensions,
        yAxis: {
          name: this.yAxisLabel,
          nameGap: this.yNameGap,
          axisLabel: {
            // converts values to percentage based on input
            ...(this.percentage && {
              // eslint-disable-next-line func-names
              formatter: (val: number) => `${val * 100}%`,
            }),
          },
        },
        xAxis: {
          name: this.xAxisLabel,
          data: this.apiResponse?.labels,
          nameGap: this.xNameGap,
        },
        series: [
          {
            type: this.eChartType,
            data: this.apiResponse?.labels.map(
              (label: string, index: number) => ({
                groupId: label,
                value: this.apiResponse?.datasets[0].data[index],
              })
            ),
            ...(this.chartColor && {
              itemStyle: {
                color: this.chartColor,
              },
            }),
            ...(this.chartType === ChartType.Area && { areaStyle: {} }),
          },
        ],
      });
    } else {
      this.chartOptions = {
        title: {
          show: true,
          textStyle: {
            color: 'grey',
            fontSize: 20,
            fontWeight: 'lighter',
          },
          text: 'No Data',
          left: 'center',
          top: 'center',
        },
        grid: this.gridDimensions,
        xAxis: {
          show: false,
          nameGap: this.xNameGap,
        },
        yAxis: {
          show: false,
          nameGap: this.yNameGap,
        },
        series: [],
      };
    }
  }
}
