import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { ngbDateStructFromDate } from 'src/app/common/utilities/date-helpers';
import {
  getTzRegionFromLinkBack,
  TimezoneLinkBack,
  timezones,
} from 'src/app/common/utilities/time-helpers';

import {
  TimePickerShift,
  datepickerDisplayTime,
  getValidTimepickerValue,
} from './datepicker.helpers';

export interface DatepickerOutput {
  time: number;
  timezone: TimezoneLinkBack;
}

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit {
  @Input() timepicker = false;

  @Input() defaultTime = '9:00';

  @Input() pickTimezones = true;

  @Input() timezone: TimezoneLinkBack;

  @Input() minDate: Date | null;

  @Output() dateSelectedUnixTimestamp: EventEmitter<DatepickerOutput> =
    new EventEmitter();

  @Input() initialDateUnixTimestamp = 0;

  @ViewChild('timepickerElement') timepickerElement: HTMLSelectElement;

  availableTimes: { value: string; display: string }[] = [];

  selectedDate: NgbDateStruct;

  selectedTimezone: TimezoneLinkBack;

  selectedTime = '9:00';

  timezoneList: { value: string; display: string }[] = [];

  setDate(): void {
    const date = DateTime.fromJSDate(
      new Date(
        `${this.selectedDate.year}-${this.selectedDate.month}-${this.selectedDate.day} ${this.selectedTime}`
      ),
      { zone: getTzRegionFromLinkBack(this.selectedTimezone) }
    );
    this.dateSelectedUnixTimestamp.emit({
      time: date.toMillis(),
      timezone: this.selectedTimezone,
    });
  }

  generateAvailableTimes(): void {
    // 5am to 8:45pm
    Array.from(Array.from({ length: 15 }).keys()).forEach((hour) => {
      hour = (hour as number) + 5;
      ['00', '15', '30', '45'].forEach((minute) => {
        const value = `${hour}:${minute}`;
        this.availableTimes.push({
          value,
          display: datepickerDisplayTime(hour, minute),
        });
      });
    });
  }

  generateTimezones(): void {
    this.timezoneList = Object.keys(TimezoneLinkBack).map((key) => ({
      value: TimezoneLinkBack[key as keyof typeof TimezoneLinkBack],
      display: TimezoneLinkBack[key as keyof typeof TimezoneLinkBack],
    }));
  }

  constructor(private config: NgbDatepickerConfig) {
    this.generateAvailableTimes();
    this.generateTimezones();
  }

  ngOnInit(): void {
    if (!this.timezone) {
      this.selectedTimezone =
        (timezones.find(
          (tz) =>
            tz.regional === Intl.DateTimeFormat().resolvedOptions().timeZone
        )?.linkBack as TimezoneLinkBack) || TimezoneLinkBack.Central;
    } else {
      this.selectedTimezone = this.timezone;
    }
    if (this.initialDateUnixTimestamp !== 0) {
      const date = DateTime.fromSeconds(this.initialDateUnixTimestamp, {
        zone: this.selectedTimezone,
      }).toJSDate();
      this.selectedDate = ngbDateStructFromDate(date);
      this.selectedTime = getValidTimepickerValue(
        date,
        TimePickerShift.FORWARD
      );
    } else {
      this.selectedDate = ngbDateStructFromDate(new Date());
      this.selectedTime = this.defaultTime;
    }
    this.setDate();

    if (this.minDate) {
      const current = this.minDate;
      this.config.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate(),
      };
      this.config.outsideDays = 'hidden';
    }
  }
}
