<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-container>
  <div style="clear: both; height: 4px"></div>
  <ng-select
    [labelForId]="labelId"
    (change)="userSelected($event); select.blur()"
    [items]="userList | async"
    bindLabel="id"
    [clearable]="true"
    typeToSearchText="Please enter 2 or more characters"
    [typeahead]="searchInput$"
    [loading]="searchLoading"
    [disabled]="disabled"
    [(ngModel)]="internalSelectedUser"
    [placeholder]="placeholder"
    #select>
    <ng-template ng-multi-label-tmp>
      <span style="display: block" class="ng-placeholder"></span>
    </ng-template>
    <ng-template
      ng-option-tmp
      let-item="item"
      let-index="index"
      let-search="term">
      <app-user-select-label [user]="item" />
    </ng-template>
  </ng-select>

  <ng-container
    *ngIf="
      internalSelectedUserList &&
      internalSelectedUserList.length &&
      !hideSelectedUserList
    ">
    <div>
      <ng-container *ngFor="let eachUser of internalSelectedUserList">
        <span
          class="badge badge-item mt-2"
          (click)="removeUserFromList(eachUser)"
          >{{ eachUser.profile.first_name }}
          {{ eachUser.profile.last_name }}&nbsp;<i class="bi bi-x"></i> </span
        >&nbsp;
      </ng-container>
    </div>
  </ng-container>
</ng-container>
