import { Component, OnInit } from '@angular/core';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import {
  ResourceFiltersDTO,
  ResourceListItemDTO,
} from '../../shared/dtos/resources.dto';
import { ResourcesSearchService } from '../../shared/services/resources/resources-search.service';

@Component({
  selector: 'app-resources-search',
  templateUrl: './resources-search.component.html',
  styleUrls: ['./resources-search.component.scss'],
})
export class ResourcesSearchComponent implements OnInit {
  resourceList: ResourceListItemDTO[];

  currentSearchTerm = '';

  inputValue: string;

  isLoading = true;

  searchMetaData: PaginationDTO | null;

  currentFilters: ResourceFiltersDTO;

  constructor(private resourceService: ResourcesSearchService) {}

  ngOnInit(): void {
    this.resourceService.searchResultsMetaObs.subscribe((metaData) => {
      this.searchMetaData = metaData;
    });

    this.resourceService.searchFilters.subscribe((searchFilters) => {
      this.currentFilters = searchFilters as ResourceFiltersDTO;
    });

    this.resourceService.searchTrigger.subscribe(() => {
      this.getResources();
    });

    this.getResources();
  }

  getResources() {
    this.currentFilters.search = this.inputValue;
    this.isLoading = true;
    const searchObservable = this.resourceService.performSearch();
    searchObservable
      .forEach((item) => {
        this.resourceList = item;
      })
      .then(() => {
        this.isLoading = false;
      });
  }

  onSearchFieldKeydown(event: { key: string }, searchTerm: string) {
    if (event.key === 'Enter') {
      this.resourceService.setFilters({
        search: searchTerm,
      });
    }
  }

  pageChanged() {
    if (this.searchMetaData) {
      this.resourceService.setFilters({
        page: this.searchMetaData.currentPage,
        search: this.currentSearchTerm,
      });
    }
  }
}
