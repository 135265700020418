import { Component, OnInit } from '@angular/core';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';

import { CoachingSessionDTO } from '../../../dtos/coaching-session.dto';
import { getSessionLink } from '../../../helpers/coachee-log.utilities';
import { CoachingLogService } from '../../../services/coaching-log/coaching-log.service';

@Component({
  selector: 'app-card-upcoming-sessions',
  templateUrl: './card-upcoming-sessions.component.html',
  styleUrls: ['./card-upcoming-sessions.component.scss'],
})
export class CardUpcomingSessionsComponent implements OnInit {
  isCollapsed = this.cardStorageService.getCollapsedState('upcomingsessions');

  isLoading = true;

  sessions: CoachingSessionDTO[] = [];

  user$ = select(UserState.getUser);

  getSessionLink = getSessionLink;

  constructor(
    private coachingLogService: CoachingLogService,
    private cardStorageService: CardStorageService
  ) {}

  ngOnInit(): void {
    this.getSessions();

    this.coachingLogService.coachlogCreated.subscribe(() => {
      this.getSessions();
    });
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'upcomingsessions',
      this.isCollapsed
    );
  }

  getSessions(): void {
    this.coachingLogService.getUpcomingSessions().subscribe((res) => {
      if (res) {
        this.sessions = res;
      }
      this.isLoading = false;
    });
  }
}
