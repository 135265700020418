<div #container class="container competency-card shadow mb-3">
  <div class="d-flex justify-content-between align-items-center">
    <h3 class="fs-6 fw-bold mt-2">
      <i class="bi bi-upload me-1"></i>
      Evidence
    </h3>
    <button
      role="button"
      ngbTooltip="New evidence"
      class="icon-container circle"
      (click)="addEvidence()">
      <i class="bi bi-plus fs-19"></i>
    </button>
  </div>
  <div *ngIf="evidenceByOption" class="row">
    <div *ngFor="let option of evidenceByOption | keyvalue">
      <div class="fs-16 mb-0 mt-2">
        <i class="bi bi-lightbulb me-1"></i
        >{{ getOptionData(option.key).content }}
      </div>
      <ul class="list-unstyled">
        <li
          class="d-flex justify-content-between"
          *ngFor="let evidence of option.value">
          <div>
            <app-evidence-summary
              [evidenceId]="evidence.id"
              [sessionId]="coachlogId"
              ><span class="fw-bold fs-6">{{
                evidence.title
              }}</span></app-evidence-summary
            >
            <button
              class="bg-transparent border-0 ms-1"
              ngbTooltip="Edit evidence"
              (click)="editEvidence(evidence.id)">
              <i class="bi bi-pencil color-tertiary"></i>
            </button>
          </div>
          <i
            *ngIf="evidence.assessments.length > 0"
            ngbTooltip="Reviewed"
            class="bi fs-15 bi-check-circle-fill color-gold ms-1"></i>
          <i
            *ngIf="evidence.assessments.length == 0"
            class="bi fs-15 bi-circle color-secondary ms-1"></i>
        </li>
      </ul>
    </div>
  </div>
</div>
<app-modal #evidenceModal [modalConfig]="modalConfig">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModalOnly()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <p class="text-center fs1">
    <strong>Level {{ level }}</strong> -
    <span>{{ levelData.indicators[0].content }}</span>
  </p>
  <div class="col-8 offset-2 text-center">
    <label class="form-label">Option:</label>
    <ng-select
      #optionSelect
      [disabled]="!newEvidence"
      class="border-secondary"
      [clearable]="false"
      [markFirst]="true"
      [searchable]="false"
      [items]="optionList"
      bindLabel="content"
      bindValue="id"
      [(ngModel)]="currentEvidence.coachlogOptionId"
      placeholder="Select an option"></ng-select>
  </div>
  <label class="form-label">Title:</label>
  <input
    class="form-control rounded"
    type="text"
    placeholder="Enter a title for this evidence"
    [(ngModel)]="currentEvidence.title" />
  <label class="form-label">Context:</label>
  <app-wysiwyg-editor
    version="mini"
    [config]="{ placeholderText: 'Enter a description for this evidence' }"
    [editorContent]="currentEvidence.narrative"
    (editorContentEvent)="updateNarrative($event)" />
  <label class="form-label">Evidence:</label>
  <ng-select
    placeholder="Select evidence type"
    [disabled]="uploadType || false"
    class="mb-2"
    [items]="evidenceModalTypes"
    (change)="changeEvidenceType()"
    [clearable]="false"
    bindLabel="label"
    bindValue="value"
    [(ngModel)]="uploadType"></ng-select>
  <div
    *ngIf="
      uploadType === uploadTypes.VIDEO &&
      currentEvidence.videos &&
      currentEvidence.videos[0]
    ">
    <app-video-player [video]="currentEvidence.videos[0]"></app-video-player>
    <a
      href="javascript:void(0)"
      [ngClass]="[disableVideoRemoval ? 'visually-hidden' : '']"
      (click)="removeVideo()"
      >Remove video</a
    >
  </div>
  <app-file-list
    *ngIf="uploadType && currentEvidence.videos?.length !== 1"
    (fileCount)="numberOfFilesUploaded = $event; fileUploaded()"
    [uploadList]="uploadList"
    [fileList]="uploadedFiles"></app-file-list>
  <app-file-upload
    *ngIf="
      (currentEvidence.videos?.length !== 1 &&
        uploadType &&
        uploadType === uploadTypes.VIDEO &&
        numberOfFilesUploaded < 2) ||
      (uploadType && uploadType === uploadTypes.EVIDENCE)
    "
    #uploadComponent
    [style]="uploadStyle"
    [multiple]="uploadType != uploadTypes.VIDEO"
    (outputFiles)="uploadList.emit($event)"
    [uploadType]="uploadType"></app-file-upload>
  <div footerContent>
    <button
      [disabled]="!currentEvidence.title || !currentEvidence.narrative"
      (click)="evidenceModal.close()"
      class="btn btn-md text-white fw-800 bg-level-{{ this.level }}">
      Submit
    </button>
  </div>
</app-modal>
