import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription, take } from 'rxjs';
import { CoachlogSearchPayloadItemsDTO } from 'src/app/common/dtos/coachlog-search-payload.dto';
import {
  UserRole,
  compareRoles,
} from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';

import {
  CoachingSessionFilterDTO,
  coachingSessionFilterDTOToCoachlogSearchPayloadItemsDTO,
  emptyCoachingSessionFilters,
  setDefaultCoachingSessionFilterDateRange,
} from 'src/app/common/dtos/coaching-session-filter.dto';
import { FilterList } from '../../shared/components/applied-filters/applied-filters.dto';
import { CoachingSessionFilterModalComponent } from '../../shared/components/modals/coaching-session-filter-modal/coaching-session-filter-modal.component';
import { ModalComponent } from '../../shared/components/modals/modal/modal.component';
import { CoachingSessionDTO } from '../../shared/dtos/coaching-session.dto';
import { CoachingLogService } from '../../shared/services/coaching-log/coaching-log.service';
import { ResetLogDefaults } from '../../shared/state/coaching-log/coaching-log.actions';

@Component({
  selector: 'app-coaching-sessions-page',
  templateUrl: './coaching-sessions-page.component.html',
  styleUrls: ['./coaching-sessions-page.component.scss'],
})
export class CoachingSessionsPageComponent implements OnInit, OnDestroy {
  displayFilterModal = false;

  coachingSessionFilters: CoachingSessionFilterDTO =
    emptyCoachingSessionFilters();

  subs: Subscription[] = [];

  loading = true;

  sessionsEmpty = false;

  sessions: CoachingSessionDTO[];

  selectedLogId: number;

  coachlogSearchOptions: CoachlogSearchPayloadItemsDTO = {
    keywords: '',
    from_date: 0,
    to_date: 0,
    competency_items: [],
    school_items: [],
    coach_items: [],
    coachee_items: [],
    per_page: 50,
    ownonly: 0,
    sort_order: 'DESC',
  };

  @ViewChild('filterModal') filterModal: CoachingSessionFilterModalComponent;

  @ViewChild('createSessionModal') videoModal: ModalComponent;

  dateOpen = false;

  userRoles = UserRole;

  canCreateLog = false;

  keywords = '';

  user: User;

  showAppliedFilters = true;

  constructor(
    private coachingLogService: CoachingLogService,
    private store: Store
  ) {}

  ngOnInit() {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;

    this.canCreateLog = compareRoles(
      [UserRole.COACH, UserRole.E2L_EMPLOYEE, UserRole.OPERATOR],
      this.user.roles
    );

    this.coachingSessionFilters = emptyCoachingSessionFilters();
    this.setFilters();

    this.coachingLogService.searchFiltersItemsObs.subscribe((res) => {
      if (res) {
        this.coachlogSearchOptions = res;
      }
    });

    this.coachingLogService.coachlogCreated.subscribe(() => {
      this.getSessions();
    });
  }

  showFilterModal() {
    this.displayFilterModal = true;
  }

  setFilters(filters?: CoachingSessionFilterDTO) {
    // This timeout and this.showAppliedFilters exist to refresh the applied filters component (b/c sometimes it doesn't recognize changes)
    if (filters) {
      this.coachingSessionFilters = filters;
    }
    this.coachlogSearchOptions =
      coachingSessionFilterDTOToCoachlogSearchPayloadItemsDTO(
        this.coachingSessionFilters
      );
    this.showAppliedFilters = false;
    setTimeout(() => {
      this.showAppliedFilters = true;
    }, 0);
    this.getSessions();
  }

  getSessions(): void {
    this.loading = true;

    this.coachingLogService.setFilters(this.coachlogSearchOptions);

    this.coachingLogService.getSessions().subscribe((res) => {
      take(1);
      if (res) {
        this.sessions = res;
        if (this.sessions.length === 0) {
          this.sessionsEmpty = true;
        }
      }
      this.loading = false;
    });
  }

  setDefaultDateDateRange() {
    const defaultDates = setDefaultCoachingSessionFilterDateRange();
    this.coachingSessionFilters.fromDate = defaultDates.fromDate;
    this.coachingSessionFilters.toDate = defaultDates.toDate;
  }

  removeFilter(keyName: string) {
    /* eslint-disable-next-line default-case */
    switch (keyName) {
      case 'date':
        this.setDefaultDateDateRange();
        break;
      case 'logtype':
        this.coachingSessionFilters.logType = null;
        break;
    }
    this.setFilters();
  }

  removeFilterFromList(filterList: FilterList) {
    /* eslint-disable-next-line default-case */
    switch (filterList.keyName) {
      case 'schools':
        if (this.coachingSessionFilters.schoolList) {
          this.coachingSessionFilters.schoolList =
            this.coachingSessionFilters.schoolList.filter(
              (item) => item !== filterList.value
            );
        }
        break;
      case 'competency_items':
        if (this.coachingSessionFilters.competencyList) {
          this.coachingSessionFilters.competencyList =
            this.coachingSessionFilters.competencyList.filter(
              (item) => item !== filterList.value
            );
        }
        break;
      case 'coach_items':
        if (this.coachingSessionFilters.coachList) {
          this.coachingSessionFilters.coachList =
            this.coachingSessionFilters.coachList.filter(
              (item) => item !== filterList.value
            );
        }
        break;
      case 'coachee_items':
        if (this.coachingSessionFilters.coacheeList) {
          this.coachingSessionFilters.coacheeList =
            this.coachingSessionFilters.coacheeList.filter(
              (item) => item !== filterList.value
            );
        }
        break;
    }
    this.setFilters();
  }

  closeFilterModal() {
    this.displayFilterModal = false;
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetLogDefaults());

    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
