/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  /* eslint-disable class-methods-use-this */
  // skipcq: JS-0105
  transform(value: number): string {
    return DateTime.fromSeconds(value).toFormat('MMM d, yyyy');
  }
}
