import { AfterViewInit, Component, Input } from '@angular/core';
import { User } from 'src/app/common/state/user/user.model';
import { map } from 'rxjs';
import {
  KpiReportFilter,
  ReportApiResponse,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { CURRENT_SCHOOL_YEAR } from '../../../enums/reports.enum';

@Component({
  selector: 'app-card-kpi',
  templateUrl: './card-kpi.component.html',
  styleUrls: ['./card-kpi.component.scss'],
})
export class CardKpiComponent implements AfterViewInit {
  @Input() kpiName: string; // label for the KPI value

  @Input() dataset = ''; // required

  @Input() measure = 'count';

  @Input() field = '';

  @Input() districtId: number; // default to user's district if not provided

  @Input() schoolId: number;

  @Input() schoolYearId: number; // default to current school year if not provided

  @Input() fetchRequestParams: ReportRequestParams; // overwrites traditional kpi logic (backwards compatible)

  user$ = select(UserState.getUser);

  user: User | null = null;

  apiResponse: ReportApiResponse;

  kpiValue: number;

  isCollapsed: boolean;

  currentError = '';

  constructor(
    private apiService: APICoreService,
    private cardStorageService: CardStorageService
  ) {}

  ngAfterViewInit() {
    this.user$
      .pipe(
        map((user) => {
          if (user) {
            this.user = user;
            this.getData();
          }
        })
      )
      .subscribe();
  }

  getData(): void {
    if (this.user && this.user.district) {
      if (!this.districtId) {
        this.districtId = this.user.district.id;
      }
    }
    if (!this.schoolYearId) {
      this.schoolYearId = parseInt(CURRENT_SCHOOL_YEAR.toString(), 10);
    }

    let requestParams: ReportRequestParams = {
      dataset: this.dataset,
      measure: {
        type: this.measure,
        field: this.field,
      },
      filters: [
        {
          codename: 'district',
          title: 'District',
          value: [this.districtId.toString()],
          displayName: 'District',
        },
        {
          codename: 'school_year',
          title: 'School Year',
          value: [this.schoolYearId.toString()],
          displayName: 'School Year',
        },
      ],
    };

    if (this.fetchRequestParams) {
      requestParams = this.fetchRequestParams;

      const districtFilter = requestParams.filters.find(
        (filter: KpiReportFilter) => filter.codename === 'district'
      );
      if (!districtFilter) {
        requestParams.filters.push({
          codename: 'district',
          title: 'District',
          value: [this.districtId.toString()],
          displayName: 'District',
        });
      }

      const schoolYearFilter = requestParams.filters.find(
        (filter: KpiReportFilter) => filter.codename === 'school_year'
      );
      if (!schoolYearFilter) {
        requestParams.filters.push({
          codename: 'school_year',
          title: 'School Year',
          value: [this.schoolYearId.toString()],
          displayName: 'School Year',
        });
      }
    }

    this.apiService
      .getRequest('reporting/custom', requestParams)
      .subscribe((value) => {
        this.apiResponse = value;
        this.kpiValue = value.datasets[0].data[0];
        if (value.valid === false) {
          const errorMessage = value.error_messages[0];
          console.log(errorMessage); // eslint-disable-line
        }
      });
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      this.kpiName.replace(/\s/g, '').toLowerCase(),
      this.isCollapsed
    );
  }
}
