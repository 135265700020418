import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { TodoType } from '../../../enums/todo-type.enum';
import { TodoService } from '../../../services/todo/todo.service';
import { ToDoAPIPackage, ToDoDTO, ToDoPayload } from './todo.dto';

@Component({
  selector: 'app-card-todos',
  templateUrl: './card-todos.component.html',
  styleUrls: ['./card-todos.component.scss'],
})
export class CardTodosComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  isCollapsed = this.cardStorageService.getCollapsedState('todos');

  isLoading = true;

  todo: ToDoDTO;

  selectedTodo: ToDoDTO | null;

  todos: ToDoDTO[];

  dueTodayTodos: ToDoDTO[] = [];

  dueThisWeekTodos: ToDoDTO[] = [];

  dueTodos: ToDoDTO[] = [];

  completedTodos: ToDoDTO[] = [];

  todoCreate: ToDoAPIPackage;

  todaysDate: number;

  nextWeeksDate: Date;

  nextWeeksDateStamp: number;

  testDate: Date;

  testDateStamp: number;

  selectedDate: NgbDateStruct;

  tempContent: string;

  tempDate: NgbDateStruct | null;

  constructor(
    private todoService: TodoService,
    private calendar: NgbCalendar,
    private cardStorageService: CardStorageService
  ) {}

  ngOnInit(): void {
    this.resetTodoCreate();
    this.setCreateCalendarStart();

    const newDate = new Date();
    this.todaysDate = Math.floor(newDate.setHours(0, 0, 0, 0) / 1000);

    this.nextWeeksDate = new Date();
    this.nextWeeksDateStamp = this.nextWeeksDate.setDate(
      this.nextWeeksDate.getDate() + 7
    );
    this.nextWeeksDateStamp = Math.floor(this.nextWeeksDateStamp / 1000);

    this.subs.push(
      this.todoService.getTodos().subscribe((res) => {
        if (res) {
          this.todos = res;
          this.sortTodos();
          this.isLoading = false;
        }
      })
    );
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState('todos', this.isCollapsed);
  }

  sortTodos() {
    this.checkIfOverdue();
    this.dueTodayTodos = [];
    this.dueThisWeekTodos = [];
    this.dueTodos = [];
    this.completedTodos = [];
    this.todos.forEach((todo) => {
      if (todo.completed) {
        this.completedTodos.push(todo);
      } else if (todo.dueDate) {
        const normalizeDate =
          new Date(todo.dueDate * 1000).setHours(0, 0, 0, 0) / 1000;
        if (normalizeDate <= this.todaysDate) {
          this.dueTodayTodos.push(todo);
        } else if (normalizeDate < this.nextWeeksDateStamp) {
          this.dueThisWeekTodos.push(todo);
        } else {
          this.dueTodos.push(todo);
        }
      } else {
        this.dueTodos.push(todo);
      }
    });
    this.dueTodayTodos.sort((first, second) => first.dueDate - second.dueDate);
    this.dueThisWeekTodos.sort(
      (first, second) => first.dueDate - second.dueDate
    );
    this.dueTodos.sort((first, second) => first.dueDate - second.dueDate);
    this.completedTodos.sort((first, second) => second.dueDate - first.dueDate);
  }

  hideCreateForm() {
    this.resetCreateForm();
  }

  resetCreateForm() {
    this.resetTodoCreate();
    this.setCreateCalendarStart();
  }

  setCreateCalendarStart() {
    this.selectedDate = this.calendar.getToday();
  }

  resetTodoCreate() {
    this.todoCreate = {
      content: '',
      description: '',
      due_date: 0,
      type: TodoType.GROWELAB,
    };
  }

  createTodo() {
    this.todoCreate.due_date =
      new Date(
        `${this.selectedDate.month}/${this.selectedDate.day}/${this.selectedDate.year}`
      ).setHours(0, 0, 0, 0) / 1000;

    this.subs.push(
      this.todoService.createTodo(this.todoCreate).subscribe((response) => {
        this.todos.push(response);
        this.sortTodos();
        this.hideCreateForm();
        this.resetCreateForm();
      })
    );
  }

  completeTodo(checkTodo: ToDoDTO) {
    const requestBody: ToDoPayload = {};
    if (checkTodo.completed) {
      requestBody.completed = false;
      this.completedTodos = this.completedTodos.filter(
        (td) => td.id !== checkTodo.id
      );
    } else {
      requestBody.completed = true;
    }
    this.todoService
      .updateTodo(checkTodo, requestBody)
      .subscribe((response) => {
        const foundIndex = this.todos.findIndex(
          (x) => x.id === response.item.id
        );
        this.todos[foundIndex].completed = response.item.completed;
        this.sortTodos();
      });
  }

  deleteTodo(delTodo: ToDoDTO | null) {
    if (delTodo) {
      this.todoService.deleteTodo(delTodo.id).subscribe((response) => {
        if (response) {
          this.todos = this.todos.filter((todo) => todo.id !== delTodo.id);
          this.sortTodos();
        }
      });
      this.selectedTodo = null;
    }
  }

  selectTodo(editTodo: ToDoDTO) {
    this.selectedTodo = editTodo;
    this.tempContent = editTodo.content;
    if (editTodo.dueDate) {
      const currentDueDate = new Date(editTodo.dueDate * 1000);
      this.tempDate = {
        year: currentDueDate.getFullYear(),
        month: currentDueDate.getMonth() + 1,
        day: currentDueDate.getDate(),
      };
    } else {
      this.tempDate = null;
    }
  }

  updateTodoContent() {
    if (this.tempContent == null || this.tempContent.length < 1) {
      return;
    }
    const requestBody = {
      content: this.tempContent,
    };
    if (this.selectedTodo) {
      this.todoService
        .updateTodo(this.selectedTodo, requestBody)
        .subscribe(() => {
          if (this.selectedTodo) {
            this.selectedTodo.content = this.tempContent;
          }
        });
    }
  }

  updateTodoDueDate(): void {
    const selDate = new Date(
      `${this.tempDate?.month}/${this.tempDate?.day}/${this.tempDate?.year}`
    ).setHours(0, 0, 0, 0);
    const requestBody = {
      due_date: selDate / 1000,
    };
    if (this.selectedTodo) {
      this.todoService
        .updateTodo(this.selectedTodo, requestBody)
        .subscribe((response) => {
          const foundIndex = this.todos.findIndex(
            (x) => x.id === response.item.id
          );
          this.todos[foundIndex].dueDate = response.item.due_date;
          this.sortTodos();
        });
    }
  }

  closeEditor() {
    this.selectedTodo = null;
  }

  checkIfOverdue() {
    this.todos.forEach((todo) => {
      const normalizeDate =
        new Date(todo.dueDate * 1000).setHours(0, 0, 0, 0) / 1000;
      if (normalizeDate < this.todaysDate) {
        todo.overdue = true;
      } else {
        todo.overdue = false;
      }
    });
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
