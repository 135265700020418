import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject, Subscription, map } from 'rxjs';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import { v4 as uuidv4 } from 'uuid';
import { getIdsFromDefaultItemsForSelect } from '../../helpers/select.utilities';
import { SchoolSearchService } from '../../services/school-search/school-search.service';

@Component({
  selector: 'app-school-select',
  templateUrl: './school-select.component.html',
  styleUrls: ['./school-select.component.scss'],
})
export class SchoolSelectComponent implements OnInit {
  schoolList: Observable<SchoolDTO[]>;

  schoolLibrary: SchoolDTO[] = [];

  searchInput$ = new Subject<string>();

  searchSubscription: Subscription | null;

  searchLoading = false;

  labelId = uuidv4();

  internalSelectedSchool: SchoolDTO | null = null;

  internalSelectedSchoolList: SchoolDTO[] = [];

  @ViewChild('select') select: NgSelectComponent;

  @Input() simpleSelect = false;

  @Input() clearAfterSelection = false;

  @Input() label = 'Search for and select a school';

  @Input() placeholder = 'Search for a school...';

  @Input() isMulti = false;

  @Input() districtId: number | undefined;

  @Input() defaultSchools: number | number[] | SchoolDTO[] | null;

  @Output() selectedSchoolList: EventEmitter<SchoolDTO[]> = new EventEmitter();

  @Output() selectedSchool: EventEmitter<SchoolDTO | null> = new EventEmitter();

  constructor(private schoolSearch: SchoolSearchService) {}

  ngOnInit(): void {
    this.loadSchools(true);

    this.schoolList = this.schoolSearch.items.pipe(
      map((schools) => {
        this.schoolLibrary = [...this.schoolLibrary, ...schools];
        this.searchLoading = false;
        return schools;
      })
    );

    this.searchInput$.subscribe((term) => {
      this.searchLoading = true;
      return this.schoolSearch.search(term);
    });
  }

  loadSchools(loadDefaults = false) {
    if (!this.districtId) {
      this.districtId = 0;
    }
    this.schoolSearch.getAllSchools(this.districtId).subscribe((schools) => {
      this.schoolLibrary = [...this.schoolLibrary, ...schools];
      this.searchLoading = false;
      if (loadDefaults && this.defaultSchools) {
        const schoolIds = getIdsFromDefaultItemsForSelect(this.defaultSchools);
        if (this.isMulti && this.defaultSchools) {
          schoolIds.forEach((schoolId) => {
            this.internalSelectedSchoolList.push(
              this.schoolLibrary.find(
                (school) => school.id === schoolId
              ) as SchoolDTO
            );
          });
        } else {
          this.internalSelectedSchool = this.schoolLibrary.find(
            (school) => school.id === schoolIds[0]
          ) as SchoolDTO;
        }
      }
    });
  }

  reset() {
    if (this.isMulti) {
      this.internalSelectedSchoolList = [];
    } else {
      this.internalSelectedSchool = null;
    }
    this.select.selectedItems.forEach((item) => {
      this.select.unselect(item);
    });
  }

  schoolSelected(school: SchoolDTO) {
    if (this.isMulti && school) {
      if (!this.internalSelectedSchoolList.includes(school)) {
        this.internalSelectedSchoolList.push(school);
      }
      this.select.handleClearClick();
    } else {
      this.internalSelectedSchool = school;
    }
    this.outputSchools();
    if (this.clearAfterSelection) {
      setTimeout(() => {
        this.select.unselect(this.select.selectedItems[0]);
      });
    }
  }

  outputSchools() {
    if (this.isMulti) {
      this.selectedSchoolList.emit(this.internalSelectedSchoolList);
    } else {
      this.selectedSchool.emit(this.internalSelectedSchool);
    }
  }

  removeSchoolFromList(school: SchoolDTO) {
    this.internalSelectedSchoolList = this.internalSelectedSchoolList.filter(
      (s) => s.id !== school.id
    );
    this.outputSchools();
  }
}
