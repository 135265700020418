<ng-container *ngIf="!isLoading; else loadingTemplate">
  <table *ngIf="userList" class="table">
    <thead>
      <tr>
        <!-- <th class="hide-on-mobile"></th> -->
        <th class="hide-on-mobile" scope="col">ID</th>
        <th scope="col" (sort)="onSort($event)" sortable="name">
          Name
          <ng-container *ngIf="!loadingIcons">
            <i class="bi bi-caret-down {{ sortIconTemp.name }}"></i>
          </ng-container>
        </th>
        <th scope="col" (sort)="onSort($event)" sortable="email">
          Email
          <ng-container *ngIf="!loadingIcons">
            <i class="bi bi-caret-down {{ sortIconTemp.email }}"></i>
          </ng-container>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="school"
          class="hide-on-mobile">
          School
          <ng-container *ngIf="!loadingIcons">
            <i class="bi bi-caret-down {{ sortIconTemp.school }}"></i>
          </ng-container>
        </th>
        <!-- <th scope="col" class="hide-on-mobile">User Type</th> -->
        <!-- <th scope="col" class="hide-on-mobile">Assigned Coach</th> -->
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="cohort"
          class="hide-on-mobile">
          Cohorts
          <ng-container *ngIf="!loadingIcons">
            <i class="bi bi-caret-down {{ sortIconTemp.cohort }}"></i>
          </ng-container>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="v-mid" *ngFor="let user of userList">
        <!-- <td class="hide-on-mobile">
          <input
            [(ngModel)]="user.isSelected"
            class="form-check-input"
            type="checkbox"
            id="{{ 'checkbox' + user.id }}"
            value="{{ user.id }}"
            aria-label="select" />
        </td> -->
        <td class="hide-on-mobile">{{ user.id }}</td>
        <td>
          <div class="d-flex justify-content-start align-items-center">
            <app-avatar [profile]="user.profile" [avatarSize]="'small'">
            </app-avatar>
            <div class="username ms-2">
              {{ user.profile.first_name }}
              {{ user.profile.last_name }}
            </div>
          </div>
        </td>
        <td style="word-break: break-all">{{ user.email }}</td>
        <td class="hide-on-mobile">{{ user.school?.title || "-" }}</td>
        <!-- <td class="hide-on-mobile">{{ user.coachee_type || "-" }}</td> -->
        <!-- <td class="hide-on-mobile">
              {{ showCoachFullName(user) }}
            </td> -->
        <td class="hide-on-mobile">
          <ng-container *ngFor="let cohort of user.cohorts">
            <span class="badge badge-item">{{ cohort.tag }}</span
            >&nbsp;
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
  <section>
    <div class="d-flex justify-content-center">
      <ngb-pagination
        *ngIf="listMeta && listMeta.totalCount > 10"
        [(page)]="listMeta.currentPage"
        [pageSize]="listMeta.perPage"
        [collectionSize]="listMeta.totalCount"
        [maxSize]="5"
        (pageChange)="paginationChange()"></ngb-pagination>
    </div>
  </section>
</ng-container>
<ng-template #loadingTemplate>
  <div style="clear: both; height: 300px"></div>
  <app-loader [top]="200" />
</ng-template>
