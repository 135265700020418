<div
  *ngIf="user$ | async as user"
  class="row mb-3 align-items-center justify-content-between">
  <div class="col-lg">
    <h1>
      Happy {{ dayOfWeek }}, <b>{{ user.profile.first_name }}</b
      >!
    </h1>
  </div>
  <div
    *ngIf="user$ | async as user"
    class="col-10 col-lg-auto d-flex align-items-center flex-row">
    <ng-container *ngIf="showAddCoachee"
      ><app-assign-coachee (coacheeEvent)="coacheesUpdated()" />
    </ng-container>
    <ng-container *ngIf="showSwitcher" [ngSwitch]="user.type">
      <span *ngSwitchCase="'coach'">
        <div class="btn-group btn-group-sm" role="group">
          <input
            type="radio"
            class="btn-check"
            routerLink="/dashboard/coach"
            [checked]="dashboardType == 'coach'"
            id="coach-radio" />
          <label class="btn btn-outline-primary" for="coach-radio"
            >Coach View</label
          >
          <input
            type="radio"
            class="btn-check"
            routerLink="/dashboard/learner"
            [checked]="dashboardType == 'learner'"
            id="learner-radio" />
          <label class="btn btn-outline-primary" for="learner-radio"
            >Learner View</label
          >
        </div>
      </span>
      <span *ngSwitchCase="'leader'">
        <div class="btn-group btn-group-sm" role="group">
          <input
            type="radio"
            class="btn-check"
            routerLink="/dashboard/leader"
            [checked]="dashboardType == 'leader'"
            id="leader-radio" />
          <label class="btn btn-outline-primary" for="leader-radio"
            >Leader View</label
          >
          <input
            type="radio"
            class="btn-check"
            routerLink="/dashboard/coach"
            [checked]="dashboardType == 'coach'"
            id="coach-radio" />
          <label class="btn btn-outline-primary" for="coach-radio"
            >Coach View</label
          >
          <input
            type="radio"
            class="btn-check"
            routerLink="/dashboard/learner"
            [checked]="dashboardType == 'learner'"
            id="learner-radio" />
          <label class="btn btn-outline-primary" for="learner-radio"
            >Learner View</label
          >
        </div>
      </span>
      <span *ngSwitchDefault></span>
    </ng-container>
  </div>
  <div class="col-2 col-md-auto">
    <div
      [autoClose]="'outside'"
      [ngbPopover]="celebrationsPopover"
      popoverClass="popover-lg"
      placement="bottom-end"
      class="icon"
      #celebrationPopover="ngbPopover"
      [ngStyle]="{
        'background-image': 'url(/assets/celebrations.svg)'
      }"></div>
  </div>
  <ng-template #celebrationsPopover>
    <app-card-celebrations-feed
      (openCelebrationModalEvent)="openCelebrationModal()"
      (createCelebrationEvent)="handleCreateCelebrationEvent()"
      [celebrations]="celebrations"
      [user]="user" />
  </ng-template>
  <app-celebration-modal
    (createCelebrationEvent)="handleCreateCelebrationEvent()"
    #celebrationModal></app-celebration-modal>
</div>
