/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';

import { StorageService } from '../../services/storage/storage.service';
import { User } from './user.model';
import { UserService } from './user.service';
import { userFromDto } from './user.utilities';

@State<UserStateModel>({
  name: 'user',
  defaults: {
    hasData: false,
    isLoading: false,
    user: null,
    impersonated: false,
  },
})
export class FetchUser {
  static readonly type = '[User] Fetch User';
}

export interface UserStateModel {
  hasData: boolean;
  isLoading: boolean;
  user: User | null;
  impersonated: boolean;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    hasData: false,
    isLoading: false,
    user: null,
    impersonated: false,
  },
})
@Injectable()
export class UserState {
  constructor(private userService: UserService) {}

  @Selector()
  static getUser(state: UserStateModel) {
    return state.user;
  }

  @Selector()
  static isLoading(state: UserStateModel) {
    return state.isLoading;
  }

  @Selector()
  static hasData(state: UserStateModel) {
    return state.hasData;
  }

  @Action(FetchUser)
  fetchUser(ctx: StateContext<UserStateModel>) {
    ctx.patchState({
      isLoading: true,
    });
    if (StorageService.getItem('user') !== null) {
      const impersonated =
        StorageService.getItem('isImpersonatingUser') || false;
      ctx.patchState({
        user: StorageService.getItem('user'),
        isLoading: false,
        hasData: true,
        impersonated,
      });
    }
    return this.userService.fetchUser().pipe(
      tap((result) => {
        if (result !== null) {
          const userData = userFromDto(result?.item);
          ctx.patchState({
            user: userData,
            isLoading: false,
            hasData: true,
          });
          StorageService.storeItem('user', userData);
        } else {
          ctx.patchState({
            user: null,
            hasData: false,
            isLoading: false,
          });
        }
      })
    );
  }
}
