<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-select
  class="year-select"
  [labelForId]="labelId"
  [placeholder]="placeholder"
  [items]="selectedYearList"
  bindLabel="name"
  [searchable]="false"
  [clearable]="false"
  [(ngModel)]="selectedYear"
  (change)="emittedYear.emit(selectedYear); yearSelect.blur()"
  #yearSelect></ng-select>
