import {
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { map, Subscription } from 'rxjs';
import {
  EvidenceAssessedDTO,
  EvidenceSummaryAttachmentResponse,
  EvidenceSummaryItemResponse,
} from 'src/app/common/types/responses/responses';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { User } from 'src/app/common/state/user/user.model';
import {
  checkIfCoach,
  checkIfShadower,
} from 'src/app/common/utilities/session-helpers';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { UserState } from 'src/app/common/state/user/user.state';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { EvidenceDTO } from '../../dtos/attendee-rubric.dto';
import { CoachingSessionDTO } from '../../dtos/coaching-session.dto';
import { FileDTO } from '../../dtos/file.dto';
import { fileDTOFromEvidenceSummaryAttachmentResponse } from '../../helpers/translators/evidence.translators';
import { EvidenceService } from '../../services/evidence/evidence.service';
import { CoachingLogState } from '../../state/coaching-log/coaching-log.state';
import { CommentModelDTO } from '../evidence-comments/evidence-comments.dto';
import { ModalComponent, ModalConfig } from '../modals/modal/modal.component';
import { videoDTOFromAPIResponse } from '../../helpers/translators/video.translators';

@Component({
  selector: 'app-evidence-summary',
  templateUrl: './evidence-summary.component.html',
  styleUrls: ['./evidence-summary.component.scss'],
})
export class EvidenceSummaryComponent implements OnInit, OnDestroy {
  @Input() evidenceId: number;

  @Input() sessionId: number;

  @Output() evidenceAssesed: EventEmitter<EvidenceAssessedDTO> =
    new EventEmitter();

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO | null;

  subs: Subscription[] = [];

  loadingData = true;

  user$ = select(UserState.getUser);

  currentUser: User | null;

  isCoach = false;

  isShadower = false;

  evidence: EvidenceDTO;

  evidenceSummary: EvidenceSummaryItemResponse;

  modalConfig: ModalConfig;

  hasAttachments = false;

  uploadedFiles: FileDTO[] = [];

  commentList: CommentModelDTO[];

  commentRequest: Subscription;

  evidenceAssessing = false;

  evidenceAssessed = false;

  video: VideoDTO;

  @ViewChild('evidenceSummaryModal') evidenceSummaryModal: ModalComponent;

  constructor(private evidenceService: EvidenceService) {}

  ngOnInit(): void {
    this.user$
      .pipe(
        map((user) => {
          this.currentUser = user;
        })
      )
      .subscribe();

    this.getEvidenceSummary();

    this.modalConfig = {
      headerClass: ['modal-header', 'text-white', 'text-center'],
      closeButton: {
        classes: ['btn', 'btn-md', 'text-white', 'fw-800', 'text-uppercase'],
        label: 'Submit',
      },
      hideDismissButton: true,
      customFooter: true,
      titleText: '',
      options: {
        size: 'lg',
      },
    };

    if (this.sessionId) {
      this.sessionData$
        .pipe(map((filterFn) => filterFn(this.sessionId)))
        .pipe(
          map((sessionData) => {
            if (sessionData) {
              this.sessionData = sessionData;
              this.isShadower = checkIfShadower(this.sessionData);
              this.isCoach = checkIfCoach(this.sessionData);
            }
          })
        )
        .subscribe();
    } else {
      this.checkIfCoach();
    }
  }

  checkIfCoach() {
    if (this.currentUser) {
      if (
        this.currentUser.roles.includes(UserRole.COACH) ||
        this.currentUser.roles.includes(UserRole.E2L_EMPLOYEE)
      ) {
        this.isCoach = true;
      }
    }
  }

  getEvidenceSummary() {
    this.evidenceService
      .getEvidenceSummary(this.evidenceId)
      .subscribe((res) => {
        this.evidenceSummary = res.item;
        if (
          this.evidenceSummary.assessments &&
          this.evidenceSummary.assessments.length > 0
        ) {
          this.evidenceAssessed = true;
        }
        if (this.evidenceSummary.evidenceAttachments.length > 0) {
          this.evidenceSummary.evidenceAttachments.forEach(
            (e: EvidenceSummaryAttachmentResponse) =>
              this.uploadedFiles.push(
                fileDTOFromEvidenceSummaryAttachmentResponse(e)
              )
          );
          this.hasAttachments = true;
        }
        if (this.evidenceSummary.videos.length > 0) {
          this.video = videoDTOFromAPIResponse(this.evidenceSummary.videos[0]);
        }
        this.getComments();

        this.loadingData = false;
      });
  }

  showEvidenceModal() {
    this.evidenceSummaryModal.config.titleText = this.evidenceSummary.title;
    this.evidenceSummaryModal.config.headerClass.push(
      `bg-level-${this.evidenceSummary.level}`
    );

    this.evidenceSummaryModal.open();
  }

  closeEvidenceModal() {
    this.evidenceSummaryModal.close();
  }

  deleteEvidence() {
    this.evidenceService
      .deleteEvidence(this.evidenceSummary.id)
      .subscribe(() => {
        this.evidenceSummaryModal.close();
      });
  }

  assessEvidence() {
    this.evidenceAssessing = true;
    const payload = {
      ...this.evidenceSummary,
      pusher_coachlog_id: this.sessionId ? this.sessionId : null,
    };

    this.evidenceService.approveEvidence(payload).subscribe((res) => {
      if (res.item) {
        this.evidenceAssesed.next(res.item);
        this.evidenceAssessing = false;
        this.evidenceAssessed = true;
        this.evidenceSummaryModal.close();
      }
    });
  }

  getComments() {
    if (this.commentRequest) this.commentRequest.unsubscribe();
    this.commentRequest = this.evidenceService
      .getEvidenceComments(this.evidenceSummary.id)
      .subscribe((response) => {
        if (response) {
          this.commentList = response.items;
        }
      });

    this.subs.push(this.commentRequest);
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
