import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resources-subnav',
  templateUrl: './resources-subnav.component.html',
})
export class ResourcesSubnavComponent implements OnInit {
  constructor(private router: Router) {}

  isRootResoucePage = false;

  ngOnInit() {
    if (this.router.url === '/resources') {
      this.isRootResoucePage = true;
    }
  }
}
