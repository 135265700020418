import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import {
  CreateActionItemAPIResponse,
  CreateTodoAPIResponse,
  TodoApiResponse,
  CreateMultipleTodoPusherResponse,
  DeleteTodoPusherResponse,
  UpdateTodoPusherResponse,
} from 'src/app/common/types/responses/responses';
import {
  ActionPlanFiltersAPIPackage,
  ActionItemAPIPackage,
  ToDoAPIPackage,
  ToDoDTO,
  ToDoPayload,
  UpdateActionItemAPIPackage,
  ActionItemDTO,
} from '../../components/todos/card-todos/todo.dto';
import {
  actionItemsDTOFromAPIResponse,
  todoDTOFromAPIResponse,
  todoDTOFromCreateAPIResponse,
  translateActionItemFromResponseItem,
} from '../../helpers/translators/todo.translators';

@Injectable({ providedIn: 'root' })
export class TodoService {
  private actionTodosData: ActionItemDTO[] | null = null;

  private actionTodosSource = new BehaviorSubject(this.actionTodosData);

  public actionTodos = this.actionTodosSource.asObservable();

  public localActionTodos: ActionItemDTO[];

  constructor(private apiService: APICoreService) {}

  createTodo(data: ToDoAPIPackage) {
    return this.apiService
      .postRequest('todo', data)
      .pipe(
        map((res) => todoDTOFromCreateAPIResponse(res as CreateTodoAPIResponse))
      );
  }

  getTodos() {
    return this.apiService
      .getRequest('todo')
      .pipe(map((res) => todoDTOFromAPIResponse(res as TodoApiResponse)));
  }

  deleteTodo(id: number) {
    return this.apiService.deleteRequest(`todo/${id}`, {});
  }

  updateTodo(userTodo: ToDoDTO, payload: ToDoPayload) {
    return this.apiService.putRequest(`todo/${userTodo.id}`, payload);
  }

  createActionItem(data: ActionItemAPIPackage) {
    return this.apiService.postRequest('todo/multiple', data).subscribe();
  }

  updateActionItem(id: number, payload: UpdateActionItemAPIPackage) {
    return this.apiService.putRequest(`todo/${id}`, payload);
  }

  getActionItems(data: ActionPlanFiltersAPIPackage) {
    return this.apiService.getRequest('todo/by-meta', data).subscribe((res) => {
      this.localActionTodos = actionItemsDTOFromAPIResponse(
        res as CreateActionItemAPIResponse
      );
      this.actionTodosSource.next(this.localActionTodos);
    });
  }

  deleteActionItem(id: number, logId: number) {
    return this.apiService.deleteRequest(`todo/${id}?coachlog_id=${logId}`, {});
  }

  // TODO PUSHER UPDATES
  applyTodoMultipleCreatedChange(changeData: CreateMultipleTodoPusherResponse) {
    const actionDtos: ActionItemDTO[] = changeData.user_todos.map((item) =>
      translateActionItemFromResponseItem(item)
    );
    this.localActionTodos.push(...actionDtos);
    this.actionTodosSource.next(this.localActionTodos);
  }

  applyTodoUpdate(changeData: UpdateTodoPusherResponse) {
    this.localActionTodos.forEach((todoItem) => {
      if (changeData.user_todo.id === todoItem.id) {
        todoItem.content = changeData.user_todo.content;
        todoItem.description = changeData.user_todo.description;
        todoItem.dueDate = parseInt(changeData.user_todo.due_date);
      }
    });
    this.actionTodosSource.next(this.localActionTodos);
  }

  applyTodoDelete(changeData: DeleteTodoPusherResponse) {
    this.localActionTodos = this.localActionTodos.filter(
      (todo) => todo.id !== parseInt(changeData.user_todo_id)
    );
    this.actionTodosSource.next(this.localActionTodos);
  }
}
