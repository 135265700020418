<table class="table">
  <thead>
    <tr>
      <th scope="col" (sort)="onSort($event)" sortable="logTitle">
        Title
        <i class="bi bi-caret-down {{ sortIconTemp.logTitle }}"></i>
      </th>
      <th scope="col" (sort)="onSort($event)" sortable="date">
        Date
        <i class="bi bi-caret-down {{ sortIconTemp.date }}"></i>
      </th>
      <th scope="col" (sort)="onSort($event)" sortable="logType">
        Log Type
        <i class="bi bi-caret-down {{ sortIconTemp.logType }}"></i>
      </th>
      <th
        scope="col"
        (sort)="onSort($event)"
        sortable="coachee"
        class="hide-on-mobile">
        Coachee
        <i class="bi bi-caret-down {{ sortIconTemp.coachee }}"></i>
      </th>
      <th
        scope="col"
        (sort)="onSort($event)"
        sortable="coach"
        class="hide-on-mobile">
        Coach
        <i class="bi bi-caret-down {{ sortIconTemp.coach }}"></i>
      </th>
      <th
        scope="col"
        (sort)="onSort($event)"
        sortable="school"
        class="hide-on-mobile">
        School
        <i class="bi bi-caret-down {{ sortIconTemp.school }}"></i>
      </th>
      <td class="hide-on-mobile"></td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let session of tableData">
      <td>
        <a [routerLink]="getSessionLink(session)" class="session-title">
          <b>{{ session.title }}</b>
        </a>
      </td>
      <td>
        <b class="fs-4">{{ session.startDatetime | formatDateMMDD }}</b>
      </td>
      <td>
        {{ session.type.title }}
      </td>
      <td class="hide-on-mobile">
        <ng-container *ngFor="let attendee of session.attendees">
          <div class="d-flex align-items-center my-2">
            <app-avatar
              [profile]="attendee.user.profile"
              [avatarSize]="'small'">
            </app-avatar>
            <div class="username ms-2">
              {{ attendee.user.profile.first_name }}
              {{ attendee.user.profile.last_name }}
            </div>
          </div>
        </ng-container>
      </td>
      <td class="hide-on-mobile">
        <div class="d-flex align-items-center my-2">
          <app-avatar [profile]="session.user.profile" [avatarSize]="'small'">
          </app-avatar>
          <div class="username ms-2">
            {{ session.user.profile.first_name }}
            {{ session.user.profile.last_name }}
          </div>
        </div>
      </td>
      <td class="hide-on-mobile">
        <ng-container *ngFor="let attendee of session.attendees">
          <div class="d-flex my-2">
            {{ attendee.user.school?.title }}
          </div>
        </ng-container>
      </td>
      <td class="hide-on-mobile">
        <ng-container *ngIf="session.user.id == user?.id">
          <a
            container="body"
            ngbTooltip="Delete Log"
            [autoClose]="'outside'"
            [ngbPopover]="popoverContent"
            [popoverTitle]="'Confirm Deletion'"
            #p="ngbPopover">
            <i class="bi bi-trash"></i>
          </a>
          <ng-template #popoverContent>
            <div>Permanently delete this log?</div>
            <div class="app-hr-thin"></div>
            <div class="text-center mt-2">
              <button
                type="button"
                class="btn btn-danger px-3 m-1"
                (click)="deleteLog(session); p.close()">
                Delete
              </button>
            </div>
          </ng-template>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>
