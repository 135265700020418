import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ResourceDTO } from '../../../dtos/resources.dto';

import { ResourceService } from '../../../services/resources/resource.service';
import {
  CloudDocumentInfo,
  CollabApiPayload,
  SaveData,
} from '../../../types/payloads/resources.payloads';
import { WysiwygEditorConfig } from '../../wysiwyg-editor/wysiwyg-editor.component';

@Component({
  selector: 'app-resource-content',
  templateUrl: './resource-content.component.html',
  styleUrls: ['./resource-content.component.scss'],
})
export class ResourceContentComponent implements OnInit, OnChanges {
  @Input() isAllowed = false;

  @Input() editMode = false;

  @Input() docId: number;

  @Input() resource: ResourceDTO;

  docType = 'elearnResource';

  docPart = 'content';

  initialContent: string;

  isSaving = false;

  collabApiInfo: CollabApiPayload;

  editorConfig: WysiwygEditorConfig;

  constructor(private resourceService: ResourceService) {}

  ngOnInit(): void {
    this.collabApiInfo = {
      apiEndpoint: `elearn/resources/${this.docId}`,
      cloudDocumentId: `elearn_resource-${this.docId}-content`,
    };
    this.getContent();
  }

  ngOnChanges() {
    this.collabApiInfo = {
      apiEndpoint: `elearn/resources/${this.docId}`,
      cloudDocumentId: `elearn_resource-${this.docId}-content`,
    };
    this.getContent();
  }

  getContent() {
    this.resourceService
      .getResourceContent(this.collabApiInfo)
      .subscribe((response) => {
        this.initialContent = response.item.stored_doc || ' ';

        this.editorConfig = {
          initialContent: this.initialContent,
          useCodox: true,
          docId: this.docId.toString(),
          fontFamilySelection: true,
          fontSizeSelection: true,
          paragraphFormatSelection: true,
          editId: `resourceEditor-${this.docId}`,
        };
      });
  }

  emitAutosave(updatedContent: string) {
    this.initialContent = updatedContent;

    const cloudDocumentInfo: CloudDocumentInfo = {
      content: updatedContent,
      cloudDocumentHistory: updatedContent,
      cloudDocumentId: this.collabApiInfo.cloudDocumentId,
      cloudDocumentVersion: Date.now(),
    };

    const saveData: SaveData = {
      id: this.docId,
      _cloudDocumentInfo: cloudDocumentInfo,
    };

    this.resourceService
      .updateResourceContent(this.collabApiInfo, saveData)
      .subscribe();
  }
}
