/* eslint-disable class-methods-use-this */
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { DomElement } from 'src/app/common/utilities/dom-element';

import { FileDTO } from '../../../dtos/file.dto';
import { FileIcon } from '../../../enums/file-icons.enum';
import { FileService } from '../../../services/file/file.service';
import { LogFileService } from '../../../services/file/log.file.service';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() fileList: FileDTO[];

  @Input() uploadList: Observable<FileDTO>;

  @Input() logId: number;

  @Output() fileCount = new BehaviorSubject<number>(0);

  @Input() canDelete: boolean;

  @Output() deletedFile = new Subject<FileDTO>();

  downloadUrl: string;

  public internalList: FileDTO[] = [];

  sub: Subscription;

  documentIcons = [FileIcon.doc, FileIcon.ppt, FileIcon.sheet];

  constructor(
    public fileService: FileService,
    public logFileService: LogFileService
  ) {}

  viewFile(file: FileDTO) {
    window.open(`${EnvironmentService.apiUrl()}${file.location}`);
  }

  downloadFile(file: FileDTO) {
    this.fileService.downloadFile(file).subscribe((blob) => {
      if (!this.downloadUrl) {
        this.downloadUrl = window.URL.createObjectURL(blob);
      }
      const link = DomElement.create(
        `a[href="${this.downloadUrl}"][download="${file.name}"]`
      );
      link.click();
      link.remove();
    });
  }

  ngOnInit() {
    if (this.fileList) {
      this.fileListUpdate();
    }
    if (this.uploadList) {
      this.uploadListUpdate();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fileList']) {
      if (
        changes['fileList'].currentValue !== changes['fileList'].previousValue
      ) {
        this.fileListUpdate();
      }
    }

    if (changes['uploadList']) {
      if (
        changes['uploadList'].currentValue !==
        changes['uploadList'].previousValue
      ) {
        this.uploadListUpdate();
      }
    }
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  fileListUpdate() {
    this.internalList = this.fileList;
    this.fileCount.next(this.internalList.length + 1);
  }

  uploadListUpdate() {
    this.sub = this.uploadList.subscribe((file) => {
      this.internalList.push(file);
      this.fileCount.next(this.internalList.length + 1);
    });
  }

  deleteFile(file: FileDTO) {
    if (this.logId) {
      const sub = this.logFileService
        .deleteLogFile(this.logId, file)
        .subscribe(() => {
          this.internalList = this.internalList.filter((f) => f.id !== file.id);
          this.fileCount.next(this.internalList.length + 1);
          this.deletedFile.next(file);
          sub.unsubscribe();
        });
    } else {
      const sub = this.fileService.deleteFile(file).subscribe(() => {
        this.internalList = this.internalList.filter((f) => f.id !== file.id);
        this.fileCount.next(this.internalList.length + 1);
        this.deletedFile.next(file);
        sub.unsubscribe();
      });
    }
  }
}
