import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
})
export class VideoModalComponent implements AfterViewInit {
  @ViewChild('modal') modal: ModalComponent;

  @Input() video: VideoDTO;

  @Input() autoOpen = false;

  shouldRestart = false;

  isPlaying = false;

  ngAfterViewInit() {
    if (this.autoOpen) {
      this.open();
    }
  }

  open() {
    this.modal.open();
    this.isPlaying = true;
  }

  resetVideo() {
    this.isPlaying = false;
    this.shouldRestart = true;
  }
}
