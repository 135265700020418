<div class="container container-main">
  <h1>{{ (data.id) ? 'Edit' : 'Create' }} Form</h1>
  <a
    href="javascript:void(0)"
    class="text-decoration-none color-primary"
    (click)="backToFormsList()"
    ><i class="bi bi-chevron-double-left"></i> Back to forms</a
  >
  <div class="card border-0 p-4 mt-3 overflow-auto container-fluid">
    <div class="row justify-content-between">
      <div class="col-xs-12 col-sm-8">
        <div class="form-group">
          <input
            aria-label="Form title"
            class="form-control title-input"
            [(ngModel)]="data.title"
            placeholder="Form title"
            name="title" />
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-xl-3 d-flex justify-content-center">
        <ng-template [ngIf]="data.id">
          <button
            (click)="previewModal.modal.open()"
            [disabled]="!data.title || !data.description || isSaving"
            class="btn btn-secondary px-3 my-3">
            <i class="bi bi-eye me-1"></i> Preview Form
          </button>
        </ng-template>
      </div>
    </div>
    <div class="row mt-3 justify-content-between">
      <div class="col-xs-12 col-sm-8">
        <div
          *ngIf="data.fields.length > 0"
          id="fieldsContainer"
          cdkDropList
          (cdkDropListDropped)="drop($event)">
          <div *ngFor="let field of data.fields" cdkDrag>
            <div class="block card mb-2 bg-light">
              <div class="toolbar d-flex justify-content-between bg-gray mb-0">
                <div
                  cdkDragHandle
                  [ngClass]="[
                      'drag-handle',
                      'p-1',
                      data.fields.length < 2 ? 'disabled' : ''
                    ]">
                  <i class="bi bi-grip-vertical"></i>
                </div>
                <div>
                  <h2>{{ fieldTypeDisplay[field.type].toUpperCase() }}</h2>
                </div>
                <div
                  [ngbPopover]="popContent"
                  [autoClose]="false"
                  triggers="manual"
                  class="p-1"
                  [style.cursor]="'pointer'"
                  (click)="popover.open()"
                  #popover="ngbPopover">
                  <i class="bi bi-trash color-danger mt-3 me-2"></i>
                </div>
                <ng-template #popContent>
                  Are you sure you want to delete this field?
                  <p class="popChoice">
                    <a
                      class="color-primary"
                      (click)="deleteField(field.id); popover.close()"
                      href="javascript:void(0)"
                      >Yes</a
                    >
                    <a
                      class="color-tertiary"
                      (click)="popover.close()"
                      href="javascript:void(0)"
                      >No</a
                    >
                  </p>
                </ng-template>
              </div>
              <app-form-edit-field
                [formStatus]="data.status"
                [type]="field.type"
                [inputData]="field"
                (outputData)="updateField($event)"></app-form-edit-field>
            </div>
          </div>
        </div>
        <button class="btn btn-primary my-3" (click)="openFieldModal()">
          <i class="bi bi-plus-circle"></i> Add Field
        </button>
      </div>
      <div class="col-xs-12 col-sm-4 col-xl-3">
        <div class="form-group">
          <label class="form-label" for="description">Description</label>
          <textarea
            [(ngModel)]="data.description"
            class="form-control"
            style="height: 100px"
            placeholder="Form description"
            name="description"></textarea>
        </div>
        <div class="form-group">
          <label class="form-label" for="status">Status</label>
          <ng-select
            class="growelab"
            labelForId="status"
            [items]="statuses"
            bindLabel="value"
            [clearable]="false"
            [searchable]="false"
            bindValue="key"
            [(ngModel)]="data.status"
            name="status"
            placeholder="Select status"></ng-select>
        </div>
        <div class="form-group">
          <label class="form-label" for="formType">Type</label>
          <ng-select
            class="growelab"
            labelForId="formType"
            [items]="formTypes"
            bindLabel="label"
            [clearable]="false"
            [searchable]="false"
            bindValue="key"
            [(ngModel)]="data.type"
            name="formType"
            placeholder="Select type"></ng-select>
        </div>
        <!-- This *ngIf would also include checking if they're b2b; if so, list of districts will be narrowed to their managed districts -->
        <div
          *ngIf="user.roles.includes(userRoles.E2L_EMPLOYEE)"
          class="form-group mt-2">
          <div class="d-flex align-items-center" (click)="changeTemplate()">
            <i
              *ngIf="!data.isTemplate"
              class="fs-2 bi cursor-pointer bi-toggle2-off color-tertiary"></i>
            <i
              *ngIf="data.isTemplate"
              class="fs-2 bi cursor-pointer bi-toggle2-on color-primary"></i>
            <input
              class="visually-hidden"
              type="checkbox"
              aria-describedby="isTemplateLabel"
              [value]="data.isTemplate" />
            <span id="isTemplateLabel" class="d-block px-2"
              >Share form across districts</span
            >
          </div>
        </div>
        <div class="my-2" *ngIf="data.isTemplate">
          <label
            >Share with these districts as a template (default is share across
            all your districts):</label
          >
          <app-district-select
            (selectedDistrictList)="updateDistrictsSharedWith($event)"
            [defaultDistricts]="data.districtsSharedWith || null"
            [isMulti]="true"></app-district-select>
        </div>
        <div class="my-2" *ngIf="data.isTemplate">
          <label>Exclude these districts:</label>
          <app-district-select
            (selectedDistrictList)="updateExcludedDistricts($event)"
            [defaultDistricts]="data.districtsSharedExclusion || null"
            [isMulti]="true"></app-district-select>
        </div>
        <div
          class="d-flex flex-wrap justify-content-end align-items-center mt-5">
          <button
            (click)="backToFormsList()"
            class="btn btn-secondary me-2 mb-2">
            Cancel
          </button>
          <ng-container>
            <ng-template #deleteContent>
              Are you sure you want to delete this form?
              <p class="popChoice mt-2">
                <a
                  class="color-primary"
                  (click)="deleteForm(); popover.close()"
                  href="javascript:void(0)"
                  >Yes</a
                >
                <a
                  class="color-tertiary"
                  (click)="popover.close()"
                  href="javascript:void(0)"
                  >No</a
                >
              </p>
            </ng-template>
            <button
              [ngbPopover]="deleteContent"
              [autoClose]="false"
              triggers="manual"
              (click)="popover.open()"
              #popover="ngbPopover"
              class="btn btn-danger me-2 mb-2">
              Delete
            </button>
          </ng-container>
          <button
            (click)="saveForm()"
            [disabled]="!data.title || !data.description || isSaving"
            class="btn btn-primary btn-next mb-2">
            <span
              *ngIf="isSaving"
              class="spinner-border spinner-border-sm me-1"></span
            >Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="data.hasSubmission" class="alert alert-warning mt-2" role="alert">
    This form already has submissions. Do not significantly modify question
    text, as there are already responses for the question. If you need to
    materially change a question, delete the existing question and create a new
    one.
  </div>
</div>
<app-modal
  #AddFieldModal
  [modalConfig]="{
      modalLabel: 'Choose a field type to add',
      headerClass: ['visually-hidden'],
      hideCloseButton: true,
      options: { size: 'md' }
    }">
  <div class="row d-flex">
    <div
      class="card choice col-6 text-center"
      *ngFor="let choice of fieldChoices">
      <div class="p-4 m-1" (click)="addField(choice.type)">
        <i class="bi bi-{{ choice.icon }} fs-18 color-tertiary"></i>
        <p>{{ choice.name }}</p>
      </div>
    </div>
  </div>
</app-modal>
<app-form-modal *ngIf="data.id" #previewModal [form]="data"></app-form-modal>
