import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldChoice } from 'src/app/private/shared/dtos/forms.dto';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-form-edit-field-options',
  templateUrl: './form-edit-field-options.component.html',
  styleUrls: ['../../edit-field/form-edit-field.component.scss'],
})
export class FormEditFieldOptionsComponent implements OnInit {
  @Input() data: FieldChoice[] = [];

  id = `label${uuidv4()}`;

  @Output() dataChange = new EventEmitter<FieldChoice[]>();

  tempChoiceId: string;

  ngOnInit() {
    if (!this.data || this.data.length === 0) {
      this.data = [];
      this.addChoice();
    }
  }

  /* eslint-disable-next-line class-methods-use-this */
  slugify(text: string): string {
    return text
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }

  addChoice() {
    this.data.push({ label: '', value: '' });
  }

  deleteChoice(value: string) {
    this.data = this.data.filter((choice) => choice.value !== value);
    this.dataChange.emit(this.data);
  }
}
