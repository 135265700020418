import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, map, Subscription } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';

import {
  NewCoachingSessionItemsPayloadDTO,
  NewCoachingSessionUserDto,
} from '../../dtos/coaching-session.dto';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { LogTypeSelectDto } from '../log-type-select/log-type-select.dto';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-create-session-modal',
  templateUrl: './create-session-modal.component.html',
  styleUrls: ['./create-session-modal.component.scss'],
})
export class CreateSessionModalComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('createSessionModal')
  createSessionModal: ModalComponent;

  @Input() attendeeUserProfiles: NewCoachingSessionUserDto[] | null;

  @Input() retainAttendees = false;

  coachlogItemsPayload: NewCoachingSessionItemsPayloadDTO = {
    title: '',
    logType: null,
    startDatetime: 0,
    endDatetime: 0,
    timezone: TimezoneLinkBack.Central,
    coachUser: null,
    creatorUserId: 0,
    coachees: [],
    shadowers: [],
  };

  user$ = select(UserState.getUser);

  user: User | null = null;

  formSubmitted = false;

  formValid = true;

  currentDateTime = new Date().getTime() / 1000;

  selectedDate = new BehaviorSubject<number>(this.currentDateTime);

  selectedDate$ = this.selectedDate.asObservable();

  selectedTimezone = new BehaviorSubject<TimezoneLinkBack>(
    TimezoneLinkBack.Central
  );

  selectedTimezone$ = this.selectedTimezone.asObservable();

  subscriptions: Subscription[] = [];

  duration = 60;

  availableDurations: { value: number; display: string }[] = [
    {
      value: 15,
      display: '15 minutes',
    },
    {
      value: 30,
      display: '30 minutes',
    },
    {
      value: 45,
      display: '45 minutes',
    },
    {
      value: 60,
      display: '1 hour',
    },
  ];

  isLoading = false;

  serverError = '';

  constructor(private coachingLogService: CoachingLogService) {}

  ngOnInit(): void {
    if (!this.attendeeUserProfiles) {
      this.attendeeUserProfiles = [];
    }

    this.user$
      .pipe(
        map((user) => {
          this.user = user;

          this.initializeNewCoachlogPayload();
          if (this.attendeeUserProfiles) {
            this.coachlogItemsPayload.coachees = this.attendeeUserProfiles;
          }
        })
      )
      .subscribe();

    this.selectedDate.subscribe((timestamp) => {
      this.coachlogItemsPayload.startDatetime = timestamp;
      if (this.duration) {
        this.coachlogItemsPayload.endDatetime =
          this.coachlogItemsPayload.startDatetime + 60 * this.duration;
      }
    });

    this.selectedTimezone.subscribe((timezone) => {
      this.coachlogItemsPayload.timezone = timezone;
    });

    this.subscriptions.push(
      this.coachingLogService.coachlogCreated.subscribe(() => {
        this.closeModal();
      })
    );

    this.subscriptions.push(
      this.coachingLogService.coachlogError.subscribe((error) => {
        this.isLoading = false;
        this.serverError = error;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((keyName) => {
      switch (keyName) {
        case 'attendeeUserProfiles':
          this.coachlogItemsPayload.coachees = this.attendeeUserProfiles;
          break;
        default:
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  initializeNewCoachlogPayload() {
    let userId = 0;
    if (this.user) {
      userId = this.user.id;
    }

    this.coachlogItemsPayload = {
      title: '',
      logType: null,
      startDatetime: 0,
      endDatetime: 0,
      coachUser: null,
      timezone: TimezoneLinkBack.Central,
      creatorUserId: userId,
      coachees: [],
      shadowers: [],
    };
    this.coachlogItemsPayload.coachUser = this.user;
  }

  clearOutNewCoachlogPaylog() {
    this.coachlogItemsPayload.title = '';
    this.coachlogItemsPayload.logType = null;
    if (!this.retainAttendees) {
      this.attendeeUserProfiles = [];
      this.coachlogItemsPayload.coachees = this.attendeeUserProfiles;
    }
    this.coachlogItemsPayload.shadowers = [];
  }

  updateDateTime(params: { time: number; timezone: TimezoneLinkBack }) {
    this.selectedDate.next(params.time / 1000);
    this.selectedTimezone.next(params.timezone);
  }

  setDuration(durationValue: { value: number; display: string }) {
    if (this.coachlogItemsPayload.startDatetime) {
      this.coachlogItemsPayload.endDatetime =
        this.coachlogItemsPayload.startDatetime + 60 * durationValue.value;
    }
  }

  closeModal() {
    this.clearOutNewCoachlogPaylog();
    this.formSubmitted = false;
    this.isLoading = false;
    this.serverError = '';
    if (this.createSessionModal) {
      // Custom modal control is breaking this, so check if it's still instantiated before closing
      this.createSessionModal.close();
    }
  }

  openModal() {
    this.createSessionModal.open();
  }

  updateSessionLogType(logType: LogTypeSelectDto) {
    this.coachlogItemsPayload.logType = logType;
  }

  addSessionCoachee(coachee: UserDTO | null) {
    if (coachee) {
      const user: NewCoachingSessionUserDto = {
        id: coachee.id,
        profile: coachee.profile,
      };
      if (!this.checkIfAlreadyAdded(user)) {
        this.coachlogItemsPayload.coachees?.push(user);
      }
    }
  }

  addSessionShadower(shadower: UserDTO | null) {
    if (shadower) {
      const user: NewCoachingSessionUserDto = {
        id: shadower.id,
        profile: shadower.profile,
      };
      if (!this.checkIfAlreadyAdded(user)) {
        this.coachlogItemsPayload.shadowers?.push(user);
      }
    }
  }

  checkIfAlreadyAdded(user: NewCoachingSessionUserDto) {
    if (
      this.coachlogItemsPayload.coachees?.map((e) => e.id)?.indexOf(user.id) !==
      -1
    ) {
      return true;
    }
    if (
      this.coachlogItemsPayload.shadowers?.map((e) => e.id).indexOf(user.id) !==
      -1
    ) {
      return true;
    }
    if (this.attendeeUserProfiles?.map((e) => e.id).indexOf(user.id) !== -1) {
      return true;
    }
    return false;
  }

  validateForm() {
    this.formValid = true;
    if (!this.coachlogItemsPayload.logType) {
      this.formValid = false;
    }
    if (!this.coachlogItemsPayload.title) {
      this.formValid = false;
    }
  }

  createCoachingLog() {
    this.formSubmitted = true;
    this.validateForm();
    if (this.formValid) {
      this.isLoading = true;
      this.coachingLogService.createCoachinglog(this.coachlogItemsPayload);
      return true;
    }
    return false;
  }

  deleteAttendee(user: NewCoachingSessionUserDto) {
    if (this.coachlogItemsPayload.coachees) {
      this.coachlogItemsPayload.coachees =
        this.coachlogItemsPayload.coachees?.filter(
          (coachee: NewCoachingSessionUserDto) => user.id !== coachee.id
        );
      this.attendeeUserProfiles = this.coachlogItemsPayload.coachees;
    }
  }

  deleteShadower(user: NewCoachingSessionUserDto) {
    if (this.coachlogItemsPayload.shadowers) {
      this.coachlogItemsPayload.shadowers =
        this.coachlogItemsPayload.shadowers?.filter(
          (shadower: NewCoachingSessionUserDto) => shadower !== user
        );
    }
  }
}
