import { Component } from '@angular/core';
import { map } from 'rxjs';
import {
  KpiReportFilter,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { ChartType } from 'src/app/private/shared/enums/chart-type.enum';

@Component({
  selector: 'app-leader-dashboard-page',
  templateUrl: './leader-dashboard.component.html',
  styleUrls: ['./leader-dashboard.component.scss'],
})
export class LeaderDashboardComponent {
  chartTypes = ChartType;

  user$ = select(UserState.getUser);

  user: User | null = null;

  schoolFilter: KpiReportFilter = {
    codename: 'school',
    title: 'School',
    value: [''],
    displayName: 'School',
  };

  constructor() {
    this.user$
      .pipe(
        map((user) => {
          this.user = user;
          if (
            this.user &&
            this.user.school &&
            this.user.coacheeType === 'School Admin'
          ) {
            this.schoolFilter.value = [this.user.school.id.toString()];
          }
        })
      )
      .subscribe();
  }

  peopleCoachedParams: ReportRequestParams = {
    dataset: 'fct_coaching_session',
    measure: {
      type: 'count_distinct',
      field: 'user_id',
    },
    filters: [this.schoolFilter],
  };

  badgesEarnedParams: ReportRequestParams = {
    dataset: 'fct_user_badge',
    measure: {
      type: 'count',
      field: 'badge_id',
    },
    filters: [this.schoolFilter],
  };

  celebrationParams: ReportRequestParams = {
    dataset: 'fct_user_celebration',
    measure: {
      type: 'count_distinct',
      field: 'user_id',
    },
    filters: [this.schoolFilter],
  };

  coachingSessionsParams: ReportRequestParams = {
    dataset: 'fct_coaching_session',
    measure: {
      type: 'count_distinct',
      field: 'log_id',
    },
    filters: [this.schoolFilter],
  };

  yearToDateBadgeParams: ReportRequestParams = {
    dataset: 'fct_coaching_session',
    measure: {
      type: 'count_distinct',
      field: 'log_id',
    },
    filters: [this.schoolFilter],
  };

  yearToDateSessionParams: ReportRequestParams = {
    dataset: 'fct_coaching_session',
    measure: {
      type: 'count',
      field: '',
    },
    filters: [this.schoolFilter],
  };

  yearToDateObservationParams: ReportRequestParams = {
    dataset: 'fct_form_submission',
    measure: {
      type: 'count',
      field: '',
    },
    filters: [this.schoolFilter],
  };

  yearToDateCampusParams: ReportRequestParams = {
    dataset: 'fct_coaching_session',
    measure: {
      type: 'count_distinct',
      field: 'school_id',
    },
    filters: [this.schoolFilter],
  };

  yearToDateGIParams: ReportRequestParams = {
    dataset: 'fct_indicator_assessment',
    measure: {
      type: 'count',
      field: '',
    },
    filters: [this.schoolFilter],
  };

  yearToDateCelebrationsParams: ReportRequestParams = {
    dataset: 'fct_user_celebration',
    measure: {
      type: 'count',
      field: '',
    },
    filters: [this.schoolFilter],
  };

  competencyBadgesParams: ReportRequestParams = {
    dataset: 'fct_user_badge',
    dimension: 'user_badge_count',
    measure: {
      type: 'count',
      field: '*',
    },
    filters: [
      this.schoolFilter,
      {
        codename: 'is_competency',
        value: ['1'],
      },
    ],
  };
}
