import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SchoolYear } from 'src/app/common/dtos/school.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-year-select',
  templateUrl: './year-select.component.html',
  styleUrls: ['./year-select.component.scss'],
})
export class YearSelectComponent implements OnInit, OnChanges {
  @Input() defaultYear: SchoolYear | null;

  @Input() placeholder = 'Select a year for reporting district';

  @Output() emittedYear = new EventEmitter<SchoolYear | null>();

  @Input() label = 'Select a year';

  labelId = uuidv4();

  selectedYear: SchoolYear;

  selectedYearList: SchoolYear[] = [];

  constructor(private apiCoreService: APICoreService) {}

  ngOnInit(): void {
    this.apiCoreService
      .getRequest('reporting/school-years')
      .subscribe((yearItems) => {
        this.selectedYearList = yearItems.items.reverse();

        if (this.defaultYear) {
          this.selectedYear = this.defaultYear;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('defaultYear' in changes) {
      if (this.defaultYear) {
        this.selectedYear = this.defaultYear;
      }
    }
  }
}
