import { Component } from '@angular/core';
import { User } from 'src/app/common/state/user/user.model';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-user-settings-page',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsPageComponent {
  isLoading = false;

  user: User;

  constructor(private store: Store) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
  }
}
