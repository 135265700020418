import { timeStructFromDate } from 'src/app/common/utilities/time-helpers';

export enum TimePickerShift {
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
}

export const datepickerDisplayTime = (hour: number, minute: string): string =>
  `${hour > 12 && hour % 12 > 0 ? hour - 12 : hour}:${minute} ${
    hour < 12 ? 'AM' : 'PM'
  }`;

export const getValidTimepickerValue = (
  date: Date,
  shift?: TimePickerShift
): string => {
  const timeStruct = timeStructFromDate(date);
  const over45 = timeStruct.minute > 45;
  switch (shift) {
    case TimePickerShift.FORWARD:
      timeStruct.minute = (Math.ceil(timeStruct.minute / 15) * 15) % 60;
      break;
    case TimePickerShift.BACKWARD:
      timeStruct.minute = (Math.floor(timeStruct.minute / 15) * 15) % 60;
      break;
    default:
      timeStruct.minute = (Math.round(timeStruct.minute / 15) * 15) % 60;
      break;
  }
  if (timeStruct.minute === 0 && over45) {
    timeStruct.hour += 1;
  }
  if (timeStruct.hour === 24) {
    timeStruct.hour = 23;
    timeStruct.minute = 45;
  }
  const minute =
    timeStruct.minute.toString().length === 1
      ? `0${timeStruct.minute}`
      : timeStruct.minute;
  return `${timeStruct.hour}:${minute}`;
};
