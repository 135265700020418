import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PresenceChannel } from 'pusher-js';
import { Subscription, map } from 'rxjs';
import { CompetencyDTO } from 'src/app/common/dtos/competency.dto';
import { User as UserModel } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { LeveledCompetencyDTO } from '../../shared/components/leveled-resource-competencies/leveled-competency.dto';

import { CompetencyLeveledResourcesDto } from '../../shared/dtos/leveled-resource.dto';
import { ResourceDTO } from '../../shared/dtos/resources.dto';
import { CompetenciesSelectService } from '../../shared/services/competencies/competencies-select.service';
import { ResourceService } from '../../shared/services/resources/resource.service';

@Component({
  selector: 'app-resource-view-page',
  templateUrl: './resource-view-page.component.html',
  styleUrls: ['./resource-view-page.component.scss'],
})
export class ResourceViewPageComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  doneLoading = false;

  resourceSlug: string;

  currentUser: UserModel | null;

  currentResource: ResourceDTO | null;

  isOwnerOrEditor = false;

  user$ = select(UserState.getUser);

  editMode = false;

  isLocked: boolean | null = false;

  channel: PresenceChannel;

  currentCompetency: CompetencyDTO;

  competenciesToAdd: LeveledCompetencyDTO[] | null;

  resourceCompetencies: LeveledCompetencyDTO[] | null;

  invalidSelection = false;

  selectionNotAllowed = false;

  selectionAlreadyAdded = false;

  constructor(
    private route: ActivatedRoute,
    private resourceService: ResourceService,
    private titleService: Title,
    private competenciesSelectService: CompetenciesSelectService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((url) => {
      this.resourceSlug = url['resourceId'];
    });
    if (!this.competenciesToAdd) {
      this.competenciesToAdd = [];
    }
    if (!this.resourceCompetencies) {
      this.resourceCompetencies = [];
    }
    this.doneLoading = false;

    this.user$
      .pipe(
        map((user) => {
          this.currentUser = user;
          this.resourceService.getResourceBySlug(this.resourceSlug);
        })
      )
      .subscribe();

    // get the resource from the resourceService
    this.subs.push(
      this.resourceService.currentResource.subscribe((currentResource) => {
        if (currentResource) {
          this.currentResource = currentResource;
          if (this.currentResource) {
            this.titleService.setTitle(
              `GroweLab: Resource - ${this.currentResource.title}`
            );
          }

          if (
            this.currentResource.your_access === 'own' ||
            this.currentResource.your_access === 'edit'
          ) {
            this.isOwnerOrEditor = true;
          }
          if (
            this.currentResource.your_access === 'own' ||
            this.currentResource.your_access === 'edit'
          ) {
            this.isOwnerOrEditor = true;
          }
        } else {
          this.currentResource = null;
        }
        this.doneLoading = true;
      })
    );

    // locked status
    this.subs.push(
      this.resourceService.isLocked.subscribe((isLocked) => {
        this.isLocked = isLocked;
      })
    );

    const leveledCompetencyFromCompetencyDTO = (
      item: CompetencyDTO
    ): LeveledCompetencyDTO => ({
      id: item.id,
      name: item.title,
      level1: false,
      level2: false,
      level3: false,
      level4: false,
      levelsFormatted: '',
      can_add: false,
      can_delete: false,
    });

    this.subs.push(
      this.competenciesSelectService.competencyLeveledResources.subscribe(
        (competency: CompetencyLeveledResourcesDto) => {
          this.invalidSelection = false;
          this.selectionNotAllowed = false;
          this.selectionAlreadyAdded = false;

          // make sure has permissions to add competency
          if (competency.can_add) {
            // make sure competency has not been added yet
            let didFind = this.competenciesToAdd?.find(
              (eachCompetency) =>
                eachCompetency.id === this.currentCompetency.id
            );
            if (!didFind) {
              didFind = this.resourceCompetencies?.find(
                (eachCompetency) =>
                  eachCompetency.id === this.currentCompetency.id
              );
            }
            if (!didFind) {
              const newComp = leveledCompetencyFromCompetencyDTO(
                this.currentCompetency
              );
              newComp.can_add = true;
              newComp.can_delete = true;
              this.competenciesToAdd?.push(newComp);
            } else {
              this.invalidSelection = true;
              this.selectionAlreadyAdded = true;
            }
          } else {
            this.invalidSelection = true;
            this.selectionNotAllowed = true;
          }
        }
      )
    );
  }

  toggleEditMode() {
    // toggling to view mode
    if (this.editMode) {
      this.resourceService.slugUnlock(this.resourceSlug);
      this.resourceService.getResourceBySlug(this.resourceSlug);
      this.editMode = !this.editMode;
    } else if (this.currentResource && this.currentUser) {
      if (
        !this.isLocked ||
        this.currentResource.lock_user_id === this.currentUser.id
      ) {
        // toggling to edit mode
        this.resourceService.slugLock(this.resourceSlug);
        this.editMode = !this.editMode;
      }
    }
  }

  saveTitle() {
    if (this.currentResource) {
      this.resourceService.updateTitle(this.currentResource.title).subscribe();
    }
  }

  saveType() {
    if (this.currentResource) {
      this.resourceService.updateType(this.currentResource.type).subscribe();
    }
  }

  saveDescription() {
    if (this.currentResource) {
      this.resourceService
        .updateDescription(this.currentResource.description)
        .subscribe();
    }
  }

  updateCompetenciesToAdd(competency: CompetencyDTO | null) {
    if (competency) {
      this.currentCompetency = competency;
      this.competenciesSelectService
        .getLeveledResourcesOnCompetency(competency.id)
        .subscribe();
    }
  }

  refreshCompetenciesToAdd(competencies: LeveledCompetencyDTO[]) {
    this.competenciesToAdd = competencies;
  }

  refreshResourceCompetencies(competencies: LeveledCompetencyDTO[]) {
    this.resourceCompetencies = competencies;
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
