<ng-container *ngIf="initialContent">
  <ng-container *ngIf="isAllowed && editMode; else readOnlyTemplate">
    <app-wysiwyg-editor
      #resourceeditor
      [isSaving]="isSaving"
      [version]="'full'"
      [config]="editorConfig"
      [editorContent]="initialContent"
      (editorContentEvent)="emitAutosave($event)" />
  </ng-container>
  <ng-template #readOnlyTemplate>
    <div class="overflow-auto">
      <app-safehtml [html]="initialContent"></app-safehtml>
    </div>
  </ng-template>
</ng-container>
