<ng-container *ngIf="!loadingData; else noLink">
  <a href="javascript:void(0)" (click)="showEvidenceModal()">
    <ng-content></ng-content>
  </a>
</ng-container>
<ng-template #noLink>
  <div class="spinner-border spinner-border-sm" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</ng-template>

<app-modal #evidenceSummaryModal [modalConfig]="modalConfig">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="evidenceSummaryModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <ng-container *ngIf="evidenceSummary">
    <div class="text-center px-2">
      <h3 class="competency-title fs-23 fw-800">
        {{ evidenceSummary.standard.title }}
      </h3>
      <div class="strand-title-level fs-21 fw-800">
        {{ evidenceSummary.indicatorGroup.title }} - Level
        {{ evidenceSummary.level }}
      </div>
      <p class="gi-indicator px-5">
        {{ evidenceSummary.standard.content }}
      </p>
    </div>

    <div class="px-2">
      <hr />
      <div class="fs-23 fw-800">Context</div>
      <div class="py-3">
        <app-safehtml [html]="evidenceSummary.narrative"></app-safehtml>
      </div>
      <ng-container *ngIf="hasAttachments && uploadedFiles">
        <div class="px-3 bg-light">
          <app-file-list
            [fileList]="uploadedFiles"
            [canDelete]="false"></app-file-list>
        </div>
      </ng-container>

      <div class="py-3" *ngIf="video">
        <app-video-player [video]="video"></app-video-player>
      </div>

      <div class="py-4">
        <div class="fs-23 fw-800">Comments</div>
        <div class="card mt-3 px-4">
          <app-evidence-comments
            [refTableId]="evidenceSummary.id"
            [commentList]="commentList" />
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4 mb-2">
        <ng-container *ngIf="!evidenceAssessed">
          <ng-container *ngIf="isCoach; else default">
            <button
              (click)="assessEvidence()"
              class="btn btn-primary btn-next"
              [disabled]="evidenceAssessing">
              <ng-container *ngIf="evidenceAssessing">
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </ng-container>
              Mark Evidence as Reviewed
            </button>
          </ng-container>
          <ng-template #default>
            <div class="fs-19">
              <i class="bi bi-exclamation-circle text-warning me-2"></i>
              This evidence has not been reviewed yet
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="evidenceAssessed">
          <div class="fs-19">
            <i class="bi bi-check2-circle text-info me-2"></i>
            This evidence has been reviewed
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div footerContent class="w-100">
    <div class="d-flex justify-content-between">
      <ng-container *ngIf="!isShadower">
        <button
          type="button"
          class="btn btn-md btn-delete gl-tertiary"
          container="body"
          [autoClose]="'outside'"
          [ngbPopover]="contentTemplate"
          [popoverTitle]="titleTemplate"
          #p="ngbPopover">
          Delete
        </button>
        <ng-template #titleTemplate>
          <div class="custom-title">Delete Evidence</div>
        </ng-template>
        <ng-template #contentTemplate>
          <div class="custom-content">
            Are you sure you wish to delete this evidence?
          </div>
          <div class="app-hr-thin"></div>
          <div style="text-align: right">
            <button
              type="button"
              class="btn btn-secondary btn-sm px-3 m-1"
              (click)="p.close()">
              No
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm px-3 m-1"
              (click)="deleteEvidence(); p.close()">
              Yes
            </button>
          </div>
        </ng-template>
      </ng-container>

      <!-- <ng-container *ngIf="(!isShadower && isCoach)>
        <button type="button" class="btn btn-md btn-primary" disabled>
          <i class="bi bi-pencil"></i> Edit
        </button>
      </ng-container> -->
    </div>
  </div>
</app-modal>
