/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, DurationUnit } from 'luxon';
import {
  formatDurationAsString,
  fullDurationUnits,
} from '../utilities/date-helpers';

@Pipe({
  name: 'formatDistanceToNow',
})
export class FormatDistanceToNowPipe implements PipeTransform {
  transform(value: number): string {
    const duration = DateTime.fromSeconds(value).diffNow(
      fullDurationUnits as DurationUnit[]
    );
    return formatDurationAsString(duration);
  }
}
