<div class="container">
  <div class="d-flex justify-content-between mb-3">
    <h1>District Settings</h1>
    <div class="align-self-center">
      <button routerLink="new-district" type="button" class="btn btn-link">
        <i class="bi bi-plus-circle me-1"></i>Add a District
      </button>
    </div>
  </div>
  <div class="card border-0 p-3 table-container">
    <app-settings-nav [districtCode]="'engage2learn'"></app-settings-nav>
    <div class="card-body">
      <ng-container *ngIf="!isLoading; else loadingTemplate">
        <table *ngIf="tableData" class="table">
          <thead>
            <tr>
              <th scope="col" (sort)="onSort($event)" sortable="id">
                ID
                <i class="bi bi-caret-down {{ sortIconTemp.id }}"></i>
              </th>
              <th scope="col" (sort)="onSort($event)" sortable="title">
                District Name
                <i class="bi bi-caret-down {{ sortIconTemp.title }}"></i>
              </th>
              <th scope="col" (sort)="onSort($event)" sortable="districtCode">
                Slug
                <i class="bi bi-caret-down {{ sortIconTemp.districtCode }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="state"
                class="hide-on-mobile">
                State
                <i class="bi bi-caret-down {{ sortIconTemp.state }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="timezone"
                class="hide-on-mobile">
                Timezone
                <i class="bi bi-caret-down {{ sortIconTemp.timezone }}"></i>
              </th>
              <!-- <th
                scope="col"
                (sort)="onSort($event)"
                sortable="domain"
                class="hide-on-mobile">
                Domain
                <i class="bi bi-caret-down {{ sortIconTemp.domain }}"></i>
              </th> -->
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="hubspotCompanyId"
                class="hide-on-mobile">
                CRM ID
                <i
                  class="bi bi-caret-down {{
                    sortIconTemp.hubspotCompanyId
                  }}"></i>
              </th>
              <th class="hide-on-mobile">B2B</th>
              <th class="hide-on-mobile">e2L</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let district of tableData"
              routerLink="districts/{{ district.districtCode }}">
              <td>{{ district.id }}</td>
              <td>{{ district.title }}</td>
              <td>{{ district.districtCode }}</td>
              <td class="hide-on-mobile">{{ district.state || "-" }}</td>
              <td class="hide-on-mobile">{{ district.timezone || "-" }}</td>
              <!-- <td class="hide-on-mobile">{{ district.domain }}</td> -->
              <td class="hide-on-mobile">
                {{ district.hubspotCompanyId || "-" }}
              </td>
              <td class="hide-on-mobile">
                <ng-container
                  *ngIf="
                    district.isB2b === 1;
                    then check;
                    else x
                  "></ng-container>
              </td>
              <td class="hide-on-mobile">
                <ng-container
                  *ngIf="
                    district.isInternal === 1;
                    then check;
                    else x
                  "></ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-template #loadingTemplate>
        <app-loader />
      </ng-template>
    </div>
  </div>
</div>

<ng-template #check><i class="bi bi-check"></i></ng-template>
<ng-template #x><i class="bi bi-dash color-secondary"></i></ng-template>
