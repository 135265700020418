import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldType } from 'src/app/private/shared/dtos/forms.dto';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-form-edit-field-label',
  templateUrl: './form-edit-field-label.component.html',
  styleUrls: ['../../edit-field/form-edit-field.component.scss'],
})
export class FormEditFieldLabelComponent {
  @Input() fieldName = 'Prompt';

  @Input() fieldType = FieldType.TEXT;

  @Input() data: string;

  fieldTypes = FieldType;

  id = `label${uuidv4()}`;

  @Output() dataChange = new EventEmitter<string>();
}
