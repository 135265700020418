import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';

@Injectable({
  providedIn: 'root',
})
export class RosterUploadService {
  constructor(private apiService: APICoreService) {}

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  uploadCsvFile(file: File, districtId: number, type: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('district_id', districtId.toString());
    formData.append('type', type);
    return this.apiService.postFormRequest('rosters/upload', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
