<div class="container container-main">
  <div class="d-flex justify-content-between mb-3">
    <h1>
      {{
        (selectedSchool && selectedSchool.title) ||
          (selectedDistrict && selectedDistrict.title) ||
          "District"
      }}
      Reporting
    </h1>
  </div>
  <div id="filterRow" class="row my-4">
    <ng-container *ngIf="isE2L">
      <div class="col-12 col-md-6 col-xl-3">
        <app-district-select (selectedDistrict)="didSelectDistrict($event)" />
      </div>
    </ng-container>
    <div class="col-12 col-md-6 col-xl-3">
      <app-school-select
        (selectedSchool)="setSchool($event)"
        [placeholder]="'Choose a school'"
        [simpleSelect]="true"
        [districtId]="selectedDistrict?.id"></app-school-select>
    </div>
    <div class="col-12 col-md-6 col-xl-3">
      <label class="visually-hidden" [attr.for]="labelId"
        >View Available School Years</label
      >
      <div style="clear: both; height: 4px"></div>
      <app-year-select
        [defaultYear]="schoolYearItem"
        (emittedYear)="onYearSelected($event)"></app-year-select>
    </div>
  </div>
  <ng-container *ngIf="loadedDistrictInfo">
    <div id="kpi-row" class="row">
      <div class="col-md-4 mb-3">
        <div id="sessions-kpi" class="card border-0 text-center p-3">
          <app-kpi-controller
            [primaryRequestParams]="{
              dataset: 'fct_coaching_session',
              measure: { type: 'count_distinct', field: 'log_id' },
              filters: [
                {
                  codename: 'session_type',
                  value: ['2', '13', '43'],
                },
                {
                  codename: 'district',
                  value: [this.selectedDistrict ? this.selectedDistrict.id.toString() : ''],
                },
                {
                  codename: 'school',
                  value: [this.selectedSchool ? this.selectedSchool.id.toString() : ''],
                },
                {
                  codename: 'school_year',
                  value: [this.schoolYearId.toString()],
                }
              ],
            }"
            [secondaryRequestParams]="{
              dataset: 'fct_coaching_session',
              measure: { type: 'count_distinct', field: 'user_id' },
              filters: [
                {
                  codename: 'session_type',
                  value: ['2', '13', '43'],
                },
                {
                  codename: 'district',
                  title: 'District',
                  value: [this.selectedDistrict ? this.selectedDistrict.id.toString() : ''],
                  displayName: 'District',
                },
                {
                  codename: 'school',
                  title: 'School',
                  value: [this.selectedSchool ? this.selectedSchool.id.toString() : ''],
                  displayName: 'School',
                },
              ],
            }" />
          <!-- Was the above request param supposed to be missing the school year id filter? -->
          <div class="fs-5 fw-800">Avg Sessions per Coachee</div>
        </div>
      </div>
      <div class="col-md-4 mb-3">
        <div id="badges-kpi" class="card border-0 text-center p-3">
          <app-kpi-controller
            [primaryRequestParams]="{
              dataset: 'fct_user_badge',
              measure: { type: 'count', field: '' },
              filters: [
                {
                  codename: 'district',
                  value: [this.selectedDistrict ? this.selectedDistrict.id.toString() : ''],
                },
                {
                  codename: 'school',
                  value: [this.selectedSchool ? this.selectedSchool.id.toString() : ''],
                },
                {
                  codename: 'school_year',
                  value: [this.schoolYearId.toString()],
                }
              ]
            }"
            [secondaryRequestParams]="{
              dataset: 'fct_coaching_session',
              measure: { type: 'count', field: '' },
              filters: [
                {
                  codename: 'district',
                  value: [this.selectedDistrict ? this.selectedDistrict.id.toString() : ''],
                },
                {
                  codename: 'school',
                  value: [this.selectedSchool ? this.selectedSchool.id.toString() : ''],
                },
                {
                  codename: 'session_type',
                  value: ['2', '13', '43'],
                },
                {
                  codename: 'school_year',
                  value: [this.schoolYearId.toString()],
                }
              ],
            }" />
          <div class="fs-5 fw-800">Avg Badges per Session</div>
        </div>
      </div>
      <div class="col-md-4 mb-3">
        <div id="celebrations-kpi" class="card border-0 text-center p-3">
          <app-kpi-controller
            [primaryRequestParams]="{
              dataset: 'fct_user_celebration',
              measure: { type: 'count', field: '' },
              filters: [
                {
                  codename: 'district',
                  value: [this.selectedDistrict ? this.selectedDistrict.id.toString() : ''],
                },
                {
                  codename: 'school',
                  value: [this.selectedSchool ? this.selectedSchool.id.toString() : ''],
                },
                {
                  codename: 'school_year',
                  value: [this.schoolYearId.toString()],
                }              
              ],
            }"
            [secondaryRequestParams]="{
              dataset: 'fct_user_celebration',
              measure: { type: 'count', field: 'user_id' },
              filters: [
                {
                  codename: 'district',
                  value: [this.selectedDistrict ? this.selectedDistrict.id.toString() : ''],
                },
                {
                  codename: 'school',
                  value: [this.selectedSchool ? this.selectedSchool.id.toString() : ''],
                },
                {
                  codename: 'school_year',
                  value: [this.schoolYearId.toString()],
                }              
              ],
            }" />
          <div class="fs-5 fw-800">Avg Celebrations per Person</div>
        </div>
      </div>
    </div>
    <div id="report-row" class="row">
      <div class="col-md-6 mb-3">
        <div class="card border-0">
          <div class="card-body">
            <div class="fs-5 fw-800 mb-3">Coaching Conversations (Average)</div>
            <app-report-controller
              [chartType]="chartTypes.Area"
              [gridDimensions]="{
                top: '10',
                left: '30',
                right: '15',
                bottom: '25',
              }"
              [requestParams]="{
                dataset: 'fct_coaching_session',
                complex_measure: {
                  type: 'rolling_average',
                  numerator_measure: {
                    type: 'count_distinct',
                    field: 'log_id',
                  },
                  denominator_measure: {
                    type: 'count_distinct',
                    field: 'user_id',
                  },
                },
                filters: [
                  {
                    codename: 'district',
                    value: [this.selectedDistrict ? this.selectedDistrict.id.toString() : ''],
                  },
                  {
                    codename: 'school',
                    value: [this.selectedSchool ? this.selectedSchool.id.toString() : ''],
                  },
                  {
                    codename: 'session_type',
                    value: ['2', '13', '43'],
                  },
                  {
                    codename: 'school_year',
                    value: [this.schoolYearId.toString()],
                  }
                ]
              }"
              yAxisLabel="Session Count" />
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <div class="card border-0">
          <div class="card-body">
            <div class="fs-5 fw-800 mb-3">Classroom Visits (Average)</div>
            <app-report-controller
              [chartType]="chartTypes.Area"
              [chartColor]="'#a68dbe'"
              [requestParams]="{
                dataset: 'fct_form_submission',
                complex_measure: {
                  type: 'rolling_average',
                  numerator_measure: {
                    type: 'count_distinct',
                    field: 'log_id',
                  },
                  denominator_measure: {
                    type: 'count_distinct',
                    field: 'user_id',
                  },
                },
                filters: [
                  {
                    codename: 'district',
                    value: [this.selectedDistrict ? this.selectedDistrict.id.toString() : ''],
                  },
                  {
                    codename: 'school',
                    value: [this.selectedSchool ? this.selectedSchool.id.toString() : ''],
                  },
                  {
                    codename: 'school_year',
                    value: [this.schoolYearId.toString()],
                  }
                ]
              }"
              yAxisLabel="Observation Count" />
          </div>
        </div>
      </div>
    </div>
    <div id="alternate-report-row" class="row">
      <div class="col-md-6 mb-3">
        <div class="card border-0">
          <div class="card-body">
            <div class="fs-5 fw-800 mb-3">Competency Badges</div>
            <app-report-controller
              [xNameGap]="23"
              [yNameGap]="25"
              [chartColor]="'#a68dbe'"
              [gridDimensions]="{
                top: '10px',
                left: '27px',
                right: '15px',
                bottom: '25px',
              }"
              [chartType]="chartTypes.HorizontalBar"
              [requestParams]="{
                dataset: 'fct_user_badge',
                dimension: 'user_badge_count',
                measure: {
                  type: 'count',
                  field: '*',
                },
                filters: [
                  {
                    codename: 'is_competency',
                    value: ['1'],
                  },
                  {
                    codename: 'district',
                    value: [this.selectedDistrict ? this.selectedDistrict.id.toString() : ''],
                  },
                  {
                    codename: 'school',
                    value: [this.selectedSchool ? this.selectedSchool.id.toString() : ''],
                  },
                  {
                    codename: 'school_year',
                    value: [this.schoolYearId.toString()],
                  }
                ],
              }"
              yAxisLabel="Learner Count"
              xAxisLabel="Number of Competency Badges Earned" />
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <div class="card border-0">
          <div class="card-body">
            <div class="fs-5 fw-800 mb-3">Session Attendance</div>
            <app-report-controller
              [yNameGap]="35"
              [chartType]="chartTypes.Line"
              [chartColor]="'#a68dbe'"
              [percentage]="true"
              [requestParams]="{
                dataset: 'fct_coaching_session',
                measure: {
                  type: 'average',
                  field: 'is_present',
                },
                filters: [
                  {
                    codename: 'district',
                    value: [this.selectedDistrict ? this.selectedDistrict.id.toString() : ''],
                  },
                  {
                    codename: 'school',
                    value: [this.selectedSchool ? this.selectedSchool.id.toString() : ''],
                  },
                  {
                    codename: 'school_year',
                    value: [this.schoolYearId.toString()],
                  }
                ],
              }"
              yAxisLabel="Attendance Percentage" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
