import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject, Subscription, map } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { v4 as uuidv4 } from 'uuid';

import { getIdsFromDefaultItemsForSelect } from '../../helpers/select.utilities';
import { UserSearchService } from '../../services/user-search/user-search.service';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
})
export class UserSelectComponent implements OnInit {
  userList: Observable<UserDTO[]>;

  userLibrary: UserDTO[] = [];

  searchInput$ = new Subject<string>();

  searchSubscription: Subscription | null;

  searchLoading = false;

  labelId = uuidv4();

  internalSelectedUser: UserDTO | null = null;

  internalSelectedUserList: UserDTO[] = [];

  @ViewChild('select') select: NgSelectComponent;

  @Input() hideSelectedUserList = false;

  @Input() clearAfterSelection = false;

  @Input() disabled = false;

  @Input() label = 'Search for and select a user';

  @Input() placeholder = 'Search for a user...';

  @Input() isMulti = false;

  @Input() defaultUsers: UserDTO[] | null;

  @Input() districtId: number | null = null;

  @Output() selectedUser: EventEmitter<UserDTO | null> = new EventEmitter();

  @Output() selectedUserList: EventEmitter<UserDTO[] | null> =
    new EventEmitter();

  constructor(private userSearch: UserSearchService) {}

  ngOnInit(): void {
    this.userList = this.userSearch.items.pipe(
      map((users) => {
        this.userLibrary = [...this.userLibrary, ...users];
        this.searchLoading = false;

        return users;
      })
    );

    this.searchInput$.subscribe((term) => {
      this.searchLoading = true;
      const districtIds: number[] = [];
      if (this.districtId) {
        districtIds.push(this.districtId);
      }
      return this.userSearch.search(term, districtIds);
    });

    if (this.defaultUsers) {
      const userIds = getIdsFromDefaultItemsForSelect(this.defaultUsers);
      userIds.forEach((userId) => {
        this.userSearch.getUser(userId as number).subscribe((user) => {
          if (user) {
            if (this.isMulti) {
              this.internalSelectedUserList.push(user);
            } else {
              this.internalSelectedUser = user;
            }
          }
        });
      });
    }
  }

  userSelected(user: UserDTO) {
    if (this.isMulti && user) {
      if (!this.internalSelectedUserList.includes(user)) {
        this.internalSelectedUserList.push(user);
      }
      this.select.handleClearClick();
    } else {
      this.internalSelectedUser = user;
    }
    this.outputUsers();
    if (this.clearAfterSelection) {
      // Without a timeout you get infinite recursion
      setTimeout(() => {
        this.select.unselect(this.select.selectedItems[0]);
      });
    }
  }

  reset() {
    if (this.isMulti) {
      this.internalSelectedUserList = [];
    } else {
      this.internalSelectedUser = null;
    }
    this.select.selectedItems.forEach((item) => {
      this.select.unselect(item);
    });
  }

  outputUsers() {
    if (this.isMulti) {
      this.selectedUserList.emit(this.internalSelectedUserList);
    } else {
      this.selectedUser.emit(this.internalSelectedUser);
    }
  }

  removeUserFromList(user: UserDTO) {
    this.internalSelectedUserList = this.internalSelectedUserList.filter(
      (u) => u.id !== user.id
    );
    this.outputUsers();
  }
}
