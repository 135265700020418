import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { ResourceAccessModel, ResourceDTO } from '../../dtos/resources.dto';
import {
  MultiTagPayload,
  CollabApiPayload,
  SaveData,
  CreateResourcePayload,
} from '../../types/payloads/resources.payloads';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  private resourceData: ResourceDTO | null = null;

  private resourceSource = new BehaviorSubject(this.resourceData);

  public currentResource = this.resourceSource.asObservable();

  public localCurrentResource: ResourceDTO | null;

  public currentResourceId: number;

  private resourceAccessData: ResourceAccessModel[] | null = null;

  private resourceAccessSource = new BehaviorSubject(this.resourceAccessData);

  public resourceAccess = this.resourceAccessSource.asObservable();

  private isPublishedData: boolean | null = null;

  private isPublishedSource = new BehaviorSubject(this.isPublishedData);

  public isPublished = this.isPublishedSource.asObservable();

  private isLockedData: boolean | null = null;

  private isLockedSource = new BehaviorSubject(this.isLockedData);

  public isLocked = this.isLockedSource.asObservable();

  constructor(private apiService: APICoreService) {}

  createResource(payload: CreateResourcePayload) {
    return this.apiService.postRequest('elearn/resources', payload);
  }

  getResourceContent(collabApiInfo: CollabApiPayload) {
    return this.apiService.getRequest(collabApiInfo.apiEndpoint, {
      collabDoc: 'content',
    });
  }

  updateResourceContent(collabApiInfo: CollabApiPayload, saveData: SaveData) {
    return this.apiService.putRequest(collabApiInfo.apiEndpoint, saveData);
  }

  slugLock(slug: string) {
    this.apiService
      .putRequest(`elearn/resources/${slug}/lock`)
      .subscribe((response) => {
        if (response && response.item) {
          if (this.localCurrentResource) {
            this.localCurrentResource.lock_user_id = response.item.lock_user_id;
            this.localCurrentResource.lock_expires = response.item.lock_expires;
          }
          this.isLockedSource.next(true);
        }
      });
  }

  slugUnlock(slug: string) {
    this.apiService
      .putRequest(`elearn/resources/${slug}/unlock`)
      .subscribe((response) => {
        if (response && response.item) {
          if (this.localCurrentResource) {
            this.localCurrentResource.lock_user_id = response.item.lock_user_id;
            this.localCurrentResource.lock_expires = response.item.lock_expires;
          }
          this.isLockedSource.next(false);
        }
      });
  }

  getResourceBySlug(slug: string) {
    this.apiService
      .getRequest(`elearn/resource-from-slug/${slug}`)
      .subscribe((response) => {
        if (response.item && response.item.your_access) {
          this.resourceSource.next(response.item);
          this.currentResourceId = response.item.id;
        } else {
          this.resourceSource.next(null);
          this.currentResourceId = 0;
        }
      });
  }

  updateTitle(title: string) {
    return this.apiService.putRequest(
      `elearn/resources/${this.currentResourceId}`,
      {
        title,
      }
    );
  }

  updateType(type: string) {
    return this.apiService.putRequest(
      `elearn/resources/${this.currentResourceId}`,
      {
        type,
      }
    );
  }

  updateDescription(description: string) {
    return this.apiService.putRequest(
      `elearn/resources/${this.currentResourceId}`,
      {
        description,
      }
    );
  }

  getAvailTags() {
    return this.apiService.getRequest('tags/search?ref_table=elearn_resource');
  }

  addNewTags(newTags: MultiTagPayload) {
    return this.apiService.postRequest('tags', newTags);
  }

  deleteTag(tagId: number) {
    return this.apiService.deleteRequest(`tags/${tagId}`);
  }

  getResourceAccess() {
    if (this.currentResourceId) {
      this.apiService
        .getRequest(`elearn/resources/${this.currentResourceId}/access`)
        .subscribe((response) => {
          if (response.items) {
            this.resourceAccessSource.next(response.items);
          } else {
            this.resourceAccessSource.next([]);
          }
        });
    }
  }

  addAccess(newAccess: ResourceAccessModel) {
    this.apiService
      .postRequest(
        `elearn/resources/${newAccess.elearn_resource_id}/access`,
        newAccess
      )
      .subscribe(() => {
        this.getResourceAccess();
      });
  }

  updateAccess(access: ResourceAccessModel) {
    this.apiService
      .putRequest(
        `elearn/resources/${access.elearn_resource_id}/access`,
        access
      )
      .subscribe(() => {
        this.getResourceAccess();
      });
  }

  deleteAccess(access: ResourceAccessModel) {
    this.apiService
      .deleteRequest(
        `elearn/resources/${access.elearn_resource_id}/access?access_scope=${access.access_scope}&access_scopes_id=${access.access_scopes_id}`
      )
      .subscribe(() => {
        this.getResourceAccess();
      });
  }

  updateIsPublished(bool: boolean) {
    this.isPublishedSource.next(bool);
  }
}
