export enum ReportSchoolYear {
  SY_2014_2015 = '1',
  SY_2015_2016 = '2',
  SY_2016_2017 = '3',
  SY_2017_2018 = '4',
  SY_2018_2019 = '5',
  SY_2019_2020 = '6',
  SY_2020_2021 = '7',
  SY_2021_2022 = '8',
  SY_2022_2023 = '9',
  SY_2023_2024 = '10',
}

export const CURRENT_SCHOOL_YEAR: ReportSchoolYear =
  ReportSchoolYear.SY_2022_2023;

export enum ReportSessionType {
  COACHING_CONVERSATION = '2',
}
