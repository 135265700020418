<h3 class="fw-800">Action Plan</h3>
<div class="d-flex justify-content-end">
  <button class="btn btn-link" (click)="resetForm(); actionPlanModal.open()">
    <i class="bi bi-plus-circle me-1"></i>Add Action Item
  </button>
</div>
<ng-container *ngIf="actionItems.length > 0; else noActionItems">
  <div class="items-container bg-secondary rounded p-3">
    <div *ngFor="let actionItem of actionItems" class="card mb-2">
      <div class="card-header border-bottom d-flex justify-content-between">
        <p class="fw-bold mb-0">{{ actionItem.content }}</p>
        <div class="tools">
          <button
            (click)="editActionItem(actionItem.id)"
            title="Edit action item"
            class="btn btn-link me-2 p-0 text-secondary">
            <i class="bi bi-pencil-square"></i>
          </button>
          <ng-template #deleteContent>
            Are you sure you want to delete this action item?
            <p class="mt-2 mb-0">
              <a
                class="color-primary me-4"
                (click)="deleteActionItem(actionItem.id); popover.close()"
                href="javascript:void(0)"
                >Yes</a
              >
              <a
                class="color-tertiary"
                (click)="popover.close()"
                href="javascript:void(0)"
                >No</a
              >
            </p>
          </ng-template>
          <button
            class="btn btn-link p-0 text-secondary"
            title="Delete action item"
            [ngbPopover]="deleteContent"
            triggers="manual"
            (click)="popover.open()"
            #popover="ngbPopover"
            placement="auto">
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <app-safehtml [html]="actionItem.description"></app-safehtml>
      </div>
      <div
        class="card-footer bg-white d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <span class="fw-bold me-2">Assignee:</span>
          <app-avatar
            #avatar
            avatarSize="small"
            [userId]="actionItem.userId"></app-avatar>
          <span *ngIf="avatar.avatarData" class="username ms-2">
            {{ avatar.avatarData.firstName }}
            {{ avatar.avatarData.lastName }}
          </span>
        </div>
        <span class="fw-light text-muted">{{
          actionItem.dueDate | formatDate
        }}</span>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #noActionItems>
  <p class="text-muted fw-light m-3">
    No tasks have been created yet.<br /><br />Get started by clicking “Add
    Action Item” to stay organized and productive. All tasks will show up on the
    assignee's To Do list on their dashboard
  </p>
</ng-template>
<app-modal
  #actionPlanModal
  [modalConfig]="{
    titleText: 'Action Item',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    windowClass: 'custom-class',
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="actionPlanModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <form class="form-wrapper" [formGroup]="actionItemForm">
    <label for="title" class="form-label">Title</label>
    <div class="input-group">
      <input
        formControlName="title"
        type="text"
        class="form-control"
        id="title"
        aria-label="Title" />
    </div>
    <label for="description" class="form-label">Description</label>
    <app-wysiwyg-editor #editor [version]="'mini'" [config]="editorConfig" />
    <label for="assignee" class="form-label">Assignee</label>
    <app-user-select
      #userSelect
      (selectedUserList)="selectUserChange($event)"
      [isMulti]="true"></app-user-select>
    <label for="dueDate" class="form-label">Due Date</label>
    <app-datepicker
      #datepicker
      (dateSelectedUnixTimestamp)="
        selectedDate.next($event.time / 1000)
      "></app-datepicker>
  </form>
  <div footerContent>
    <button
      (click)="saveActionItem(); actionPlanModal.close()"
      type="button"
      class="btn btn-md btn-primary"
      [disabled]="
        actionItemForm.invalid ||
        checkIfEditorEmpty() ||
        (userSelect.selectedUserList &&
          userSelect.selectedUserList.length === 0)
      ">
      {{ editor.config.editId === "" ? "Create" : "Save" }}
    </button>
  </div>
</app-modal>
