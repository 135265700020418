import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ResourcePreviewDTO } from './resource-preview.dto';
import { getResourceIcon } from '../../../enums/resource-type.enum';

@Component({
  selector: 'app-resource-preview',
  templateUrl: './resource-preview.component.html',
  styleUrls: ['./resource-preview.component.scss'],
})
export class ResourcePreviewComponent implements OnInit {
  @Input() resource: ResourcePreviewDTO;

  @Input() limitDescriptionLength = true;

  @Input() showRemovalIcon = false;

  @Output() signalRemoval: EventEmitter<number> = new EventEmitter();

  description: string;

  iconString: string;

  ngOnInit() {
    this.iconString = getResourceIcon(this.resource.type);
    this.description = this.limitDescriptionLength
      ? `${this.resource.description.substring(0, 70)} ...`
      : this.resource.description;
  }

  removeItem() {
    this.signalRemoval.emit(this.resource.id);
  }
}
