import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { AuthService } from '../../../../../common/services/auth/auth.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-impersonate-modal',
  templateUrl: './impersonate.modal.component.html',
})
export class ImpersonateModalComponent implements AfterViewInit {
  userToImpersonate: UserDTO;

  @ViewChild('modal') modal: ModalComponent;

  constructor(private auth: AuthService) {}

  chooseUser(user: UserDTO | null) {
    if (user) {
      this.userToImpersonate = user;
    }
  }

  ngAfterViewInit() {
    this.modal.isClosing.subscribe(() => {
      if (this.userToImpersonate) {
        this.auth.impersonateUser(this.userToImpersonate.username);
      }
    });
  }
}
