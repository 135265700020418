import { Component, Input, OnInit } from '@angular/core';
import { CompetencyDTO } from 'src/app/common/dtos/competency.dto';
import { AssessmentDTO } from 'src/app/private/shared/dtos/attendee-rubric.dto';
import {
  FormCompetencyDTO,
  FormIndicatorDTO,
  FormIndicatorGroupDTO,
} from 'src/app/private/shared/dtos/forms.dto';
import { FormsService } from 'src/app/private/shared/services/forms/forms.service';

import { AbstractViewFieldComponent } from '../base/abstract-view-field';

@Component({
  selector: 'app-form-view-competency',
  templateUrl: './view-competency.component.html',
  styleUrls: ['../view-field/form-view-field.component.scss'],
})
export class FormViewCompetencyComponent
  extends AbstractViewFieldComponent
  implements OnInit
{
  assessments: AssessmentDTO[] = [];

  userInput = '';

  formCompetency: FormCompetencyDTO;

  selectedCompetency: CompetencyDTO;

  indicatorsChecked: number[] = [];

  indicatorGroups: FormIndicatorGroupDTO[] = [];

  answer: FormIndicatorDTO[] = [];

  @Input() inputDisabled = false;

  @Input() initialValue: FormIndicatorDTO[] | null;

  constructor(private formsService: FormsService) {
    super();
  }

  ngOnInit() {
    if (this.fieldDefinition.competency) {
      this.fetchIndicators();
    }
    if (this.formsService.isLiveFormSubmission) {
      this.formsService.assessmentsList.subscribe((assessments) => {
        this.assessments = assessments;
      });
    }
  }

  fetchIndicators() {
    this.formsService.getCompetencies();
    this.formsService.competencyList.subscribe((competencies) => {
      this.selectedCompetency = competencies.find(
        (competency) => competency.id === this.fieldDefinition?.competency?.id
      ) as CompetencyDTO;
    });
    const indicatorSub = this.formsService
      .fetchIndicators(this.fieldDefinition.competency?.id as number)
      .subscribe(() => {
        indicatorSub.unsubscribe();
      });
    this.formsService.indicatorsList.subscribe((indicatorsList) => {
      indicatorsList.forEach((competencyDTO) => {
        if (
          competencyDTO.competencyId === this.fieldDefinition.competency?.id
        ) {
          this.formCompetency = competencyDTO;
          this.indicatorGroups = competencyDTO.indicatorSet.groups;
          if (this.initialValue) {
            this.answer = this.initialValue;
            this.setIndicatorsChecked();
          }
        }
      });
    });
  }

  setIndicatorsChecked() {
    this.indicatorsChecked = this.answer.map((item) => item.id);
  }

  addAssessment(indicator: FormIndicatorDTO) {
    if (
      this.formsService.assessedUser &&
      this.formsService.isLiveFormSubmission
    ) {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      this.formsService.scrollPosition = (window as any).scrollY;
      this.formsService.addAssessment({
        indicatorId: indicator.id,
        rubricId: this.selectedCompetency.rubric_id,
        assesseeUserId: this.formsService.assessedUser.id as number,
        coachlogId: this.formsService.coachlogId,
      });
    }
  }

  removeAssessment(indicator: FormIndicatorDTO) {
    const assessment = this.assessments.find(
      (item) => item.egroweIndicatorId === indicator.id
    );
    if (
      assessment &&
      this.formsService.assessedUser &&
      this.formsService.isLiveFormSubmission
    ) {
      this.formsService.removeAssesment(assessment);
    }
  }

  selectIndicator(indicator: FormIndicatorDTO) {
    if (this.inputDisabled) return;
    if (this.answer.findIndex((item) => item.id === indicator.id) === -1) {
      this.answer.push(indicator);
      this.addAssessment(indicator);
    } else {
      this.answer = this.answer.filter((item) => item.id !== indicator.id);
      this.removeAssessment(indicator);
    }
    this.setIndicatorsChecked();
    this.value.emit(JSON.stringify(this.answer));
    if (this.formsService.isLiveFormSubmission) {
      this.formsService.promptSave.next(true);
    }
  }
}
