import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { ReportRequestParams } from 'src/app/common/dtos/reports.dto';
import { SchoolDTO, SchoolYear } from 'src/app/common/dtos/school.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { v4 as uuidv4 } from 'uuid';
import { ChartType } from '../../shared/enums/chart-type.enum';
import {
  CURRENT_SCHOOL_YEAR,
  ReportSessionType,
} from '../../shared/enums/reports.enum';
import { DistrictSearchService } from '../../shared/services/district-search/district-search.service';

@Component({
  selector: 'app-reporting-page',
  templateUrl: './reporting-page.component.html',
  styleUrls: ['./reporting-page.component.scss'],
})
export class ReportingPageComponent implements OnInit {
  user$ = select(UserState.getUser);

  user: User | null = null;

  isE2L = false;

  labelId = uuidv4();

  availableSchoolYears: string[] = [];

  schoolYearName: string;

  schoolYearItem: SchoolYear;

  schoolYearId = 0;

  selectedSchool: SchoolDTO | null;

  selectedDistrict: DistrictDTO | null;

  districtMissingYear = false;

  loadedDistrictInfo = false;

  chartTypes = ChartType;

  requestParams: ReportRequestParams = {
    dataset: 'fct_coaching_session',
    dimension: 'month_of_school_year',
    measure: {
      type: 'count_distinct',
      field: 'log_id',
    },
    filters: [
      {
        codename: 'district',
        title: 'District',
        value: ['234'],
        displayName: 'Demo ISD',
      },
      {
        codename: 'school_year',
        title: 'School Year',
        value: [CURRENT_SCHOOL_YEAR],
        displayName: 'School Year',
      },
      {
        codename: 'session_type',
        title: 'Session Type',
        value: [ReportSessionType.COACHING_CONVERSATION],
        displayName: 'Session Type',
      },
    ],
  };

  constructor(private districtSearchService: DistrictSearchService) {}

  ngOnInit(): void {
    this.user$
      .pipe(
        map((user) => {
          this.user = user;
          if (this.user) {
            this.isE2L = checkIfE2L(this.user);

            if (this.user.district?.id) {
              this.districtSearchService
                .getDistrict(this.user.district?.id)
                .subscribe((returnedDistrict) => {
                  if (returnedDistrict) {
                    this.setDistrict(returnedDistrict);
                  }
                });
            }
          }
        })
      )
      .subscribe();

    this.districtSearchService.currentSchoolYearIdObs.subscribe(
      (currentSchoolYearItem: SchoolYear) => {
        this.schoolYearItem = currentSchoolYearItem;
        this.schoolYearId = currentSchoolYearItem.id;
        if (this.districtMissingYear) {
          this.availableSchoolYears = [this.schoolYearItem.name];
          this.schoolYearName = this.schoolYearItem.name;
          this.loadedDistrictInfo = true;
        }
      }
    );

    this.districtSearchService.schoolYearObs.subscribe(
      (schoolYearItem: SchoolYear) => {
        this.schoolYearItem = schoolYearItem;
        this.schoolYearId = schoolYearItem.id;
        this.loadedDistrictInfo = true;
      }
    );
  }

  onYearSelected(event: SchoolYear | null) {
    if (event?.id) {
      this.schoolYearId = event.id;
    }
  }

  getDistrictSchoolYear() {
    if (this.selectedDistrict && this.selectedDistrict.reportingSchoolYear) {
      this.availableSchoolYears = [this.selectedDistrict.reportingSchoolYear];
      this.schoolYearName = this.selectedDistrict.reportingSchoolYear;

      this.districtSearchService.getSchoolYear(
        0,
        this.selectedDistrict.reportingSchoolYear
      );
    } else {
      this.districtMissingYear = true;
      this.districtSearchService.getCurrentSchoolYear();
    }
  }

  didSelectDistrict(selection: DistrictDTO | null) {
    if (selection) {
      this.districtSearchService
        .getDistrict(selection.id)
        .subscribe((returnedDistrict) => {
          if (returnedDistrict) {
            this.setDistrict(returnedDistrict);
          }
        });
    }
  }

  setDistrict(selection: DistrictDTO | null) {
    if (!selection && this.user?.district) {
      this.selectedDistrict = this.user.district;
    } else {
      this.selectedDistrict = selection;
    }
    this.getDistrictSchoolYear();
  }

  setSchool(selection: SchoolDTO | null) {
    this.selectedSchool = selection;
  }
}
