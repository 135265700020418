import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NewCoachingSessionUserDto } from '../../dtos/coaching-session.dto';

@Component({
  selector: 'app-new-coaching-attendee',
  templateUrl: './new-coaching-attendee.component.html',
  styleUrls: ['./new-coaching-attendee.component.scss'],
})
export class NewCoachingAttendeeComponent {
  @Input() user: NewCoachingSessionUserDto;

  @Output() removedAttendee: EventEmitter<boolean> = new EventEmitter();

  deleteAttendee() {
    this.removedAttendee.emit(true);
  }
}
