<ng-container>
  <h3 class="competencies-title">Select Competencies</h3>
  <div class="container">
    <div class="row body">
      <ng-container *ngIf="competencyList">
        <div
          *ngFor="let competency of competencyList"
          class="col-12 col-sm-6 col-md-4 col-xl-3 mt-4">
          <app-select-competency
            (selectedEvent)="selectCompetency($event)"
            [selected]="competency.selected"
            [competency]="competency"></app-select-competency>
        </div>
      </ng-container>
      <app-loader *ngIf="!competencyList"></app-loader>
    </div>
  </div>
</ng-container>
