<ng-container *ngIf="!inputDisabled">
  <app-form-view-checkbox
    #fieldView
    [initialValue]="deserializeStrings(inputData?.value)"
    (value)="data.next({ fieldId: fieldWithChoicesDefs.id, value: $event })"
    [fieldDefinition]="fieldWithChoicesDefs"
    *ngIf="
      fieldWithChoicesDefs && fieldWithChoicesDefs.type === fieldTypes.CHECKBOX
    "></app-form-view-checkbox>
  <app-form-view-select
    #fieldView
    [initialValue]="inputData?.value"
    (value)="data.next({ fieldId: fieldWithChoicesDefs.id, value: $event })"
    [fieldDefinition]="fieldWithChoicesDefs"
    *ngIf="
      fieldWithChoicesDefs && fieldWithChoicesDefs.type === fieldTypes.SELECT
    "></app-form-view-select>
  <app-form-view-radio
    #fieldView
    [initialValue]="inputData?.value"
    (value)="data.next({ fieldId: fieldWithChoicesDefs.id, value: $event })"
    [fieldDefinition]="fieldWithChoicesDefs"
    *ngIf="
      fieldWithChoicesDefs && fieldWithChoicesDefs.type === fieldTypes.RADIO
    "></app-form-view-radio>
  <app-form-view-text
    #fieldView
    [initialValue]="inputData?.value"
    (value)="data.next({ fieldId: fieldDefs.id, value: $event })"
    [fieldDefinition]="fieldDefs"
    *ngIf="
      fieldDefs && fieldDefs.type === fieldTypes.TEXT
    "></app-form-view-text>
  <app-form-view-wysiwyg
    #fieldView
    [initialValue]="inputData?.value"
    (value)="data.next({ fieldId: fieldDefs.id, value: $event })"
    [fieldDefinition]="fieldDefs"
    *ngIf="
      fieldDefs && fieldDefs.type === fieldTypes.TEXTBLOCK
    "></app-form-view-wysiwyg>
  <app-form-view-media
    #fieldView
    [initialValue]="inputData?.value"
    (value)="data.next({ fieldId: fieldDefs.id, value: $event })"
    [fieldDefinition]="fieldDefs"
    *ngIf="
      fieldDefs && fieldDefs.type === fieldTypes.MEDIA
    "></app-form-view-media>
</ng-container>
<ng-container *ngIf="inputDisabled">
  <app-form-view-no-input
    *ngIf="fieldDefs && fieldDefs.type !== fieldTypes.COMPETENCY"
    [value]="inputData?.value"
    [fieldDefinition]="fieldDefs"></app-form-view-no-input>
  <app-form-view-no-input
    *ngIf="fieldWithChoicesDefs"
    [value]="inputData?.value"
    [fieldWithChoicesDefinition]="
      fieldWithChoicesDefs
    "></app-form-view-no-input>
</ng-container>
<app-form-view-competency
  #fieldView
  [inputDisabled]="inputDisabled"
  [initialValue]="deserializeIndicators(inputData?.value)"
  (value)="data.next({ fieldId: fieldDefs.id, value: $event })"
  [fieldDefinition]="fieldDefs"
  *ngIf="
    fieldDefs && fieldDefs.type === fieldTypes.COMPETENCY
  "></app-form-view-competency>
