import { User } from 'src/app/common/state/user/user.model';
import {
  booleanFromBooleanNumber,
  BooleanNumber,
} from 'src/app/common/utilities/enums/boolean-number.enum';

import {
  Form,
  FormStatus,
  FormType,
  ObservationFormSubmission,
} from '../../dtos/forms.dto';
import { UserAPIResponse } from './user.responses';

export interface LearnerBehaviorListApiResponse {
  items: {
    abbreviation: string | null;
    content: string;
    created_at: number;
    egrowe_standard_group_id: number;
    icon: string;
    order: number;
    rubricLearnerBehaviorStrands: {
      created_at: number;
      egrowe_indicator_set_id: number;
      egrowe_standard_id: number;
      egrowe_rubric_id: number;
      id: number;
      indicatorSet: IndicatorSetAPIResponse[];
    }[];
    title: string;
    updatedAt: number;
  }[];
}

export interface IndicatorSetAPIResponse {
  content: string | null;
  created_at: number;
  district_id: number | null;
  id: number;
  indicatorGroups: [
    {
      content: string;
      created_at: number;
      district_id: number | null;
      egrowe_indicator_set_id: number;
      id: number;
      indicators: [
        {
          egrowe_indicator_id: number;
          id: number;
          indicator: {
            content: string;
            id: number;
          };
          level: number;
        }
      ];
      is_deleted: BooleanNumber;
      order: number;
      title: string;
      updated_at: number;
    }
  ];
  is_deleted: BooleanNumber;
  level1_name: string;
  level2_name: string;
  level3_name: string;
  level4_name: string;
  title: string;
  updated_at: number;
}

export interface LearnerBehaviorAPIResponse {
  item: {
    created_at: number;
    egrowe_indicator_set_id: number;
    egrowe_standard_id: number;
    egrowe_rubric_id: number;
    id: number;
    indicatorSet: IndicatorSetAPIResponse;
    is_deleted: BooleanNumber;
    sample_questions: string | null;
    updated_at: number;
  };
}

export interface DistrictShareResponse {
  created_at: number;
  district_id: number;
  id: number;
  is_shared: BooleanNumber;
  observation_form_id: number;
  updated_at: number;
}

export interface FormResponse {
  title: string;
  district_id: number;
  description: string;
  fields: string;
  status: string;
  type: string;
  districtsSharedWith?: DistrictShareResponse[];
  districtsSharedExclusion?: DistrictShareResponse[];
  deletedFields?: string;
  id: number;
  created_at: number;
  modified_at: number;
  user_id: number;
  is_deleted: BooleanNumber;
  is_template: BooleanNumber;
  user: User;
  hasSubmission: boolean;
}

export const translateDistrictShareResponse = (
  response: DistrictShareResponse
): number => response.district_id;

export const translateFormResponseToFormDTO = (
  response: FormResponse
): Form => ({
  createdAt: response.created_at,
  districtsSharedExclusion: response.districtsSharedExclusion?.map((d) =>
    translateDistrictShareResponse(d)
  ),
  districtsSharedWith: response.districtsSharedWith?.map((d) =>
    translateDistrictShareResponse(d)
  ),
  title: response.title,
  districtId: response.district_id,
  description: response.description,
  fields: JSON.parse(response.fields),
  status: response.status as FormStatus,
  type: response.type as FormType,
  deletedFields: response.deletedFields
    ? JSON.parse(response.deletedFields)
    : undefined,
  id: response.id,
  isTemplate: booleanFromBooleanNumber(response.is_template),
  modifiedAt: response.modified_at,
  author: response.user,
  hasSubmission: response.hasSubmission,
});

export interface ObservationFormSubmissionResponse {
  created_at: number;
  updated_at: number;
  egrowe_coachlog_id: string;
  id: number;
  is_deleted: BooleanNumber;
  observation_form_id: string;
  observed_user_id: string;
  submission_data: string;
  submitter_user: UserAPIResponse;
  submitter_district_id: string;
  submitter_user_id: string;
}

export const translateObservationFormSubmissionDTOFromResponse = (
  response: ObservationFormSubmissionResponse
): ObservationFormSubmission => ({
  id: response.id,
  formId: parseInt(response.observation_form_id),
  submitterUserId: parseInt(response.submitter_user_id),
  submitterDistrictId: parseInt(response.submitter_district_id),
  submissionData: JSON.parse(response.submission_data),
  createdAt: response.created_at,
  modifiedAt: response.updated_at,
  coachingLogId: parseInt(response.egrowe_coachlog_id),
  observedUserId: parseInt(response.observed_user_id),
});
