import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';

@Component({
  selector: 'app-resources-header',
  templateUrl: './resources-header.component.html',
  styleUrls: ['./resources-header.component.scss'],
})
export class ResourcesHeaderComponent implements OnInit {
  @Input() createResourceBtn = false;

  user$ = select(UserState.getUser);

  user: User | null;

  canCreate = false;

  ngOnInit() {
    this.user$
      .pipe(
        map((user) => {
          this.user = user;
          if (this.user) {
            this.user.roles.forEach((role) => {
              if (role === 'ELEARN_RESOURCE_CREATOR') {
                this.canCreate = true;
              }
            });
          }
        })
      )
      .subscribe();
  }
}
