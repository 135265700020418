<div class="div my-2">
  <div
    class="d-flex flex-nowrap align-items-center"
    [class.absent]="attendee.present != 1 && !isCoach">
    <ng-container *ngIf="attendee.user.profile">
      <app-avatar [profile]="attendee.user.profile" [avatarSize]="'medium'" />
      <div class="username ms-2">
        {{ attendee.user.profile.first_name }}
        {{ attendee.user.profile.last_name }}
        <ng-container *ngIf="!isCoach">
          <span class="ms-2">
            <ng-container *ngIf="attendee.present != 1; else present">
              (Absent)
            </ng-container>
            <ng-template #present> (Present) </ng-template>
          </span>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="isCoach">
      <a
        container="body"
        [autoClose]="'outside'"
        [ngbPopover]="popoverContent"
        [popoverTitle]="'Remove Coachee'"
        #p="ngbPopover">
        <button
          class="btn-delete gl-tertiary"
          role="button"
          title="Remove Coachee">
          <i class="bi bi-x-circle-fill"></i>
        </button>
      </a>
      <ng-template #popoverContent>
        <div>Are you sure you wish to remove this coachee?</div>
        <div class="app-hr-thin"></div>
        <div class="text-center mt-2">
          <button
            type="button"
            class="btn btn-secondary btn-sm px-3 m-1"
            (click)="p.close()">
            No
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm px-3 m-1"
            (click)="deleteAttendee(); p.close()">
            Yes
          </button>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <div class="attendance-block">
    <ng-container *ngIf="isCoach">
      <div class="form-check form-switch d-flex align-items-center mb-1">
        <input
          id="attendance-toggle"
          class="form-check-input"
          type="checkbox"
          role="switch"
          [(ngModel)]="attendee.present"
          (ngModelChange)="savePresence()" />
        <label class="form-check-label ms-2" for="attendance-toggle"
          >Present?</label
        >
      </div>
    </ng-container>
    <ng-container *ngIf="isCoach && absentReasonsList">
      <ng-container *ngIf="!attendee.present">
        <label class="visually-hidden" [attr.for]="'absent-reason'"
          >Absent Reason</label
        >
        <ng-select
          [items]="absentReasonsList"
          bindLabel="title"
          bindValue="id"
          labelForId="absent-reason"
          [(ngModel)]="selectedAbsentReason"
          (ngModelChange)="saveAbsentReason()"
          [trackByFn]="trackByFn"
          placeholder="Reason for Absence">
        </ng-select>
      </ng-container>
    </ng-container>
  </div>
</div>
