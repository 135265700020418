import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GLEnvironment } from '../../types/env';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  static isProduction(): boolean {
    return environment.production;
  }

  static env(): GLEnvironment {
    return environment.environment;
  }

  static showStyleLibrary(): boolean {
    return (
      environment.environment !== GLEnvironment.PRODUCTION &&
      environment.environment !== GLEnvironment.DEMO &&
      environment.environment !== GLEnvironment.STAGING
    );
  }

  static apiUrl(): string {
    return environment.apiUrl;
  }

  static clarityID(): string {
    return environment.microsoftClarityID || '';
  }

  static googleAnalyticsID(): string {
    return environment.googleAnalyticsID;
  }

  static froalaKey: string = environment.froalaKey;

  static codoxApiKey(): string {
    return environment.codoxApiKey;
  }

  static pusherInfo(key: string): string {
    switch (key) {
      case 'key':
        return environment.pusher.key;
      case 'cluster':
        return environment.pusher.cluster;
      default:
        return '';
    }
  }

  static growelabGoogleClientId(): string {
    return environment.growelabGoogleClientId;
  }

  static cleverClientId(): string {
    return environment.cleverClientId;
  }

  static cleverRedirectURI(): string {
    return environment.cleverRedirectURI;
  }
}
