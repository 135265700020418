import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-competency-level-checkbox',
  templateUrl: './competency-level-checkbox.component.html',
  styleUrls: ['./competency-level-checkbox.component.scss'],
})
export class CompetencyLevelCheckboxComponent {
  @Input() level: number;

  @Input() isChecked: boolean;

  @Output() sendIsChecked: EventEmitter<boolean> = new EventEmitter();

  clickedCheckbox(checkValue: number) {
    this.sendIsChecked.emit(!!checkValue);
  }
}
