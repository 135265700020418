<div class="card-body">
  <i
    *ngIf="isSelectable === true"
    class="float-end"
    [ngClass]="{
      'bi bi-toggle-off': !isSelected,
      'bi bi-toggle-on': isSelected
    }"
    (click)="toggleSelectIcon()"
    style="
      line-height: 0;
      margin-top: -8px;
      font-size: 2em;
      color: var(--gl-tertiary);
    "></i>
  <div class="w-100" *ngIf="!isLoading; else placeHolder">
    <div
      *ngIf="competency; else competencyNotFound"
      class="d-flex flex-row align-items-center">
      <img
        src="{{ iconUrl }}"
        class="img-fluid"
        alt="Assessment icon"
        style="height: 60px" />

      <div class="fw-bold fs-5">{{ competencyTitle }}</div>
    </div>
    <ng-template #competencyNotFound>
      <div class="d-flex flex-column align-items-center">
        <h3>Competency</h3>
        <h3>Not Found</h3>
      </div>
    </ng-template>
    <div class="competency-list">
      <app-quartile-progress-strand-overview
        [progress]="
          user?.level_completed ?? 0
        "></app-quartile-progress-strand-overview>
      <div class="strand-container" *ngIf="user; else userNotFound">
        <div *ngFor="let strand of user?.strands">
          {{ strand.title }}
          <div class="col-9">
            <app-quartile-progress-bar
              [progress]="strand.level_completed"
              [highlightedBlockIndex]="
                strand.wills
              "></app-quartile-progress-bar>
          </div>
        </div>
      </div>
      <ng-template #userNotFound>
        <div class="d-flex justify-content-center my-3">
          <p style="font-size: 2.2rem; font-weight: 500">User Not Found</p>
        </div>
      </ng-template>
    </div>
  </div>
  <ng-template #placeHolder>
    <div class="placeholder-glow">
      <div class="d-flex flex-row align-items-center py-3">
        <div class="placeholder mx-3" style="width: 60px; height: 60px"></div>
        <div class="placeholder placeholder-lg w-75"></div>
      </div>
      <div class="placeholder placeholder-lg w-75 my-2"></div>
      <div class="placeholder placeholder-lg w-50 my-2"></div>
      <div class="placeholder placeholder-lg w-75 my-2"></div>
      <div class="placeholder placeholder-lg w-50 my-2"></div>
      <div class="placeholder placeholder-lg w-75 my-2"></div>
    </div>
  </ng-template>
</div>
