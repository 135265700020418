<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-8 text-center">
      <a href="/">
        <img
          id="groweLabLogo"
          src="assets/logo_black.png"
          class="my-4"
          width="240"
          alt="GroweLab Logo" />
      </a>
    </div>
  </div>
  <div class="row justify-content-center bg-light rounded pb-5">
    <div class="col-lg-8">
      <h1 class="my-5">GroweLab Privacy Policy</h1>

      <p><em>Effective date: July 1, 2023</em></p>

      <p>
        Engage! Learning, Inc. dba engage2learn (also known as "e2L," "us,"
        "we," "our," or "the company") values its users' privacy. This Privacy
        Policy ("Policy") will provide you with information about how we collect
        and use personal information from those who make use of GroweLab, and
        what we will and will not do with the information we collect.
      </p>

      <p>
        Our Policy has been designed and created to ensure those who are
        affiliated with e2L of our commitment to meet and exceed privacy
        standards. This Policy governs any and all data collection and usage by
        e2L. Through the use of e2L's GroweLab, you are thereby consenting to
        the data collection procedures expressed in this Policy. e2L will not
        implement any hidden features, access or collect any information, or
        request any permissions outside of the ones listed in this Policy
        without express consent. By using GroweLab, you acknowledge your
        agreement to both the Terms and Conditions and the Privacy Policy.
      </p>

      <p>Specifically, this Policy informs you of the following:</p>

      <ul>
        <li>
          What personally identifiable information is collected from you through
          GroweLab;
        </li>
        <li>
          Why we collect personally identifiable data and the legal basis for
          such collection;
        </li>
        <li>How we use the collected information;</li>
        <li>
          The security procedures in place to protect your personal information.
        </li>
      </ul>

      <h2>Information We Collect</h2>
      <p>GroweLab collects information, such as:</p>

      <p>
        Voluntarily provided information, which may include your name, email
        address, and other personally identifiable information. e2L collects
        voluntary information through surveys, registration forms, research
        questionnaires, and user-uploaded documents.
      </p>
      <p>
        Information automatically collected when visiting the GroweLab platform,
        which includes cookies and third-party tracking technology. A "cookie"
        is a digital identifier assigned to your device when visiting the
        platform. These cookies are used solely for informational purposes to
        provide a better online experience, more relevant materials, and to
        understand how we can improve the GroweLab platform.
      </p>

      <h2>Why We Collect Information</h2>
      <p>
        We collect information to better understand your needs and provide the
        best version of GroweLab.
      </p>

      <h2>Use of Information Collected</h2>
      <p>
        e2L does not now, nor will we in the future, sell, rent or lease any of
        its customer lists and/or any captured data from GroweLab, including
        name and email address to any third party. e2L may collect and use
        personal information, such as name and email address, to assist in
        GroweLab's operation and ensure delivery of the services you need and
        request. At times, we may find it necessary to use personally
        identifiable information as a means to keep you informed of other
        possible products and/or services that may be available to you from e2L.
      </p>

      <p>
        The data we collect from you will be stored for no longer than
        necessary. The length of time we retain collected information will be
        determined based on the following criteria:
      </p>

      <ul>
        <li>the length of time your personal information remains relevant;</li>
        <li>
          the length of time it is reasonable to keep records to demonstrate
          that we have fulfilled our duties and obligations;
        </li>
        <li>the existence of your consent;</li>
        <li>as required by the agreement between e2L and your employer;</li>
        <li>
          and our legitimate interest in keeping such information as stated in
          this Policy.
        </li>
      </ul>

      <p>
        e2L shall not use or disclose the information provided by you except
        under the following circumstances:
      </p>

      <ul>
        <li>
          In the ways described in this Policy or to which you have otherwise
          consented;
        </li>
        <li>
          As necessary to provide services or products you have requested;
        </li>
        <li>As necessary to enforce the Terms of Service.</li>
      </ul>

      <p>
        e2L may also be in contact with you regarding completing surveys and/or
        research questionnaires related to your opinion of previous, current, or
        potential future services and products that may be offered.
      </p>

      <h2>Security</h2>
      <p>
        e2L takes reasonable and appropriate steps to protect your information
        from unauthorized or unlawful access, use, destruction, loss,
        alteration, or disclosure. We maintain strict administrative, technical,
        and physical procedures to protect the information stored on our
        servers. GroweLab uses industry-standard encryption technology to
        safeguard data both in transit as well as at rest and all systems are
        located in the United States. Other security safeguards include but are
        not limited to data encryption, firewalls, and physical access controls
        to buildings and files.
      </p>

      <p>
        Access to information is limited (through user/password credentials and
        two-factor authentication) to those employees who require it to perform
        their job functions. We use industry-standard Secure Socket Layer (SSL)
        encryption technology to safeguard the account registration process and
        sign-up information.
      </p>

      <p>
        The safety and security of your information also depend on you. Where
        e2L has given you (or where you have chosen) a password for access to
        GroweLab, you are responsible for keeping this password confidential.
        e2L asks you not to share your password with anyone. Unfortunately, the
        transmission of information via the Internet is not completely secure.
        Although e2L does its best to protect your personal information, we
        cannot guarantee the security of your personal information transmitted
        via the Internet. Any transmission of personal information is at your
        own risk. e2L is not responsible for the circumvention of any privacy
        settings or security measures contained in GroweLab.
      </p>

      <h2>Third-Party Applications</h2>

      <p>
        GroweLab uses third-party applications or tools which help us to
        understand and improve its performance. None of the data collected
        contains personally identifiable information. A non-exclusive list of
        third-party tools or applications currently used by GroweLab is listed
        below:
      </p>

      <ul>
        <li>Google Analytics</li>
        <li>Google Calendar</li>
        <li>Pendo</li>
        <li>Sentry</li>
      </ul>

      <p>
        Privacy Policies and detailed information on how data is used are
        available on the respective product websites.
      </p>

      <h2>Non-Marketing Purposes</h2>
      <p>
        e2L respects your privacy. We maintain and reserve the right to contact
        you if needed for non-marketing purposes, such as bug alerts, security
        breaches, account issues, and/or changes in e2L's products or services.
        In certain circumstances, we may use our website or GroweLab to post a
        notice.
      </p>

      <p><strong>Children Under the Age of 13</strong></p>

      <p>
        e2L does not knowingly collect any information from children under the
        age of 13 (or the applicable age of consent in local jurisdictions)
        unless and until the school has obtained appropriate parental consent
        for the student to use GroweLab. Because e2L collects and uses student
        data at the direction of and under the control of a school, e2L relies
        on each school to provide appropriate notice to parents of the school’s
        use of third-party service providers such as e2L, and for the schools to
        provide consent, if necessary, and authorization for e2L to collect
        student data, as permitted by the Children’s Online Privacy Protection
        Act (COPPA) and other applicable data protection laws. Please contact us
        if you believe we have inadvertently collected the personal information
        of a child without proper consent so that we may delete such data as
        soon as possible.
      </p>

      <h2>Unsubscribe or Opt-Out</h2>
      <p>
        To opt out of the use of cookies, set your browser preferences to refuse
        to allow cookies before accessing GroweLab. If you choose to block
        cookies, some platform features may not function properly.
      </p>

      <h2>Links to Other Websites</h2>
      <p>
        Our website may contain links to affiliates and other websites. e2L does
        not claim nor accept responsibility for any privacy policies, practices,
        and/or procedures of other such websites. Therefore, we encourage all
        users and visitors to be aware when they leave our website to read each
        website's privacy statements as to how they collect personally
        identifiable information. This Privacy Policy applies only and solely to
        the information collected by the GroweLab platform.
      </p>

      <h2>Acceptance of Terms</h2>
      <p>
        By use of the e2L GroweLab, you are hereby accepting the Terms and
        Conditions stipulated within this Privacy Policy Agreement. If you are
        not in agreement with our Terms and Conditions and Privacy Policy, you
        should refrain from further using GroweLab. We reserve the right to make
        changes to this Policy at any given time. If you want to make sure that
        you are up to date with the latest Policy changes, we advise that you
        frequently visit this page. In addition, the Privacy Policy is
        accessible from the login page and from the footer of the GroweLab
        platform.
      </p>

      <h2>How to Contact Us</h2>
      <p>
        If you have any questions or concerns regarding the Privacy Policy
        Agreement related to GroweLab, please feel free to contact us at the
        following email, telephone number, or mailing address:
      </p>

      <p>Email: info@engage2learn.org Telephone number: 361.704.9654</p>

      <p>
        Mailing Address: Engage! Learning Inc. PO Box 695, Portland, TX 78374
      </p>
    </div>
  </div>
  <app-footer />
</div>
