import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  KpiReportFilter,
  ReportApiResponse,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { CURRENT_SCHOOL_YEAR } from '../../../enums/reports.enum';

@Component({
  selector: 'app-kpi-controller',
  styleUrls: ['./kpi-controller.component.scss'],
  templateUrl: './kpi-controller.component.html',
})
export class KpiControllerComponent implements OnChanges {
  @ViewChild('kpiValueElement') kpiValueElement: ElementRef<HTMLDivElement>;

  @Input() kpiTitle: string;

  @Input() districtId: number;

  @Input() schoolYearId: number;

  @Input() primaryRequestParams: ReportRequestParams;

  @Input() secondaryRequestParams: ReportRequestParams;

  numerator?: number;

  denominator?: number;

  user$ = select(UserState.getUser);

  user: User | null = null;

  kpiValue: string;

  constructor(private apiService: APICoreService, private store: Store) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnChanges() {
    delete this.numerator;
    delete this.denominator;
    if (this.kpiValueElement) {
      this.kpiValueElement.nativeElement.classList.add('fadeOut');
    }
    this.getData(this.primaryRequestParams).subscribe(
      (value: ReportApiResponse) => {
        this.numerator = value.datasets[0].data[0] || 0;
        this.calculateFinalValue();
      }
    );
    this.getData(this.secondaryRequestParams).subscribe(
      (value: ReportApiResponse) => {
        this.denominator = value.datasets[0].data[0] || 0;
        this.calculateFinalValue();
      }
    );
  }

  calculateFinalValue() {
    if (
      (this.denominator === 0 || this.denominator) &&
      (this.numerator === 0 || this.numerator)
    ) {
      if (this.denominator === 0) {
        this.kpiValue = '-';
      } else {
        this.kpiValue = (
          Math.round((this.numerator / this.denominator) * 100) / 100
        ).toFixed(1);
      }
      this.kpiValueElement.nativeElement.classList.remove('fadeOut');
    }
  }

  checkDefaultFilters(params: ReportRequestParams) {
    const districtFilter = params.filters.find(
      (filter: KpiReportFilter) => filter.codename === 'district'
    );
    if (!districtFilter) {
      params.filters.push({
        codename: 'district',
        title: 'District',
        value: [this.districtId?.toString()],
        displayName: 'District',
      });
    }

    const schoolYearFilter = params.filters.find(
      (filter: KpiReportFilter) => filter.codename === 'school_year'
    );
    if (!schoolYearFilter) {
      params.filters.push({
        codename: 'school_year',
        title: 'School Year',
        value: [this.schoolYearId.toString()],
        displayName: 'School Year',
      });
    }

    return params;
  }

  getData(paramEntered: ReportRequestParams): Observable<ReportApiResponse> {
    if (this.user && this.user.district) {
      if (!this.districtId) {
        this.districtId = this.user.district.id;
      }
    }
    if (!this.schoolYearId) {
      this.schoolYearId = parseInt(CURRENT_SCHOOL_YEAR.toString(), 10);
    }

    paramEntered = this.checkDefaultFilters(paramEntered);

    return this.apiService.getRequest('reporting/custom', paramEntered);
  }
}
