<div class="container">
  <div class="row justify-content-center">
    <div class="col-10">
      <div class="d-flex justify-content-between mb-3">
        <h1 class="fs-3 fw-bold">Classroom Visit Log</h1>
      </div>
      <div class="card border-0">
        <div class="card-body p-5 min-vh-75">
          <ng-container *ngIf="!isLoading; else loadingTemplate">
            <ng-container *ngIf="hasPresentAttendee; else noAttendeeTemplate">
              <h2 class="fs-1 fw-bold mb-5 text-center">
                {{ sessionData.title }}
              </h2>
              <ng-container *ngIf="isCoach || isShadower">
                <div class="d-flex justify-content-between align-items-center">
                  <a
                    href="javascript:void(0)"
                    (click)="navigatePreviousScreen()"
                    class="text-decoration-none text-muted">
                    < Back to Session Info
                  </a>
                  <button
                    (click)="navigateNextScreen()"
                    [disabled]="!formLocked"
                    *ngIf="isCoach"
                    class="btn btn-primary btn-next"
                    style="pointer-events: auto">
                    Complete <i class="bi bi-arrow-right"></i>
                  </button>
                </div>
                <hr class="my-4" />
                <div class="row">
                  <div class="col-6">
                    <div>
                      <label class="mb-2" [id]="'form' + sessionData.id"
                        >Form:</label
                      >
                      <ng-select
                        [labelForId]="'form' + sessionData.id"
                        [items]="formsList"
                        bindLabel="title"
                        *ngIf="!formLocked && isCoach"
                        placeholder="Please select a form"
                        (change)="updateFormSelect($event)" />
                      <h2
                        class="fs-15 border p-2"
                        *ngIf="formLocked || !isCoach">
                        {{ selectedForm?.title }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-6 text-end">
                    <div
                      *ngIf="sessionData && sessionData.attendees.length > 1">
                      <label class="mb-2" [id]="'attendee' + sessionData.id"
                        >Attendee:</label
                      >
                      <ng-select
                        [labelForId]="'attendee' + sessionData.id"
                        [items]="sessionData.attendees"
                        [searchable]="false"
                        [clearable]="false"
                        bindLabel="fullName"
                        placeholder="Choose attendee"
                        (ngModelChange)="attendeeSelected($event)"
                        [(ngModel)]="selectedAttendee">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="selectedForm">
                  <app-form-view
                    #formView
                    [showTitle]="false"
                    [submissionStatus]="submissionStatus"
                    [submissionData]="currentSubmission"
                    (data)="updateSubmission($event)"
                    [inputDisabled]="!isCoach"
                    [form]="selectedForm"></app-form-view>
                </ng-container>
              </ng-container>
              <div *ngIf="!isCoach && !isShadower">
                <app-form-view
                  *ngIf="currentSubmission && selectedForm"
                  [showTitle]="false"
                  [submissionStatus]="submissionStatus"
                  [submissionData]="currentSubmission"
                  (data)="updateSubmission($event)"
                  [inputDisabled]="true"
                  [form]="selectedForm"></app-form-view>
              </div>
              <div
                *ngIf="
                  !isCoach &&
                  !isShadower &&
                  (!currentSubmission || !selectedForm)
                ">
                <p>No data submitted yet.</p>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noAttendeeTemplate>
            <p>
              <ngb-alert [dismissible]="false">
                <strong>No Present Attendees</strong> At least 1 attendee must
                be marked present.
                <a
                  routerLink="/coaching/log/{{ sessionId }}/info"
                  class="text-decoration-none text-muted"
                  ><br />
                  &lt; back
                </a>
              </ngb-alert>
            </p>
          </ng-template>
          <ng-template #loadingTemplate>
            <div class="w-100" style="height: 400px">
              <app-loader></app-loader>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="saveBar text-center" *ngIf="isCoach && selectedForm">
    <button
      [ngClass]="['btn', 'btn-primary']"
      [disabled]="formSaving"
      (click)="submitForm()">
      <span *ngIf="!formSaving">Save Progress</span>
      <span *ngIf="formSaving">Saving...</span>
    </button>
  </div>
</div>
