import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { User } from 'src/app/common/state/user/user.model';
import { StorageService } from '../services/storage/storage.service';
import { MONTH_IN_MILLISECONDS } from './time.definitions';

@Injectable()
export class ApiAuthInterceptor implements HttpInterceptor {
  user$ = select(UserState.getUser);

  user: User | null = null;

  constructor() {
    this.user$
      .pipe(
        map((user) => {
          this.user = user;
        })
      )
      .subscribe();
  }

  // eslint-disable-next-line class-methods-use-this
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // const idToken = this.user?.id_token;
    const idToken = StorageService.getItem(
      'authToken',
      MONTH_IN_MILLISECONDS
    )?.token;

    if (idToken) {
      const cloned = request.clone({
        headers: request.headers
          .set('Authorization', `Bearer ${idToken}`)
          .set('e2LClient', 'growelab'),
      });

      return next.handle(cloned);
    }

    return next.handle(request);
  }
}
