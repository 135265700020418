import { CoachingSessionUserType } from 'src/app/private/shared/enums/coaching-session-user-type.enum';
import { CoachingSessionDTO } from '../../private/shared/dtos/coaching-session.dto';

export const checkIfCoach = (sessionData: CoachingSessionDTO) => {
  if (
    sessionData.currentUsersRole === CoachingSessionUserType.COACH ||
    sessionData.currentUsersRole === CoachingSessionUserType.EDITOR
  ) {
    return true;
  }
  return false;
};

export const checkIfShadower = (sessionData: CoachingSessionDTO) => {
  if (sessionData.currentUsersRole === CoachingSessionUserType.SHADOWER) {
    return true;
  }
  return false;
};

export const checkPresentAttendee = (sessionData: CoachingSessionDTO) =>
  sessionData.attendees.some((attendee) => attendee.present);
