import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ResourcePreviewDTO } from '../resource-preview/resource-preview.dto';

@Component({
  selector: 'app-resource-preview-list',
  templateUrl: './resource-preview-list.component.html',
  styleUrls: ['./resource-preview-list.component.scss'],
})
export class ResourcePreviewListComponent {
  @Output() signalRemoval = new EventEmitter<number>();

  @Input() showRemovalIcon = false;

  @Input() resources: ResourcePreviewDTO[];
}
