<div class="field">
  <app-form-edit-field-label
    #label
    [(data)]="data.label"></app-form-edit-field-label>
  <div class="form-group my-3">
    <label class="mb-1" [for]="'field' + id">Leveled look-fors</label>
    <ng-select
      [labelForId]="'field' + id"
      *ngIf="competencyList.length > 0"
      [items]="competencyList"
      bindLabel="label"
      placeholder="Select a set of leveled look-fors"
      [clearable]="false"
      [(ngModel)]="data.competency"
      (ngModelChange)="generateIndicatorSets()" />
  </div>
</div>
