<div class="card border-0">
  <ng-container *ngIf="!isLoading; else placeHolder">
    <div class="card-header pt-3">
      <h2
        id="todo-title"
        class="card-title float-start"
        (click)="triggerCollapse()">
        To Do
        <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
      </h2>
      <div class="card-tools float-end">
        <button
          class="btn-create"
          aria-label="Add a new to-do"
          ngbTooltip="Add a new to-do"
          role="button"
          (click)="createModal.open()">
          <i class="bi bi-plus-circle fs-6 color-tertiary"></i>
        </button>
      </div>
    </div>
    <div collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div
        class="card-body pt-0 overflow-auto"
        tabindex="0"
        role="group"
        aria-labelledby="todo-title"
        style="max-height: 18rem">
        <form
          #createForm="ngForm"
          (ngSubmit)="createTodo(); createModal.close()">
          <app-modal
            #createModal
            [modalConfig]="{
              titleText: 'Create To-Do',
              headerClass: ['modal-header', 'bg-primary', 'text-white'],
              customFooter: true
            }">
            <button
              class="bg-transparent border-0"
              title="Close"
              aria-label="close"
              headerContent
              (click)="createModal.close()">
              <i class="bi bi-x-lg text-white"></i>
            </button>
            <div class="form-row">
              <label style="width: 100%">
                <textarea
                  aria-label="Todo Content"
                  name="createcontent"
                  class="form-control"
                  #createContent="ngModel"
                  rows="3"
                  [(ngModel)]="todoCreate.content"
                  [ngClass]="{
                    'is-invalid': createContent.invalid && createContent.touched
                  }"
                  required></textarea>
              </label>
            </div>
            <div class="row my-2">
              <div class="col">
                <div class="input-group datepicker-popup">
                  <input
                    name="createDate"
                    class="form-control"
                    #createcDate="ngModel"
                    placeholder="yyyy-mm-dd"
                    name="dp"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    [firstDayOfWeek]="0"
                    [(ngModel)]="this.selectedDate"
                    [ngClass]="{
                      'is-invalid': createcDate.invalid
                    }"
                    placement="bottom-end top-end"
                    required />
                  <button
                    aria-label="Select date"
                    class="btn btn-outline-secondary bi bi-calendar3"
                    (click)="d.toggle()"
                    type="button"></button>
                </div>
              </div>
            </div>
            <div footerContent>
              <button
                type="submit"
                class="btn btn-primary btn-md"
                [disabled]="!createForm.form.valid"
                (click)="
                  createTodo(); createContent.reset(); createModal.close()
                ">
                Save
              </button>
            </div>
          </app-modal>
        </form>

        <!-- BEGIN EDIT TODO -->
        <app-modal
          #editModal
          [modalConfig]="{
            titleText: 'Edit To-Do',
            headerClass: ['modal-header', 'bg-primary', 'text-white'],
            customFooter: true
          }">
          <button
            class="bg-transparent border-0"
            title="Close"
            aria-label="close"
            headerContent
            (click)="editModal.close()">
            <i class="bi bi-x-lg text-white"></i>
          </button>

          <form>
            <label style="width: 100%">
              <textarea
                aria-label="Edit Todo Content"
                #todocontent="ngModel"
                name="todocontent"
                id="todocontent"
                class="form-control"
                appAutosave
                [(ngModel)]="tempContent"
                (autosave)="updateTodoContent()"
                [ngClass]="{
                  'is-invalid':
                    todocontent.invalid &&
                    (todocontent.dirty || todocontent.touched)
                }"
                rows="3"
                required></textarea>
            </label>
            <div class="row my-2">
              <div class="col align-self-end">
                <div class="input-group datepicker-popup">
                  <input
                    #dp="ngModel"
                    name="dp"
                    id="dp"
                    class="form-control"
                    placeholder="yyyy-mm-dd"
                    [(ngModel)]="tempDate"
                    (change)="updateTodoDueDate()"
                    (dateSelect)="updateTodoDueDate()"
                    ngbDatepicker
                    #ed="ngbDatepicker"
                    [ngClass]="{
                      'is-invalid': dp.invalid && (dp.dirty || dp.touched)
                    }"
                    required="" />
                  <button
                    aria-label="Select date"
                    class="btn btn-outline-secondary bi bi-calendar3"
                    (click)="ed.toggle()"
                    type="button"></button>
                </div>
              </div>
            </div>
          </form>

          <div
            footerContent
            style="width: 100%"
            class="d-flex flex-row justify-content-between my-2">
            <button
              class="btn btn-danger btn-sm"
              (click)="deleteTodo(selectedTodo); editModal.close()">
              Delete To-Do
            </button>
            <button
              class="btn btn-primary btn-sm"
              (click)="closeEditor(); editModal.close()">
              Done
            </button>
          </div>
        </app-modal>

        <div class="today-group border-bottom">
          <h3>Today</h3>
          <ng-container
            *ngIf="dueTodayTodos && dueTodayTodos.length > 0; else noToday">
            <div *ngFor="let todo of dueTodayTodos">
              <app-todo-item
                [todo]="todo"
                (checkboxClicked)="completeTodo(todo)"
                (editClicked)="
                  selectTodo(todo); editModal.open()
                "></app-todo-item>
            </div>
          </ng-container>
          <ng-template #noToday>
            <div class="message-wrapper text-muted pb-2">
              No to-dos due today
            </div>
          </ng-template>
        </div>
        <div class="this-week-group border-bottom">
          <h3>This Week</h3>
          <ng-container
            *ngIf="
              dueThisWeekTodos && dueThisWeekTodos.length > 0;
              else noThisWeek
            ">
            <div *ngFor="let todo of dueThisWeekTodos">
              <app-todo-item
                [todo]="todo"
                (checkboxClicked)="completeTodo(todo)"
                (editClicked)="
                  selectTodo(todo); editModal.open()
                "></app-todo-item>
            </div>
          </ng-container>
          <ng-template #noThisWeek>
            <div class="message-wrapper text-muted pb-2">
              No to-dos due this upcoming week
            </div>
          </ng-template>
        </div>
        <div class="future-group border-bottom">
          <h3>Future</h3>
          <ng-container *ngIf="dueTodos && dueTodos.length > 0; else noFuture">
            <div *ngFor="let todo of dueTodos">
              <app-todo-item
                [todo]="todo"
                (checkboxClicked)="completeTodo(todo)"
                (editClicked)="
                  selectTodo(todo); editModal.open()
                "></app-todo-item>
            </div>
          </ng-container>
          <ng-template #noFuture>
            <div class="message-wrapper text-muted pb-2">
              No upcoming to-dos are scheduled
            </div>
          </ng-template>
        </div>
        <div class="completed-group">
          <h3>Completed</h3>
          <div *ngFor="let todo of completedTodos">
            <app-todo-item
              [todo]="todo"
              (checkboxClicked)="completeTodo(todo)"
              (editClicked)="
                selectTodo(todo); editModal.open()
              "></app-todo-item>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #placeHolder>
    <div class="card-header pt-3">
      <div class="card-title placeholder-glow">
        <span class="placeholder col-5"></span>
      </div>
    </div>
    <div class="card-body pt-0" style="height: 18rem">
      <div class="card-text placeholder-glow">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
      <div class="card-text placeholder-glow mt-2">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
    </div>
  </ng-template>
</div>
