import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { CompetencyDTO } from 'src/app/common/dtos/competency.dto';
import { ResourceFiltersDTO } from '../../../dtos/resources.dto';
import { TagDTO } from '../../../dtos/tags.dto';
import { ResourcesSearchService } from '../../../services/resources/resources-search.service';
import { TagSearchService } from '../../../services/tags/tags.service';
import { ModalComponent } from '../../modals/modal/modal.component';
import { CompetencySelectComponent } from '../../competency-select/competency-select.component';

interface AuthorId {
  name: string;
  id: number;
}

@Component({
  selector: 'app-resource-filter-modal',
  templateUrl: './resource-filter-modal.component.html',
  styleUrls: ['./resource-filter-modal.component.scss'],
})
export class ResourceFilterModalComponent implements AfterViewInit {
  @ViewChild('customModal') customModal: ModalComponent;

  @ViewChild('selectCompetency')
  selectCompetency: CompetencySelectComponent;

  @Input() filterType: string;

  @Output() currentAuthorsEvent = new EventEmitter<AuthorId[]>();

  @Output() currentCompentenciesEvent = new EventEmitter<CompetencyDTO[]>();

  currentTags: TagDTO[] = [];

  currentCompetencies: CompetencyDTO[] = [];

  currentAuthors: AuthorId[] = [];

  editedFilters: ResourceFiltersDTO;

  constructor(
    private resourceService: ResourcesSearchService,
    private tagService: TagSearchService
  ) {
    this.resourceService.editedSearchFilters.subscribe((editedFilters) => {
      this.editedFilters = JSON.parse(JSON.stringify(editedFilters));
    });
  }

  ngAfterViewInit() {
    this.customModal.isOpening.subscribe(() => {
      this.resourceService.resetEditedFilters();
    });
  }

  applyFilters() {
    const tags = this.currentTags.map((tag) => tag.tag);
    const competencies = this.currentCompetencies.map(
      (competency) => competency.id
    );

    this.editedFilters.tags = tags;
    this.editedFilters.competencies = competencies;

    this.resourceService.setEditedFilters(this.editedFilters);

    this.resourceService.applyEditedFilters();

    this.currentAuthorsEvent.emit(this.currentAuthors);
    this.currentCompentenciesEvent.emit(this.currentCompetencies);

    this.customModal.close();
  }

  resetFilters() {
    this.resourceService.resetFilters();
    this.currentAuthors = [];
    this.currentCompetencies = [];
    this.selectCompetency.internalSelectedCompetency = null;
    this.currentTags = [];
    this.currentAuthorsEvent.emit(this.currentAuthors);
    this.currentCompentenciesEvent.emit(this.currentCompetencies);
  }

  // Needs user dto
  // eslint-disable-next-line
  addUser(incomingUser: any) {
    if (incomingUser && this.editedFilters.authors) {
      if (
        !this.editedFilters.authors.some(
          (authorId) => incomingUser.id === authorId
        )
      ) {
        this.editedFilters.authors.push(incomingUser.id);
        this.currentAuthors.push({
          id: incomingUser.id,
          name: `${incomingUser.profile.first_name} ${incomingUser.profile.last_name}`,
        });
      }
    }
  }

  addTag(incomingTag: TagDTO) {
    if (incomingTag) {
      if (incomingTag !== undefined || incomingTag !== null) {
        if (!this.currentTags.some((item) => incomingTag.id === item.id)) {
          this.currentTags.push(incomingTag);
        }
      }
    }
  }

  addCompetency(incomingCompetency: CompetencyDTO | null) {
    if (incomingCompetency) {
      if (incomingCompetency !== undefined || incomingCompetency !== null) {
        if (
          !this.currentCompetencies.some(
            (item) => incomingCompetency.id === item.id
          )
        ) {
          this.currentCompetencies = [incomingCompetency];
        }
      }
    } else {
      this.currentCompetencies = [];
    }
  }

  removeUser(author: AuthorId) {
    this.currentAuthors = this.currentAuthors.filter(
      (currentAuthor: AuthorId) => currentAuthor !== author
    );
  }

  removeTag(tagName: string) {
    this.currentTags = this.currentTags.filter((tag) => tag.tag !== tagName);
  }

  removeCompetency() {
    this.currentCompetencies = [];
    this.selectCompetency.internalSelectedCompetency = null;
  }

  onCheckboxChange(event: Event): void {
    const target = event.target as HTMLInputElement | null;

    if (target) {
      if (target.checked === true) {
        this.editedFilters.levels?.push(Number(target.value));
      } else {
        const matchedLevel = this.editedFilters.levels?.find(
          (level) => level === Number(target.value)
        );
        this.editedFilters.levels = this.editedFilters.levels?.filter(
          (level) => level !== matchedLevel
        );
      }
    }
  }
}
