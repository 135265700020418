import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  Field,
  FieldData,
  FieldType,
  Form,
  FormSubmissionStatus,
  ObservationFormSubmission,
  ObservationFormSubmissionRawData,
} from 'src/app/private/shared/dtos/forms.dto';
import { FormsService } from 'src/app/private/shared/services/forms/forms.service';

@Component({
  selector: 'app-form-view',
  templateUrl: './view-form.component.html',
  styleUrls: ['./view-form.component.scss'],
})
export class ViewFormComponent implements OnInit {
  fieldTypes = FieldType;

  fieldData: FieldData[] = [];

  showForm = true;

  @Input() inputDisabled = false;

  @Input() form: Form;

  @Input() forSubmission = false;

  @Input() submissionStatus = FormSubmissionStatus.NEW;

  @Input() submissionData?: ObservationFormSubmission;

  @Input() showTitle = false;

  @Output() data = new EventEmitter<ObservationFormSubmissionRawData>();

  user: User;

  constructor(
    private formsService: FormsService,
    private store: Store,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit() {
    // Removing and re-adding the form to force a re-render
    this.showForm = false;
    if (
      this.submissionData &&
      this.submissionStatus === FormSubmissionStatus.SUBMITTED
    ) {
      this.fieldData = this.submissionData.submissionData;
    } else {
      this.fieldData = [];
    }
    // Need this to prevent updating in the same paint
    setTimeout(() => {
      this.showForm = true;
      // When converting from new form to submitted, component redraws and the scroll position is lost. This is a hacky way to restore it.
      if (this.formsService.repositionFormOnSave) {
        setTimeout(
          () => window.scrollTo(0, this.formsService.scrollPosition),
          0
        );
        this.formsService.repositionFormOnSave = false;
      }
    }, 0);
  }

  getInputData(field: Field) {
    if (
      // Check status so data already passed doesn't get thrown in
      this.submissionStatus === FormSubmissionStatus.SUBMITTED &&
      this.submissionData &&
      this.submissionData.submissionData &&
      this.submissionData.submissionData.length > 0
    ) {
      const data = this.submissionData.submissionData.find(
        (x) => x.fieldId === field.id
      );
      if (data) {
        return data;
      }
    }
    return null;
  }

  outputData() {
    this.data.emit({
      formId: this.form.id,
      submitterUser: this.user,
      submissionData: this.fieldData,
    });
  }

  updateData(data: FieldData) {
    if (this.fieldData.find((x) => x.fieldId === data.fieldId)) {
      this.fieldData = this.fieldData.map((x) =>
        x.fieldId === data.fieldId ? data : x
      );
    } else {
      this.fieldData.push(data);
    }
    this.outputData();
  }
}
