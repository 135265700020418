const icons: string[] = [
  'assessment',
  'collaboration',
  'communication',
  'criticalanalysis',
  'cultureenvironment',
  'differentiation',
  'digitallearning',
  'goalsetting',
  'problemsolving',
  'reflectiongrowthmindset',
  'relevanceauthenticity',
  'smallgroupinstruction',
  'standardsalignment',
];

export function getIcon(icon: string): string {
  let currentIcon = '';
  let iconUrl = '';
  if (icon) {
    for (let i = 0; i < icons.length; i += 1) {
      iconUrl = icon.toLowerCase();
      currentIcon = icons[i];
      if (iconUrl.includes(currentIcon)) {
        currentIcon = icons[i];
        break;
      }
    }
  } else {
    // temporary filler for competencies without an icon until a generic is made
    // eslint-disable-next-line prefer-destructuring
    currentIcon = icons[0];
  }
  return currentIcon;
}

export function getIconUrl(icon: string): string {
  const currentIcon = getIcon(icon);
  return `/assets/competency_icons/${currentIcon}.svg`;
}
