<app-modal
  #modal
  [modalConfig]="{
    titleText: 'Filter Results',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <div class="row datePickerRow noTop gy-3">
    <label class="form-label">Date Range</label>
    <app-daterange-picker
      [fromDate]="this.currentFilters.fromDate"
      [toDate]="this.currentFilters.toDate"
      (selectedFromDate)="this.currentFilters.fromDate = $event"
      (selectedToDate)="
        this.currentFilters.toDate = $event
      "></app-daterange-picker>
  </div>
  <div class="row gy-3">
    <div class="col-md-12 gx-5" ngbDropdown>
      <label class="form-label">Log Type</label>
      <div class="input-style-row">
        <app-log-type-select
          [clearAfterSelection]="false"
          [chosenLogType]="this.currentFilters.logType"
          (selectedChosenLogType)="
            this.currentFilters.logType = $event
          "></app-log-type-select>
      </div>
    </div>
  </div>
  <div class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">Competencies</label>
      <app-competency-select
        #competencySelect
        [clearAfterSelection]="false"
        [isMulti]="true"
        [defaultCompetencies]="this.currentFilters.competencyList"
        (selectedCompetencyList)="
          this.currentFilters.competencyList = $event
        " />
    </div>
  </div>
  <!-- <div class="row gy-3">
      <div class="col-md-12 gx-5">
        <label class="form-label">District</label>
        <app-district-select
          [clearAfterSelection]="false"
          [isMulti]="false"
          [defaultDistricts]="useDistrict ? [useDistrict] : null"
          (selectedDistrict)="updateDistrict($event)" />
      </div>
    </div> -->
  <div class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">Schools</label>
      <app-school-select
        #schoolSelect
        [clearAfterSelection]="false"
        [isMulti]="true"
        [defaultSchools]="this.currentFilters.schoolList"
        (selectedSchoolList)="updateSchools($event)" />
    </div>
  </div>
  <div class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">Coaches</label>
      <app-user-select
        #coachSelect
        [clearAfterSelection]="false"
        [isMulti]="true"
        [defaultUsers]="this.currentFilters.coachList"
        (selectedUserList)="this.currentFilters.coachList = $event" />
    </div>
  </div>
  <div class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">Coachees</label>
      <app-user-select
        #coacheeSelect
        [clearAfterSelection]="false"
        [isMulti]="true"
        [defaultUsers]="this.currentFilters.coacheeList"
        (selectedUserList)="this.currentFilters.coacheeList = $event" />
    </div>
  </div>
  <div footerContent>
    <button type="button" class="btn btn-md" (click)="clearFilters()">
      Clear All
    </button>
    <button
      type="button"
      class="btn btn-md btn-primary"
      (click)="closeModal(true)">
      Search
    </button>
  </div>
</app-modal>
