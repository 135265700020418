import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription, map } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { checkPresentAttendee } from 'src/app/common/utilities/session-helpers';

import {
  ModalComponent,
  ModalConfig,
} from '../../shared/components/modals/modal/modal.component';
import { ResourcePreviewDTO } from '../../shared/components/resources/resource-preview/resource-preview.dto';
import { SelectResourceComponent } from '../../shared/components/resources/select-resource/select-resource.component';
import {
  AttendeeRubricDTO,
  CompetencyListItemDTO,
} from '../../shared/dtos/attendee-rubric.dto';
import {
  CoachingSessionAttendeeDTO,
  CoachingSessionDTO,
} from '../../shared/dtos/coaching-session.dto';
import { checkSessionDataType } from '../../shared/helpers/coachee-log.utilities';
import { CoachingLogResourceService } from '../../shared/services/coaching-log/coaching-log-resource.service';
import { CoachingLogService } from '../../shared/services/coaching-log/coaching-log.service';
import {
  AddCompetencies,
  FetchAttendeeRubrics,
  FetchSessionData,
} from '../../shared/state/coaching-log/coaching-log.actions';
import {
  CoachingLogState,
  CompetencyStateObject,
} from '../../shared/state/coaching-log/coaching-log.state';

@Component({
  selector: 'app-coaching-log-page',
  templateUrl: './coaching-log-page.component.html',
  styleUrls: ['./coaching-log-page.component.scss'],
})
export class CoachingLogPageComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('resourceSearchDescription')
  resourceSearchDescription: ElementRef<HTMLDivElement>;

  @ViewChild('resourceSearchModal') resourceSearchModal: ModalComponent;

  @ViewChild('resourceSelect') resourceSelect: SelectResourceComponent;

  attendeeRubrics$ = select(CoachingLogState.getAttendeeRubricsData);

  resourceSearchModalConfig: ModalConfig = {};

  resources: ResourcePreviewDTO[] = [];

  rubricData: AttendeeRubricDTO[] = [];

  user$ = select(UserState.getUser);

  loggedInAsAttendee = false;

  user: User | null = null;

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO | null;

  sessionId: number;

  notesActive = false;

  notesTabActive = true;

  subs: Subscription[] = [];

  currentScreen = 'selectCompetencies';

  letsGroweActive: boolean;

  competencyData$ = select(CoachingLogState.getCompetencyData);

  competencyData: CompetencyStateObject;

  competencySelections: CompetencyListItemDTO[] = [];

  attendeeIds: number[] = [];

  userSelectAttendees: UserDTO[] | null = [];

  selectedAttendee: UserDTO;

  assignedRubrics: any[] = [];

  attendeeUserDTOs: UserDTO[] = [];

  hasPresentAttendee = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    public coachingService: CoachingLogService,
    public coachlogResourceService: CoachingLogResourceService
  ) {
    this.sessionData$
      .pipe(map((filterFn) => filterFn(this.sessionId)))
      .subscribe((data) => {
        if (data) {
          this.sessionData = data;
          checkSessionDataType(data, 'coaching', this.router);
          this.attendeeUserDTOs = this.sessionData.attendees
            .filter((attendee) => attendee.present)
            .map((attendee) => attendee.user);
          if (!this.selectedAttendee) {
            if (this.attendeeUserDTOs && this.attendeeUserDTOs.length > 0) {
              // eslint-disable-next-line prefer-destructuring
              this.selectedAttendee = this.attendeeUserDTOs[0];
            }
          }
          this.coachlogResourceService.config.modalTitle = data?.title;
          if (this.sessionData) {
            this.hasPresentAttendee = checkPresentAttendee(this.sessionData);
          } else {
            this.hasPresentAttendee = false;
          }
          if (this.sessionData.currentUsersRole === 'coachee') {
            this.loggedInAsAttendee = true;
          }
        }
      });
    this.user$.subscribe((user) => {
      this.user = user;
    });
    this.competencyData$.subscribe((data) => {
      this.competencyData = data;
    });
  }

  pullCompetencies() {
    this.assignedRubrics.forEach((rubric) => {
      rubric.standardSet.standardGroups.forEach((standardGroup: any) => {
        standardGroup.standards.forEach((standard: any) => {
          standard.rubricId = rubric.id;
          standard.selected = false;
          this.competencySelections.push(standard);
        });
      });
    });
  }

  /* eslint-disable class-methods-use-this */
  attendeeCompareFn(
    attendee1: CoachingSessionAttendeeDTO,
    attendee2: CoachingSessionAttendeeDTO
  ) {
    return attendee1.id === attendee2.id;
  }

  getCurrentCompetencyData() {
    let competenciesSetForAllUsers = false;
    let competencySelected = false;
    this.attendeeIds = [];
    const attendeeCompetencyDictionary: {
      [key: number]: number[];
    } = {};
    if (this.sessionData) {
      this.attendeeUserDTOs.forEach((attendee) => {
        attendeeCompetencyDictionary[attendee.id] = [];
      });
      this.rubricData.forEach((attendee) => {
        this.attendeeIds.push(attendee.userId);
        attendee.rubrics.forEach((rubric) => {
          rubric.competencyGroups.forEach((group) => {
            group.competencies.forEach((competency) => {
              this.competencySelections.forEach((selection) => {
                if (selection.id === competency.id) {
                  attendeeCompetencyDictionary[attendee.userId].push(
                    competency.id
                  );
                  selection.selected = true;
                  competencySelected = true;
                }
              });
            });
          });
        });
      });
    }

    const selectedCompetencies = this.competencySelections.filter(
      (competency) => competency.selected
    );

    // Add competencies for people added to the log after competencies were selected
    if (
      !competenciesSetForAllUsers &&
      this.currentScreen === 'selectCompetencies'
    ) {
      Object.keys(attendeeCompetencyDictionary).forEach((attendeeId) => {
        let missingCompetencies: CompetencyListItemDTO[] = [];
        if (attendeeCompetencyDictionary[parseInt(attendeeId)].length > 0) {
          const attendeeCompetencyIds =
            attendeeCompetencyDictionary[parseInt(attendeeId)];
          missingCompetencies = selectedCompetencies.filter(
            (competencyId) => !attendeeCompetencyIds.includes(competencyId.id)
          );
        } else {
          missingCompetencies = selectedCompetencies;
        }

        if (missingCompetencies.length > 0) {
          missingCompetencies.forEach((competency) => {
            this.coachingService
              .addCompetency(
                {
                  egrowe_rubric_id: competency.rubricId as number,
                  egrowe_standard_id: competency.id as number,
                  user_id: parseInt(attendeeId),
                },
                this.sessionId
              )
              .subscribe();
          });
        }
      });
      competenciesSetForAllUsers = true;
    }

    this.store.dispatch(
      new AddCompetencies([this.competencySelections, competencySelected])
    );
  }

  updateScreen(screen: string): void {
    this.currentScreen = screen;
    this.updateAttendeeRubrics();
  }

  toggleNotes(): void {
    this.notesActive = !this.notesActive;
    this.notesTabActive = !this.notesTabActive;
  }

  ngOnInit(): void {
    this.route.params.subscribe((url) => {
      this.sessionId = parseInt(url['logId']);
      this.store.dispatch(new FetchSessionData(this.sessionId));
      this.store.dispatch(new FetchAttendeeRubrics(this.sessionId));
      this.coachingService.getCompetencies(this.sessionId).subscribe((res) => {
        this.assignedRubrics = res.items;
        this.pullCompetencies();
      });
    });
    this.subs.push(
      this.attendeeRubrics$
        .pipe(map((filterFn) => filterFn(this.sessionId)))
        .subscribe((data) => {
          // This causes an infinite loop if you don't check if the data has changed
          if (data && data !== this.rubricData) {
            this.rubricData = data;
            setTimeout(() => {
              this.getCurrentCompetencyData();
            }, 500);
          }
        })
    );

    if (this.router.url.endsWith('growth')) {
      this.currentScreen = 'groweCoaching';
    }

    if (this.router.url.endsWith('summary')) {
      this.currentScreen = 'sessionSummary';
    }
  }

  ngAfterViewInit(): void {
    this.coachlogResourceService.addRefs(
      this.resourceSearchModal,
      this.resourceSearchDescription,
      this.resourceSelect
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.subs = [];
  }

  removePreview(resourceId: number) {
    this.coachlogResourceService.removeResource(resourceId);
  }

  updateAttendeeRubrics() {
    this.rubricData = [];
    this.store.dispatch(new FetchAttendeeRubrics(this.sessionId));
  }

  navigateNextScreen() {
    if (this.competencyData.competencySelected) {
      this.router.navigate([`/coaching/log/${this.sessionId}/growth`]);
    }
  }
}
