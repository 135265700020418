import { EnvironmentService } from '../services/environment/environment.service';
import { compareRoles, UserRole } from '../state/user/role/user-role';
import { compareTypes } from '../state/user/type/user-type';
import { User } from '../state/user/user.model';
import { PrivateRoute, PublicRoute } from '../types/routes';

export const canAccessByRole = (
  route: PublicRoute | PrivateRoute,
  user: User | null
): boolean => {
  const roles = route.data?.roles;
  if (!roles) {
    return true;
  }
  return !!(user && user.roles && compareRoles(roles, user.roles));
};

export const canAccessByUserType = (
  route: PrivateRoute,
  user: User | null
): boolean => {
  const types = route.data?.types;
  if (!types) {
    return true;
  }
  return !!(user && user.type && compareTypes(types, user.type));
};

export const checkRouteEnvironment = (path: string): boolean => {
  if (path === 'style-library' && !EnvironmentService.showStyleLibrary()) {
    return false;
  }
  return true;
};

export const currentDistrictOrE2lEmployee = (
  user: User | null,
  district: {
    code?: string;
    id?: number;
  }
): boolean => {
  if (user) {
    if (
      user.roles.includes(UserRole.E2L_EMPLOYEE) ||
      (district.code && district.code === user.district?.districtCode) ||
      (district.id && district.id === user.district?.id)
    ) {
      return true;
    }
  }
  return false;
};
