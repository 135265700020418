import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { DistrictSearchService } from '../../services/district-search/district-search.service';

@Component({
  selector: 'app-district-name',
  templateUrl: './district-name.component.html',
  styleUrls: ['./district-name.component.scss'],
})
export class DistrictNameComponent implements OnInit {
  @Input() districtId = 0;

  selectedDistrict: DistrictDTO;

  constructor(private districtSearchService: DistrictSearchService) {}

  ngOnInit(): void {
    if (this.districtId) {
      this.districtSearchService
        .getDistrict(this.districtId)
        .pipe(
          map((district) => {
            if (district) {
              this.selectedDistrict = district;
            }
          })
        )
        .subscribe();
    }
  }
}
