import {
  EChartsOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from 'echarts';
import merge from 'src/app/common/utilities/deep-merge';
import { ChartType } from '../../../enums/chart-type.enum';

export const chartOptionsHelper = (type: ChartType, options: EChartsOption) => {
  const barChartDefaults: EChartsOption = {
    title: {
      textStyle: {
        fontWeight: 'bolder',
        fontSize: 16,
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
    },
    legend: {},
    // toolbox: {
    //   // Various predefined bells 'n whistles that can be added to the chart. See https://echarts.apache.org/en/option.html#toolbox
    //   show: true,
    //   feature: {
    //     // saveAsImage: {},
    //     // dataView: { readOnly: true }, // Toggle between graph and table view
    //     magicType: { type: ['line', 'bar'] }, // Toggle between line and bar graph
    //   },
    // },
    grid: {
      containLabel: true,
    },
    yAxis: {
      nameLocation: 'middle',
      axisLine: {
        onZero: false,
      },
      type: 'value',
      axisLabel: { margin: 0 },
    },
    xAxis: {
      nameLocation: 'middle',
      type: 'category',
    },
  };

  switch (type) {
    case ChartType.HorizontalBar:
      /* eslint-disable-next-line */
      const optionsMeld = merge(barChartDefaults, options);
      /* eslint-disable-next-line */
      const returnOptions: EChartsOption = {
        ...optionsMeld,
        xAxis: optionsMeld['yAxis'] as XAXisComponentOption,
        yAxis: optionsMeld['xAxis'] as YAXisComponentOption,
      };
      return returnOptions;
    case ChartType.Bar:
    default:
      return merge(barChartDefaults, options);
  }
};

export interface ChartGrid {
  top?: string | number;
  left?: string | number;
  right?: string | number;
  bottom?: string | number;
  containLabel?: boolean;
  width?: string | number;
  height?: string | number;
}
