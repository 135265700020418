import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CoachlogSearchPayloadItemsDTO } from 'src/app/common/dtos/coachlog-search-payload.dto';
import { FilterList } from './applied-filters.dto';

@Component({
  selector: 'app-applied-filters',
  templateUrl: './applied-filters.component.html',
  styleUrls: ['./applied-filters.component.scss'],
})
export class AppliedFiltersComponent {
  // Add more Payload DTOs as needed, and display them separately in template
  @Input() coachlogSearchOptions: CoachlogSearchPayloadItemsDTO;

  @Output() filterRemoved: EventEmitter<string> = new EventEmitter();

  @Output() filterListRemoved: EventEmitter<FilterList> = new EventEmitter();

  removeFilter(keyName: string) {
    this.filterRemoved.emit(keyName);
  }

  removeFilterFromList(keyName: string, value: any) {
    this.filterListRemoved.emit({
      keyName,
      value,
    });
  }
}
