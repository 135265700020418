import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';

@Component({
  selector: 'app-settings-nav',
  templateUrl: './settings-nav.component.html',
})
export class SettingsNavComponent {
  user: User;

  env = EnvironmentService;

  @Input() districtCode: string;

  Roles = UserRole;

  constructor(private store: Store) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }
}
