<div class="col-12 goal-slider">
  <div #track class="track">
    <div #backfill class="backfill">
      <div #fill class="fill rounded-start"></div>
      <div class="stops">
        <div (click)="setPosition(1); setGoal(1)"></div>
        <div (click)="setPosition(2); setGoal(2)"></div>
        <div (click)="setPosition(3); setGoal(3)"></div>
        <div (click)="setPosition(4); setGoal(4)"></div>
      </div>
    </div>
    <div class="avatar" #avatar>
      <span class="fs-sm">My Goal</span>
      <app-avatar [profile]="userData" [avatarSize]="'medium'"></app-avatar>
    </div>
  </div>
</div>
