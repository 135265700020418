import { DragDropModule } from '@angular/cdk/drag-drop';
import { DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import {
  NgbCollapseModule,
  NgbModule,
  NgbPopoverModule,
  NgbProgressbar,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'froala-editor/js/plugins.pkgd.min';
import { NgParticlesModule } from 'ng-particles';
import { NgxEchartsModule } from 'ngx-echarts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './common/guards/auth-guard/auth-guard.service';
import { RouteGuard } from './common/guards/route-guard/route-guard.service';
import { FormatDateMMDDPipe } from './common/pipes/format-date-mmdd';
import { FormatDatePipe } from './common/pipes/format-date.pipe';
import { FormatDateTimePipe } from './common/pipes/format-datetime.pipe';
import { FormatDistanceToNowPipe } from './common/pipes/format-distance-to-now.pipe';
import { FormatTimePipe } from './common/pipes/format-time.pipe';
import { ShowDateTimezonePipe } from './common/pipes/show-date-timezone.pipe';
import { StripHtmlPipe } from './common/pipes/strip-html.pipe';
import { TimezoneLinkBackPipe } from './common/pipes/timezonelinkback.pipe';
import { TruncatePhrasePipe } from './common/pipes/truncate-phrase.pipe';
import { EnvironmentService } from './common/services/environment/environment.service';
import { ErrorHandlerService } from './common/services/error-handler/error-handler.service';
import { UserState } from './common/state/user/user.state';
import { ApiAuthInterceptor } from './common/utilities/api-auth.interceptor';
import { GrowelabLayoutComponent } from './private/layout/growelab-layout/growelab-layout.component';
import { SidebarComponent } from './private/layout/sidebar/sidebar.component';
import { AddDistrictComponent } from './private/pages/add-district/add-district.component';
import { CoacheeEvidencePageComponent } from './private/pages/coachee-evidence-page/coachee-evidence-page.component';
import { CoacheeListPageComponent } from './private/pages/coachee-list-page/coachee-list-page.component';
import { FilterTableSearch } from './private/pages/coachee-list-page/search-filter.pipe';
import { CoachingLogInfoPageComponent } from './private/pages/coaching-log-info-page/coaching-log-info-page.component';
import { CoachingLogPageComponent } from './private/pages/coaching-log-page/coaching-log-page.component';
import { CoachingSessionsPageComponent } from './private/pages/coaching-sessions-page/coaching-sessions-page.component';
import { CohortsPageComponent } from './private/pages/cohorts-page/cohorts-page.component';
import { CoachDashboardComponent } from './private/pages/dashboard/coach-dashboard/coach-dashboard.component';
import { DashboardComponent } from './private/pages/dashboard/dashboard.component';
import { LeaderDashboardComponent } from './private/pages/dashboard/leader-dashboard/leader-dashboard.component';
import { LearnerDashboardComponent } from './private/pages/dashboard/learner-dashboard/learner-dashboard.component';
import { EditDistrictComponent } from './private/pages/edit-district/edit-district.component';
import { CreateFormPageComponent } from './private/pages/forms/create-form-page/create-form-page.component';
import { EditFormPageComponent } from './private/pages/forms/edit-form-page/edit-form-page.component';
import { FormsListPageComponent } from './private/pages/forms/forms-list-page/forms-list-page.component';
import { GeneralLogInfoPageComponent } from './private/pages/general/general-log-info-page/general-log-info-page.component';
import { GeneralLogPageComponent } from './private/pages/general/general-log-page/general-log-page.component';
import { MyResourcesPageComponent } from './private/pages/my-resources-page/my-resources-page.component';
import { ObservationFormPageComponent } from './private/pages/observation/observation-form-page/observation-form-page.component';
import { ObservationInfoPageComponent } from './private/pages/observation/observation-info-page/observation-info-page.component';
import { ObservationSummaryPageComponent } from './private/pages/observation/observation-summary-page/observation-summary-page.component';
import { PermissionDeniedPageComponent } from './private/pages/permission-denied/permission-denied.component';
import { ReportingPageComponent } from './private/pages/reporting-page/reporting-page.component';
import { ResourceViewPageComponent } from './private/pages/resource-view-page/resource-view-page.component';
import { ResourcesOnlineCoursesComponent } from './private/pages/resources-online-courses/resources-online-courses.component';
import { ResourcesSearchComponent } from './private/pages/resources-search/resources-search.component';
import { ResourcesVideoLibraryComponent } from './private/pages/resources-video-library/resources-video-library.component';
import { ResourcesComponent } from './private/pages/resources/resources.component';
import { RostersPageComponent } from './private/pages/rosters-page/rosters-page.component';
import { SettingsComponent } from './private/pages/settings/settings.component';
import { StyleLibraryComponent } from './private/pages/style-library/style-library.component';
import { BasicInputComponent } from './private/pages/user-settings/basic-input/basic-input.component';
import { UserSettingsPageComponent } from './private/pages/user-settings/user-settings.component';
import { ActionPlanComponent } from './private/shared/components/action-plan/action-plan.component';
import { AppliedFiltersComponent } from './private/shared/components/applied-filters/applied-filters.component';
import { AssignCoacheeComponent } from './private/shared/components/assign-coachee/assign-coachee.component';
import { AvatarComponent } from './private/shared/components/avatar/avatar.component';
import { BadgeEarnedModalComponent } from './private/shared/components/badge-earned-modal/badge-earned-modal.component';
import { BadgeGraphicComponent } from './private/shared/components/badge-graphic/badge-graphic.component';
import { CardBadgeProgressComponent } from './private/shared/components/badges/card-badge-progress/card-badge-progress.component';
import { CardBadgesEarnedComponent } from './private/shared/components/badges/card-badges-earned/card-badges-earned.component';
import { CardRecentBadgesComponent } from './private/shared/components/badges/card-recent-badges/card-recent-badges.component';
import { CardEvidenceForReviewItemComponent } from './private/shared/components/card-evidence-for-review-item/card-evidence-for-review-item.component';
import { CardCelebrationsFeedComponent } from './private/shared/components/cards/card-celebrations-feed/card-celebrations-feed.component';
import { CardCurrentCompetenciesComponent } from './private/shared/components/cards/card-current-competencies/card-current-competencies.component';
import { CardEvidenceForReviewComponent } from './private/shared/components/cards/card-evidence-for-review/card-evidence-for-review.component';
import { CardIdeasComponent } from './private/shared/components/cards/card-ideas/card-ideas.component';
import { CardKpiComponent } from './private/shared/components/cards/card-kpi/card-kpi.component';
import { CardLeaderGlanceComponent } from './private/shared/components/cards/card-leader-glance/card-leader-glance.component';
import { CardPickupComponent } from './private/shared/components/cards/card-pick-up/card-pick-up.component';
import { CardReportComponent } from './private/shared/components/cards/card-report/card-report.component';
import { CardS2SpotlightComponent } from './private/shared/components/cards/card-s2-spotlight/card-s2-spotlight.component';
import { CardSmartSuggestionsComponent } from './private/shared/components/cards/card-smart-suggestions/card-smart-suggestions.component';
import { CardUpcomingSessionsComponent } from './private/shared/components/cards/card-upcoming-sessions/card-upcoming-sessions.component';
import { CelebrationModalComponent } from './private/shared/components/celebration-modal/celebration-modal.component';
import { CoacheeListCardComponent } from './private/shared/components/coachee/coachee-list-card/coachee-list-card.component';
import { CoacheeListItemComponent } from './private/shared/components/coachee/coachee-list-item/coachee-list-item.component';
import { CoacheeNotesComponent } from './private/shared/components/coachee/coachee-notes/coachee-notes.component';
import { CoachingAttendeeComponent } from './private/shared/components/coaching-attendee/coaching-attendee.component';
import { CoachingLogNotesComponent } from './private/shared/components/coaching-log-notes/coaching-log-notes.component';
import { CoachingSessionListComponent } from './private/shared/components/coaching-session-list/coaching-session-list.component';
import { CoachingSessionSummaryComponent } from './private/shared/components/coaching-session-summary/coaching-session-summary.component';
import { CoachingShadowerComponent } from './private/shared/components/coaching-shadower/coaching-shadower.component';
import { CodoxTextareaComponent } from './private/shared/components/codox-textarea/codox-textarea.component';
import { CohortPreviewCardComponent } from './private/shared/components/cohort-preview-card/cohort-preview-card.component';
import { CohortUsersComponent } from './private/shared/components/cohort-users/cohort-users.component';
import { CompetenciesSummaryComponent } from './private/shared/components/competencies-summary/competencies-summary.component';
import { CompetencyNameComponent } from './private/shared/components/competency-name/competency-name.component';
import { CompetencySelectComponent } from './private/shared/components/competency-select/competency-select.component';
import { GoalSliderComponent } from './private/shared/components/competency/goal-slider/goal-slider.component';
import { LeveledCompetencyModalComponent } from './private/shared/components/competency/leved-competency-modal/leveled-competency-modal.component';
import { LeveledCompetencyStrandLevelEvidenceComponent } from './private/shared/components/competency/leveled-competency-strand/level-evidence/leveled-competency-strand-level-evidence.component';
import { LeveledCompetencyStrandLevelOptionComponent } from './private/shared/components/competency/leveled-competency-strand/level-options/leveled-competency-strand-level-options.component';
import { LeveledCompetencyStrandLevelComponent } from './private/shared/components/competency/leveled-competency-strand/level/leveled-competency-strand-level.component';
import { LeveledCompetencyStrandComponent } from './private/shared/components/competency/leveled-competency-strand/strand/leveled-competency-strand.component';
import { LeveledCompetencyComponent } from './private/shared/components/competency/leveled-competency/leveled-competency.component';
import { SelectCompetenciesScreenComponent } from './private/shared/components/competency/select-competencies-screen/select-competencies-screen.component';
import { SelectCompetencyComponent } from './private/shared/components/competency/select-competency/select-competency.component';
import { ConfettiComponent } from './private/shared/components/confetti/confetti.component';
import { CreateSessionModalComponent } from './private/shared/components/create-session-modal/create-session-modal.component';
import { CreateUserModalComponent } from './private/shared/components/create-user-modal/create-user-modal.component';
import { CsvUploadComponent } from './private/shared/components/csv-upload/csv-upload.component';
import { CurrentCompetenciesListItemComponent } from './private/shared/components/current-competencies-list-item/current-competencies-list-item.component';
import { CurrentCompetenciesProgressBarComponent } from './private/shared/components/current-competencies-progress-bar/current-competencies-progress-bar.component';
import { DashboardHeaderComponent } from './private/shared/components/dashboard-header/dashboard-header.component';
import { DatepickerComponent } from './private/shared/components/datepicker/datepicker.component';
import { DaterangePickerComponent } from './private/shared/components/daterange-picker/daterange-picker.component';
import { DistrictNameComponent } from './private/shared/components/district-name/district-name.component';
import { DistrictSelectComponent } from './private/shared/components/district-select/district-select.component';
import { EvidenceCommentsComponent } from './private/shared/components/evidence-comments/evidence-comments.component';
import { EvidenceSummaryComponent } from './private/shared/components/evidence-summary/evidence-summary.component';
import { FileListComponent } from './private/shared/components/file-management/file-list/file-list.component';
import { FileUploadComponent } from './private/shared/components/file-management/file-upload/file-upload.component';
import { FormEditFieldLabelComponent } from './private/shared/components/forms/edit/base/label/form-edit-field-label.component';
import { FormEditFieldOptionsComponent } from './private/shared/components/forms/edit/base/options/form-edit-field-options.component';
import { FormEditCompetencyComponent } from './private/shared/components/forms/edit/edit-competency/edit-competency.component';
import { FormEditFieldComponent } from './private/shared/components/forms/edit/edit-field/form-edit-field.component';
import { FormEditFreetextComponent } from './private/shared/components/forms/edit/edit-freetext/edit-freetext.component';
import { FormEditMediaComponent } from './private/shared/components/forms/edit/edit-media/edit-media.component';
import { FormEditMultipleChoiceComponent } from './private/shared/components/forms/edit/edit-multiple-choice/edit-multiple-choice.component';
import { FormEditSectionComponent } from './private/shared/components/forms/edit/edit-section/edit-section.component';
import { FormEditTextComponent } from './private/shared/components/forms/edit/edit-text/edit-text.component';
import { FormModalComponent } from './private/shared/components/forms/view/form-modal/form-modal.component';
import { FormViewCheckboxComponent } from './private/shared/components/forms/view/view-checkbox/view-checkbox.component';
import { FormViewCompetencyComponent } from './private/shared/components/forms/view/view-competency/view-competency.component';
import { FormViewFieldComponent } from './private/shared/components/forms/view/view-field/form-view-field.component';
import { ViewFormComponent } from './private/shared/components/forms/view/view-form/view-form.component';
import { FormViewMediaComponent } from './private/shared/components/forms/view/view-media/view-media.component';
import { FormViewNoInputComponent } from './private/shared/components/forms/view/view-no-input/view-no-input.component';
import { FormViewRadioComponent } from './private/shared/components/forms/view/view-radio/view-radio.component';
import { FormViewSelectComponent } from './private/shared/components/forms/view/view-select/view-select.component';
import { FormViewTextComponent } from './private/shared/components/forms/view/view-text/view-text.component';
import { FormViewWysiwygComponent } from './private/shared/components/forms/view/view-wysiwyg/view-wysiwyg.component';
import { GrowthIndicatorComponent } from './private/shared/components/growth-indicator/growth-indicator.component';
import { GrowthSnapshotComponent } from './private/shared/components/growth-snapshot/growth-snapshot.component';
import { CompetencyLevelCheckboxComponent } from './private/shared/components/leveled-resource-competencies/competency-level-checkbox/competency-level-checkbox.component';
import { LeveledResourceCompetenciesComponent } from './private/shared/components/leveled-resource-competencies/leveled-resource-competencies.component';
import { LogTypeNameComponent } from './private/shared/components/log-type-name/log-type-name.component';
import { LogTypeSelectLabelComponent } from './private/shared/components/log-type-select/label/log-type-select-label.component';
import { LogTypeSelectComponent } from './private/shared/components/log-type-select/log-type-select.component';
import { CoachingSessionFilterModalComponent } from './private/shared/components/modals/coaching-session-filter-modal/coaching-session-filter-modal.component';
import { ImpersonateModalComponent } from './private/shared/components/modals/impersonate-modal/impersonate.modal.component';
import { ModalComponent } from './private/shared/components/modals/modal/modal.component';
import { VideoModalComponent } from './private/shared/components/modals/video-modal/video-modal.component';
import { NewCoachingAttendeeComponent } from './private/shared/components/new-coaching-attendee/new-coaching-attendee.component';
import { NewCoachingShadowerComponent } from './private/shared/components/new-coaching-shadower/new-coaching-shadower.component';
import { QuartileProgressBarComponent } from './private/shared/components/quartile-progress-bar/quartile-progress-bar.component';
import { QuartileProgressStrandOverviewComponent } from './private/shared/components/quartile-progress-strand-overview/quartile-progress-strand-overview.component';
import { RecentCelebrationComponent } from './private/shared/components/recent-celebration/recent-celebration.component';
import { KpiControllerComponent } from './private/shared/components/reports/kpi-controller/kpi-controller.component';
import { ReportControllerComponent } from './private/shared/components/reports/report-controller/report-controller.component';
import { ItemShelvesBookmarkComponent } from './private/shared/components/resources/item-shelves-bookmark/item-shelves-bookmark.component';
import { MyResourcesListComponent } from './private/shared/components/resources/my-resources-list/my-resources-list.component';
import { OnlineCourseListComponent } from './private/shared/components/resources/online-course-list/online-course-list.component';
import { OnlineCourseComponent } from './private/shared/components/resources/online-course/online-course.component';
import { ResourceAccessRulesComponent } from './private/shared/components/resources/resource-access-rules/resource-access-rules.component';
import { ResourceContentComponent } from './private/shared/components/resources/resource-content/resource-content.component';
import { ResourceCreateModalComponent } from './private/shared/components/resources/resource-create-modal/resource-create-modal.component';
import { ResourceFilterContainerComponent } from './private/shared/components/resources/resource-filter-container/resource-filter-container.component';
import { ResourceFilterModalComponent } from './private/shared/components/resources/resource-filter-modal/resource-filter-modal.component';
import { ResourceLeveledListComponent } from './private/shared/components/resources/resource-leveled-list/resource-leveled-list.component';
import { ResourceLeveledComponent } from './private/shared/components/resources/resource-leveled/resource-leveled.component';
import { ResourceListItemComponent } from './private/shared/components/resources/resource-list-item/resource-list-item.component';
import { ResourcePreviewListComponent } from './private/shared/components/resources/resource-preview-list/resource-preview-list.component';
import { ResourcePreviewComponent } from './private/shared/components/resources/resource-preview/resource-preview.component';
import { ResourceTagsComponent } from './private/shared/components/resources/resource-tags/resource-tags.component';
import { ResourcesHeaderComponent } from './private/shared/components/resources/resources-header/resources-header.component';
import { ResourcesSubnavComponent } from './private/shared/components/resources/resources-subnav/resources-subnav.component';
import { SelectResourceComponent } from './private/shared/components/resources/select-resource/select-resource.component';
import { SelectTagsComponent } from './private/shared/components/resources/select-tags/select-tags.component';
import { ShelfItemComponent } from './private/shared/components/resources/shelf-item/shelf-item.component';
import { ShelfComponent } from './private/shared/components/resources/shelf/shelf.component';
import { RosterSchoolComponent } from './private/shared/components/roster-schools/roster-schools.component';
import { RosterUsersComponent } from './private/shared/components/roster-users/roster-users.component';
import { SafehtmlComponent } from './private/shared/components/safehtml/safehtml.component';
import { SchoolNameComponent } from './private/shared/components/school-name/school-name.component';
import { SchoolSelectLabelComponent } from './private/shared/components/school-select/label/school-select-label.component';
import { SchoolSelectComponent } from './private/shared/components/school-select/school-select.component';
import { SettingsNavComponent } from './private/shared/components/settings/settings-nav/settings-nav.component';
import { StudentProjectedGrowthComponent } from './private/shared/components/student-projected-growth/student-projected-growth.component';
import { CardTodosComponent } from './private/shared/components/todos/card-todos/card-todos.component';
import { TodoItemComponent } from './private/shared/components/todos/todo-item/todo-item.component';
import { UserSelectLabelComponent } from './private/shared/components/user-select/label/user-select-label.component';
import { UserSelectComponent } from './private/shared/components/user-select/user-select.component';
import { UsersNameComponent } from './private/shared/components/users-name/users-name.component';
import { VideoCardComponent } from './private/shared/components/video-card/video-card.component';
import { VideoMenuActionsComponent } from './private/shared/components/video-menu-actions/video-menu-actions.component';
import { VideoPlayerComponent } from './private/shared/components/video-player/video-player.component';
import { WysiwygEditorComponent } from './private/shared/components/wysiwyg-editor/wysiwyg-editor.component';
import { YearSelectComponent } from './private/shared/components/year-select/year-select.component';
import { AutosaveDirective } from './private/shared/directives/autosave.directive';
import { SortableHeader } from './private/shared/directives/sortable-header.directive';
import { CoachingLogPusherService } from './private/shared/services/coaching-log/coaching-log-pusher.service';
import { CodoxService } from './private/shared/services/codox/codox.service';
import { DisplayService } from './private/shared/services/display/display.service';
import { DistrictSearchService } from './private/shared/services/district-search/district-search.service';
import { EvidenceFileService } from './private/shared/services/file/evidence.file.service';
import { FileService } from './private/shared/services/file/file.service';
import { LogFileService } from './private/shared/services/file/log.file.service';
import { FormsService } from './private/shared/services/forms/forms.service';
import { LogTypeSearchService } from './private/shared/services/log-type-search/log-type-search.service';
import { PusherService } from './private/shared/services/pusher/pusher.service';
import { UserPusherService } from './private/shared/services/pusher/user.pusher.service';
import { SchoolSearchService } from './private/shared/services/school-search/school-search.service';
import { ThoughtsService } from './private/shared/services/thoughts/thoughts.service';
import { TodoService } from './private/shared/services/todo/todo.service';
import { UserSearchService } from './private/shared/services/user-search/user-search.service';
import { CoachingLogState } from './private/shared/state/coaching-log/coaching-log.state';
import { FooterComponent } from './public/components/footer/footer.component';
import { LoaderComponent } from './public/components/loader/loader.component';
import { GuestLayoutComponent } from './public/layouts/guest-layout/guest-layout.component';
import { PageNotFoundComponent } from './public/pages/404/404.component';
import { CleverLoginComponent } from './public/pages/clever-login/clever-login.component';
import { DataSharingComponent } from './public/pages/data-sharing/data-sharing.component';
import { ForgotPasswordComponent } from './public/pages/forgot-password/forgot-password.component';
import { LoginComponent } from './public/pages/login/login.component';
import { PrivacyPolicyComponent } from './public/pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from './public/pages/terms/terms.component';
import { WelcomeComponent } from './public/pages/welcome/welcome.component';

@NgModule({
  declarations: [
    SidebarComponent,
    DashboardHeaderComponent,
    AppComponent,
    StudentProjectedGrowthComponent,
    AvatarComponent,
    CoacheeListCardComponent,
    CoacheeListItemComponent,
    GrowelabLayoutComponent,
    GuestLayoutComponent,
    FilterTableSearch,
    SortableHeader,
    LoginComponent,
    PageNotFoundComponent,
    DashboardComponent,
    CoachDashboardComponent,
    LeaderDashboardComponent,
    LearnerDashboardComponent,
    SettingsComponent,
    PermissionDeniedPageComponent,
    ResourcesComponent,
    CoacheeListPageComponent,
    StyleLibraryComponent,
    AvatarComponent,
    CoacheeListCardComponent,
    CoacheeListItemComponent,
    LoginComponent,
    CoachingSessionFilterModalComponent,
    GrowelabLayoutComponent,
    GuestLayoutComponent,
    FilterTableSearch,
    CoachingLogPageComponent,
    CoacheeNotesComponent,
    SortableHeader,
    GoalSliderComponent,
    CardUpcomingSessionsComponent,
    ShowDateTimezonePipe,
    FormatTimePipe,
    FormatDatePipe,
    FormatDateMMDDPipe,
    FormatDistanceToNowPipe,
    FormatDateTimePipe,
    TimezoneLinkBackPipe,
    LeveledCompetencyModalComponent,
    LeveledCompetencyComponent,
    LeveledCompetencyStrandComponent,
    LeveledCompetencyStrandLevelComponent,
    RecentCelebrationComponent,
    LeveledCompetencyStrandLevelOptionComponent,
    LeveledCompetencyStrandLevelEvidenceComponent,
    GrowthIndicatorComponent,
    GrowthSnapshotComponent,
    ConfettiComponent,
    CardS2SpotlightComponent,
    CardSmartSuggestionsComponent,
    CardPickupComponent,
    CardIdeasComponent,
    BadgeEarnedModalComponent,
    CardEvidenceForReviewComponent,
    BadgeEarnedModalComponent,
    CardBadgeProgressComponent,
    CardBadgesEarnedComponent,
    LoaderComponent,
    CardTodosComponent,
    CoachingSessionsPageComponent,
    CoachingSessionListComponent,
    CoachingLogNotesComponent,
    TodoItemComponent,
    DatepickerComponent,
    CodoxTextareaComponent,
    CoachingSessionsPageComponent,
    CoachingSessionListComponent,
    WysiwygEditorComponent,
    ModalComponent,
    UserSelectComponent,
    UserSelectLabelComponent,
    SelectResourceComponent,
    ResourcePreviewComponent,
    ResourcePreviewListComponent,
    ResourcesSubnavComponent,
    AutosaveDirective,
    ResourcesSearchComponent,
    ResourceListItemComponent,
    UsersNameComponent,
    SelectCompetenciesScreenComponent,
    SelectCompetencyComponent,
    AddDistrictComponent,
    EditDistrictComponent,
    CoachingAttendeeComponent,
    DaterangePickerComponent,
    SchoolSelectComponent,
    SchoolSelectLabelComponent,
    LogTypeSelectComponent,
    LogTypeSelectLabelComponent,
    AppliedFiltersComponent,
    LogTypeNameComponent,
    DistrictNameComponent,
    SchoolNameComponent,
    DistrictSelectComponent,
    ResourceViewPageComponent,
    FileUploadComponent,
    FileListComponent,
    ResourcesVideoLibraryComponent,
    OnlineCourseListComponent,
    OnlineCourseComponent,
    ResourcesOnlineCoursesComponent,
    ResourceFilterContainerComponent,
    ResourceFilterContainerComponent,
    ResourceFilterModalComponent,
    SelectTagsComponent,
    ItemShelvesBookmarkComponent,
    VideoCardComponent,
    VideoPlayerComponent,
    VideoMenuActionsComponent,
    SafehtmlComponent,
    CoachingShadowerComponent,
    CoachingLogInfoPageComponent,
    ResourceAccessRulesComponent,
    ResourceTagsComponent,
    ResourceContentComponent,
    CoachingSessionSummaryComponent,
    ObservationInfoPageComponent,
    ObservationFormPageComponent,
    ObservationSummaryPageComponent,
    CreateSessionModalComponent,
    NewCoachingAttendeeComponent,
    NewCoachingShadowerComponent,
    ResourceCreateModalComponent,
    ResourcesHeaderComponent,
    CoacheeEvidencePageComponent,
    CompetenciesSummaryComponent,
    QuartileProgressBarComponent,
    QuartileProgressStrandOverviewComponent,
    MyResourcesPageComponent,
    ShelfComponent,
    CompetencySelectComponent,
    ImpersonateModalComponent,
    TruncatePhrasePipe,
    CompetencyNameComponent,
    ResourceLeveledListComponent,
    ResourceLeveledComponent,
    ShelfItemComponent,
    MyResourcesListComponent,
    EvidenceSummaryComponent,
    CardEvidenceForReviewComponent,
    LeveledResourceCompetenciesComponent,
    CompetencyLevelCheckboxComponent,
    FormsListPageComponent,
    CreateFormPageComponent,
    EditFormPageComponent,
    FormEditFieldComponent,
    FormEditCompetencyComponent,
    FormEditSectionComponent,
    FormEditFreetextComponent,
    FormEditTextComponent,
    FormEditMultipleChoiceComponent,
    FormEditFieldLabelComponent,
    FormEditFieldOptionsComponent,
    FormEditMediaComponent,
    ViewFormComponent,
    FormModalComponent,
    FormViewFieldComponent,
    FormViewCheckboxComponent,
    FormViewSelectComponent,
    FormViewTextComponent,
    FormViewWysiwygComponent,
    FormViewRadioComponent,
    FormViewCompetencyComponent,
    FormViewNoInputComponent,
    FormViewMediaComponent,
    SettingsNavComponent,
    UserSettingsPageComponent,
    BasicInputComponent,
    EvidenceCommentsComponent,
    CardEvidenceForReviewItemComponent,
    ForgotPasswordComponent,
    CohortsPageComponent,
    CohortPreviewCardComponent,
    ReportControllerComponent,
    KpiControllerComponent,
    BadgeGraphicComponent,
    ReportingPageComponent,
    CardKpiComponent,
    CardReportComponent,
    CardCurrentCompetenciesComponent,
    CurrentCompetenciesListItemComponent,
    CurrentCompetenciesProgressBarComponent,
    RostersPageComponent,
    RosterSchoolComponent,
    CardRecentBadgesComponent,
    CardLeaderGlanceComponent,
    CreateUserModalComponent,
    RosterUsersComponent,
    WelcomeComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    DataSharingComponent,
    FooterComponent,
    StripHtmlPipe,
    CsvUploadComponent,
    CardCelebrationsFeedComponent,
    CelebrationModalComponent,
    AssignCoacheeComponent,
    CleverLoginComponent,
    YearSelectComponent,
    GeneralLogPageComponent,
    GeneralLogInfoPageComponent,
    ActionPlanComponent,
    CohortUsersComponent,
    VideoModalComponent,
  ],
  imports: [
    NgxsModule.forRoot([UserState, CoachingLogState]),
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgbCollapseModule,
    NgbProgressbar,
    ReactiveFormsModule,
    NgbPopoverModule,
    DragDropModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgParticlesModule,
    NgSelectModule,
    BrowserAnimationsModule,
  ],
  providers: [
    DecimalPipe,
    CodoxService,
    TodoService,
    EnvironmentService,
    DisplayService,
    HttpClientModule,
    PusherService,
    UserPusherService,
    RouteGuard,
    AuthGuard,
    ThoughtsService,
    CoachingLogPusherService,
    UserSearchService,
    SchoolSearchService,
    DistrictSearchService,
    LogTypeSearchService,
    FileService,
    LogFileService,
    EvidenceFileService,
    FormsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiAuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
