import { APILoadingStatus } from '../types/types';

export const defaultAPILoadingStatus: APILoadingStatus = {
  loading: false,
  loaded: false,
};

export const apiLoadingStatusComplete: APILoadingStatus = {
  loading: false,
  loaded: true,
};
