<div class="container">
  <div class="d-flex mb-3 align-items-center justify-content-between">
    <h1 class="fs-3 fw-bold">Coaching Session</h1>
    <div
      [autoClose]="'outside'"
      [ngbPopover]="celebrationsPopover"
      popoverClass="popover-lg"
      placement="bottom-end"
      class="icon"
      [ngStyle]="{
        'background-image': 'url(/assets/celebrations.svg)'
      }"></div>
    <ng-template #celebrationsPopover>
      <app-card-celebrations-feed [celebrations]="celebrations" [user]="user" />
    </ng-template>
  </div>

  <div class="card border-0">
    <div class="card-body p-3 p-lg-5 pt-lg-4">
      <ng-container *ngIf="sessionData; else loadingTemplate">
        <div class="session-nav-bar">
          <a
            routerLink="/general/log/{{ sessionId }}/info"
            class="text-decoration-none text-muted">
            < Back to Session Info
          </a>
        </div>
        <h2 class="fs-1 fw-bold text-center my-3">
          {{ sessionData.title }}
        </h2>
        <div
          class="d-flex flex-wrap align-items-center justify-content-between mb-4">
          <div class="d-flex flex-row align-items-center">
            <div class="me-3 text-muted">Attendees</div>
            <app-avatar
              style="margin-left: -5px"
              avatarSize="small"
              *ngFor="
                let attendee of coachingService.logPusherService
                  .attendeeRosters[sessionId]
              "
              [avatarData]="attendee"
              data-testid="attendee-avatar"></app-avatar>
          </div>
          <button routerLink="/coaching" class="btn btn-primary btn-next">
            Finish
            <i class="bi bi-arrow-right"></i>
          </button>
        </div>
        <div class="row">
          <ng-container *ngIf="sessionData">
            <div class="col-lg-7 mb-5">
              <h3 class="fw-800">Meeting Notes</h3>
              <app-wysiwyg-editor
                #resourceeditor
                [isSaving]="isSaving"
                [version]="'full'"
                [config]="editorConfig"
                [editorContent]="initialContent"
                (editorContentEvent)="updateNotes($event)" />
            </div>
            <div class="col-lg-5">
              <app-action-plan [coachlogId]="sessionId"></app-action-plan>
              <div class="mt-5 mb-3">
                <h3 class="fw-800">Attachments</h3>
                <div class="asdf p-3" style="background: #efebf1">
                  <app-file-upload #uploadComponent [uploadType]="uploadType" />
                  <div class="p-2 mt-3">
                    <app-file-list
                      *ngIf="attachments"
                      [fileList]="attachments"
                      [canDelete]="true"
                      [logId]="sessionId"></app-file-list>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #loadingTemplate>
        <div class="w-100" style="height: 400px">
          <app-loader></app-loader>
        </div>
      </ng-template>
    </div>
  </div>
</div>
