import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  checkIfCoach,
  checkIfShadower,
  checkPresentAttendee,
} from 'src/app/common/utilities/session-helpers';
import { ViewFormComponent } from 'src/app/private/shared/components/forms/view/view-form/view-form.component';
import {
  CoachingSessionAttendeeDTO,
  CoachingSessionDTO,
} from 'src/app/private/shared/dtos/coaching-session.dto';
import {
  Form,
  FormStatus,
  FormSubmissionStatus,
  FormType,
  ObservationFormSubmission,
} from 'src/app/private/shared/dtos/forms.dto';
import { FetchSessionData } from 'src/app/private/shared/state/coaching-log/coaching-log.actions';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';

import { FormsService } from '../../../shared/services/forms/forms.service';

@Component({
  selector: 'app-observation-summary',
  templateUrl: './observation-summary-page.component.html',
  styleUrls: ['./observation-summary-page.component.scss'],
})
export class ObservationSummaryPageComponent implements OnInit {
  @ViewChild('formView') formView: ViewFormComponent;

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO;

  attendeeUsers: UserDTO[] = [];

  hasPresentAttendee = false;

  isCoach = false;

  isShadower = false;

  isLoading = true;

  loadStatus = {
    forms: false,
    sessionData: false,
    submissions: false,
  };

  user: User;

  sessionId: number;

  formsList: Form[];

  selectedAttendee: CoachingSessionAttendeeDTO;

  selectedForm: Form;

  currentSubmission: ObservationFormSubmission;

  submissions: ObservationFormSubmission[] = [];

  SubmissionStatuses = FormSubmissionStatus;

  constructor(
    private route: ActivatedRoute,
    private formsService: FormsService,
    private store: Store,
    private router: Router
  ) {
    this.formsService.loadForms().subscribe((forms) => {
      this.formsList = forms.filter(
        (form) =>
          form.status === FormStatus.PUBLISHED && form.type === FormType.VISIT
      );
      this.loadStatus.forms = true;
      this.checkDataLoaded();
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((url) => {
      this.sessionId = parseInt(url['logId']);
      this.store.dispatch(new FetchSessionData(this.sessionId));

      this.formsService
        .getFormSubmissionsFromCoachingLog(this.sessionId)
        .subscribe((submissions) => {
          this.submissions = submissions;
          this.loadStatus.submissions = true;
          this.checkDataLoaded();
        });
      this.sessionData$
        .pipe(map((filterFn) => filterFn(this.sessionId)))
        .pipe(
          map((sessionData) => {
            if (sessionData) {
              this.sessionData = sessionData;
              this.sessionData.attendees = this.sessionData.attendees.map(
                (attendee) => ({
                  ...attendee,
                  fullName: `${attendee.user.profile.first_name} ${attendee.user.profile.last_name}`,
                })
              );
              this.loadStatus.sessionData = true;
              this.hasPresentAttendee = checkPresentAttendee(this.sessionData);
              this.isCoach = checkIfCoach(this.sessionData);
              this.isShadower = checkIfShadower(this.sessionData);
              this.checkDataLoaded();
            }
          })
        )
        .subscribe();
    });
  }

  navigatePreviousScreen() {
    this.router.navigate(['../form'], { relativeTo: this.route });
  }

  checkDataLoaded() {
    if (
      this.loadStatus.forms &&
      this.loadStatus.sessionData &&
      this.loadStatus.submissions
    ) {
      if (this.isCoach || this.isShadower) {
        this.attendeeSelected(this.sessionData.attendees[0]);
      } else {
        const attendeeIdx = this.sessionData.attendees.findIndex(
          (attendee) => attendee.userId === this.user.id
        );
        if (attendeeIdx > -1) {
          this.attendeeSelected(this.sessionData.attendees[attendeeIdx]);
        }
      }
      this.isLoading = false;
    }
  }

  attendeeSelected(user: CoachingSessionAttendeeDTO) {
    this.selectedAttendee = user;
    if (this.submissions && this.submissions.length > 0) {
      const submissionIdx = this.submissions.findIndex(
        (submission) =>
          submission.observedUserId === this.selectedAttendee.userId
      );
      if (submissionIdx > -1) {
        this.currentSubmission = this.submissions[submissionIdx];
        this.selectedForm = this.formsList.find(
          (form) => form.id === this.currentSubmission.formId
        ) as Form;
        if (this.formView) {
          this.formView.submissionStatus = FormSubmissionStatus.SUBMITTED;
          this.formView.submissionData = this.currentSubmission;
          this.formView.ngOnInit();
        }
      }
    }
  }
}
