import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { SchoolSearchService } from 'src/app/private/shared/services/school-search/school-search.service';
import { DistrictListService } from '../../shared/services/district-list/district-list-service';
import { UserSearchService } from '../../shared/services/user-search/user-search.service';

@Component({
  selector: 'app-rosters-page',
  templateUrl: './rosters-page.component.html',
  styleUrls: ['./rosters-page.component.scss'],
})
export class RostersPageComponent {
  @ViewChild('searchText') input: ElementRef;

  districtId: number;

  districtCode: string;

  district: DistrictDTO;

  user$ = select(UserState.getUser);

  user: User | null = null;

  isLoading = true;

  schoolData: SchoolDTO[];

  numPerPage = 20;

  activeSection = 'schools';

  showCreateUserModal = false;

  constructor(
    private districtList: DistrictListService,
    private route: ActivatedRoute,
    public router: Router,
    private schoolSearchService: SchoolSearchService,
    private userSearchService: UserSearchService
  ) {
    this.user$
      .pipe(
        map((user) => {
          this.user = user;
        })
      )
      .subscribe();

    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;
    this.districtList
      .fetchDistrictsSimple({ per_page: 1000 })
      .subscribe((districts) => {
        if (districts) {
          const district = districts.find(
            (searchDistrict) =>
              searchDistrict.districtCode === this.districtCode
          ) as DistrictDTO;
          if (district) {
            this.district = district;
            this.districtId = this.district.id;
          } else {
            this.district = districts.find(
              (searchDistrict) => searchDistrict.id === 2
            ) as DistrictDTO;
            this.districtCode = this.district.districtCode;
            this.districtId = this.district.id;
          }
          this.schoolSearchService
            .getDistrictSchools(this.districtId)
            .subscribe((data) => {
              if (data) this.schoolData = data;
              this.isLoading = false;
            });
        }
      });
  }

  searchUsers(event: Event) {
    const term = (<HTMLInputElement>event.target).value;

    const districtIds: number[] = [];
    if (this.district) {
      districtIds.push(this.district.id);
    }

    this.userSearchService.searchFull(
      term,
      districtIds,
      '',
      this.numPerPage,
      1,
      '',
      false
    );
  }

  searchSchools(event: Event) {
    const term = (<HTMLInputElement>event.target).value;

    this.schoolSearchService.search(term, this.districtId);
  }

  changeSection(sectionName: string) {
    this.activeSection = sectionName;
  }
}
