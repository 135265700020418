<nav
  id="nav-main"
  role="navigation"
  class="d-flex flex-column justify-content-between h-100 p-md-0">
  <a
    routerLink="/dashboard"
    (click)="linkSelected()"
    class="gl-logo d-none d-md-block w-100 text-center mt-3 mb-4"
    title="GroweLab">
    <img
      id="groweLabLogo"
      width="80px"
      src="assets/logo_light.png"
      alt="GroweLab Logo" />
  </a>
  <ul class="nav main-nav flex-column flex-nowrap mb-auto">
    <ng-template ngFor let-route [ngForOf]="sidebarItems">
      <ng-template [ngIf]="shouldShowRoute(route)">
        <li class="nav-item">
          <a
            routerLink="{{ route.path }}"
            routerLinkActive="active"
            ariaCurrentWhenActive="page"
            (click)="linkSelected()"
            class="nav-link"
            aria-current="page"
            title="{{ route.title }}">
            <i
              class="bi bi-{{ route.data.icon }}"
              role="img"
              ariaLabel="{{ route.data.ariaLabel }}"></i>
            <div class="txt">{{ route.title }}</div>
          </a>
        </li>
      </ng-template>
    </ng-template>
  </ul>
  <ul class="nav flex-column flex-nowrap mb-auto text-center">
    <li class="nav-item">
      <a
        href="https://learn.growelab.app"
        target="_blank"
        (click)="linkSelected()"
        class="nav-link"
        aria-current="page"
        title="Help">
        <i class="bi bi-question-circle" role="img" aria-label="Help"></i>
        <div class="txt">Help</div>
      </a>
    </li>
  </ul>
  <div ngbDropdown class="dropdown dropend" *ngIf="user">
    <a
      ngbDropdownToggle
      href="javascript:;"
      class="user-menu-toggle nav-link d-flex align-items-center text-center justify-content-md-center link-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-label="User Menu"
      aria-expanded="false">
      <div
        class="user d-flex flex-row flex-md-column align-items-center text-center">
        <div
          [ngClass]="[
            'position-relative',
            'border',
            'border-2',
            'rounded-circle',
            isImpersonatingUser ? 'border-warning' : 'border-secondary'
          ]">
          <i
            class="bi bi-exclamation-circle-fill color-warning impersonating"
            *ngIf="isImpersonatingUser"></i>
          <app-avatar
            [hideTooltip]="true"
            [profile]="user.profile"
            [avatarSize]="'avatar-side-nav'" />
        </div>
        <div class="mx-2" id="username">
          {{ user.profile.first_name }} {{ user.profile.last_name.charAt(0) }}
        </div>
      </div>
    </a>
    <ul ngbDropdownMenu class="dropdown-menu shadow">
      <li>
        <h6 class="dropdown-header" aria-label="User Menu Header">
          {{ user.profile.first_name }} {{ user.profile.last_name }}
        </h6>
      </li>
      <li ngbDropdownItem class="px-0">
        <a class="dropdown-item" routerLink="/settings/user">
          <i
            class="bi bi-sliders mx-1"
            role="img"
            aria-label="User Settings"></i>
          Settings
        </a>
      </li>
      <ng-container
        *ngIf="isImpersonatingUser || user.roles.includes(Roles.ESUITE_ADMIN)">
        <li><hr class="dropdown-divider" /></li>
        <li
          ngbDropdownItem
          *ngIf="
            user.roles.includes(Roles.ESUITE_ADMIN) && !isImpersonatingUser
          "
          class="px-0">
          <a
            class="dropdown-item"
            (click)="impersonateModal.modal.open()"
            href="javascript:void(0)">
            <i
              class="bi bi-person-lines-fill mx-1"
              role="img"
              aria-label="Impersonate user"></i>
            Login as User
          </a>
        </li>
        <li ngbDropdownItem *ngIf="isImpersonatingUser" class="px-0">
          <a
            class="dropdown-item"
            (click)="unimpersonate()"
            href="javascript:void(0)">
            <i
              class="bi bi-person-lines-fill mx-1"
              role="img"
              aria-label="Impersonate user"></i>
            Logout as User
          </a>
        </li>
      </ng-container>
      <li><hr class="dropdown-divider" /></li>
      <li ngbDropdownItem class="px-0">
        <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
          <i
            class="bi bi-box-arrow-right mx-1"
            role="img"
            aria-label="User Sign Out"></i>
          Sign out
        </a>
      </li>
    </ul>
  </div>
  <app-impersonate-modal #impersonateModal />
</nav>
