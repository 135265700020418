import { VideoCategoryDTO, VideoDTO } from 'src/app/common/dtos/video.dto';

import { booleanFromBooleanNumber } from '../../../../common/utilities/enums/boolean-number.enum';
import {
  VideoCategoryResponse,
  VideoAPIResponse,
} from '../../types/responses/video.responses';
import { userDTOFromAPIResponse } from './user.translators';

export const videoDTOFromAPIResponse = (item: VideoAPIResponse): VideoDTO => ({
  allowComments: booleanFromBooleanNumber(item.allow_comments),
  belongsToVideoLibrary: booleanFromBooleanNumber(
    item.belongs_to_video_library
  ),
  categoryId: item.category_id,
  createdAt: item.created_at,
  description: item.description,
  esuiteLink: item.esuite_link,
  id: item.id,
  imagePath: item.image_path,
  isDeleted: booleanFromBooleanNumber(item.is_deleted),
  length: item.length,
  processedByVimeo: booleanFromBooleanNumber(item.processed_by_vimeo),
  role: item.role,
  tags: item.tags,
  title: item.title,
  updatedAt: item.updated_at,
  user: item.user ? userDTOFromAPIResponse(item.user) : undefined,
  userId: item.user_id,
  viewsCount: item.views_count,
  vimeoLink: item.vimeo_link,
  vimeoUri: item.vimeo_uri,
});

export const videoModelsFromAPIResponse = (response: {
  items: VideoAPIResponse[];
}): VideoDTO[] => response.items.map((item) => videoDTOFromAPIResponse(item));

export const videoCategoryDTOFromAPIResponse = (
  item: VideoCategoryResponse
): VideoCategoryDTO => ({
  id: item.id,
  name: item.name,
  createdAt: item.created_at,
  updatedAt: item.updated_at,
  isDeleted: item.is_deleted,
});
