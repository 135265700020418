import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  canAccessByRole,
  canAccessByUserType,
  currentDistrictOrE2lEmployee,
} from '../../utilities/check-route';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  user: User | null = null;

  constructor(public store: Store, private router: Router) {}

  /* eslint-disable-next-line */
  canActivate(next: any, state: any): boolean {
    this.user = this.store.selectSnapshot(UserState.getUser);
    if (next.url.length === 1 && next.url[0].path === 'login') {
      if (this.user) {
        this.router.navigate(['/dashboard']);
        return false;
      }
      return true;
    }
    if (this.user == null) {
      this.loginRedirect();
      return false;
    }
    if (next.url.length === 0) {
      this.router.navigate(['/dashboard']);
      return false;
    }

    if (
      next.url.length === 5 &&
      next.url[0].path === 'settings' &&
      next.url[1].path === 'districts' &&
      next.url[3].path === 'forms' &&
      !currentDistrictOrE2lEmployee(this.user, { code: next.url[2].path })
    ) {
      this.router.navigate(['/permission-denied']);
      return false;
    }
    if (!canAccessByRole(next, this.user)) {
      this.router.navigate(['/permission-denied']);
      return false;
    }
    if (!canAccessByUserType(next, this.user)) {
      this.router.navigate(['/permission-denied']);
      return false;
    }
    return true;
  }

  loginRedirect() {
    this.router.navigate(['/login']);
  }
}
