import { Component, Input, OnInit } from '@angular/core';
import { ReportRequestParams } from 'src/app/common/dtos/reports.dto';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { ChartType } from '../../../enums/chart-type.enum';
import { ChartGrid } from '../../reports/report-controller/chart-options-helper';

@Component({
  selector: 'app-card-report',
  templateUrl: './card-report.component.html',
  styleUrls: ['./card-report.component.scss'],
})
export class CardReportComponent implements OnInit {
  @Input() cardTitle = 'At a Glance Report';

  @Input() cardHeight: string;

  @Input() reportTitle: '';

  @Input() chartType: ChartType = ChartType.Bar;

  @Input() yAxisLabel = '';

  @Input() xAxisLabel = '';

  @Input() requestParams: ReportRequestParams;

  @Input() percentage: boolean;

  @Input() xNameGap = 30;

  @Input() yNameGap = 25;

  @Input() gridDimensions: ChartGrid = {
    top: '10px',
    left: '23px',
    right: '15px',
    bottom: '25px',
  };

  @Input() chartColor: string;

  isCollapsed: boolean;

  currentError = '';

  constructor(private cardStorageService: CardStorageService) {}

  ngOnInit() {
    this.isCollapsed = this.cardStorageService.getCollapsedState(
      this.cardTitle.replace(/\s/g, '').toLowerCase()
    );
  }

  resetError() {
    this.currentError = '';
  }

  handleError(errorMessage: string) {
    this.currentError = errorMessage;
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      this.cardTitle.replace(/\s/g, '').toLowerCase(),
      this.isCollapsed
    );
  }
}
