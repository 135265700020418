/* eslint-disable no-unused-vars  */
import { HttpClientModule } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { MockProvider } from 'ng-mocks';
import { of } from 'rxjs';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';

import { SingleApiResponse } from '../../types/responses/responses';
import { BooleanNumber } from '../../utilities/enums/boolean-number.enum';
import { State } from '../../utilities/enums/state.enum';
import {
  MOCK_DISTRICT_RESPONSE,
  MOCK_PROFILE,
  MOCK_PROFILE_COACH,
  MOCK_SCHOOL,
} from '../state.mocks';
import { UserRole } from './role/user-role';
import {
  MOCK_USER_ROLE_DTO_OPERATOR,
  MOCK_USER_ROLE_DTO_USER,
} from './role/user-role.mocks';
import { UserType } from './type/user-type';
import { UserDTO } from './user.dto';
import { User } from './user.model';
import { UserService } from './user.service';
import { UserState } from './user.state';
import { userFromDto } from './user.utilities';

export const MOCK_USER_DTO: UserDTO = {
  id: 44408,
  username: 'matt@engage2learn.org',
  email: 'matt@engage2learn.org',
  status: 10,
  district: MOCK_DISTRICT_RESPONSE,
  school: MOCK_SCHOOL,
  profile: MOCK_PROFILE,
  roles: [MOCK_USER_ROLE_DTO_USER],
  coachee_type: 'Teacher',
  is_deleted: BooleanNumber.FALSE,
  is_smart: BooleanNumber.FALSE,
  is_smart_default: null,
  smartCoach: null,
  years_coached: 1,
};

export const MOCK_DISTRICT_STATE_DTO = {
  calendarBlocks: [],
  created_at: 1614787200,
  updated_at: 1614787200,
  data_contact_email: 'fake@district.isd',
  default_coach_timeline_id: 1,
  default_principal_timeline_id: 1,
  default_teacher_timeline_id: 1,
  id: 2,
  hubspot_company_id: '1234',
  district_code: 'engage2learn',
  is_b2b: 0,
  is_internal: 1,
  title: 'engage2learn',
  address: '',
  address2: '',
  city: '',
  state: State.TX,
  zip: '',
  phone: '',
  domain: 'engage2learn.org',
  is_deleted: 0,
  logo: '/uploads/user_upload/202211/eSuiteLogoHorizontal.png',
  timezone: 'US/Central',
  counts: {
    users: 295,
    schools: 38,
  },
  schools: [],
  why: '',
  hubspotCompanyId: '123',
  districtCode: 'fakedistrict',
  isB2b: BooleanNumber.FALSE,
  isInternal: BooleanNumber.FALSE,
  isDeleted: BooleanNumber.FALSE,
  reportingSchoolYear: '2023-2024',
};

export const MOCK_USER_STATE_DTO: User = {
  id: 44408,
  coach: undefined,
  username: 'matt@engage2learn.org',
  profile: MOCK_PROFILE,
  type: UserType.COACH,
  status: 10,
  email: 'matt@engage2learn.org',
  district: MOCK_DISTRICT_STATE_DTO,
  school: MOCK_SCHOOL,
  roles: [UserRole.COACH],
  isDeleted: BooleanNumber.FALSE,
  isSmart: BooleanNumber.FALSE,
  isSmartDefault: false,
  smartCoach: null,
  yearsCoached: 1,
  isCoach: true,
  fullName: 'matt webster',
  createdAt: 1614787200,
  coacheeType: UserType.LEARNER,
};

const MOCK_USER_COACH_DTO: UserDTO = {
  ...MOCK_USER_DTO,
  id: 44409,
  username: 'shannon@engage2learn.org',
  email: 'shannon@engage2learn.org',
  status: 10,
  district: MOCK_DISTRICT_RESPONSE,
  school: MOCK_SCHOOL,
  profile: MOCK_PROFILE_COACH,
  coachee_type: UserType.COACH,
  roles: [MOCK_USER_ROLE_DTO_USER],
};

export const MOCK_USER = userFromDto(MOCK_USER_DTO);
export const MOCK_USER_NO_ROLES = userFromDto({ ...MOCK_USER_DTO, roles: [] });
export const MOCK_USER_OPERATOR = userFromDto({
  ...MOCK_USER_DTO,
  roles: [MOCK_USER_ROLE_DTO_OPERATOR],
});

export const MOCK_USER_COACH = userFromDto(MOCK_USER_COACH_DTO);

export const MOCK_SELECTABLE_COACHEE = {
  id: 44408,
  username: 'matt@engage2learn.org',
  profile: MOCK_PROFILE,
  badgeCount: 1,
  celebrationsCount: 0,
  sessionCount: 5,
  classroomvisitCount: 0,
  nextSessionDate: null,
  nextSessionId: null,
  personalNotes: 'Great job',
  isSelected: true,
};

export const MOCK_REGULAR_USER = { ...MOCK_USER, roles: [UserRole.USER] };
export const MOCK_OPERATOR_USER = { ...MOCK_USER, roles: [UserRole.OPERATOR] };

export const MOCK_LEADER_USER = { ...MOCK_USER, type: UserType.LEADER };
export const MOCK_COACH_USER = { ...MOCK_USER, type: UserType.COACH };
export const MOCK_OPERATOR_COACH_USER = {
  ...MOCK_USER,
  type: UserType.COACH,
  roles: [UserRole.OPERATOR],
};

export const MOCK_USER_STATE_DEFAULT = {
  hasData: false,
  isLoading: false,
  user: null,
};

export const MOCK_USER_STATE_LOADING = {
  ...MOCK_USER_STATE_DEFAULT,
  isLoading: true,
};

export const MOCK_USER_STATE_SUCCESS = {
  hasData: true,
  isLoading: false,
  user: MOCK_USER,
};

export const MOCK_USER_API_RESPONSE: SingleApiResponse = {
  item: MOCK_USER_DTO,
};

export const MOCK_USER_COACH_API_RESPONSE: SingleApiResponse = {
  item: MOCK_USER_COACH,
};

export const STATE_TEST_CONFIG = {
  imports: [
    NgxsModule.forRoot([UserState, CoachingLogState]),
    HttpClientModule,
  ],
  providers: [
    MockProvider(UserService, {
      fetchUser: () => of(MOCK_USER_API_RESPONSE),
    }),
  ],
};
