import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import {
  Field,
  FieldType,
  FieldWithChoices,
  FormStatus,
} from 'src/app/private/shared/dtos/forms.dto';
import { isChoiceField } from 'src/app/private/shared/helpers/forms.utiltiies';
import { FormFieldAbstractComponent } from '../base/edit-field-abstract';

@Component({
  selector: 'app-form-edit-field',
  templateUrl: './form-edit-field.component.html',
  styleUrls: ['./form-edit-field.component.scss'],
})
export class FormEditFieldComponent implements AfterViewInit, OnInit {
  @ViewChild('field') field: FormFieldAbstractComponent;

  @Input() type: FieldType;

  @Input() inputData: Field;

  @Input() formStatus: FormStatus = FormStatus.DRAFT;

  @Output() outputData = new ReplaySubject<Field>();

  FormStatuses = FormStatus;

  fieldData: Field;

  fieldTypes = FieldType;

  fieldWithChoicesData: FieldWithChoices;

  ngOnInit(): void {
    if (isChoiceField(this.type)) {
      this.fieldWithChoicesData = this.inputData as FieldWithChoices;
    } else {
      this.fieldData = this.inputData as Field;
    }
  }

  ngAfterViewInit(): void {
    this.fieldUpdate();
    this.field.dataChange.subscribe((data) => {
      if (isChoiceField(this.type)) {
        this.fieldWithChoicesData = data as FieldWithChoices;
      } else {
        this.fieldData = data;
      }
      this.fieldUpdate();
    });
  }

  fieldUpdate() {
    if (isChoiceField(this.type)) {
      this.outputData.next(this.fieldWithChoicesData);
    } else {
      this.outputData.next(this.fieldData);
    }
  }
}
