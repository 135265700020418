<ng-container *ngIf="competency">
  <h2 class="text-center competency-title mt-3">{{ competency.title }}</h2>
  <div class="row justify-content-center mt-3">
    <div class="col-lg-8">
      <p class="text-center">{{ competency.content }}</p>
    </div>
  </div>
  <ng-content></ng-content>
  <ng-container *ngFor="let strand of competency.indicatorSet.strands">
    <app-leveled-competency-strand
      [strand]="strand"
      [rubricId]="rubricId"
      [userId]="userId"
      [logId]="logId"
      [competencyId]="competency.id"></app-leveled-competency-strand>
  </ng-container>
</ng-container>
