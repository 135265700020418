<div class="container">
  <main role="main" class="form-signin w-100 m-auto text-center">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="text-center">
        <img
          id="groweLabLogo"
          src="assets/logo_dark.png"
          class="my-3"
          width="240"
          alt="GroweLab Logo"
          @delayfadeInOut />
      </div>
      <div @fadeInOut>
        <h1 class="h5 mb-3 fw-normal">Please sign in</h1>
        <ng-container *ngIf="errorMessage">
          <div class="alert alert-danger text-start" role="alert">
            {{ errorMessage }}
          </div>
        </ng-container>
        <div class="form-floating mb-2">
          <input
            type="email"
            formControlName="username"
            class="form-control"
            id="floatingInput"
            placeholder="name@example.com"
            [ngClass]="{ 'is-invalid': submitted && f['username'].errors }" />
          <label for="floatingInput">Username</label>
        </div>
        <div class="form-floating">
          <input
            type="password"
            formControlName="password"
            class="form-control"
            id="floatingPassword"
            placeholder="Password"
            [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
          <label for="floatingPassword">Password</label>
        </div>
        <div class="my-3 text-end">
          <a
            href="/forgot-password"
            id="forgotPasswordLink"
            class="text-link-color"
            >Forgot Password</a
          >
        </div>
        <button
          [disabled]="loading"
          class="w-100 btn btn-lg btn-primary mb-2"
          type="submit">
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"></span>
          Sign in
        </button>
        <hr />
        <div
          id="g_id_onload"
          [attr.data-client_id]="growelabGoogleClientId"
          data-context="signin"
          data-ux_mode="popup"
          data-callback="handleGSIResponse"
          data-auto_prompt="false"></div>
        <div
          class="g_id_signin"
          data-type="standard"
          data-shape="rectangular"
          data-theme="filled_blue"
          data-text="signin_with"
          data-size="large"
          data-logo_alignment="left"
          data-width="300"></div>
        <div class="loginWithClever mt-3">
          <a href="{{ liwcLink }}"
            ><img
              src="/assets/liwc.png"
              alt="Log in with Clever"
              title="Log in with Clever"
          /></a>
        </div>
      </div>
    </form>
    <div class="small mt-5">
      © 2023 engage2learn. All rights reserved.
      <a
        href="legal/privacy-policy"
        aria-label="link to engage2learn privacy policy"
        >Privacy</a
      >
      |
      <a href="legal/terms" aria-label="link to engage2learn terms of use"
        >Terms</a
      >
    </div>
  </main>
</div>
