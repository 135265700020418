<div class="form-group wysiwyg my-5 rounded">
  <label
    class="form-label text-center d-block pt-1 pb-2 fs-17 fw-bold"
    [for]="id"
    >{{ fieldDefinition.label }}</label
  >
  <app-wysiwyg-editor
    (editorContentEvent)="this.value.emit($event)"
    [config]="editorConfig" />
</div>
