import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs';
import { PusherService } from 'src/app/private/shared/services/pusher/pusher.service';
import { FetchUser } from '../../state/user/user.state';
import { AuthResponse } from '../../types/responses/responses-template';
import { AuthToken } from '../../types/types';
import { APICoreService } from '../api-core/api-core.service';
import { StorageService } from '../storage/storage.service';

/* eslint-disable */

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private apiService: APICoreService,
    private store: Store,
    private router: Router,
    private pusherService: PusherService
  ) {}

  login(username: string, password: string) {
    return this.apiService
      .postRequest('auth/login', { username, password })
      .pipe(
        tap((res) => {
          this.setSession(res);
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  impersonateUser(emailAddress: string) {
    return (
      this.apiService
        .postRequest('auth/impersonate', { emailAddress })
        .subscribe((res) => {
          StorageService.storeItem('isImpersonatingUser', true);
          this.pusherService.deinitialize();
          StorageService.storeItem(
            'originalAuthToken',
            StorageService.getItem('authToken')
          );
          this.setSession(res, true);
        }),
      catchError((err) => {
        throw err;
      })
    );
  }

  stopImpersonatingUser() {
    StorageService.storeItem('isImpersonatingUser', false);
    this.pusherService.deinitialize();
    StorageService.storeItem(
      'authToken',
      StorageService.getItem('originalAuthToken')
    );
    StorageService.removeItem('originalAuthToken');
    this.pusherService.initialize();
    this.store.dispatch(FetchUser).subscribe(() => {
      location.reload();
      const user = this.store.selectSnapshot((state) => state.user.user);
      this.router.navigate([`/dashboard/${user.type}`]);
    });
  }

  googleLogin(googleToken: string) {
    return this.apiService
      .postRequest('auth/growelab-google-login', { token: googleToken })
      .pipe(
        tap((res) => {
          this.setSession(res);
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  cleverLogin(cleverCode: string) {
    return this.apiService
      .postRequest('auth/growelab-clever-login', { code: cleverCode })
      .pipe(
        tap((res) => {
          this.setSession(res);
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  private setSession(authResult: AuthResponse, reloadAfterDispatch = false) {
    const expiresAt = authResult.expires * 1000;
    const authToken: AuthToken = {
      token: authResult.id_token,
      expiresAt,
    };
    StorageService.storeItem('authToken', authToken);
    this.pusherService.initialize();
    this.store.dispatch(FetchUser).subscribe(() => {
      const user = this.store.selectSnapshot((state) => state.user.user);
      this.router.navigate([`/dashboard/${user.type}`]);
      if (reloadAfterDispatch) {
        window.setTimeout(() => location.reload(), 0);
      }
    });
  }

  logout() {
    StorageService.storeItem('isImpersonatingUser', false);
    StorageService.removeItem('authToken');
    StorageService.removeItem('user');
    this.router.navigate(['/login']);
    location.reload();
  }

  getExpiration() {
    let expiration = StorageService.getItem('authToken').expiresAt;
    if (expiration == null) {
      expiration = '0';
    }
    return expiration;
  }

  resetPasswordRequest(emailAddress: string) {
    const emailParam = { email: emailAddress };
    return this.apiService.getRequest(
      'growelab/auth/send-password-reset',
      emailParam
    );
  }
}
