import { Injectable } from '@angular/core';
import {
  Subject,
  debounceTime,
  switchMap,
  shareReplay,
  Observable,
  map,
} from 'rxjs';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { SchoolSearchResponse } from 'src/app/common/types/responses/responses';

@Injectable()
export class SchoolSearchService {
  items: Observable<SchoolDTO[]>;

  currentResponse: SchoolSearchResponse;

  districtId: number;

  private term: Subject<string> = new Subject<string>();

  constructor(private apiService: APICoreService) {
    this.items = this.term.pipe(
      debounceTime(250),

      switchMap((term: string) =>
        this.findSchools(term, 10000, 1, this.districtId)
      ),
      shareReplay(1)
    );
  }

  search(term: string, districtId = 0) {
    /**
     * Set the value which the search stream will work.
     */
    this.districtId = districtId;
    return this.term.next(term);
  }

  complete(): void {
    this.term.complete();
  }

  public findSchools(
    searchTerm = '',
    per_page = 20,
    page = 1,
    districtId = 0
  ): Observable<SchoolDTO[]> {
    const params: {
      search?: string;
      page: number;
      per_page: number;
      district_id?: number;
    } = {
      page,
      per_page,
    };

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (districtId) {
      params.district_id = districtId;
    }

    return this.apiService.getRequest('schools', params).pipe(
      map((response) => {
        this.currentResponse = {
          schools: response.items,
          _meta: response._meta,
          _links: response._links,
        };
        return response.items;
      })
    );
  }

  public getAllSchools(districtId = 0): Observable<SchoolDTO[]> {
    const params: {
      page: number;
      per_page: number;
      district_id?: number;
    } = {
      page: 1,
      per_page: 300,
    };
    if (districtId) {
      params.district_id = districtId;
    }

    return this.apiService.getRequest('schools', params).pipe(
      map((response) => {
        this.currentResponse = {
          schools: response.items,
          _meta: response._meta,
          _links: response._links,
        };
        return response.items;
      })
    );
  }

  public getDistrictSchools(districtId: number): Observable<SchoolDTO[]> {
    return this.apiService
      .getRequest(`schools?page=1&per_page=10000&district_id=${districtId}`)
      .pipe(
        map((response) => {
          this.currentResponse = {
            schools: response.items,
            _meta: response._meta,
            _links: response._links,
          };
          return response.items;
        })
      );
  }

  getSchool(id: number): Observable<SchoolDTO | null> {
    return this.apiService
      .getRequest(`schools/${id}`)
      .pipe(map((response) => response.item));
  }
}
