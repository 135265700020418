import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Subscription } from 'rxjs';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  checkIfShadower,
  checkPresentAttendee,
} from 'src/app/common/utilities/session-helpers';
import { ViewFormComponent } from 'src/app/private/shared/components/forms/view/view-form/view-form.component';
import {
  CoachingSessionAttendeeDTO,
  CoachingSessionDTO,
} from 'src/app/private/shared/dtos/coaching-session.dto';
import {
  Form,
  FormStatus,
  FormSubmissionStatus,
  FormType,
  ObservationFormSubmission,
  ObservationFormSubmissionRawData,
} from 'src/app/private/shared/dtos/forms.dto';
import { CoachingSessionUserType } from 'src/app/private/shared/enums/coaching-session-user-type.enum';
import { checkSessionDataType } from 'src/app/private/shared/helpers/coachee-log.utilities';
import { UserPusherService } from 'src/app/private/shared/services/pusher/user.pusher.service';
import { FetchSessionData } from 'src/app/private/shared/state/coaching-log/coaching-log.actions';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';

import { FormsService } from '../../../shared/services/forms/forms.service';

@Component({
  selector: 'app-observation-form',
  templateUrl: './observation-form-page.component.html',
  styleUrls: ['./observation-form-page.component.scss'],
})
export class ObservationFormPageComponent implements OnInit, OnDestroy {
  @ViewChild('formView') formView: ViewFormComponent;

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO;

  attendeeUsers: UserDTO[] = [];

  hasPresentAttendee = false;

  isCoach = false;

  isShadower = false;

  isLoading = true;

  loadStatus = {
    forms: false,
    sessionData: false,
    submissions: false,
  };

  user: User;

  sessionId: number;

  formsList: Form[];

  selectedAttendee: CoachingSessionAttendeeDTO;

  selectedForm: Form | null = null;

  formLocked = false;

  submissions: ObservationFormSubmission[] = [];

  SubmissionStatuses = FormSubmissionStatus;

  submissionStatus: FormSubmissionStatus = FormSubmissionStatus.NEW;

  createSubmissionData: ObservationFormSubmissionRawData;

  currentSubmission: ObservationFormSubmission;

  formSaving = false;

  promptSave: Subscription;

  constructor(
    private route: ActivatedRoute,
    private formsService: FormsService,
    private store: Store,
    private router: Router,
    private userPusherService: UserPusherService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.route.params.subscribe((url) => {
      this.sessionId = parseInt(url['logId']);
      this.store.dispatch(new FetchSessionData(this.sessionId));

      this.formsService.isLiveFormSubmission = true;
      this.formsService.setCoachlog(this.sessionId);
      this.formsService
        .getFormSubmissionsFromCoachingLog(this.sessionId)
        .subscribe((submissions) => {
          this.submissions = submissions;
          this.loadStatus.submissions = true;
          this.checkDataLoaded();
        });
      this.sessionData$
        .pipe(map((filterFn) => filterFn(this.sessionId)))
        .pipe(
          map((sessionData) => {
            /* Session data will not meaningfully change after it's called; however, assessments will get added to state,
            causing a too-early redraw if we just set it every time. Thus, we check if this.sessionData is not yet set */
            if (sessionData && !this.sessionData) {
              this.sessionData = sessionData;
              checkSessionDataType(sessionData, 'observation', this.router);

              this.sessionData.attendees = this.sessionData.attendees.map(
                (attendee) => {
                  this.userPusherService.addChannel(
                    `growth-${attendee.userId}`
                  );
                  return {
                    ...attendee,
                    fullName: `${attendee.user.profile.first_name} ${attendee.user.profile.last_name}`,
                  };
                }
              );
              this.formsService
                .loadForms(this.sessionData.district.id)
                .subscribe((forms) => {
                  this.formsList = forms.filter(
                    (form) =>
                      form.type === FormType.VISIT &&
                      form.status === FormStatus.PUBLISHED
                  );
                  this.loadStatus.forms = true;
                  this.checkDataLoaded();
                });
              this.loadStatus.sessionData = true;
              this.hasPresentAttendee = checkPresentAttendee(this.sessionData);
              this.isCoach =
                this.sessionData.currentUsersRole ===
                CoachingSessionUserType.COACH;
              this.isShadower =
                checkIfShadower(this.sessionData) ||
                this.user.roles.includes(UserRole.E2L_EMPLOYEE);
              this.checkDataLoaded();
            }
          })
        )
        .subscribe();
      this.promptSave = this.formsService.promptSave.subscribe((save) => {
        if (save) {
          this.submitForm();
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.formsService.isLiveFormSubmission = false;
    this.promptSave.unsubscribe();
  }

  checkDataLoaded() {
    if (
      this.loadStatus.forms &&
      this.loadStatus.sessionData &&
      this.loadStatus.submissions
    ) {
      if (this.isCoach || this.isShadower) {
        this.attendeeSelected(this.sessionData.attendees[0]);
      } else {
        const attendeeIdx = this.sessionData.attendees.findIndex(
          (attendee) => attendee.userId === this.user.id
        );
        if (attendeeIdx > -1) {
          this.attendeeSelected(this.sessionData.attendees[attendeeIdx]);
        }
      }
      this.isLoading = false;
    }
  }

  attendeeSelected(user: CoachingSessionAttendeeDTO) {
    this.selectedAttendee = user;
    this.formsService.assessedUser = user.user;
    if (this.submissions && this.submissions.length > 0) {
      const submissionIdx = this.submissions.findIndex(
        (submission) =>
          submission.observedUserId === this.selectedAttendee.userId
      );
      if (submissionIdx > -1) {
        this.currentSubmission = this.submissions[submissionIdx];
        this.selectedForm = this.formsList.find(
          (form) => form.id === this.currentSubmission.formId
        ) as Form;
        this.formLocked = true;
        this.submissionStatus = FormSubmissionStatus.SUBMITTED;
        if (this.formView) {
          this.formView.submissionStatus = this.submissionStatus;
          this.formView.submissionData = this.currentSubmission;
          this.formView.ngOnInit();
        }
        return;
      }
    }
    this.submissionStatus = FormSubmissionStatus.NEW;
    this.createNewSubmission();
    if (this.formView) {
      this.formView.submissionStatus = this.submissionStatus;
      delete this.formView.submissionData;
      this.formView.ngOnInit();
    }
  }

  createNewSubmission() {
    this.createSubmissionData = {
      formId: this.selectedForm?.id || 0,
      submitterUser: this.user,
      submissionData: [],
      coachingLogId: this.sessionId,
      observedUserId: this.selectedAttendee.userId,
    };
  }

  submitForm() {
    this.formLocked = true;
    this.formSaving = true;
    if (this.submissionStatus === FormSubmissionStatus.NEW) {
      const sub = this.formsService
        .createFormSubmission(this.createSubmissionData)
        .subscribe((submission) => {
          this.submissions.push(submission);
          this.formSaving = false;
          this.attendeeSelected(this.selectedAttendee);
          this.formsService.repositionFormOnSave = true;
          sub.unsubscribe();
        });
    } else {
      const sub = this.formsService
        .updateFormSubmission(this.currentSubmission)
        .subscribe((submission) => {
          const idx = this.submissions.findIndex((s) => s.id === submission.id);
          if (idx > -1) {
            this.submissions[idx] = submission;
          }
          this.formSaving = false;
          sub.unsubscribe();
        });
    }
  }

  navigatePreviousScreen() {
    this.router.navigate(['../info'], { relativeTo: this.route });
  }

  navigateNextScreen() {
    this.submitForm();
    this.router.navigate(['../summary'], { relativeTo: this.route });
  }

  updateFormSelect(form: Form) {
    this.selectedForm = form;
    if (this.submissionStatus === FormSubmissionStatus.NEW) {
      this.createSubmissionData.formId = form.id;
    } else {
      this.currentSubmission.formId = form.id;
    }
  }

  updateSubmission(formData: ObservationFormSubmissionRawData) {
    if (this.submissionStatus === FormSubmissionStatus.NEW) {
      this.createSubmissionData.submissionData = formData.submissionData;
    } else {
      this.currentSubmission = {
        ...this.currentSubmission,
        ...formData,
      };
    }
  }
}
