import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Subscription } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  checkIfCoach,
  checkIfShadower,
  checkPresentAttendee,
} from 'src/app/common/utilities/session-helpers';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import { DatepickerOutput } from 'src/app/private/shared/components/datepicker/datepicker.component';
import { CelebrationDTO } from 'src/app/private/shared/dtos/celebrations.dto';
import {
  CoachingSessionAttendeeDTO,
  CoachingSessionDTO,
} from 'src/app/private/shared/dtos/coaching-session.dto';
import { CelebrationsService } from 'src/app/private/shared/services/celebrations/celebrations.service';
import { CoachingLogService } from 'src/app/private/shared/services/coaching-log/coaching-log.service';
import { FetchSessionData } from 'src/app/private/shared/state/coaching-log/coaching-log.actions';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';
import {
  AbsentReasonsAPIResponse,
  CoachlogTypesAPIResponse,
} from 'src/app/private/shared/types/responses/coaching-log.responses';

@Component({
  selector: 'app-general-log-info-page',
  templateUrl: './general-log-info-page.component.html',
  styleUrls: ['./general-log-info-page.component.scss'],
})
export class GeneralLogInfoPageComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  sessionId: number;

  sessionData$ = select(CoachingLogState.getSessionData);

  user$ = select(UserState.getUser);

  user: User;

  sessionData: CoachingSessionDTO | null;

  coachlogTypesList: CoachlogTypesAPIResponse[];

  absentReasonsList: AbsentReasonsAPIResponse[];

  isCoach: boolean;

  isShadower: boolean;

  logStart: number;

  logEnd: number;

  logDuration = 1800;

  timezone: TimezoneLinkBack;

  durationList = [
    { value: 900, label: '15 minutes' },
    { value: 1800, label: '30 minutes' },
    { value: 2700, label: '45 minutes' },
    { value: 3600, label: '1 hour' },
  ];

  hasPresentAttendee = false;

  celebrations: CelebrationDTO[] | null = null;

  constructor(
    private route: ActivatedRoute,
    public coachingService: CoachingLogService,
    private router: Router,
    private celebrationService: CelebrationsService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.route.params.subscribe((url) => {
      this.sessionId = parseInt(url['logId']);
      this.store.dispatch(new FetchSessionData(this.sessionId));
    });

    this.celebrationService.getCelebrations().subscribe((res) => {
      if (res) {
        if (res.length > 6) {
          this.celebrations = res.slice(0, 6);
        } else {
          this.celebrations = res;
        }
      }
    });

    this.sessionData$
      .pipe(map((filterFn) => filterFn(this.sessionId)))
      .pipe(
        map((sessionData) => {
          if (sessionData) {
            this.sessionData = sessionData;

            this.isCoach = checkIfCoach(this.sessionData);
            this.isShadower = checkIfShadower(this.sessionData);
            this.hasPresentAttendee = checkPresentAttendee(this.sessionData);

            this.logStart = this.sessionData.startDatetime;
            this.logEnd = this.sessionData.endDatetime;
            const seconds = this.logEnd - this.logStart;
            const roundedSeconds = Math.round(seconds / 900) * 900;
            /* eslint-disable no-nested-ternary */
            this.logDuration =
              roundedSeconds !== 0
                ? roundedSeconds > 3600
                  ? 3600
                  : roundedSeconds
                : 900;
            this.timezone = this.sessionData.timezone as TimezoneLinkBack;
          }
        })
      )
      .subscribe();
  }

  onCheckboxChange($event: Event): void {
    const target = $event.target as HTMLInputElement | null;
    const coachlogUpdates = {
      is_private: false,
    };

    if (target) {
      if (target.checked === true) {
        coachlogUpdates.is_private = true;
      }
    }

    if (this.sessionData) {
      this.coachingService.updateCoachingLog(
        this.sessionData?.id,
        coachlogUpdates
      );
    }
  }

  addCoachee(newCoachee: UserDTO | null) {
    if (newCoachee) {
      this.coachingService.addAttendee(newCoachee.id, this.sessionData?.id);
    }
  }

  removeCoachee(coachee: CoachingSessionAttendeeDTO) {
    if (this.sessionData) {
      this.sessionData.attendees = this.sessionData.attendees.filter(
        (attendee) => attendee.id !== coachee.id
      );
    }
  }

  addShadower(newShadower: UserDTO | null) {
    if (newShadower) {
      const isCoachee = this.sessionData?.attendees.find(
        (item) => item.userId === newShadower.id
      );
      if (!isCoachee) {
        this.coachingService.addShadower(newShadower.id, this.sessionData?.id);
      }
    }
  }

  saveTitle() {
    if (this.sessionData) {
      const coachlogUpdates = { title: this.sessionData?.title };
      this.coachingService.updateCoachingLog(
        this.sessionData?.id,
        coachlogUpdates
      );
    }
  }

  saveDateTime() {
    if (this.sessionData) {
      this.logEnd = this.logStart + this.logDuration;
      this.coachingService.updateCoachingLog(this.sessionData.id, {
        start_datetime: this.logStart,
        end_datetime: this.logEnd,
        timezone: this.timezone,
      });
    }
  }

  updateDateTime(timeUpdate: DatepickerOutput) {
    this.logStart = timeUpdate.time / 1000;
    this.timezone = timeUpdate.timezone;
    this.saveDateTime();
  }

  updateDuration(duration: number) {
    this.logDuration = duration;
    this.saveDateTime();
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
