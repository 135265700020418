import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

export enum TimezoneAbbreviation {
  EST = 'US/Eastern',
  EDT = 'US/Eastern',
  CST = 'US/Central',
  CDT = 'US/Central',
  MST = 'US/Mountain',
  MDT = 'US/Mountain',
  PST = 'US/Pacific',
  PDT = 'US/Pacific',
}

export const getTimezoneAbbreviationValue = (
  tz: TimezoneAbbreviation
): string =>
  Object.values(TimezoneAbbreviation)[
    Object.keys(TimezoneAbbreviation).indexOf(tz)
  ];

/**
 * Replacement for moment.tz.guess() that works in the
 *  browser by parsing the date string for a timezone,
 * finds the abreviation and returns from the enum
 */
export const guessTimezone = (dateInput?: Date): TimezoneAbbreviation => {
  const dateObject = dateInput || new Date();
  const dateString = `${dateObject}`;
  /* eslint-disable no-useless-escape */
  // skipcq: JS-0097
  const tzMatches: RegExpMatchArray | null = dateString.match(/\(([^\)]+)\)$/);
  if (tzMatches && tzMatches[1]) {
    return TimezoneAbbreviation[
      (tzMatches[1] as string)
        .match(/[A-Z]/g)
        ?.join('') as keyof typeof TimezoneAbbreviation
    ];
  }
  return TimezoneAbbreviation.CST;
};

export type Timezone = {
  label: string;
  regional: string;
  linkBack: string;
};

export enum TimezoneLinkBack {
  Alaska = 'US/Alaska',
  Aleutian = 'US/Aleutian',
  Arizona = 'US/Arizona',
  Central = 'US/Central',
  EastIndiana = 'US/East-Indiana',
  Eastern = 'US/Eastern',
  Hawaii = 'US/Hawaii',
  IndianaStarke = 'US/Indiana-Starke',
  Michigan = 'US/Michigan',
  Mountain = 'US/Mountain',
  Pacific = 'US/Pacific',
  Samoa = 'US/Samoa',
}

export const getTimezoneLinkBackFromValue = (
  tzString: string
): TimezoneLinkBack =>
  Object.values(TimezoneLinkBack)[
    Object.keys(TimezoneLinkBack).indexOf(
      tzString as keyof typeof TimezoneLinkBack
    )
  ];

export const timezones: Timezone[] = [
  {
    label: 'Alaska Time',
    regional: 'America/Anchorage',
    linkBack: TimezoneLinkBack.Alaska,
  },
  {
    label: 'Aleutian Time',
    regional: 'America/Adak',
    linkBack: TimezoneLinkBack.Aleutian,
  },
  {
    label: 'Arizona Time',
    regional: 'America/Phoenix',
    linkBack: TimezoneLinkBack.Arizona,
  },
  {
    label: 'Central Time',
    regional: 'America/Chicago',
    linkBack: TimezoneLinkBack.Central,
  },
  {
    label: 'Indiana Time',
    regional: 'America/Indiana/Indianapolis',
    linkBack: TimezoneLinkBack.EastIndiana,
  },
  {
    label: 'Eastern Time',
    regional: 'America/New_York',
    linkBack: TimezoneLinkBack.Eastern,
  },
  {
    label: 'Hawaii Time',
    regional: 'Pacific/Honolulu',
    linkBack: TimezoneLinkBack.Hawaii,
  },
  {
    label: 'Indiana-Starke Time',
    regional: 'America/Indiana/Knox',
    linkBack: TimezoneLinkBack.IndianaStarke,
  },
  {
    label: 'Michigan Time',
    regional: 'America/Detroit',
    linkBack: TimezoneLinkBack.Michigan,
  },
  {
    label: 'Mountain Time',
    regional: 'America/Denver',
    linkBack: TimezoneLinkBack.Mountain,
  },
  {
    label: 'Pacific Time',
    regional: 'America/Los_Angeles',
    linkBack: TimezoneLinkBack.Pacific,
  },
  {
    regional: 'Pacific/Pago_Pago',
    linkBack: TimezoneLinkBack.Samoa,
    label: 'Samoa Time',
  },
];

export const getTzRegionFromLinkBack = (tz: TimezoneLinkBack): string =>
  timezones.filter((t) => t.linkBack === tz)[0].regional;
// Used to convert timezones received from data warehouse into timezone format that API uses
export function convertTimeZone(regional: string): string {
  const convertedTimezone = timezones.find(
    (timezone) => timezone.regional === regional
  );
  if (convertedTimezone) {
    return convertedTimezone.linkBack;
  }
  return regional;
}

export const timeStructFromDate = (date: Date): NgbTimeStruct => ({
  hour: date.getHours(),
  minute: date.getMinutes(),
  second: date.getSeconds(),
});
