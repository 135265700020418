import { Router } from '@angular/router';

import { GoalDTO, StrandDTO } from '../dtos/attendee-rubric.dto';
import { CoachingSessionDTO } from '../dtos/coaching-session.dto';

export const updateStrandWithNewGoal = (
  strand: StrandDTO,
  goal: GoalDTO
): StrandDTO => {
  strand.goalLevel = goal.goalLevel;
  strand.level1.goal = null;
  strand.level2.goal = null;
  strand.level3.goal = null;
  strand.level4.goal = null;
  /* eslint-disable-next-line default-case */
  switch (goal.goalLevel) {
    case 1:
      strand.level1.goal = goal;
      break;
    case 2:
      strand.level2.goal = goal;
      break;
    case 3:
      strand.level3.goal = goal;
      break;
    case 4:
      strand.level4.goal = goal;
      break;
  }
  return strand;
};

export const getSessionLink = (session: CoachingSessionDTO): string | null => {
  switch (session.type.view) {
    case 'egrowe-coachlog-v2':
      return `/coaching/log/${session.id}/info`;
    case 'universal-log':
      return `/coaching/log/${session.id}/info`;
    case 'observation':
      return `/observation/log/${session.id}/info`;
    case 'general-log':
      return `/general/log/${session.id}/info`;
    default:
      return null;
  }
};

export const checkSessionDataType = (
  sessionData: CoachingSessionDTO,
  type: string,
  router: Router
): void => {
  /* eslint-disable-next-line default-case */
  switch (sessionData.type.title) {
    case 'Coaching Conversation':
      if (type !== 'coaching') {
        router.navigate([`/coaching/log/${sessionData.id}`]);
      }
      break;
    case 'Observation and Feedback':
      if (type !== 'observation') {
        router.navigate([`/observation/log/${sessionData.id}`]);
      }
      break;
  }
};
