import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-growelab-layout',
  templateUrl: './growelab-layout.component.html',
  styleUrls: ['./growelab-layout.component.scss'],
})
export class GrowelabLayoutComponent {
  navExpanded = false;

  isImpersonatingUser = false;

  constructor(private store: Store) {
    this.isImpersonatingUser = this.store.selectSnapshot(
      (state) => state.user.impersonated
    );
  }

  toggleNav() {
    this.navExpanded = !this.navExpanded;
  }
}
