import { LogTypeDTO } from '../../../../common/dtos/log-type.dto';

export interface LogTypeSelectDto {
  id: number;
  name: string;
}

export const LogTypeSelectDTOFromLogTypeDTO = (
  log_type: LogTypeDTO
): LogTypeSelectDto => ({
  id: log_type.id,
  name: log_type.title,
});
