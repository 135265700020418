<div class="container">
  <main role="main" class="form-forgot-password w-100 m-auto">
    <div class="text-center">
      <img
        id="groweLabLogo"
        src="assets/logo_dark.png"
        class="my-4"
        width="240"
        alt="GroweLab Logo" />
      <h1 class="h2" @fadeInOut>Reset password</h1>
      <!-- <ng-container *ngIf="errorMessage">
        <div class="alert alert-danger text-start" role="alert">
          {{ errorMessage }}
        </div>
      </ng-container> -->
    </div>
    <ng-container *ngIf="!successful; else successMessage">
      <div @delayfadeInOut>
        <p class="text-center my-4 fs-17">
          Enter your email and we'll send you<br />
          a link to reset your password
        </p>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="sendEmail()">
          <div class="input-group input-group-lg">
            <input
              formControlName="email"
              class="form-control rounded-start"
              type="text"
              aria-label="email"
              placeholder="name@example.com"
              [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
              (keydown.enter)="sendEmail()" />
            <button
              aria-label="Send email address"
              class="btn btn-primary"
              (click)="sendEmail()"
              type="submit"
              [disabled]="loading">
              <span
                *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"></span>
              Send
            </button>
          </div>
          <div *ngIf="submitted && f['email'].errors">
            <div *ngIf="f['email'].errors['required']" class="text-danger m-2">
              Email is required
            </div>
            <div *ngIf="f['email'].errors['pattern']" class="text-danger m-2">
              Email must be a valid email address
            </div>
          </div>
        </form>
      </div>
    </ng-container>
    <ng-template #successMessage>
      <div @delayfadeInOut>
        <p class="fs-18 my-5 text-center">
          Password reset requested. Check your email for further instructions.
        </p>
        <div class="my-4 d-flex justify-content-between">
          <a href="/login" class="text-link-color"
            ><i class="bi bi-arrow-left-circle"></i> Return to login</a
          >
          <a
            class="text-link-color"
            href="https://e2l.atlassian.net/servicedesk/customer/portal/8"
            target="_blank"
            ><i role="img" aria-label="Help" class="bi bi-question-circle"></i>
            Need assistance?</a
          >
        </div>
      </div>
    </ng-template>
  </main>
</div>
