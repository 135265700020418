import { Component, OnInit } from '@angular/core';
import { ProfileDTO } from 'src/app/common/dtos/profile.dto';
import { EvidenceAssessedDTO } from 'src/app/common/types/responses/responses';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { EvidenceDTO } from '../../../dtos/attendee-rubric.dto';
import { EvidenceService } from '../../../services/evidence/evidence.service';

@Component({
  selector: 'app-card-evidence-for-review',
  templateUrl: './card-evidence-for-review.component.html',
  styleUrls: ['./card-evidence-for-review.component.scss'],
})
export class CardEvidenceForReviewComponent implements OnInit {
  isCollapsed = this.cardStorageService.getCollapsedState('evidenceforreview');

  assignedUsersEvidence: UserEvidenceDTO[];

  constructor(
    private evidenceService: EvidenceService,
    private cardStorageService: CardStorageService
  ) {}

  ngOnInit(): void {
    this.evidenceService.getCoacheeEvidenceList().subscribe((response) => {
      this.assignedUsersEvidence = [];
      response.forEach((item) => {
        item.wills.forEach((will) => {
          will.evidence.forEach((evidence) => {
            const evidenceObject = {} as UserEvidenceDTO;
            evidenceObject.profile = item.user.profile;
            evidenceObject.evidence = evidence;
            this.assignedUsersEvidence.push(evidenceObject);
          });
        });
      });
      this.assignedUsersEvidence.sort((a, b) => {
        if (a.evidence.createdAt && b.evidence.createdAt) {
          return a.evidence.createdAt - b.evidence.createdAt;
        }
        return 0;
      });
    });
  }

  removeEvidence(response: EvidenceAssessedDTO) {
    this.assignedUsersEvidence = this.assignedUsersEvidence.filter(
      (x) => x.evidence.id !== response.egrowe_evidence_id
    );
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'evidenceforreview',
      this.isCollapsed
    );
  }
}

interface UserEvidenceDTO {
  evidence: EvidenceDTO;
  profile: ProfileDTO;
}
