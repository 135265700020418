import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterState } from '@angular/router';
import { Store } from '@ngxs/store';
import { FetchUser, UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';

import { EnvironmentService } from './common/services/environment/environment.service';
import { gtag } from './common/services/google-analytics/gtag';
import { StorageService } from './common/services/storage/storage.service';
import { TemplatePageTitleService } from './common/services/template-page-title/template-page-title.service';
import { GLEnvironment } from './common/types/env';
import { DomElement } from './common/utilities/dom-element';
import { UserPusherService } from './private/shared/services/pusher/user.pusher.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'growelab-web';

  user$ = select(UserState.getUser);

  constructor(
    private store: Store,
    private router: Router,
    private titleService: TemplatePageTitleService,
    private userPusherService: UserPusherService
  ) {}

  /* eslint-disable-next-line class-methods-use-this */
  addScriptsByEnvironment() {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    (window as any).environment = EnvironmentService.env() as string;

    document.head.appendChild(
      DomElement.create(
        'link[rel="stylsheet"][href="https://cdn1.codox.io/lib/2.x/css/wave.client.css"]'
      )
    );
    /* eslint-disable-next-line default-case */
    switch (EnvironmentService.env()) {
      case GLEnvironment.DEMO:
        document.head.appendChild(
          DomElement.create(
            `script[async][src="https://www.clarity.ms/tag/${EnvironmentService.clarityID()}"]`
          )
        );
      /* eslint-disable-next-line no-fallthrough */
      case GLEnvironment.QA:
      case GLEnvironment.STAGING:
      case GLEnvironment.PRODUCTION:
        /* eslint-disable-next-line no-case-declarations */
        const pendoLoader = DomElement.create('script');
        pendoLoader.innerHTML = `(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');})('d3644c12-2832-4a8c-7b1e-e960e3230e90');`;
        document.head.appendChild(pendoLoader);
        if (!StorageService.getItem('authToken')) {
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          (window as any).pendo.initialize();
        } else {
          this.user$.subscribe((user) => {
            if (user) {
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              (window as any).pendo.initialize({
                visitor: {
                  id: user.id,
                  district: user.district?.title,
                  district_id: user.district?.id,
                  school: user.school?.title,
                  school_id: user.school?.id,
                  user_type: user.coacheeType,
                },
                account: {
                  id: user.district?.id,
                  name: user.district?.title,
                },
              });
            }
          });
        }
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        (window as any).googleAnalyticsID =
          EnvironmentService.googleAnalyticsID();
        document.head.appendChild(
          DomElement.create(
            `script[async][src="https://www.googletagmanager.com/gtag/js?id=${EnvironmentService.googleAnalyticsID()}"]`
          )
        );
        document.head.appendChild(
          DomElement.create(
            'script[async][src="/assets/js/google-tag-manager.js"]'
          )
        );
        break;
    }
  }

  ngOnInit(): void {
    if (StorageService.getItem('authToken')) {
      this.store.dispatch(FetchUser);
      this.userPusherService.addChannel(StorageService.getItem('user').id);
    }
    this.addScriptsByEnvironment();
    this.handleRouteEvents();
  }

  handleRouteEvents() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(this.router.routerState);

        gtag('event', 'page_view', {
          page_title: title,
        });
      }
    });
  }

  getTitle(state: RouterState): string {
    const data = this.titleService.updateTitle(state.snapshot);
    return data.getTitle();
  }
}
