import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject, Subscription, map } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { DistrictDTO } from '../../../../common/dtos/district.dto';
import { getIdsFromDefaultItemsForSelect } from '../../helpers/select.utilities';
import { DistrictSearchService } from '../../services/district-search/district-search.service';

@Component({
  selector: 'app-district-select',
  templateUrl: './district-select.component.html',
  styleUrls: ['./district-select.component.scss'],
})
export class DistrictSelectComponent implements OnInit {
  @ViewChild('select') select: NgSelectComponent;

  districtList: Observable<DistrictDTO[]>;

  districtLibrary: DistrictDTO[] = [];

  searchInput$ = new Subject<string>();

  searchSubscription: Subscription | null;

  searchLoading = false;

  labelId = uuidv4();

  internalSelectedDistrictList: DistrictDTO[] = [];

  internalSelectedDistrict: DistrictDTO | null;

  @Input() clearAfterSelection = false;

  @Input() label = 'Search for and select a district';

  @Input() placeholder = 'Search for a district...';

  @Input() isMulti = false;

  @Input() defaultDistricts: number[] | DistrictDTO[] | null;

  @Output() selectedDistrictList: EventEmitter<DistrictDTO[]> =
    new EventEmitter();

  @Output() selectedDistrict: EventEmitter<DistrictDTO | null> =
    new EventEmitter();

  constructor(private districtSearch: DistrictSearchService) {}

  ngOnInit(): void {
    this.districtList = this.districtSearch.items.pipe(
      map((districts) => {
        this.districtLibrary = [...this.districtLibrary, ...districts];
        this.searchLoading = false;
        return districts;
      })
    );
    this.searchInput$.subscribe((term) => {
      this.searchLoading = true;
      return this.districtSearch.search(term);
    });

    if (this.defaultDistricts) {
      const districtIds = getIdsFromDefaultItemsForSelect(
        this.defaultDistricts
      );
      districtIds.forEach((districtId) => {
        this.districtSearch
          .getDistrict(districtId as number)
          .subscribe((district) => {
            if (district) {
              if (this.isMulti) {
                this.internalSelectedDistrictList.push(district);
              } else {
                this.internalSelectedDistrict = district;
              }
            }
          });
      });
    }
  }

  districtSelected(district: DistrictDTO) {
    if (this.isMulti && district) {
      if (!this.internalSelectedDistrictList.includes(district)) {
        this.internalSelectedDistrictList.push(district);
      }
      this.select.handleClearClick();
    } else {
      this.internalSelectedDistrict = district;
    }
    this.outputDistricts();
    if (this.clearAfterSelection) {
      // Without a timeout you get infinite recursion
      setTimeout(() => {
        this.select.unselect(this.select.selectedItems[0]);
      });
    }
  }

  outputDistricts() {
    if (this.isMulti) {
      this.selectedDistrictList.emit(this.internalSelectedDistrictList);
    } else {
      this.selectedDistrict.emit(this.internalSelectedDistrict);
    }
  }

  removeDistrictFromList(district: DistrictDTO) {
    this.internalSelectedDistrictList =
      this.internalSelectedDistrictList.filter(
        (item) => item.id !== district.id
      );
    this.outputDistricts();
  }
}
