import { Route } from '@angular/router';
import { SidebarRoute } from 'src/app/common/types/routes';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { ResourcesSearchComponent } from '../pages/resources-search/resources-search.component';
import { SettingsComponent } from '../pages/settings/settings.component';
import { StyleLibraryComponent } from '../pages/style-library/style-library.component';
import {
  AUTH_GUARDED_ROUTE,
  AUTH_GUARDED_ROUTE_COACH,
  AUTH_GUARDED_ROUTE_OPERATOR,
  AUTH_ROUTE_GUARDED_ROUTE,
} from '../../common/utilities/route.helpers';
import { CoachingSessionsPageComponent } from '../pages/coaching-sessions-page/coaching-sessions-page.component';
import { ReportingPageComponent } from '../pages/reporting-page/reporting-page.component';

export const sidebarRoutes: SidebarRoute[] = [
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'dashboard',
    component: DashboardComponent,
    title: 'Dashboard',
    data: {
      icon: 'grid-1x2',
      ariaLabel: 'Dashboard',
    },
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'coaching',
    component: CoachingSessionsPageComponent,
    title: 'Coaching Sessions',
    data: {
      icon: 'card-checklist',
      ariaLabel: 'Coaching Sessions',
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources',
    title: 'Resources',
    component: ResourcesSearchComponent,
    data: {
      icon: 'book',
      ariaLabel: 'Resources',
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'reporting',
    title: 'Reporting',
    component: ReportingPageComponent,
    data: {
      ...AUTH_GUARDED_ROUTE_COACH.data,
      icon: 'graph-up',
      ariaLabel: 'Reporting',
    },
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'settings',
    component: SettingsComponent,
    title: 'Settings',
    data: {
      ...AUTH_GUARDED_ROUTE_OPERATOR.data,
      icon: 'gear-wide-connected',
      ariaLabel: 'Settings',
    },
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'style-library',
    component: StyleLibraryComponent,
    title: 'Style Library',
    data: {
      icon: 'collection',
      ariaLabel: 'Style Library',
    },
  },
];

export const getSidebarRoute = (path: string): Route | undefined =>
  sidebarRoutes.find((route) => route.path === path);
