import { Component, Input, OnInit } from '@angular/core';

import { AbstractViewFieldChoicesComponent } from '../base/abstract-view-field-choices';

@Component({
  selector: 'app-form-view-checkbox',
  templateUrl: './view-checkbox.component.html',
  styleUrls: ['../view-field/form-view-field.component.scss'],
})
export class FormViewCheckboxComponent
  extends AbstractViewFieldChoicesComponent
  implements OnInit
{
  selectionArray: string[] = [];

  @Input() initialValue: string[] | null;

  changeSelection(choiceValue: string) {
    if (this.selectionArray.includes(choiceValue)) {
      this.selectionArray = this.selectionArray.filter(
        (value) => value !== choiceValue
      );
    } else {
      this.selectionArray.push(choiceValue);
    }
    this.selection = JSON.stringify(this.selectionArray);
    this.value.emit(this.selection);
  }

  ngOnInit(): void {
    if (this.initialValue) {
      this.selectionArray = this.initialValue;
    }
  }
}
