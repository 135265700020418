<div class="datepicker">
  <div [ngClass]="[timepicker ? 'time' : '']">
    <div class="input-group datepicker-popup">
      <label for="dateSelect" class="visually-hidden">Select Date</label>
      <input
        labelForId="dateSelect"
        class="form-control rounded-start"
        placeholder="M. dd, yyyy"
        ngbDatepicker
        #d="ngbDatepicker"
        [firstDayOfWeek]="0"
        [(ngModel)]="selectedDate"
        (change)="setDate()"
        (dateSelect)="setDate()"
        placement="bottom-end top-end"
        required />
      <button
        aria-label="Select date"
        class="btn color-tertiary btn-outline-secondary bi bi-calendar3"
        (click)="d.toggle()"
        type="button"></button>
    </div>
  </div>
  <div *ngIf="timepicker" [ngClass]="['d-flex', 'flex-row', 'mt-1']">
    <label for="timeSelect" class="visually-hidden">Select Time</label>
    <ng-select
      class="timeSelect flex-grow-1"
      labelForId="timeSelect"
      [(ngModel)]="selectedTime"
      [items]="availableTimes"
      (change)="setDate()"
      [clearable]="false"
      [searchable]="false"
      bindLabel="display"
      bindValue="value">
    </ng-select>
    <label for="timezoneSelect" class="visually-hidden">Select Timezone</label>
    <ng-select
      labelForId="timezoneSelect"
      class="flex-grow-1 ms-1"
      [(ngModel)]="selectedTimezone"
      [items]="timezoneList"
      [clearable]="false"
      [searchable]="false"
      (change)="setDate()"
      bindLabel="display"
      bindValue="value">
    </ng-select>
  </div>
</div>
