import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DistrictListService } from 'src/app/private/shared/services/district-list/district-list-service';
import { FormsService } from 'src/app/private/shared/services/forms/forms.service';

import { CreateFormPageComponent } from '../create-form-page/create-form-page.component';

@Component({
  selector: 'app-edit-form-page',
  templateUrl: '../form/form-page.html',
  styleUrls: ['../form/form-page.scss'],
})
export class EditFormPageComponent extends CreateFormPageComponent {
  constructor(
    public override router: Router,
    protected override store: Store,
    public override formsService: FormsService,
    protected override districtListService: DistrictListService,
    protected override route: ActivatedRoute
  ) {
    super(router, store, formsService, districtListService, route);
    this.formsService
      .loadForm(parseInt(this.router.url.split('/').pop() as string))
      .subscribe((form) => {
        if (form) {
          this.data = form;
        } else {
          this.router.navigate(['settings', 'forms']);
        }
      });
  }

  override saveForm() {
    this.isSaving = true;
    this.formsService.updateForm(this.data).subscribe(() => {
      setTimeout(() => {
        this.isSaving = false;
      }, 800);
    });
  }
}
