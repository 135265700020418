import {
  CustomerDTO,
  DistrictDTO,
  DistrictSimpleDto,
} from 'src/app/common/dtos/district.dto';
import { State } from 'src/app/common/utilities/enums/state.enum';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import {
  CustomerAPIResponse,
  DistrictAPIResponse,
  DistrictListAPIResponse,
  DistrictListSimpleAPIResponse,
  DistrictSimpleAPIResponse,
  SchoolAPIResponse,
} from '../../types/responses/district-list.responses';

const schoolDTOFromAPIResponse = (item: SchoolAPIResponse): SchoolDTO => ({
  address: item.address,
  address2: item.address2,
  counts: item.counts,
  district_id: item.district_id,
  id: item.id,
  title: item.title,
  isDeleted: item.is_deleted,
  city: item.city,
  state: <State>item.state,
  zip: item.zip,
  phone: item.phone,
});

const districtDTOFromAPIResponse = (
  item: DistrictAPIResponse
): DistrictDTO => ({
  domain: item.domain,
  logo: item.logo,
  timezone: item.timezone,
  counts: {
    users: item.counts ? item.counts.users : 0,
    schools: item.counts ? item.counts.schools : 0,
  },
  id: item.id,
  hubspotCompanyId: item.hubspot_company_id,
  districtCode: item.district_code,
  isB2b: item.is_b2b,
  isInternal: item.is_internal,
  title: item.title,
  isDeleted: item.is_deleted,
  address: item.address,
  address2: item.address2,
  city: item.city,
  state: <State>item.state,
  zip: item.zip,
  phone: item.phone,
  schools: item.schools.map((attendee) => schoolDTOFromAPIResponse(attendee)),
  reportingSchoolYear: item.reporting_school_year,
});

const districtSimpleDTOFromAPIResponse = (
  item: DistrictSimpleAPIResponse
): DistrictSimpleDto => ({
  domain: item.domain,
  logo: item.logo,
  timezone: item.timezone,
  id: item.id,
  hubspotCompanyId: item.hubspot_company_id,
  districtCode: item.district_code,
  isB2b: item.is_b2b,
  isInternal: item.is_internal,
  title: item.title,
  isDeleted: item.is_deleted,
  address: item.address,
  address2: item.address2,
  city: item.city,
  state: <State>item.state,
  zip: item.zip,
  phone: item.phone,
  reportingSchoolYear: item.reporting_school_year,
});

export const districtListDTOFromAPIResponse = (
  response: DistrictListAPIResponse
): DistrictDTO[] =>
  response.items.map((item) => districtDTOFromAPIResponse(item));

export const districtListSimpleDTOFromAPIResponse = (
  response: DistrictListSimpleAPIResponse
): DistrictSimpleDto[] =>
  response.items.map((item) => districtSimpleDTOFromAPIResponse(item));

const customerDTOFromAPIResponse = (
  item: CustomerAPIResponse
): CustomerDTO => ({
  hubspotCompanyId: item.hubspot_company_id.toString(),
  name: item.name,
  address: item.address,
  city: item.city,
  state: <State>item.state,
  zip: item.zip,
  timezone: item.timezone,
  domain: item.domain,
  description: item.description,
  isArchived: item.is_archived,
  licenseCount: item.license_count,
});

export const customerListDTOFromAPIResponse = (response: {
  items: CustomerAPIResponse[];
}): CustomerDTO[] =>
  response.items.map((item) => customerDTOFromAPIResponse(item));
