<ng-container *ngIf="fieldDefinition">
  <strong>{{ fieldDefinition.label }}</strong>
  <ng-container *ngIf="value">
    <div
      *ngIf="fieldDefinition.type === FieldTypes.TEXTBLOCK"
      [innerHtml]="value"></div>
    <div
      *ngIf="
        fieldDefinition.type !== FieldTypes.TEXTBLOCK &&
        fieldDefinition.type !== FieldTypes.MEDIA
      ">
      {{ value }}
    </div>
    <div *ngIf="fieldDefinition.type === FieldTypes.MEDIA">
      <div class="container" *ngIf="filesList">
        <app-file-list [fileList]="filesList"></app-file-list>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="fieldWithChoicesDefinition">
  <strong>{{ fieldWithChoicesDefinition.label }}</strong>
  <div *ngIf="value">{{ getOutputValue() }}</div>
</ng-container>
