<div>
  <div class="card h-100 border-0 shadow" *ngIf="levelData">
    <div
      [ngClass]="[
        'bg-level-' + level,
        'card-header',
        'text-light',
        'text-end',
        'text-nowrap',
        'align-middle'
      ]">
      Level
      <i class="bi bi-{{ level }}-circle"></i>
    </div>
    <div class="card-body p-2" tabindex="0">
      <ng-container *ngFor="let indicator of levelData.indicators">
        <app-growth-indicator
          [indicatorId]="indicator.id"
          [userId]="userId"
          [logId]="logId"
          [rubricId]="rubricId"></app-growth-indicator>
      </ng-container>
    </div>
    <div class="card-footer d-flex justify-content-between">
      <div class="d-flex">
        <button
          (click)="toggleOptions()"
          [disabled]="isLightBulbDisabled"
          aria-label="lightbulb-open-drawer-icon"
          [ngClass]="[
            'circle',
            'icon-container',
            hasOptionContent ? 'hasContent' : 'noContent',
            !isOptionCollapsed ? 'currentDrawer' : ''
          ]">
          <i
            class="bi bi-lightbulb"
            ngbTooltip="Add options"
            title="Add options"></i>
        </button>
        <!-- <button
          aria-label="open-chat-icon"
          class="icon-container"
          style="margin-left: 10px"
          disabled>
          <i [ngClass]="['bi', 'bi-chat-right-text']" title="Add a comment"></i>
        </button> -->
      </div>
      <button
        aria-label="evidence-open-drawer-icon"
        ngbTooltip="Add evidence"
        (click)="toggleEvidence()"
        [ngClass]="[
          'icon-container',
          'circle',
          !isEvidenceCollapsed ? 'currentDrawer' : '',
          hasEvidenceContent ? 'hasContent' : 'noContent'
        ]"
        [disabled]="!optionsHaveWills"
        class="icon-container">
        <i [ngClass]="['bi', 'bi-file-earmark-check']" title="Add evidence"></i>
      </button>
    </div>
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isDrawerCollapsed">
    <ng-container
      *ngIf="!isOptionCollapsed && optionsForChildComponents.length > 0">
      <ng-container
        *ngFor="let option of optionsForChildComponents; let isLast = last">
        <app-leveled-competency-strand-level-options
          [userId]="userId"
          [rubricId]="rubricId"
          [competencyId]="competencyId"
          [coachlogId]="logId"
          [level]="level"
          [levelData]="levelData"
          [strandId]="strandId"
          [details]="option"
          [drawerExpanded]="!isOptionCollapsed"
          [lastCard]="isLast">
        </app-leveled-competency-strand-level-options>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!isEvidenceCollapsed">
      <app-leveled-competency-strand-level-evidence
        [userId]="userId"
        [rubricId]="rubricId"
        [competencyId]="competencyId"
        [coachlogId]="logId"
        [level]="level"
        [levelData]="levelData"
        [strandId]="strandId"></app-leveled-competency-strand-level-evidence>
    </ng-container>
  </div>
</div>
