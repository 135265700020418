import { Component, Input } from '@angular/core';
import { ResourceLeveledListItemDTO } from '../../../dtos/resources.dto';

@Component({
  selector: 'app-resource-leveled-list',
  templateUrl: './resource-leveled-list.component.html',
  styleUrls: ['./resource-leveled-list.component.scss'],
})
export class ResourceLeveledListComponent {
  @Input() resources: ResourceLeveledListItemDTO[];

  @Input() levelNumber: number;

  limitResources = true;

  limit = 3;

  removeResource(resId: number) {
    this.resources = this.resources.filter((res) => res.id !== resId);
  }

  toggleLimit() {
    if (this.limitResources) {
      this.limit = 50;
    } else {
      this.limit = 3;
    }
    this.limitResources = !this.limitResources;
  }
}
