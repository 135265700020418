import { GLEnvironment, GrowelabEnv } from 'src/app/common/types/env';

export const environment: GrowelabEnv = {
  environment: GLEnvironment.DEMO,
  production: true,
  codoxApiKey: '16807ae8-ebb0-4b4a-84f3-14aca118134b',
  froalaKey:
    'Ja2A4wA1C2A1G2A4C3D3oCd2ZSb1XHi1Cb2a1KIWCWMJHXCLSwG1G1B2C2B1D7E6E1F4H4==',
  apiUrl: 'https://qa3.api.engage2learn.org',
  pusher: {
    key: 'cfe6b01015d18a2c6213',
    cluster: 'us2',
  },
  googleAnalyticsID: 'G-ZLJL57QJHS',
  growelabGoogleClientId:
    '605621249953-8uj4k1t6a7046de2mhqu8bqbnhhgvule.apps.googleusercontent.com',
  microsoftClarityID: 'gcofa8vy50',
  SENTRY_ORG: 'engage2learn',
  SENTRY_PROJECT: 'growelab-web',
  SENTRY_KEY:
    '519b763d3b944feb82e5349b62abae25d6c7b66c6c8e41db87275eaa884d75dc',
  cleverClientId: 'b7f08b4e62eb3a698369',
  cleverRedirectURI: 'https://demo.growelab.app/clever-auth',
};
