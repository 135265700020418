import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { CelebrationDTO } from '../../dtos/celebrations.dto';
import { CelebrationsService } from '../../services/celebrations/celebrations.service';
import { CelebrationModalComponent } from '../celebration-modal/celebration-modal.component';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent implements OnInit {
  @Input() dashboardType: string;

  @Input() showSwitcher = true;

  @Input() showAddCoachee = false;

  @Output() coacheeEvent = new EventEmitter<boolean>();

  @ViewChild('celebrationModal') celebrationModal: CelebrationModalComponent;

  @ViewChild('celebrationPopover') celebrationPopover: NgbPopover;

  user$ = select(UserState.getUser);

  show_dashboard_type = !EnvironmentService.isProduction();

  dayOfWeek = new Date().toLocaleDateString('en-US', { weekday: 'long' });

  celebrations: CelebrationDTO[] | null = null;

  constructor(private celebrationService: CelebrationsService) {}

  ngOnInit(): void {
    this.getCelebrations();
  }

  openCelebrationModal() {
    this.celebrationModal.openModal();
    this.celebrationPopover.close();
  }

  handleCreateCelebrationEvent() {
    this.getCelebrations();
  }

  getCelebrations() {
    this.celebrationService.getCelebrations().subscribe((res) => {
      if (res) {
        if (res.length > 6) {
          this.celebrations = res.slice(0, 6);
        } else {
          this.celebrations = res;
        }
        this.celebrations.reverse();
      }
    });
  }

  coacheesUpdated() {
    this.coacheeEvent.next(true);
  }
}
