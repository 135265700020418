<div class="container">
  <app-resources-header [createResourceBtn]="true" />
  <div class="card border-0 p-3">
    <app-resources-subnav />
    <div class="card-body" style="min-height: 600px">
      <div
        class="page-body d-flex flex-column justify-content-center align-items-center">
        <div
          class="input-group mb-3 mt-3 center w-75"
          style="max-width: 630px; height: 60px">
          <input
            #resourceSearch
            aria-label="search"
            class="form-control"
            type="text"
            (keydown)="onSearchFieldKeydown($event, resourceSearch.value)" />
          <button
            class="btn btn-primary btn-lg"
            (click)="redirectSearch(resourceSearch.value)">
            <span class="search-btn-text">Search</span>
          </button>
        </div>

        <div class="search-message text-center w-75" style="max-width: 630px">
          Don't have a keyword in mind?
          <a
            class="text-decoration-underline"
            style="cursor: pointer"
            (click)="$event.preventDefault(); redirectSearch('')"
            >Browse by filter</a
          >
          instead.<br />
          <span *ngIf="counts"
            >With {{ counts | number }} resources currently available, there's
            got to be one out there for your need-to-know!</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
