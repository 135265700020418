import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  BadgeEarnedPusherResponse,
  EvidencePusherResponse,
  PusherEvidenceAssessmentResponse,
} from 'src/app/common/types/responses/responses';

import { Subject } from 'rxjs';
import { evidenceDTOFromAPIResponse } from '../../helpers/translators/evidence.translators';
import {
  AddEvidence,
  DeleteEvidence,
  UpdateAssesment,
  UpdateEvidence,
} from '../../state/coaching-log/coaching-log.actions';
import { EvidenceAPIResponse } from '../../types/responses/coaching-log.responses';
import { PusherGuide } from './pusher-guide.service';
import { PusherService } from './pusher.service';
import { badgeEarnedDTOFromPusherResponse } from '../../helpers/translators/coaching-session-dto.translator';
import { BadgeEarnedDTO } from '../../dtos/badge.dto';

@Injectable()
export class UserPusherService extends PusherGuide {
  private badgeEarnedNotifierStream = new Subject<BadgeEarnedDTO>();

  public badgeEarnedNotifier = this.badgeEarnedNotifierStream.asObservable();

  constructor(pusherService: PusherService, private store: Store) {
    super(pusherService, 'presence');
  }

  protected override addDefaultBindings(channelId: string): void {
    const channel = this.getChannel(channelId);
    if (channel) {
      channel.bind(
        'growth:evidence_update_v2',
        (data: { items: EvidenceAPIResponse[] }) => {
          data.items.forEach((item) => {
            this.store.dispatch(
              new UpdateEvidence(evidenceDTOFromAPIResponse(item))
            );
          });
        }
      );
      channel.bind(
        'growth:evidence_create_many',
        (data: { items: EvidenceAPIResponse[] }) => {
          data.items.forEach((item) => {
            this.store.dispatch(
              new AddEvidence(evidenceDTOFromAPIResponse(item))
            );
          });
        }
      );
      channel.bind(
        'growth:evidence_delete',
        (data: { item: EvidencePusherResponse }) => {
          this.store.dispatch(new DeleteEvidence(data.item));
        }
      );
      channel.bind(
        'growth:evidence_assessment',
        (data: { item: PusherEvidenceAssessmentResponse }) => {
          this.store.dispatch(new UpdateAssesment(data.item));
        }
      );
      channel.bind(
        'live_coachlog:badgeEarned',
        (data: BadgeEarnedPusherResponse) => {
          this.badgeEarnedNotifierStream.next(
            badgeEarnedDTOFromPusherResponse(data)
          );
        }
      );
    }
  }
}
