import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';

import { UploadType } from '../../enums/upload-type.enum';
import { VideoReferenceObjectType } from '../../enums/video-reference-object-type.enum';
import { VideoAPIResponse } from '../../types/responses/video.responses';
import { FileService } from './file.service';

export interface VideoUploadResult {
  uri: string;
  path: string;
}

export interface CreateVideoResponse extends VideoUploadResult {
  video: VideoAPIResponse;
}

export interface AttachVideoPayload {
  videoId: number;
  objectType: VideoReferenceObjectType;
  objectId: number;
  allowComments?: BooleanNumber;
}

@Injectable({
  providedIn: 'root',
})
export class VideoFileService extends FileService {
  override uploadType = UploadType.VIDEO;

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  override uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('video', file);
    return this.apiService.postFormRequest('video-upload', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  finishVideo(uploadResponse: VideoUploadResult): Observable<any> {
    return this.apiService.postRequest('create-video', {
      path: uploadResponse.path,
      vimeo_uri: uploadResponse.uri,
      belongs_to_video_library: 1,
    });
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  attachVideo(payload: AttachVideoPayload): Observable<boolean> {
    return this.apiService.postRequest('video-relate', {
      allow_comments: payload.allowComments || 1,
      video_id: payload.videoId,
      ref_id: payload.objectId,
      ref_table: payload.objectType,
    });
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  unattachVideo(videoId: number): Observable<boolean> {
    return this.apiService.getRequest('video-delete-relation', {
      video_id: videoId,
    });
  }
}
