<div class="container">
  <div class="d-flex justify-content-between mb-3" style="min-height: 3rem">
    <h1>Manage {{ (district && district.title) || "District" }}</h1>
    <div>
      <button
        placement="bottom"
        (click)="
          router.navigate([
            'settings',
            'districts',
            districtCode,
            'forms',
            'create'
          ])
        "
        class="btn btn-link">
        <i class="bi bi-plus-circle me-1"></i>Create New Form
      </button>
    </div>
  </div>
  <div class="card border-0 p-3">
    <app-settings-nav [districtCode]="districtCode" />
    <div class="d-flex justify-content-between mt-3">
      <div class="coachee-search table-search">
        <input
          *ngIf="forms.length > 0"
          type="search"
          class="form-control ms-1"
          placeholder="Search Forms"
          aria-label="Search"
          (input)="searchForms($event)" />
      </div>
      <div
        class="btn-group btn-group-md align-self-right"
        role="group"
        aria-label="Basic radio toggle button group">
        <input
          type="radio"
          class="btn-check"
          name="btnradio"
          id="currentView"
          autocomplete="off"
          [checked]="currentMode"
          (click)="currentMode ? '' : toggleFormView()" />
        <label class="btn btn-outline-primary" for="currentView">Current</label>
        <input
          type="radio"
          class="btn-check"
          name="btnradio"
          id="archivedView"
          autocomplete="off"
          [checked]="!currentMode"
          (click)="!currentMode ? '' : toggleFormView()" />
        <label class="btn btn-outline-primary" for="archivedView"
          >Archived</label
        >
      </div>
    </div>
    <ng-container *ngIf="!formsService.isLoading; else loadingTemplate">
      <table
        *ngIf="filteredForms.length > 0; else noResults"
        class="table my-3">
        <thead>
          <tr class="align-middle">
            <th
              class="sortable-header"
              scope="col"
              sortable="title"
              (sort)="onSort($event)">
              Title
              <i class="bi bi-caret-down-fill {{ sortIconTemp.title }}"></i>
            </th>
            <th
              scope="col"
              class="sortable-header"
              (sort)="onSort($event)"
              sortable="description"
              class="sortable-header d-md-table-cell">
              Description
              <i
                class="bi bi-caret-down-fill {{
                  sortIconTemp.description
                }}"></i>
            </th>
            <th
              class="sortable-header hide-on-mobile"
              scope="col"
              sortable="status"
              (sort)="onSort($event)">
              Status
              <i class="bi bi-caret-down-fill {{ sortIconTemp.status }}"></i>
            </th>
            <!-- <th
              class="sortable-header hide-on-mobile"
              scope="col"
              sortable="author"
              (sort)="onSort($event)">
              <div class="d-flex">
                Author
                <i
                  class="ms-1 bi bi-caret-down-fill {{
                    sortIconTemp.author
                  }}"></i>
              </div>
            </th> -->
            <th
              class="sortable-header hide-on-mobile"
              scope="col"
              sortable="dateModified"
              (sort)="onSort($event)">
              <div class="d-flex no-wrap">
                Date Modified
                <i
                  class="ms-1 bi bi-caret-down-fill {{
                    sortIconTemp.dateModified
                  }}"></i>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let form of filteredForms"
            style="border-bottom: 1px solid #78858e5a"
            class="align-middle">
            <td class="table-cell">
              <span>{{ form.title }}</span>
            </td>
            <td class="table-cell">
              {{ form.description }}
            </td>
            <td class="table-cell hide-on-mobile">
              {{ form.status }}
            </td>
            <!-- <td class="table-cell hide-on-mobile">
              {{ form.author ? form.author.fullName : "" }}
            </td> -->
            <td class="table-cell hide-on-mobile">
              {{ form.modifiedAt * 1000 | date : "medium" }}
            </td>
            <td class="table-cell text-end">
              <button
                (click)="openPreview(form.id)"
                class="btn btn-secondary btn-sm m-1">
                <i class="bi bi-eye me-1"></i> Preview
              </button>
              <button
                *ngIf="currentMode"
                (click)="duplicateForm(form.id)"
                class="btn btn-success btn-sm m-1">
                <i class="bi bi-collection me-1"></i> Copy
              </button>
              <ng-container *ngIf="userCanEdit(form) && currentMode">
                <button
                  (click)="
                    this.router.navigate([
                      'settings',
                      'districts',
                      districtCode,
                      'forms',
                      form.id
                    ])
                  "
                  class="btn btn-primary btn-sm m-1">
                  <i class="bi bi-pencil me-1"></i> Edit
                </button>
              </ng-container>

              <div
                class="d-inline-block"
                *ngIf="district.id !== 2 && form.districtId === 2">
                <ng-template #formHideConfirm>
                  Are you sure you want to hide this shared form?
                  <p>
                    <a
                      class="color-primary d-inline-block p-1"
                      (click)="hideForm(form.id); popover.close()"
                      href="javascript:void(0)"
                      >Yes</a
                    >
                    <a
                      class="color-tertiary d-inline-block p-1"
                      (click)="popover.close()"
                      href="javascript:void(0)"
                      >No</a
                    >
                  </p>
                </ng-template>
                <button
                  [ngbPopover]="formHideConfirm"
                  class="btn btn-warning btn-sm m-1"
                  [autoClose]="false"
                  triggers="manual"
                  (click)="popover.open()"
                  #popover="ngbPopover">
                  <i class="bi bi-eye-slash"></i> Hide
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ng-template #noResults>
        <div class="p-5 align-self-center text-muted">
          <p *ngIf="district && district.id === 2">No templates created yet</p>
          <p *ngIf="!district || district.id != 2">
            There are no forms associated with your district.
          </p>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingTemplate>
      <div style="clear: both; height: 300px"></div>
      <app-loader [top]="200" />
    </ng-template>
  </div>
</div>
<app-form-modal #previewModal></app-form-modal>
