<div class="container">
  <div class="d-flex justify-content-between mb-3">
    <h1>Coaching Sessions</h1>
    <div class="align-self-center"></div>
  </div>
  <div class="card border-0">
    <div class="card-body p-3">
      <ng-container *ngIf="sessionData; else loadingTemplate">
        <ng-container *ngIf="hasPresentAttendee; else noAttendeeTemplate">
          <ng-container [ngSwitch]="currentScreen">
            <ng-container *ngSwitchCase="'selectCompetencies'">
              <div class="session-nav-bar">
                <a
                  routerLink="/coaching/log/{{ sessionId }}/info"
                  class="text-decoration-none text-muted">
                  < Back to Session Info
                </a>
              </div>
              <h2 class="text-center session-title">{{ sessionData.title }}</h2>
              <div
                class="d-flex flex-wrap justify-content-between align-items-center mb-3">
                <ng-template
                  [ngIf]="
                    coachingService.logPusherService.attendeeRosters[sessionId]
                      .length > 0
                  ">
                  <div class="d-flex flex-row align-items-center">
                    <div class="me-3 text-muted">Attendees</div>
                    <app-avatar
                      style="margin-left: -5px"
                      avatarSize="small"
                      *ngFor="
                        let attendee of coachingService.logPusherService
                          .attendeeRosters[sessionId]
                      "
                      [avatarData]="attendee"
                      data-testid="attendee-avatar"></app-avatar>
                  </div>
                </ng-template>
                <div class="col text-end">
                  <button
                    (click)="navigateNextScreen()"
                    [class.disabled]="!competencyData.competencySelected"
                    ngbTooltip="At least 1 competency must be selected."
                    [disableTooltip]="competencyData.competencySelected"
                    style="pointer-events: auto"
                    class="btn btn-primary btn-next">
                    Let's Growe! <i class="bi bi-arrow-right"></i>
                  </button>
                </div>
              </div>
              <app-select-competencies-screen
                (nextScreenEvent)="updateScreen($event)"
                (updateCompetencyEvent)="updateAttendeeRubrics()"
                [competencyList]="competencyData.competencies"
                [letsGroweActive]="competencyData.competencySelected"
                [attendeeIds]="attendeeIds"
                [logId]="sessionId" />
            </ng-container>
            <ng-container *ngSwitchCase="'groweCoaching'">
              <div class="session-nav-bar">
                <a
                  routerLink="/coaching/log/{{ sessionId }}/competencies"
                  class="text-decoration-none text-muted">
                  < Back to Select Competencies
                </a>
              </div>
              <h2 class="text-center session-title">{{ sessionData.title }}</h2>
              <div
                class="d-flex flex-wrap justify-content-between align-items-center mb-3">
                <ng-template
                  [ngIf]="
                    coachingService.logPusherService.attendeeRosters[sessionId]
                      .length > 0
                  ">
                  <div class="d-flex flex-row align-items-center">
                    <div class="me-3 text-muted">Attendees</div>
                    <app-avatar
                      style="margin-left: -5px"
                      avatarSize="small"
                      *ngFor="
                        let attendee of coachingService.logPusherService
                          .attendeeRosters[sessionId]
                      "
                      [avatarData]="attendee"
                      data-testid="attendee-avatar"></app-avatar>
                  </div>
                </ng-template>
                <div class="col text-end">
                  <button
                    routerLink="/coaching/log/{{ sessionId }}/summary"
                    class="btn btn-primary btn-next">
                    Complete
                    <i class="bi bi-arrow-right"></i>
                  </button>
                </div>
              </div>
              <ng-container *ngIf="rubricData.length > 0; else loadingTemplate">
                <label
                  class="visually-hidden"
                  [attr.for]="'log-top-attendee-select'"
                  >Select Attendees</label
                >
                <div class="text-center">
                  <div class="mb-3" style="max-width: 350px; margin: 0 auto">
                    <ng-container
                      *ngIf="
                        !loggedInAsAttendee && sessionData.attendees.length > 1
                      ">
                      <ng-select
                        [labelForId]="'log-top-attendee-select'"
                        [items]="attendeeUserDTOs"
                        [searchable]="false"
                        [clearable]="false"
                        [placeholder]="'Select Coachee'"
                        [(ngModel)]="selectedAttendee"
                        #select>
                        <ng-template ng-label-tmp let-item="item">
                          <span class="fw-800">Coachee:</span>
                          &nbsp;<app-users-name [userProfile]="item.profile" />
                        </ng-template>
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index"
                          let-search="term">
                          <app-users-name [userProfile]="item.profile" />
                        </ng-template>
                      </ng-select>
                    </ng-container>
                  </div>
                </div>
                <ng-container *ngFor="let attendeeRubric of rubricData">
                  <ng-container
                    *ngIf="
                      (!loggedInAsAttendee &&
                        selectedAttendee &&
                        selectedAttendee.id === attendeeRubric.userId) ||
                      attendeeRubric.userId == user?.id
                    ">
                    <ng-container *ngFor="let rubric of attendeeRubric.rubrics">
                      <ng-container
                        *ngFor="
                          let attendeeCompetencyGroup of rubric.competencyGroups
                        ">
                        <ng-container
                          *ngFor="
                            let attendeeCompetency of attendeeCompetencyGroup.competencies
                          ">
                          <app-leveled-competency
                            [userId]="attendeeRubric.userId"
                            [competency]="attendeeCompetency"
                            [rubricId]="rubric.id"
                            [logId]="sessionId" />
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'sessionSummary'">
              <div class="session-nav-bar">
                <a
                  routerLink="/coaching/log/{{ sessionId }}/growth"
                  class="text-decoration-none text-muted">
                  < Back to Coaching
                </a>
              </div>
              <h2 class="text-center session-title">{{ sessionData.title }}</h2>
              <div
                class="d-flex flex-wrap justify-content-between align-items-center mb-3">
                <ng-template
                  [ngIf]="
                    coachingService.logPusherService.attendeeRosters[sessionId]
                      .length > 0
                  ">
                  <div class="d-flex flex-row align-items-center">
                    <div class="me-3 text-muted">Attendees</div>
                    <app-avatar
                      style="margin-left: -5px"
                      avatarSize="small"
                      *ngFor="
                        let attendee of coachingService.logPusherService
                          .attendeeRosters[sessionId]
                      "
                      [avatarData]="attendee"
                      data-testid="attendee-avatar"></app-avatar>
                  </div>
                </ng-template>
              </div>
              <app-coaching-session-summary
                [session]="sessionData"
                [rubricData]="rubricData"></app-coaching-session-summary>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #noAttendeeTemplate>
          <p>
            <ngb-alert [dismissible]="false">
              <strong>No Present Attendees</strong> At least 1 attendee must be
              marked present.
              <a
                routerLink="/coaching/log/{{ sessionId }}/info"
                class="text-decoration-none text-muted"
                ><br />
                &lt; back
              </a>
            </ngb-alert>
          </p>
        </ng-template>
      </ng-container>
      <ng-template #loadingTemplate>
        <app-loader></app-loader>
      </ng-template>
    </div>
    <div
      role="button"
      (click)="toggleNotes()"
      class="d-none d-sm-block border border-2 border-end-0 border-secondary rounded-start notes-fade-out notes-tab {{
        notesTabActive ? '' : 'hide'
      }} ">
      <div class="d-flex align-items-center">
        <i class="bi bi-journal journal-icon"></i>
        <p class="notes-title">Notes</p>
      </div>
    </div>
    <ng-container *ngIf="sessionData">
      <app-coaching-log-notes
        (hideNotesEvent)="toggleNotes()"
        [user]="user"
        [sessionId]="sessionId"
        [notesActive]="notesActive"></app-coaching-log-notes>
    </ng-container>

    <app-modal #resourceSearchModal [ngClass]="['mw800']">
      <button
        class="bg-transparent border-0"
        title="Close"
        aria-label="close"
        headerContent
        (click)="resourceSearchModal.close()">
        <i class="bi bi-x-lg text-white"></i>
      </button>
      <div #resourceSearchDescription>
        <p class="text-center fs1"><strong></strong><br /><span></span></p>
      </div>
      <app-resource-leveled-list
        [resources]="coachlogResourceService.leveledResources"
        [levelNumber]="
          coachlogResourceService.levelNumber
        "></app-resource-leveled-list>
      <hr />
      <div class="my-4">
        <app-select-resource
          #resourceSelect
          [clearAfterSelect]="true"
          placeholder="Search for Resources"></app-select-resource>
      </div>
      <hr />
      <h5 class="fw-800">Selected Resources</h5>
      <app-resource-preview-list
        [resources]="coachlogResourceService.resourcePreviews"
        [showRemovalIcon]="true"
        (signalRemoval)="removePreview($event)"></app-resource-preview-list>
    </app-modal>
  </div>
  <app-badge-earned-modal></app-badge-earned-modal>
</div>
