import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTime, Duration } from 'luxon';

export const dateFromNgbDate = (
  dateStruct: NgbDateStruct | NgbDate,
  endOfDay = false
): Date => {
  const timeStruct = endOfDay
    ? { hour: 23, minute: 59, second: 59 }
    : { hour: 0, minute: 0, second: 0 };
  return DateTime.fromFormat(
    `${dateStruct.month}/${dateStruct.day}/${dateStruct.year}`,
    'M/d/yy'
  )
    .set(timeStruct)
    .toJSDate();
};

export const dateTimeFromNgbDate = (
  dateStruct: NgbDateStruct | NgbDate,
  endOfDay = false
): DateTime => {
  const timeStruct = endOfDay
    ? { hour: 23, minute: 59, second: 59 }
    : { hour: 0, minute: 0, second: 0 };
  return DateTime.fromFormat(
    `${dateStruct.month}/${dateStruct.day}/${dateStruct.year}`,
    'M/d/yy'
  ).set(timeStruct);
};

export const ngbDateStructFromDate = (date: Date): NgbDateStruct => ({
  year: date.getFullYear(),
  month: date.getMonth() + 1,
  day: date.getDate(),
});

export const ngbDateFromDate = (date: Date): NgbDate =>
  new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());

export const ngbDateStructFromUnixTimestamp = (
  timestamp: number
): NgbDateStruct => {
  const date = DateTime.fromSeconds(timestamp).toJSDate();
  return ngbDateStructFromDate(date);
};

export const fullDurationUnits = [
  'years',
  'months',
  'days',
  'hours',
  'minutes',
  'seconds',
];

export const formatDurationAsString = (duration: Duration): string => {
  let returnString = '';
  let count = 0;
  while (count !== fullDurationUnits.length) {
    /* eslint-disable-next-line @typescript-eslint/no-loop-func */
    fullDurationUnits.forEach((unit) => {
      /* eslint-disable-next-line no-prototype-builtins */
      if (returnString === '' && duration[unit as keyof Duration]) {
        returnString =
          `${Math.abs(
            duration[unit as keyof Duration] as number
          )?.toString()} ${unit}` || '';
      }
      count += 1;
    });
  }
  return returnString || 'not very long';
};
