import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/common/services/auth/auth.service';
import { Animations } from 'src/app/common/utilities/animations';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [Animations.fadeInOut, Animations.delayfadeInOut],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;

  submitted = false;

  successful = false;

  loading = false;

  errorMessage = '';

  emailAddress: string;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.forgotPasswordForm.controls;
  }

  sendEmail() {
    this.submitted = true;
    this.emailAddress = this.f['email'].value;
    if (this.forgotPasswordForm.valid) {
      this.loading = true;

      this.authService.resetPasswordRequest(this.emailAddress).subscribe(
        () => {
          this.submitted = false;
          this.loading = false;
          this.forgotPasswordForm.reset();
          this.successful = true;
        },
        (err) => {
          this.submitted = false;
          this.loading = false;
          this.forgotPasswordForm.reset();
          this.errorMessage = err.error.message;
          this.successful = true;
        }
      );
    }
  }
}
