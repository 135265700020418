import { BooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import { numberFromNumberOrString } from 'src/app/common/utilities/type-helpers';
import { WillDTO } from '../../dtos/attendee-rubric.dto';

export interface CreateOptionPayload {
  content: string;
  groweCoachlogId: number;
  groweStrandId: number;
  groweRubricId: number;
  groweCompetencyId: number;
  level: number;
}

export interface CreateWillPayload {
  optionId: number;
  dueDate: number;
  userId: number;
  content?: string;
}

export interface WillAPIResponse {
  id: number;
  egrowe_coachlog_option_id: number;
  due_date: number;
  user_id: number;
  created_at: number;
  updated_at: number;
  content: string;
  change_user_id?: number;
}

export const willPayloadToAPIPayload = (payload: CreateWillPayload) => ({
  egrowe_coachlog_option_id: payload.optionId,
  due_date: payload.dueDate,
  user_id: payload.userId,
  content: payload.content || '',
});

export const WillDTOFromAPIResponse = (
  response: WillAPIResponse,
  coachlogId: number
): WillDTO => ({
  coachlogId,
  id: response.id,
  optionId: numberFromNumberOrString(response.egrowe_coachlog_option_id),
  dueDate: numberFromNumberOrString(response.due_date),
  userId: numberFromNumberOrString(response.user_id),
  updatedDate: response.updated_at,
  createdDate: response.created_at,
  content: response.content,
});

export interface AddCompetencyPayload {
  egrowe_standard_id: number;
  egrowe_rubric_id: number;
  user_id: number;
}

export interface UpdateLogPayload {
  title?: string;
  egrowe_coachlog_type_id?: number;
  start_datetime?: number;
  end_datetime?: number;
  timezone?: string;
  is_private?: boolean;
}

export interface UpdateAttendeePayload {
  present?: BooleanNumber;
  absent_reason?: number | null;
}

export interface UpdateShadowerPayload {
  present: number;
}

export interface GetCoachLogTypesPayload {
  per_page: number;
  district_id?: number;
}

export interface FeedbackPayload {
  content: string;
  egrowe_coachlog_id: number;
  user_id: number;
}

export interface GetLeveledResourcesPayload {
  egrowe_coachlog_id: number;
  egrowe_rubric_id: number;
  egrowe_standard_id: number;
  level: number;
}
