<app-modal
  #createUserModal
  (isDismissing)="closeModal()"
  [modalConfig]="{
    titleText: 'Create User',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div *ngIf="createdUser">
    <ngb-alert [dismissible]="false" [type]="'success'">
      <strong>Success</strong><br />
      Created user {{ createdUser.profile.first_name }}
      {{ createdUser.profile.last_name }} &lt;{{ createdUser.email }}&gt;
    </ngb-alert>
  </div>
  <div *ngIf="!createdUser">
    <form (ngSubmit)="createUser()" [formGroup]="form">
      <div class="row mt-3">
        <div class="col">
          <label for="first-name" class="form-label">First Name</label>
          <div class="input-group">
            <input
              formControlName="first_name"
              type="text"
              class="form-control"
              id="first-name"
              aria-label="First Name"
              [ngClass]="{
                'is-invalid': formSubmitted && f['first_name'].errors
              }" />
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label for="last-name" class="form-label">Last Name</label>
          <div class="input-group">
            <input
              formControlName="last_name"
              type="text"
              class="form-control"
              id="last-name"
              aria-label="Last Name"
              [ngClass]="{
                'is-invalid': formSubmitted && f['last_name'].errors
              }" />
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label for="email-address" class="form-label">Email Address</label>
          <div class="input-group">
            <input
              formControlName="email"
              type="text"
              class="form-control"
              id="email-address"
              aria-label="Email Address"
              [ngClass]="{
                'is-invalid': formSubmitted && f['email'].errors
              }" />
          </div>
        </div>
      </div>
      <ng-container *ngIf="schoolList && schoolList.length > 0">
        <div
          class="row mt-3"
          *ngIf="schoolList.length != 1 && schoolList[0].is_default != 1">
          <div class="col">
            <label for="school" class="form-label">School</label>
            <div class="input-group">
              <select
                formControlName="school_id"
                class="form-control"
                id="school"
                aria-label="School"
                [ngClass]="{
                  'is-invalid': formSubmitted && f['school_id'].errors
                }">
                <ng-container *ngFor="let school of schoolList">
                  <option value="{{ school.id }}">{{ school.title }}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="row mt-3">
        <div class="col">
          <label for="title" class="form-label">Title</label>
          <div class="input-group">
            <input
              formControlName="title"
              type="text"
              class="form-control"
              id="title"
              aria-label="Title"
              [ngClass]="{
                'is-invalid': formSubmitted && f['title'].errors
              }" />
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label for="user-type" class="form-label">User Type</label>
          <div class="input-group">
            <select
              formControlName="coachee_type"
              class="form-control"
              id="user-type"
              aria-label="User Type"
              [ngClass]="{
                'is-invalid': formSubmitted && f['coachee_type'].errors
              }">
              <option value="Teacher">Teacher</option>
              <option value="Coach">Coach</option>
              <option value="District Admin">District Admin</option>
              <option value="School Admin">School Admin</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label for="password" class="form-label">Password</label>
          <div class="input-group">
            <input
              formControlName="password"
              type="password"
              class="form-control"
              id="password"
              aria-label="Password"
              [ngClass]="{
                'is-invalid': formSubmitted && f['password'].errors
              }" />
          </div>
        </div>
      </div>
      <div footerContent>
        <ng-container *ngIf="serverError">
          <ngb-alert [dismissible]="false" [type]="'danger'">
            <strong>Error</strong> {{ serverError }}
          </ngb-alert>
        </ng-container>
        <div class="row mt-3">
          <div class="col text-end">
            <button
              [disabled]="isLoading"
              type="submit"
              class="btn btn-md btn-primary mt-3">
              <ng-container *ngIf="isLoading">
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </ng-container>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</app-modal>
