<div class="shelf-container">
  <ngb-alert
    class="copied-success"
    #copiedAlert
    *ngIf="copiedStatus"
    type="success"
    >Copied to Clipboard
  </ngb-alert>
  <button routerLink="/resources/my" class="btn btn-link mb-2">
    < Back to my resources
  </button>
  <ng-container *ngIf="shelf; else loadingTemplate">
    <div class="shelf-head d-flex justify-content-between">
      <div class="shelf-head mb-4">
        <div class="shelf-title" *ngIf="!isCreatedByMe">{{ shelf.title }}</div>
        <div class="shelf-title" *ngIf="isCreatedByMe">Created by You</div>
        <div class="shelf-description" *ngIf="shelf.description">
          {{ shelf.description }}
        </div>
      </div>
      <div class="shelf-head-actions d-flex">
        <!-- <div
          role="button"
          title="share"
          aria-label="share"
          *ngIf="!isCreatedByMe"
          class="btn-shelf-action p-1"
          (click)="copyToClipboard(shelf.id)">
          <i class="bi bi-share"></i>
        </div> -->
        <div
          role="button"
          title="edit"
          aria-label="edit"
          *ngIf="!isCreatedByMe"
          class="btn-shelf-action p-1 mx-3"
          (click)="editShelfModal.open()">
          <i class="bi bi-pencil"></i>
        </div>
        <div
          role="button"
          title="delete"
          aria-label="delete"
          *ngIf="!isCreatedByMe && !hasChildren"
          class="btn-shelf-action p-1"
          (click)="deleteShelf()">
          <i class="bi bi-trash"></i>
        </div>
        <div
          title="delete"
          aria-label="delete"
          *ngIf="!isCreatedByMe && hasChildren"
          class="p-1 has-children btn-disabled">
          <i
            ngbTooltip="This folder contains content. Remove all resources and folders to enable this action."
            class="bi bi-trash"></i>
        </div>
      </div>
    </div>

    <div *ngIf="!isCreatedByMe" class="resource-header mb-4">
      <button class="btn btn-link p-1" (click)="createShelfModal.open()">
        <i class="bi bi-folder-plus"></i
        ><span class="text">Create a New Folder</span>
      </button>
    </div>

    <ng-container *ngIf="!isCreatedByMe">
      <ng-container
        *ngIf="myStarredChildShelves.length > 0 || myChildShelves.length > 0">
        <div class="section-head mb-2">Folders</div>
      </ng-container>
      <ng-container *ngIf="myStarredChildShelves; else loading">
        <div cdkDropList class="shelves">
          <div *ngFor="let shelf of myStarredChildShelves">
            <app-shelf-item
              [shelf]="shelf"
              [multiShelves]="myStarredChildShelves.length > 1"
              (shelfClicked)="resetShelf()"
              (copyClicked)="copyToClipboard($event)"
              (starClicked)="
                starSelect(shelf.id, shelf.usersFavorite, myStarredChildShelves)
              "></app-shelf-item>
          </div>
        </div>
        <div
          *ngIf="myStarredChildShelves.length >= 1"
          style="margin: 25px 0"></div>
      </ng-container>

      <ng-container *ngIf="myChildShelves; else loading">
        <div cdkDropList class="shelves">
          <div *ngFor="let shelf of myChildShelves">
            <app-shelf-item
              [shelf]="shelf"
              [multiShelves]="myChildShelves.length > 1"
              (shelfClicked)="resetShelf()"
              (copyClicked)="copyToClipboard($event)"
              (starClicked)="
                starSelect(shelf.id, shelf.usersFavorite, myChildShelves)
              "></app-shelf-item>
          </div>
        </div>
      </ng-container>
      <ng-template #loading>
        <div class="w-100 text-center" style="height: 60vh">
          <app-loader> </app-loader>
        </div>
      </ng-template>
    </ng-container>

    <div class="section-head mb-2">Resources</div>
    <ng-container *ngIf="shelfItems; else Loading">
      <ng-container *ngFor="let shelfItem of shelfItems">
        <ng-container *ngIf="shelfItem.type !== 'course'">
          <app-resource-list-item
            [resourceListItem]="shelfItem"></app-resource-list-item>
        </ng-container>
        <ng-container *ngIf="shelfItem.type == 'course'">
          <app-online-course [course]="shelfItem"></app-online-course>
        </ng-container>
      </ng-container>
      <ng-container
        *ngIf="
          (shelf.numbResources && shelf.numbResources > 0) ||
            shelf.numbItems > 0;
          else noResults
        ">
        <div class="d-flex justify-content-center">
          <ngb-pagination
            *ngIf="shelfListMeta && shelfListMeta.totalCount > 10"
            [(page)]="shelfListMeta.currentPage"
            [pageSize]="shelfListMeta.perPage"
            [collectionSize]="shelfListMeta.totalCount"
            (pageChange)="paginationChange()"
            [maxSize]="5"
            [ellipses]="true"></ngb-pagination>
        </div>
      </ng-container>
      <ng-template #noResults>
        <div class="no-content-message">
          No resources have been added to this Folder.
          <div class="link" routerLink="/resources/search">
            Discover Resources
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #Loading>
      <div class="w-100 text-center" style="height: 60vh">
        <app-loader></app-loader>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loadingTemplate>
    <div class="w-100 text-center" style="height: 60vh">
      <app-loader></app-loader>
    </div>
  </ng-template>
</div>

<app-modal
  #editShelfModal
  [modalConfig]="{
    titleText: 'Edit Folder',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    windowClass: 'edit-shelf-class',
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="editShelfModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div>
    <label class="form-label" for="edit-shelf-title">Title</label>
    <input class="form-control" id="edit-shelf-title" [(ngModel)]="tempTitle" />
    <div>
      <label class="form-label" for="edit-shelf-description">Description</label>
      <textarea
        class="form-control"
        id="edit-shelf-description"
        [(ngModel)]="tempDescription"
        rows="4"
        placeholder="Enter a description for this folder"></textarea>
    </div>
  </div>
  <div footerContent>
    <button
      [disabled]="tempTitle.length < 1"
      class="btn btn-primary"
      (click)="editShelfModal.close()"
      (click)="saveTitleDescription()">
      Apply Change
    </button>
  </div>
</app-modal>

<app-modal
  #createShelfModal
  [modalConfig]="{
    titleText: 'Create a New Folder',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    windowClass: 'edit-shelf-class',
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="createShelfModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <label for="create-folder-title" class="form-label">Title</label>
  <input
    class="form-control"
    id="create-folder-title"
    placeholder="Enter Folder Title"
    [(ngModel)]="newShelf.title"
    required />
  <label for="create-folder-description" class="form-label">Description</label>
  <textarea
    id="create-folder-description"
    class="form-control"
    [(ngModel)]="newShelf.description"
    rows="4"
    placeholder="Enter a description for this folder"></textarea>
  <div class="note mt-1">
    Note: This Folder will be located within the Current Folder.
  </div>
  <div footerContent>
    <div>
      <button
        [disabled]="newShelf.title.length < 1"
        class="btn btn-primary"
        (click)="createShelfModal.close()"
        (click)="createShelf()">
        Create Folder
      </button>
    </div>
  </div>
</app-modal>
