import { Injectable } from '@angular/core';

import { APICoreService } from '../../../../common/services/api-core/api-core.service';

@Injectable({
  providedIn: 'root',
})
export class DistrictOperatorService {
  constructor(private apiService: APICoreService) {}

  addOperator(userId: number) {
    return this.apiService.postRequest(
      `users/${userId}/subscriptions/operator`
    );
  }

  removeOperator(userId: number) {
    return this.apiService.deleteRequest(
      `users/${userId}/subscriptions/operator`
    );
  }

  getOperators(districtId: number) {
    return this.apiService.getRequest(
      `users?role=operator&district_id=${districtId}`
    );
  }
}
