<div class="form-group checkbox form-box mt-2 card border-0 shadow-sm">
  <label [id]="id" class="form-label m-0 p-3 fs-13 fw-bold">{{
    fieldDefinition.label
  }}</label>

  <div class="content px-4 pt-4">
    <label
      *ngFor="let choice of fieldDefinition.choices"
      class="form-input d-block mb-4 item rounded-top">
      <div class="d-flex">
        <div class="position-relative toggle-container">
          <i
            class="fs-13 bi bi-square-fill position-absolute"
            style="color: #eee"></i>
          <i
            *ngIf="selectionArray.includes(choice.value)"
            class="fs-3 bi bi-check-lg color-black position-absolute"></i>
        </div>
        <input
          (change)="changeSelection(choice.value)"
          class="visually-hidden"
          type="checkbox"
          [id]="id"
          [value]="choice.value"
          [checked]="selectionArray.includes(choice.value)" />
        <span class="d-block px-2" style="line-height: 1">{{
          choice.label
        }}</span>
      </div>
    </label>
  </div>
</div>
