<div
  tabindex="0"
  class="notes-fade notes-container {{ notesActive ? 'show' : '' }}">
  <div
    class="notes-title text-center d-flex align-items-center justify-content-between">
    <div class="ps-0 text-start"></div>
    <div>Notes</div>
    <div class="text-end pe-0">
      <i
        (click)="hideNotes()"
        title="hide-icon"
        role="button"
        class="bi bi-arrow-bar-right me-2 p-2"></i>
    </div>
  </div>
  <div class="notes-body d-flex align-items-center justify-content-center">
    <app-wysiwyg-editor
      *ngIf="notesActive"
      [version]="'none'"
      [config]="config"
      [isSaving]="notesSaving"
      [editorContent]="initialContent"
      (editorContentEvent)="updateNotes($event)"></app-wysiwyg-editor>
  </div>
</div>
