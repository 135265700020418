<div class="d-flex align-items-center item">
  <app-avatar
    id="avatar"
    avatarSize="medium"
    [profile]="user.profile"></app-avatar>
  <div>
    <p>
      <span [ngStyle]="{ color: user.profile.color }"
        >{{ user.profile.first_name }} {{ user.profile.last_name }}</span
      ><br />
      {{ user.profile.title }} @ {{ user.school?.title }}
    </p>
  </div>
</div>
