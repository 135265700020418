import { ProfileDTO } from 'src/app/common/dtos/profile.dto';
import { environment } from 'src/environments/environment';
import { AvatarDTO } from './avatar.dto';
import { PusherUserDTO } from '../../dtos/pusher-user.dto';

export const avatarDTOFromProfile = (profile: ProfileDTO): AvatarDTO => ({
  firstName: profile.first_name,
  lastName: profile.last_name,
  color: profile.color,
  photoUrl: profile.photo ? environment.apiUrl + profile.photo : undefined,
});

export const avatarDTOFromPusher = (member: PusherUserDTO): AvatarDTO => ({
  firstName: member.username.split(' ')[0],
  lastName: member.username.split(' ')[1],
  color: member.color,
  photoUrl: member.image,
});
