import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { currentDistrictOrE2lEmployee } from 'src/app/common/utilities/check-route';
import { FormModalComponent } from 'src/app/private/shared/components/forms/view/form-modal/form-modal.component';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/private/shared/directives/sortable-header.directive';
import { Form, FormStatus } from 'src/app/private/shared/dtos/forms.dto';
import { DistrictListService } from 'src/app/private/shared/services/district-list/district-list-service';
import { FormsService } from 'src/app/private/shared/services/forms/forms.service';

import { FilterFormSearch } from './forms-search-filter.pipe';

interface FormsListSortIcon {
  title: 'gray' | 'none';
  description: 'gray' | 'none';
  status: 'gray' | 'none';
}

const compare = (v1: string | number, v2: string | number) =>
  /* eslint-disable-next-line no-nested-ternary */
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

const columnDataMap = (form: Form, column: string): string | number => {
  switch (column) {
    case 'description':
      return form.description || 0;
    case 'status':
      return form.status || 0;
    case 'dateModified':
      return form.modifiedAt;
    case 'author':
      return form.author
        ? form.author.profile.last_name + form.author.profile.first_name
        : '';
    case 'title':
    default:
      return form.title || 0;
  }
};

@Component({
  selector: 'app-forms-list-page',
  templateUrl: './forms-list-page.component.html',
  styleUrls: ['./forms-list-page.component.scss'],
  providers: [FilterFormSearch],
})
export class FormsListPageComponent {
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  @ViewChild('previewModal') previewModal: FormModalComponent;

  tableData: Form[] = [];

  district: DistrictDTO;

  districtCode: string;

  sortIconTemp = {
    title: 'gray',
    description: 'gray',
    status: 'gray',
    author: 'gray',
    dateModified: 'gray',
  };

  forms: Form[] = [];

  filteredForms: Form[] = [];

  user: User | null;

  isE2lEmployee = false;

  currentMode = true;

  toggleFormView() {
    this.currentMode = !this.currentMode;
    this.updateFilteredForms();
  }

  deleteForm(formId: number) {
    this.formsService.deleteForm(formId).subscribe(() => {
      this.updateFilteredForms();
    });
  }

  hideForm(formId: number) {
    this.formsService.hideForm(formId, this.district.id).subscribe(() => {
      this.updateFilteredForms();
    });
  }

  openPreview(formId: number) {
    this.previewModal.formId = formId;
    this.previewModal.ngOnInit();
    this.previewModal.modal.open();
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
        this.sortIconTemp[<keyof FormsListSortIcon>header.sortable] = 'gray';
      } else {
        this.sortIconTemp[<keyof FormsListSortIcon>header.sortable] = 'none';
      }
    });

    if (direction === '' || column === '') {
      this.updateFilteredForms();
      this.sortIconTemp[<keyof FormsListSortIcon>column] = 'gray';
    } else {
      this.filteredForms = [...this.filteredForms].sort((a, b) => {
        const res = compare(
          columnDataMap(a, column as string),
          columnDataMap(b, column as string)
        );
        return direction === 'asc' ? res : -res;
      });
    }
  }

  duplicateForm(formId: number) {
    const form = this.forms.find((f) => f.id === formId);
    this.formsService
      .duplicateForm(form as Form, this.district.id)
      .subscribe(() => {
        this.updateFilteredForms();
      });
  }

  searchForms(event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value;

    this.filteredForms = new FilterFormSearch().transform(
      this.forms,
      searchTerm,
      this.currentMode
    );
  }

  userCanEdit(form: Form): boolean {
    return currentDistrictOrE2lEmployee(this.user, { id: form.districtId });
  }

  constructor(
    public formsService: FormsService,
    private route: ActivatedRoute,
    public router: Router,
    private districtList: DistrictListService,
    private store: Store
  ) {
    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;
    this.user = this.store.selectSnapshot(UserState.getUser);

    this.districtList
      .fetchDistrictsSimple({ per_page: 1000 })
      .subscribe((districts) => {
        if (districts) {
          const district = districts.find(
            (searchDistrict) =>
              searchDistrict.districtCode === this.districtCode
          ) as DistrictDTO;
          if (district) {
            this.district = district;
          } else {
            this.district = districts.find(
              (searchDistrict) => searchDistrict.id === 2
            ) as DistrictDTO;
            this.districtCode = this.district.districtCode;
          }
          this.formsService.loadForms().subscribe((forms) => {
            this.forms = forms;
            this.updateFilteredForms();
          });
        }
      });
  }

  showCurrentForms() {
    if (this.forms.length > 0) {
      this.filteredForms = this.forms.filter(
        (form) => form.status !== FormStatus.ARCHIVED
      );
    }
  }

  showArchivedForms() {
    if (this.forms.length > 0) {
      this.filteredForms = this.forms.filter(
        (form) => form.status === FormStatus.ARCHIVED
      );
    }
  }

  updateFilteredForms() {
    if (this.currentMode) {
      this.showCurrentForms();
    } else {
      this.showArchivedForms();
    }
  }
}
