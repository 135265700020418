import { districtDTOFromAPIResponse } from '../../dtos/translators/district.translator';
import { getRolesFromDTO } from './role/user-role';
import { getUserTypeFromDTO, UserType } from './type/user-type';
import { UserDTO } from './user.dto';
import { User } from './user.model';

export const userFromDto = ({
  id,
  coach,
  username,
  status,
  email,
  school,
  district,
  profile,
  roles,
  coachee_type,
  is_deleted,
  is_smart,
  is_smart_default,
  smartCoach,
  years_coached,
}: UserDTO): User => {
  const user = new User(
    id,
    username,
    profile,
    coachee_type ? getUserTypeFromDTO(coachee_type) : UserType.LEARNER,
    status,
    email,
    district ? districtDTOFromAPIResponse(district) : undefined,
    school,
    roles ? getRolesFromDTO(roles) : [],
    is_deleted,
    is_smart,
    is_smart_default,
    smartCoach,
    years_coached,
    coach || undefined,
    profile.created_at,
    coachee_type || null
  );
  return user;
};
