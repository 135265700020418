import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CoachingSessionFilterDTO,
  emptyCoachingSessionFilters,
} from 'src/app/common/dtos/coaching-session-filter.dto';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import {
  dateTimeFromNgbDate,
  ngbDateFromDate,
} from 'src/app/common/utilities/date-helpers';
import { CompetencySelectComponent } from '../../competency-select/competency-select.component';
import { SchoolSelectComponent } from '../../school-select/school-select.component';
import { UserSelectComponent } from '../../user-select/user-select.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-coaching-session-filter-modal',
  templateUrl: './coaching-session-filter-modal.component.html',
  styleUrls: ['./coaching-session-filter-modal.component.scss'],
})
export class CoachingSessionFilterModalComponent
  implements AfterViewInit, OnInit
{
  @Input() initialFilters: CoachingSessionFilterDTO;

  @Output() setFilters: EventEmitter<CoachingSessionFilterDTO> =
    new EventEmitter<CoachingSessionFilterDTO>();

  @Output() modalClose: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('modal') modal: ModalComponent;

  @ViewChild('schoolSelect') schoolSelect: SchoolSelectComponent;

  @ViewChild('competencySelect') competencySelect: CompetencySelectComponent;

  @ViewChild('coachSelect') coachSelect: UserSelectComponent;

  @ViewChild('coacheeSelect') coacheeSelect: UserSelectComponent;

  currentFilters: CoachingSessionFilterDTO;

  ngOnInit(): void {
    this.currentFilters = this.initialFilters || emptyCoachingSessionFilters();
  }

  ngAfterViewInit(): void {
    this.modal.open();
    this.modal.isDismissing.subscribe(() => {
      this.modalClose.emit();
    });
  }

  closeModal(saveFilters = false): void {
    if (saveFilters) {
      if (!this.currentFilters.fromDate.year) {
        this.currentFilters.fromDate = ngbDateFromDate(
          dateTimeFromNgbDate(this.currentFilters.toDate)
            .minus({ days: 7 })
            .toJSDate()
        );
      }
      if (!this.currentFilters.toDate) {
        this.currentFilters.toDate = ngbDateFromDate(
          dateTimeFromNgbDate(this.currentFilters.fromDate)
            .plus({ days: 7 })
            .toJSDate()
        );
      }
      this.setFilters.emit(this.currentFilters);
    }
    this.modal.close();
    this.modalClose.emit();
  }

  clearFilters(): void {
    this.currentFilters = emptyCoachingSessionFilters();
    this.schoolSelect.reset();
    this.competencySelect.reset();
    this.coachSelect.reset();
    this.coacheeSelect.reset();
  }

  updateSchools(schools: SchoolDTO[] | SchoolDTO) {
    this.currentFilters.schoolList =
      typeof schools === 'object' ? (schools as SchoolDTO[]) : [schools];
  }
}
