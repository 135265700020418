<div class="form-group media form-box mt-2 card border-0 shadow-sm">
  <label [id]="id" class="form-label m-0 p-3 fs-13 fw-bold">{{
    fieldDefinition.label
  }}</label>
  <div class="container">
    <app-file-list
      (deletedFile)="handleDelete($event)"
      [canDelete]="true"
      [uploadList]="fileUploads"
      [fileList]="filesArray"></app-file-list>
  </div>
  <div class="container">
    <app-file-upload (outputFiles)="handleFileUpload($event)" />
  </div>
</div>
