<div
  class="global-wrapper d-flex flex-row flex-nowrap justify-content-between h-100">
  <div
    id="impersonating"
    class="rounded-bottom pt-1 bg-warning text-center fw-bold fs-11"
    *ngIf="isImpersonatingUser">
    You are currently impersonating another user
  </div>
  <header
    id="mobile-header"
    role="banner"
    class="d-flex d-md-none position-fixed flex-row justify-content-between p-2 w-100"
    [class.expanded]="navExpanded">
    <button
      class="mobile-nav-toggle"
      aria-label="Mobile Navigation Toggle"
      (click)="toggleNav()"
      [class.expanded]="navExpanded">
      <span class="sr-only position-absolute p-0">Toggle navigation</span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>

    <a routerLink="/" class="mobile-logo" title="GroweLab">
      <img
        src="../../assets/logo_horiz_light.png"
        width="120px"
        alt="GroweLab Logo" />
    </a>

    <div class="notification"></div>
  </header>

  <div
    id="sidebar"
    [ngClass]="[
      'd-flex',
      'flex-column',
      'h-100',
      isImpersonatingUser ? 'impersonating' : ''
    ]"
    [class.expanded]="navExpanded"
    (linkClicked)="toggleNav()">
    <button
      class="mobile-nav-close"
      aria-label="Mobile Navigation Toggle"
      (click)="toggleNav()"
      [class.expanded]="navExpanded">
      <i class="bi bi-x"></i>
    </button>

    <app-sidebar (linkClicked)="toggleNav()"></app-sidebar>
  </div>
  <div
    id="mobile-background"
    [class.expanded]="navExpanded"
    (click)="toggleNav()"></div>
  <div id="content" class="h-100 d-flex flex-column">
    <div role="main" class="container-wrapper">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>

<app-confetti></app-confetti>
