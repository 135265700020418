import { numberFromNumberOrString } from 'src/app/common/utilities/type-helpers';
import {
  CreateOptionPayload,
  WillAPIResponse,
  WillDTOFromAPIResponse,
} from 'src/app/private/shared/types/payloads/coaching-log.payloads';
import {
  AssessmentAPIResponse,
  AttendeeRubricsAPIResponse,
  CreatedUpdatedOptionAPIResponse,
  GoalAPIResponse,
  IndicatorAPIResponse,
  IndicatorGroupsAPIResponse,
  IndicatorSetAPIResponse,
  LevelAPIResponse,
  OptionAPIResponse,
  RubricAPIResponse,
  StandardAPIResponse,
  StandardGroupAPIResponse,
} from 'src/app/private/shared/types/responses/coaching-log.responses';

import {
  AssessmentDTO,
  AttendeeRubricDTO,
  AttendeeRubricStateModelDTO,
  CompetencyDTO,
  CompetencyGroupDTO,
  CreatedOptionDTO,
  EvidenceDTO,
  GoalDTO,
  IndicatorDTO,
  IndicatorSetDTO,
  LevelDTO,
  OptionDTO,
  PlannedEvidenceDTO,
  RubricDTO,
  StrandDTO,
  WillDTO,
} from '../../dtos/attendee-rubric.dto';
import { DecomposedRubric } from '../../models/decomposed-rubric.model';
import { evidenceDTOFromAPIResponse } from './evidence.translators';

export const assessmentsDTOFromAPIResponse = (
  response: AssessmentAPIResponse
): AssessmentDTO => ({
  id: response.id,
  assessmentType: response.assessment_type,
  egroweIndicatorId: response.egrowe_indicator_id,
  assessorUserId: response.assessor_user_id,
  assesseeUserId: response.assessee_user_id,
  districtId: response.district_id,
  createdAt: response.created_at,
  updatedAt: response.updated_at,
  egroweRubricId: response.egrowe_rubric_id,
  egroweCoachlogId: response.egrowe_coachlog_id,
  nearestCoachlogId: response.nearest_coachlog_id,
  mainCoachlogId: response.main_coachlog_id,
  assessor: response.assessor,
});

export const plannedEvidenceDTOFromAPIResponse = (
  response: WillAPIResponse
): PlannedEvidenceDTO => ({
  id: response.id,
  content: response.content,
  dueDate: response.due_date,
});

export const checkIndicatorForCoachAssessments = (indicator: IndicatorDTO) => {
  indicator.hasCoachAssessment =
    indicator.assessments.filter(
      (assessment) => assessment.assessmentType === 'coach:standard'
    ).length > 0;
  return indicator;
};

export const indicatorDTOFromAPIResponse = (
  response: IndicatorAPIResponse
): IndicatorDTO => {
  let indicatorDto: IndicatorDTO = {
    id: response.id,
    content: response.content,
    hasCoachAssessment: false,
    assessments: response.assessments
      ? response.assessments.map((assessment) =>
          assessmentsDTOFromAPIResponse(assessment)
        )
      : [],
    requirements: response.requirements,
  };
  indicatorDto = checkIndicatorForCoachAssessments(indicatorDto);
  return indicatorDto;
};

interface CreateOptionAPIPayload {
  content: string;
  egrowe_coachlog_id: number;
  egrowe_indicator_group_id: number;
  egrowe_rubric_id: number;
  egrowe_standard_id: number;
  level: number;
}

export interface OptionAPIPayload {
  id: number;
  content: string;
}

export const createOptionPayloadToCreateOptionAPIPayload = (
  payload: CreateOptionPayload
): CreateOptionAPIPayload => ({
  content: payload.content,
  egrowe_coachlog_id: payload.groweCoachlogId,
  egrowe_indicator_group_id: payload.groweStrandId,
  egrowe_rubric_id: payload.groweRubricId,
  egrowe_standard_id: payload.groweCompetencyId,
  level: payload.level,
});

export const optionDTOFromCreatedUpdatedOptionAPIResponse = (
  response: CreatedUpdatedOptionAPIResponse
): OptionDTO => ({
  coachlogId: response.egrowe_coachlog_id,
  id: response.id,
  content: response.content,
  strandId: response.egrowe_indicator_group_id,
  level: response.level,
  fromBankOption: null,
  fromCuratedOptionId: 0,
  fromCuratedOption: null,
  resources: [],
  will: null,
});

export const optionDTOFromCreatedOptionDTO = (
  createdOption: CreatedOptionDTO
): OptionDTO => ({
  coachlogId: createdOption.groweCoachlogId,
  id: createdOption.id,
  content: createdOption.content,
  fromCuratedOption: false,
  fromBankOption: 0,
  fromCuratedOptionId: null,
  resources: [],
  level: createdOption.level,
  strandId: createdOption.groweStrandId,
  will: null,
});

export const optionDTOFromAPIResponse = (
  response: OptionAPIResponse,
  level: number,
  strandId: number,
  coachlogId: number
): OptionDTO => ({
  coachlogId,
  id: response.id,
  content: response.content,
  fromCuratedOption: response.fromCuratedOption,
  fromBankOption: response.from_bank_option,
  fromCuratedOptionId: response.from_curated_option_id,
  resources: response.resources || [],
  level,
  strandId,
  will: response.will
    ? WillDTOFromAPIResponse(
        {
          /* Data from the API is not consistent, so we need to make sure the data
       we need exists */
          ...response.will,
          egrowe_coachlog_option_id: response.id,
        },
        coachlogId
      )
    : null,
});

export const goalDTOFromAPIResponse = (response: GoalAPIResponse): GoalDTO => ({
  id: response.id,
  userId: numberFromNumberOrString(response.user_id),
  egroweStandardId: numberFromNumberOrString(response.egrowe_standard_id),
  egroweIndicatorGroupId: numberFromNumberOrString(
    response.egrowe_indicator_group_id
  ),
  egroweCoachlogId: numberFromNumberOrString(response.egrowe_coachlog_id),
  goalLevel: response.goal_level,
  createdAt: response.created_at,
  updatedAt: response.updated_at,
});

const levelDTOFromAPIResponse = (
  response: LevelAPIResponse,
  level: number,
  strandId: number,
  coachlogId: number
): { levelDto: LevelDTO; wills: WillDTO[]; evidence: EvidenceDTO[] } => {
  const wills: WillDTO[] = [];
  const evidence: EvidenceDTO[] = [];
  const levelDto: LevelDTO = {
    evidence: response.evidences
      ? response.evidences.map((evidenceResponse) => {
          const evidenceDto = evidenceDTOFromAPIResponse(evidenceResponse);
          evidence.push(evidenceDto);
          return evidenceDto;
        })
      : [],
    levelCompleted: false,
    goal: response.goal ? goalDTOFromAPIResponse(response.goal) : null,
    highestOptionLevel: response.highestOptionLevel,
    indicators: response.indicators.map((indicator) =>
      indicatorDTOFromAPIResponse(indicator)
    ),
    options: response.options
      ? response.options.map((option) => {
          const optionDto = optionDTOFromAPIResponse(
            option,
            level,
            strandId,
            coachlogId
          );
          if (optionDto.will) {
            wills.push(optionDto.will);
          }
          return optionDto;
        })
      : [],
  };
  levelDto.levelCompleted =
    levelDto.indicators.filter(
      (indicator) => indicator.hasCoachAssessment !== true
    ).length === 0;
  return { levelDto, wills, evidence };
};

export const setGoalAndCompletedLevelsFromStandDTO = (
  strand: StrandDTO
): {
  strandDto: StrandDTO;
  indicators: IndicatorDTO[];
  options: OptionDTO[];
} => {
  const levels = [strand.level1, strand.level2, strand.level3, strand.level4];
  const indicators: IndicatorDTO[] = [];
  const options: OptionDTO[] = [];
  levels.map((level) => {
    const unfilledIndicators = level.indicators.filter(
      (indicator) => !indicator.hasCoachAssessment
    );
    indicators.push(...level.indicators);
    level.levelCompleted = unfilledIndicators.length === 0;
    return level;
  });
  if (strand.level1.goal) {
    strand.goalLevel = 1;
  } else if (strand.level2.goal) {
    strand.goalLevel = 2;
  } else if (strand.level3.goal) {
    strand.goalLevel = 3;
  } else if (strand.level4.goal) {
    strand.goalLevel = 4;
  } else {
    strand.goalLevel = 0;
  }
  if (strand.level4.levelCompleted) {
    strand.completedLevel = 4;
  } else if (strand.level3.levelCompleted) {
    strand.completedLevel = 3;
  } else if (strand.level2.levelCompleted) {
    strand.completedLevel = 2;
  } else if (strand.level1.levelCompleted) {
    strand.completedLevel = 1;
  } else {
    strand.completedLevel = 0;
  }
  options.push(
    ...strand.level1.options,
    ...strand.level2.options,
    ...strand.level3.options,
    ...strand.level4.options
  );
  return { strandDto: strand, indicators, options };
};

export const strandDTOFromAPIResponse = (
  response: IndicatorGroupsAPIResponse,
  indicatorSetId: number,
  competencyId: number,
  coachlogId: number
): {
  strandDto: StrandDTO;
  decomposedStrand: {
    indicators: IndicatorDTO[];
    options: OptionDTO[];
    wills: WillDTO[];
    evidence: EvidenceDTO[];
  };
} => {
  const {
    levelDto: level1,
    wills: level1Will,
    evidence: level1Evidence,
  } = levelDTOFromAPIResponse(response.level1, 1, response.id, coachlogId);
  const {
    levelDto: level2,
    wills: level2Will,
    evidence: level2Evidence,
  } = levelDTOFromAPIResponse(response.level2, 2, response.id, coachlogId);
  const {
    levelDto: level3,
    wills: level3Will,
    evidence: level3Evidence,
  } = levelDTOFromAPIResponse(response.level3, 3, response.id, coachlogId);
  const {
    levelDto: level4,
    wills: level4Will,
    evidence: level4Evidence,
  } = levelDTOFromAPIResponse(response.level4, 4, response.id, coachlogId);
  const wills = [...level1Will, ...level2Will, ...level3Will, ...level4Will];
  const evidence = [
    ...level1Evidence,
    ...level2Evidence,
    ...level3Evidence,
    ...level4Evidence,
  ];
  const rawStrandDto: StrandDTO = {
    indicatorSetId,
    competencyId,
    goalLevel: 0,
    id: response.id,
    completedLevel: 0,
    level1,
    level2,
    level3,
    level4,
    title: response.title,
  };
  const { strandDto, indicators, options } =
    setGoalAndCompletedLevelsFromStandDTO(rawStrandDto);
  return {
    strandDto,
    decomposedStrand: {
      indicators,
      options,
      wills,
      evidence,
    },
  };
};

export const indicatorSetDTOFromAPIResponse = (
  response: IndicatorSetAPIResponse,
  competencyId: number,
  coachlogId: number
): {
  indicatorSetDto: IndicatorSetDTO;
  decomposedIndicatorSet: {
    strands: StrandDTO[];
    indicators: IndicatorDTO[];
    options: OptionDTO[];
    wills: WillDTO[];
    evidence: EvidenceDTO[];
  };
} => {
  const strands: StrandDTO[] = [];
  const indicators: IndicatorDTO[] = [];
  const options: OptionDTO[] = [];
  const wills: WillDTO[] = [];
  const evidence: EvidenceDTO[] = [];
  response.indicatorGroups.forEach((strandData) => {
    const { strandDto, decomposedStrand } = strandDTOFromAPIResponse(
      strandData,
      response.id,
      competencyId,
      coachlogId
    );
    strands.push(strandDto);
    indicators.push(...decomposedStrand.indicators);
    options.push(...decomposedStrand.options);
    wills.push(...decomposedStrand.wills);
    evidence.push(...decomposedStrand.evidence);
  });
  return {
    indicatorSetDto: {
      id: response.id,
      competencyId,
      strands,
      level1Name: response.level1_name,
      level2Name: response.level2_name,
      level3Name: response.level3_name,
      level4Name: response.level4_name,
    },
    decomposedIndicatorSet: {
      strands,
      indicators,
      wills,
      options,
      evidence,
    },
  };
};

export const competencyDTOFromAPIResponse = (
  response: StandardAPIResponse,
  coachlogId: number
): {
  competencyDto: CompetencyDTO;
  decomposedCompetency: {
    indicatorSet: IndicatorSetDTO;
    strands: StrandDTO[];
    indicators: IndicatorDTO[];
    options: OptionDTO[];
    wills: WillDTO[];
    evidence: EvidenceDTO[];
  };
} => {
  const { indicatorSetDto, decomposedIndicatorSet } =
    indicatorSetDTOFromAPIResponse(
      response.indicatorSet,
      response.id,
      coachlogId
    );
  return {
    competencyDto: {
      completedPreAssessment: response.completed_pre_assessment,
      content: response.content,
      faqResourceSlug: response.faq_resource_slug,
      id: response.id,
      indicatorSet: indicatorSetDto,
      title: response.title,
      coachlogId,
    },
    decomposedCompetency: {
      indicatorSet: indicatorSetDto,
      strands: decomposedIndicatorSet.strands,
      indicators: decomposedIndicatorSet.indicators,
      options: decomposedIndicatorSet.options,
      wills: decomposedIndicatorSet.wills,
      evidence: decomposedIndicatorSet.evidence,
    },
  };
};

export const competencyGroupsDTOFromAPIResponse = (
  response: StandardGroupAPIResponse,
  coachlogId: number
): {
  competencyGroupDto: CompetencyGroupDTO;
  decomposedCompetencyGroup: {
    competencies: CompetencyDTO[];
    indicatorSets: IndicatorSetDTO[];
    strands: StrandDTO[];
    indicators: IndicatorDTO[];
    options: OptionDTO[];
    wills: WillDTO[];
    evidence: EvidenceDTO[];
  };
} => {
  const competencies: CompetencyDTO[] = [];
  const strands: StrandDTO[] = [];
  const indicatorSets: IndicatorSetDTO[] = [];
  const indicators: IndicatorDTO[] = [];
  const options: OptionDTO[] = [];
  const wills: WillDTO[] = [];
  const evidence: EvidenceDTO[] = [];
  response.standards.forEach((standard) => {
    const { competencyDto, decomposedCompetency } =
      competencyDTOFromAPIResponse(standard, coachlogId);
    competencies.push(competencyDto);
    indicatorSets.push(decomposedCompetency.indicatorSet);
    strands.push(...decomposedCompetency.strands);
    indicators.push(...decomposedCompetency.indicators);
    options.push(...decomposedCompetency.options);
    wills.push(...decomposedCompetency.wills);
    evidence.push(...decomposedCompetency.evidence);
  });
  return {
    competencyGroupDto: {
      id: response.id,
      competencies,
      title: response.title,
    },
    decomposedCompetencyGroup: {
      competencies,
      indicatorSets,
      strands,
      indicators,
      options,
      wills,
      evidence,
    },
  };
};

export const rubricDTOFromAPIResponse = (
  response: RubricAPIResponse,
  coachlogId: number
): {
  rubricDto: RubricDTO;
  decomposedRubrics: {
    competencyGroups: CompetencyGroupDTO[];
    competencies: CompetencyDTO[];
    indicatorSets: IndicatorSetDTO[];
    strands: StrandDTO[];
    indicators: IndicatorDTO[];
    options: OptionDTO[];
    wills: WillDTO[];
    evidence: EvidenceDTO[];
  };
} => {
  const competencyGroups: CompetencyGroupDTO[] = [];
  const competenciesCollection: CompetencyDTO[] = [];
  const indicatorSets: IndicatorSetDTO[] = [];
  const strands: StrandDTO[] = [];
  const indicators: IndicatorDTO[] = [];
  const options: OptionDTO[] = [];
  const wills: WillDTO[] = [];
  const evidence: EvidenceDTO[] = [];
  response.standardGroups.forEach((standardGroup) => {
    const { competencyGroupDto, decomposedCompetencyGroup } =
      competencyGroupsDTOFromAPIResponse(standardGroup, coachlogId);
    competencyGroups.push(competencyGroupDto);
    competenciesCollection.push(...decomposedCompetencyGroup.competencies);
    indicatorSets.push(...decomposedCompetencyGroup.indicatorSets);
    strands.push(...decomposedCompetencyGroup.strands);
    indicators.push(...decomposedCompetencyGroup.indicators);
    options.push(...decomposedCompetencyGroup.options);
    wills.push(...decomposedCompetencyGroup.wills);
    evidence.push(...decomposedCompetencyGroup.evidence);
  });
  return {
    rubricDto: {
      id: response.id,
      competencyGroups,
      title: response.title,
      coachlogId,
    },
    decomposedRubrics: {
      competencyGroups,
      competencies: competenciesCollection,
      indicatorSets,
      strands,
      indicators,
      options,
      wills,
      evidence,
    },
  };
};

export const attendeeRubricsDTOFromAPIResponse = (
  response: AttendeeRubricsAPIResponse
): AttendeeRubricStateModelDTO => {
  const rubricState: AttendeeRubricStateModelDTO = {
    attendeeRubrics: [],
    decomposedByUser: {},
    options: [],
    evidence: [],
  };
  response.items.forEach((item) => {
    const rubric: AttendeeRubricDTO = {
      egroweCoachlogId: item.egrowe_coachlog_id,
      rubrics: [],
      userId: item.user_id,
    };
    if (!rubricState.decomposedByUser[item.user_id]) {
      rubricState.decomposedByUser[item.user_id] = new DecomposedRubric();
    }
    const decomposedRubric = rubricState.decomposedByUser[item.user_id];
    item.rubrics.forEach((rubricResponse) => {
      const { rubricDto, decomposedRubrics } = rubricDTOFromAPIResponse(
        rubricResponse,
        item.egrowe_coachlog_id
      );
      rubric.rubrics.push(rubricDto);
      decomposedRubric.rubrics.push(rubricDto);
      decomposedRubric.competencyGroups.push(
        ...decomposedRubrics.competencyGroups
      );
      decomposedRubric.competencies.push(...decomposedRubrics.competencies);
      decomposedRubric.indicatorSets.push(...decomposedRubrics.indicatorSets);
      decomposedRubric.strands.push(...decomposedRubrics.strands);
      decomposedRubric.indicators.push(...decomposedRubrics.indicators);
      decomposedRubrics.wills.forEach((will) => {
        // Initial response differs from the direct responses, need to rebuild the data
        decomposedRubric.wills.push({ ...will, userId: item.user_id });
      });
      // Multiple users on log have same options, so for options only we need to actively prevent dupes
      decomposedRubrics.options.forEach((option) => {
        if (rubricState.options.filter((o) => o.id === option.id).length === 0)
          rubricState.options.push(option);
      });
      rubricState.evidence.push(...decomposedRubrics.evidence);
    });
    rubricState.attendeeRubrics.push(rubric);
  });
  return rubricState;
};
