export enum BooleanNumber {
  FALSE,
  TRUE,
}

export const booleanFromBooleanNumber = (value: number): boolean => {
  if (value === BooleanNumber.FALSE) {
    return false;
  }
  return true;
};

export const booleanNumberFromBoolean = (value: boolean): number => {
  if (value === false) {
    return BooleanNumber.FALSE;
  }
  return BooleanNumber.TRUE;
};
