<div class="container container-main">
  <app-dashboard-header
    *ngIf="user$ | async as user"
    [dashboardType]="user.type"
    [showSwitcher]="false"></app-dashboard-header>
  <div class="card border-0 p-3 mt-3 overflow-y table-container">
    <div
      class="tool position-absolute top-0 start-100 translate-middle rounded-circle fs-3"
      style="background-color: white; line-height: 1rem">
      <i
        [style.cursor]="'pointer'"
        routerLink="/dashboard"
        ngbTooltip="Close"
        class="bi bi-x-circle color-primary"></i>
    </div>
    <div
      class="table table-toolbar d-flex justify-content-between align-items-center">
      <div class="coachee-search table-search">
        <input
          type="search"
          class="form-control ms-1"
          placeholder="Filter evidence"
          aria-label="Search"
          (input)="search($event)" />
      </div>
    </div>
    <ng-container *ngIf="!isLoading; else loadingTemplate">
      <ng-container *ngIf="tableData.length > 0; else noEvidence">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" (sort)="onSort($event)" sortable="coachee">
                Coachee
                <i class="bi bi-caret-down {{ sortIconTemp.coachee }}"></i>
              </th>
              <th scope="col" (sort)="onSort($event)" sortable="evidence">
                Evidence
                <i class="bi bi-caret-down {{ sortIconTemp.evidence }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="competency"
                class="hide-on-mobile">
                Competency
                <i class="bi bi-caret-down {{ sortIconTemp.competency }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="level"
                class="text-center text-nowrap hide-on-mobile">
                Level
                <i class="bi bi-caret-down {{ sortIconTemp.level }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="dateUploaded"
                class="text-center text-nowrap">
                Date
                <i class="bi bi-caret-down {{ sortIconTemp.dateUploaded }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="reviewed"
                class="text-center text-nowrap hide-on-mobile">
                Reviewed
                <i class="bi bi-caret-down {{ sortIconTemp.reviewed }}"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let evidence of tableData">
              <td>
                <div class="d-flex flex-nowrap align-items-center my-2">
                  <app-avatar
                    [profile]="evidence.coachee.profile"
                    [avatarSize]="'small'">
                  </app-avatar>
                  <div class="ms-2">
                    {{ evidence.coachee.profile.first_name }}
                    {{ evidence.coachee.profile.last_name }}
                  </div>
                </div>
              </td>
              <td class="align-middle fw-bold">
                <app-evidence-summary
                  [evidenceId]="evidence.id"
                  (evidenceAssesed)="updateReviewedStatus($event)"
                  >{{ evidence.evidence }}</app-evidence-summary
                >
              </td>
              <td class="align-middle fw-light hide-on-mobile">
                {{ evidence.competency }}
              </td>
              <td class="align-middle text-center fw-bold fs-5 hide-on-mobile">
                {{ evidence.level }}
              </td>
              <td class="align-middle text-center fw-bold fs-5">
                {{ evidence.dateUploaded | formatDateMMDD }}
              </td>
              <td class="align-middle fs-5 text-center hide-on-mobile">
                <ng-container
                  *ngIf="
                    evidence.reviewed;
                    then checked;
                    else unchecked
                  "></ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-template #noEvidence
        ><p class="text-center text-muted">
          No evidence to review.
        </p></ng-template
      >
    </ng-container>
    <ng-template #loadingTemplate>
      <app-loader></app-loader>
    </ng-template>
  </div>
</div>

<ng-template #checked
  ><i class="bi bi-check2-circle text-info"></i
></ng-template>
<ng-template #unchecked><i class="bi bi-circle-fill"></i></ng-template>
