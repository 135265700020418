import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { PresenceChannel } from 'pusher-js';
import {
  CreateMultipleTodoPusherResponse,
  DeleteTodoPusherResponse,
  PusherAttachmentsResponse,
  UpdateTodoPusherResponse,
} from 'src/app/common/types/responses/responses';

import { AvatarDTO } from '../../components/avatar/avatar.dto';
import { avatarDTOFromPusher } from '../../components/avatar/avatar.translators';
import { CoachlogPusherMessageDTO } from '../../dtos/coachlog-pusher-message.dto';
import {
  assessmentsDTOFromAPIResponse,
  goalDTOFromAPIResponse,
  optionDTOFromCreatedUpdatedOptionAPIResponse,
} from '../../helpers/translators/attendee-rubrics-dto.translator';
import { evidenceAttachmentDTOfromPusherAttachmentsResponse } from '../../helpers/translators/coaching-session-dto.translator';
import {
  AddIndicatorAssessment,
  AddLogAttachments,
  AddOption,
  AddResourceToOption,
  AddWill,
  DeleteAttendee,
  DeleteLogAttachments,
  DeleteShadower,
  RemoveCompetency,
  RemoveIndicatorAssessment,
  RemoveOption,
  RemoveResourceFromOption,
  RemoveWill,
  UpdateAttendee,
  UpdateCompetency,
  UpdateGoal,
  UpdateLog,
  UpdateNotes,
  UpdateOption,
  UpdateShadower,
  UpdateWill,
} from '../../state/coaching-log/coaching-log.actions';
import {
  WillAPIResponse,
  WillDTOFromAPIResponse,
} from '../../types/payloads/coaching-log.payloads';
import {
  AssessmentAPIResponse,
  CoachingSessionAttendeesAPIResponse,
  CoachingSessionDeleteShadowerPusherResponse,
  CoachingSessionUpdateShadowerPusherResponse,
  GoalAPIResponse,
} from '../../types/responses/coaching-log.responses';
import { ConfettiService } from '../confetti/confetti.service';
import { PusherGuide } from '../pusher/pusher-guide.service';
import { PusherService } from '../pusher/pusher.service';
import { TodoService } from '../todo/todo.service';
import {
  PusherCompetencyAdded,
  PusherCompetencyDeleted,
  PusherMessageAssessmentAdded,
  PusherMessageAssessmentDeleted,
  PusherMessageGoalSet,
  PusherMessageNotes,
  PusherMessageOptionCreatedUpdated,
  PusherMessageOptionDeleted,
  PusherMessageOptionResource,
  PusherMessageOptionResourceDeleted,
  PusherMessageWillCreated,
} from './coaching-log-service.dto';

@Injectable()
export class CoachingLogPusherService extends PusherGuide {
  public attendeeRosters: { [channelId: string]: AvatarDTO[] } = {};

  constructor(
    private confettiService: ConfettiService,
    pusherService: PusherService,
    private store: Store,
    private todoService: TodoService
  ) {
    super(pusherService, 'presence');
  }

  updateAttendeeRoster(channelId: string) {
    const channel = this.getChannel(channelId) as PresenceChannel;
    const logId = parseInt(channelId.replace('coachlog-v2-', ''));
    this.attendeeRosters[logId] = Object.keys(channel.members.members).map(
      (key) => avatarDTOFromPusher(channel.members.members[key])
    );
  }

  protected override addDefaultBindings(channelId: string): void {
    const channel = this.getChannel(channelId);
    const logId = parseInt(channelId.replace('coachlog-v2-', ''));
    this.updateAttendeeRoster(channelId);
    if (channel) {
      channel.bind('pusher:subscription_succeeded', () => {
        this.updateAttendeeRoster(channelId);
      });

      channel.bind('pusher:member_added', () => {
        this.updateAttendeeRoster(channelId);
      });

      channel.bind('pusher:member_removed', () => {
        this.updateAttendeeRoster(channelId);
      });

      channel.bind(
        'coachlog:assessment_deleted',
        (message: PusherMessageAssessmentDeleted) => {
          this.store.dispatch(
            new RemoveIndicatorAssessment(parseInt(message.item.toString(), 10))
          );
        }
      );

      channel.bind(
        'coachlog:assessment',
        (message: PusherMessageAssessmentAdded) => {
          this.store.dispatch(
            new AddIndicatorAssessment(
              assessmentsDTOFromAPIResponse(
                message.item as AssessmentAPIResponse
              )
            )
          );
          this.confettiService.showConfetti();
        }
      );

      channel.bind('coachlog:goal', (message: PusherMessageGoalSet) => {
        this.store.dispatch(
          new UpdateGoal(
            goalDTOFromAPIResponse(message.item as GoalAPIResponse)
          )
        );
      });

      channel.bind(
        'coachlog:options',
        (message: PusherMessageOptionCreatedUpdated) => {
          /* eslint-disable-next-line no-prototype-builtins */
          if (message.hasOwnProperty('is_new')) {
            if (message.is_new) {
              this.store.dispatch(
                new AddOption(
                  optionDTOFromCreatedUpdatedOptionAPIResponse(message.option)
                )
              );
            } else {
              this.store.dispatch(
                new UpdateOption(
                  optionDTOFromCreatedUpdatedOptionAPIResponse(message.option)
                )
              );
            }
          }
        }
      );

      channel.bind(
        'coachlog:option_deleted',
        (message: PusherMessageOptionDeleted) => {
          this.store.dispatch(new RemoveOption(message.option.id));
        }
      );

      channel.bind('coachlog:wills', (message: PusherMessageWillCreated) => {
        /* eslint-disable-next-line no-prototype-builtins */
        const willDTO = WillDTOFromAPIResponse(
          message.will as WillAPIResponse,
          logId
        );
        if (message?.change_user_id) {
          this.store.dispatch(new UpdateWill(willDTO));
        } else {
          this.store.dispatch(new AddWill(willDTO));
        }
      });

      channel.bind(
        'coachlog:will_deleted',
        (message: PusherMessageWillCreated) => {
          const willDTO = WillDTOFromAPIResponse(
            message.will as WillAPIResponse,
            logId
          );
          const payload = { willId: willDTO.id, userId: willDTO.userId };
          this.store.dispatch(new RemoveWill(payload));
        }
      );

      channel.bind(
        'coachlog:option_resource',
        (message: PusherMessageOptionResource) => {
          this.store.dispatch(
            new AddResourceToOption({
              optionId: parseInt(
                message.option_resource.egrowe_coachlog_option_id
              ),
              resource: message.option_resource.resource,
            })
          );
        }
      );

      channel.bind('coachlog:notes', (message: PusherMessageNotes) => {
        this.store.dispatch(
          new UpdateNotes({ notes: message.notes, coachlogId: logId })
        );
      });

      channel.bind(
        'coachlog:option_resource_deleted',
        (message: PusherMessageOptionResourceDeleted) => {
          this.store.dispatch(
            new RemoveResourceFromOption({
              optionId: message.option_resource.egrowe_coachlog_option_id,
              resourceId: message.option_resource.elearn_resource_id,
            })
          );
        }
      );

      channel.bind('coachlog:standards', (message: PusherCompetencyAdded) => {
        this.store.dispatch(new UpdateCompetency(message));
      });

      channel.bind(
        'coachlog:standard_deleted',
        (message: PusherCompetencyDeleted) => {
          this.store.dispatch(new RemoveCompetency(message));
        }
      );

      channel.bind(
        'coachlog:coachinglog',
        (message: CoachlogPusherMessageDTO) => {
          message.coachlogId = logId;
          this.store.dispatch(new UpdateLog(message));
        }
      );

      channel.bind('coachlog:attendee_deleted', (message: { id: string }) => {
        this.store.dispatch(
          new DeleteAttendee({ id: parseInt(message.id), coachlogId: logId })
        );
      });

      channel.bind(
        'coachlog:attachments',
        (message: PusherAttachmentsResponse) => {
          if (message.is_deleted) {
            this.store.dispatch(
              new DeleteLogAttachments({ message, coachlogId: logId })
            );
          }
          if (message.is_new) {
            this.store.dispatch(
              new AddLogAttachments(
                evidenceAttachmentDTOfromPusherAttachmentsResponse(message)
              )
            );
          }
        }
      );

      channel.bind(
        'coachlog:attendees',
        (message: CoachingSessionAttendeesAPIResponse) => {
          this.store.dispatch(new UpdateAttendee(message));
        }
      );

      channel.bind(
        'coachlog:shadowers',
        (message: CoachingSessionUpdateShadowerPusherResponse) => {
          this.store.dispatch(new UpdateShadower(message.item));
        }
      );

      channel.bind(
        'coachlog:shadower_deleted',
        (message: CoachingSessionDeleteShadowerPusherResponse) => {
          message.item.id = parseInt(message.item.id.toString(), 10);
          this.store.dispatch(new DeleteShadower(message.item));
        }
      );

      channel.bind(
        'coachlog:user_todo_create_multiple',
        (message: CreateMultipleTodoPusherResponse) => {
          this.todoService.applyTodoMultipleCreatedChange(message);
        }
      );

      channel.bind(
        'coachlog:user_todo_update',
        (message: UpdateTodoPusherResponse) => {
          this.todoService.applyTodoUpdate(message);
        }
      );

      channel.bind(
        'coachlog:user_todo_delete',
        (message: DeleteTodoPusherResponse) => {
          this.todoService.applyTodoDelete(message);
        }
      );
    }
  }
}
