import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { celebrationListDTOFromAPIResponse } from '../../helpers/translators/celebrations.translator';
import {
  CelebrationsListAPIResponse,
  CreatedCelebrationAPIResponse,
} from '../../types/responses/celebrations.responses';
import {
  CelebrationAttachmentPayload,
  CreateCelebrationPayload,
} from '../../types/payloads/celebrations.payloads';

@Injectable({ providedIn: 'root' })
export class CelebrationsService {
  constructor(private apiService: APICoreService) {}

  getCelebrations() {
    const toDate = new Date();
    const fromDate = new Date(new Date().setDate(toDate.getDate() - 30));
    return this.apiService
      .getRequest(
        `egrowe/endorsements?from_date=${Math.round(
          fromDate.getTime() / 1000
        )}&to_date=${Math.round(toDate.getTime() / 1000)}&own_only=1`
      )
      .pipe(
        map((res) => {
          const responseDTO = celebrationListDTOFromAPIResponse(
            res as CelebrationsListAPIResponse
          );
          return responseDTO;
        })
      );
  }

  createCelebration(users: number[], content: string) {
    const payload: CreateCelebrationPayload = {
      title: 'User Endorsement',
      content: content || '',
      endorse_users: users,
      is_brightspot: 0,
    };
    return this.apiService.postRequest(`egrowe/endorsements`, payload).pipe(
      map((res) => {
        const responseDTO = res as CreatedCelebrationAPIResponse;
        return responseDTO;
      })
    );
  }

  updateCelebrationCompetencies(celebrationId: number, standardId: number) {
    return this.apiService
      .postRequest(
        `egrowe/endorsements/${celebrationId}/set-standard/${standardId}`
      )
      .pipe(map((res) => res));
  }

  updateCelebrationAttachments(payload: CelebrationAttachmentPayload) {
    return this.apiService
      .putRequest(`growelab/endorsements/attach-file`, payload)
      .pipe(map((res) => res));
  }
}
