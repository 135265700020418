<div class="card border-0">
  <div class="card-header pt-3">
    <h2 class="card-title float-start" (click)="triggerCollapse()">
      {{ cardTitle }}
      <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
    </h2>
  </div>
  <div
    class="card-body leader-glance"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed">
    <div class="row">
      <ng-container *ngFor="let item of leaderGlanceDataList">
        <div class="col-lg-6 col-12 mb-3">
          <div class="d-flex align-items-center">
            <div
              class="icon"
              [ngStyle]="{
                'background-image':
                  'url(/assets/report/' +
                  item.title.split(' ').join('').toLowerCase() +
                  '.svg)'
              }"></div>
            <div>
              <span class="fw-800 fs-25">
                {{ item.count }}
              </span>
              <span class="fw-200 fs-17 ms-1">
                {{ item.title }}
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
