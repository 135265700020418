import { Component, OnInit } from '@angular/core';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { UserState } from 'src/app/common/state/user/user.state';
import { map } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { ResourcesBookmarkService } from '../../../services/resources/resources-bookmark.service';
import { LibraryShelfModelDTO } from '../../../dtos/resources.dto';

@Component({
  selector: 'app-my-resources-list',
  templateUrl: './my-resources-list.component.html',
  styleUrls: ['./my-resources-list.component.scss'],
})
export class MyResourcesListComponent implements OnInit {
  myShelves: LibraryShelfModelDTO[];

  myStarredShelves: LibraryShelfModelDTO[] | null = null;

  showNewShelfModal = false;

  newShelf = {} as LibraryShelfModelDTO;

  createdByYouShelf: LibraryShelfModelDTO;

  shareLibraryUrl: string;

  shareShelfUrl: string;

  canCreate = true;

  user$ = select(UserState.getUser);

  user: User | null = null;

  copiedStatus: boolean;

  constructor(
    private resourceService: ResourcesBookmarkService,
    private clipboard: Clipboard
  ) {
    this.user$
      .pipe(
        map((user) => {
          this.user = user;
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.newShelf.title = '';
    this.getCreatedByYouShelf();
    this.getMyShelfs();
  }

  getCreatedByYouShelf() {
    this.resourceService.getCreatedByYou().subscribe((result) => {
      if (result.item) {
        this.createdByYouShelf = result.item;
      }
    });
  }

  getMyShelfs() {
    this.resourceService.getMyShelfs().subscribe((response) => {
      if (response.items) {
        this.myStarredShelves = response.items.filter(
          (shelf: LibraryShelfModelDTO) => shelf.usersFavorite === 1
        );
        this.myShelves = response.items.filter(
          (shelf: LibraryShelfModelDTO) => shelf.usersFavorite === 0
        );
      } else {
        this.myShelves = [];
      }
    });
  }

  createShelf() {
    this.showNewShelfModal = false;
    this.resourceService.createShelf(this.newShelf).subscribe((response) => {
      if (response.item) {
        this.myShelves.push(response.item);
      }
      this.newShelf.title = '';
      this.newShelf.description = '';
    });
  }

  starSelect(id: number, favorite: number, shelf: LibraryShelfModelDTO[]) {
    if (favorite) {
      const starSelected = shelf.find(
        (item: LibraryShelfModelDTO) => item.id === id
      );
      if (starSelected) starSelected.isLoading = true;
      this.resourceService.deleteStarShelf(id).subscribe((response) => {
        if (response) {
          this.updateLocalShelf(shelf, id, 0);
        }
      });
    } else {
      const selected = shelf.find(
        (item: LibraryShelfModelDTO) => item.id === id
      );
      if (selected) selected.isLoading = true;
      this.resourceService.postStarShelf(id).subscribe((response) => {
        if (response) {
          this.updateLocalShelf(shelf, id, 1);
        }
      });
    }
  }

  /* eslint-disable-next-line class-methods-use-this */
  updateLocalShelf(
    libraryShelves: LibraryShelfModelDTO[],
    id: number,
    value: number
  ) {
    libraryShelves.forEach((shelf) => {
      if (shelf.id === id) {
        shelf.usersFavorite = value;
        shelf.isLoading = false;
      }
    });
  }

  copyToClipboard(shelfId: number | null) {
    if (shelfId) {
      this.clipboard.copy(
        `${window.location.origin}/resources/my/shelf/${shelfId}`
      );
    } else {
      this.clipboard.copy(window.location.href);
    }
    this.copiedStatus = true;
    setTimeout(() => {
      this.copiedStatus = false;
    }, 3000);
  }
}
