<div class="d-flex flex-row mb-2 align-top" *ngIf="indicator">
  <div class="checkbox">
    <ng-container *ngIf="isLoading; else giCheckBoxTemplate">
      <div class="spinner-border spinner-border-sm color-neutral" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </ng-container>
    <ng-template #giCheckBoxTemplate>
      <ng-container *ngIf="isChecked">
        <i class="bi bi-check2-circle active" (click)="toggleGI()"></i>
      </ng-container>
      <ng-container *ngIf="!isChecked">
        <i class="bi bi-circle-fill color-neutral" (click)="toggleGI()"></i>
      </ng-container>
    </ng-template>
  </div>
  <div [innerHTML]="indicator.content" class="content"></div>
</div>
