import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { map } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';

import { EnvironmentService } from '../../../../common/services/environment/environment.service';
import { CodoxConfig, CodoxService } from '../../services/codox/codox.service';

export interface FroalaOptions {
  attribution?: boolean;
  key?: string;
  inlineMode?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  events?: any;
  width?: number;
  height?: number;
  enter?: FroalaEnterOutput;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  toolbarButtons?: any;
  htmlSimpleAmpersand?: boolean;
  pastePlain?: boolean;
  initialContent?: string;
  editorClass?: string;
  toolbarBottom?: boolean;
  fontFamilySelection?: boolean;
  fontSizeSelection?: boolean;
  paragraphFormatSelection?: boolean;
  docId?: string;
  charCounterCount?: boolean;
  quickInsertEnabled?: boolean;
  placeholderText?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  emoticonsSet?: any;
}

export interface WysiwygEditorConfig extends FroalaOptions {
  editId?: string;
  useCodox?: boolean;
}

export enum FroalaEnterOutput {
  'INSERT_P' = 0,
  'INSERT_DIV' = 1,
  'INSERT_BR' = 2,
}

/* eslint-disable-next-line */
declare let Codox: any;

@Component({
  selector: 'app-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.scss'],
  providers: [CodoxService],
})
export class WysiwygEditorComponent implements OnInit {
  user$ = select(UserState.getUser);

  user: User | null = null;

  @ViewChild('editor') private editor: ElementRef;

  @Output() editorContentEvent = new EventEmitter<string>();

  @Input()
  public config: WysiwygEditorConfig;

  @Input()
  public editorContent: string;

  @Input()
  public isSaving = false;

  @Input() version: string;

  public froalaOptions: FroalaOptions;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private froalaEditorDirective: any;

  constructor(private codoxService: CodoxService) {
    this.user$
      .pipe(
        map((user) => {
          this.user = user;
        })
      )
      .subscribe();
  }

  defaultFroalaOptions: FroalaOptions = {
    attribution: false,
    key: EnvironmentService.froalaKey,
    charCounterCount: false,
    inlineMode: false,
    quickInsertEnabled: false,
  };

  noneFroalaOptions: FroalaOptions = {
    toolbarButtons: {},
    editorClass: 'none',
  };

  miniFroalaOptions: FroalaOptions = {
    toolbarButtons: {
      moreText: {
        buttons: ['bold', 'italic', 'underline'],
        buttonsVisible: 5,
      },
      moreParagraph: {
        buttons: ['formatOL', 'formatUL'],
        buttonsVisible: 2,
      },
      moreRich: {
        buttons: ['insertLink', 'emoticons'],
        buttonsVisible: 2,
      },
    },
    editorClass: 'mini',
  };

  fullFroalaOptions: FroalaOptions = {
    editorClass: 'full',
  };

  ngOnInit(): void {
    const events =
      this.config && this.config.useCodox
        ? {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            initialized: (editor: any) => {
              this.froalaEditorDirective = editor;
              // Codox is loaded as an external dependency and may beat the Froala editor to load
              this.startCodox();
            },
            contentChanged: () => {
              if (
                !this.config.useCodox ||
                !this.codoxService.users ||
                this.codoxService.users.length === 1 ||
                this.codoxService.users
                  .sort((a, b) => (a > b ? 1 : 0))
                  .findIndex(
                    (user) => user.name === this.codoxService.config.username
                  ) === 0
              ) {
                this.editorContentEvent.emit(this.editorContent);
              }
            },
          }
        : {
            contentChanged: () => {
              this.editorContentEvent.emit(this.editorContent);
            },
          };

    this.froalaOptions = {
      ...this.config,
      ...this.defaultFroalaOptions,
      events: { ...this.defaultFroalaOptions.events, ...events },
    };

    if (this.version === 'none') {
      this.froalaOptions = {
        ...this.froalaOptions,
        ...this.noneFroalaOptions,
      };
    } else if (this.version === 'mini') {
      this.froalaOptions = {
        ...this.froalaOptions,
        ...this.miniFroalaOptions,
      };
    } else {
      this.froalaOptions = {
        ...this.fullFroalaOptions,
        ...this.froalaOptions,
      };
    }
    if (!this.editorContent) {
      this.editorContent =
        this.config && this.config.initialContent
          ? this.config.initialContent
          : '';
    }
  }

  startCodox() {
    const codoxConfig: CodoxConfig = {
      docId: this.config.editId as string,
      username: `${this.user?.profile.first_name} ${this.user?.profile.last_name}`,
      // eslint-disable-next-line
      editor: this.froalaEditorDirective._editor,
    };
    this.codoxService.setDetails(codoxConfig);
    this.codoxService.start();
  }
}
