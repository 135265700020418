import { Component, Input, OnInit } from '@angular/core';
import { User as UserModel } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { map } from 'rxjs';
import { CommentModelDTO } from './evidence-comments.dto';
import { EvidenceService } from '../../services/evidence/evidence.service';

@Component({
  selector: 'app-evidence-comments',
  templateUrl: './evidence-comments.component.html',
  styleUrls: ['./evidence-comments.component.scss'],
})
export class EvidenceCommentsComponent implements OnInit {
  refTable = 'egrowe_evidence';

  newComment: CommentModelDTO = new CommentModelDTO();

  currentUser: UserModel | null;

  user$ = select(UserState.getUser);

  @Input() refTableId: number;

  @Input() commentList: CommentModelDTO[] = [];

  constructor(private evidenceService: EvidenceService) {}

  ngOnInit() {
    this.user$
      .pipe(
        map((user) => {
          this.currentUser = user;
        })
      )
      .subscribe();
  }

  saveComment() {
    this.newComment.ref_table = this.refTable;
    this.newComment.ref_table_id = this.refTableId;
    this.evidenceService.saveComment(this.newComment).subscribe((result) => {
      if (result.item) {
        this.commentList.push(result.item);
        this.newComment.comment = '';
      }
    });
  }

  deleteComment(comment: CommentModelDTO) {
    this.evidenceService.deleteComment(comment).subscribe(() => {
      this.commentList = this.commentList.filter(
        (item) => item.id !== comment.id
      );
    });
  }
}
