import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDTO } from '../../dtos/file.dto';
import { UploadType } from '../../enums/upload-type.enum';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  uploadType = UploadType.USER_UPLOAD;

  constructor(
    protected apiService: APICoreService,
    protected httpClient: HttpClient
  ) {}

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.apiService.postFormRequest('uploads', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  setUploadType(uploadType: UploadType) {
    this.uploadType = uploadType;
  }

  deleteFile(file: FileDTO) {
    const basedId = window.btoa(file.id.toString());
    return this.apiService.deleteRequest(
      `uploads/delete/user_upload/${basedId}`
    );
  }

  downloadFile(file: FileDTO) {
    return this.httpClient.get(
      `${EnvironmentService.apiUrl()}${file.location}`,
      {
        responseType: 'blob',
      }
    );
  }
}
