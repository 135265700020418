import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import {
  dateFromNgbDate,
  dateTimeFromNgbDate,
  ngbDateStructFromDate,
} from 'src/app/common/utilities/date-helpers';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-daterange-picker',
  templateUrl: './daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss'],
})
export class DaterangePickerComponent implements OnInit, OnChanges {
  hoveredDate: NgbDate | null = null;

  @Input() label = '';

  labelId = uuidv4();

  displayDateFrom = '';

  displayDateTo = '';

  dateOpen = false;

  @Input() fromDateBefore: NgbDate | null = null;

  @Input() fromDate: NgbDate | null = null;

  @Input() toDate: NgbDate | null = null;

  @Output() selectedFromDate: EventEmitter<NgbDate> = new EventEmitter();

  @Output() selectedToDate: EventEmitter<NgbDate> = new EventEmitter();

  ngOnInit() {
    this.displayDates();
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((propName) => {
      switch (propName) {
        case 'fromDate':
        case 'toDate':
          this.displayDates();
          break;
        default:
      }
    });
  }

  onDateSelection(date: NgbDate) {
    if (this.fromDate) {
      let fromDateBefore = dateFromNgbDate(this.fromDate);
      fromDateBefore = new Date(fromDateBefore.getTime() / 1000 - 86400);

      this.fromDateBefore = new NgbDate(
        fromDateBefore.getFullYear(),
        fromDateBefore.getMonth() + 1,
        fromDateBefore.getDate()
      );
    }

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date.after(this.fromDateBefore)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.toDate && this.fromDate) {
      this.dateOpen = false;
    }

    if (this.toDate) {
      this.selectedFromDate.emit(this.fromDate);
      this.selectedToDate.emit(this.toDate);

      this.displayDates();
    }
  }

  onDateFromChange(newDate: string) {
    const date = this.convertStringDate(newDate);
    if (!Number.isNaN(date.year)) {
      this.fromDate = date;
      this.selectedFromDate.emit(this.fromDate);
    } else {
      this.fromDate = null;
    }
  }

  onDateToChange(newDate: string) {
    const date = this.convertStringDate(newDate);
    if (!Number.isNaN(date.year)) {
      this.toDate = date;
      this.selectedToDate.emit(this.toDate);
    } else {
      this.toDate = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  convertStringDate(dateAsString: string) {
    return ngbDateStructFromDate(
      DateTime.fromFormat(dateAsString, 'M/d/yyyy').toJSDate()
    ) as NgbDate;
  }

  displayDates() {
    if (this.fromDate) {
      const date = this.showDate(this.fromDate);
      if (date !== 'Invalid DateTime') {
        this.displayDateFrom = date;
      }
    }
    if (this.toDate) {
      const date = this.showDate(this.toDate);
      if (date !== 'Invalid DateTime') {
        this.displayDateTo = date;
      }
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  /* eslint-disable-next-line class-methods-use-this */
  showDate(dateVal: NgbDate) {
    if (dateVal) {
      const outputFormat = `M/d/yyyy`;
      return dateTimeFromNgbDate(dateVal).toFormat(outputFormat);
    }
    return '';
  }
}
