<div class="card border-0">
  <ng-template [ngIf]="coachees && !isLoading" [ngIfElse]="placeHolder">
    <div class="card-header pt-3" *ngIf="coachees">
      <h2
        id="coachee-list-title"
        class="card-title float-start"
        (click)="triggerCollapse()">
        Coachees ({{ totalCoachees }})
        <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
      </h2>
      <div class="card-tools float-end">
        <a
          routerLink="/dashboard/coachee-list"
          class="text-primary"
          ngbTooltip="See all coachees">
          See All
          <i class="ps-1 bi bi-box-arrow-up-right"></i>
        </a>
      </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div
        class="card-body pt-0 overflow-auto"
        tabindex="0"
        role="group"
        aria-labelledby="coachee-list-title">
        <ul>
          <li *ngFor="let coachee of coachees">
            <app-coachee-list-item [coachee]="coachee" />
          </li>
        </ul>
        <div
          *ngIf="coachees && coachees.length == 0"
          class="text-muted my-5 d-flex h-100 w-100 justify-content-center align-items-center">
          No assigned coachees
        </div>
        <div class="text-center small">
          Showing {{ coachees.length }} of {{ totalCoachees }} coachees
          <a *ngIf="totalCoachees > 10" routerLink="/dashboard/coachee-list">
            (full list)
          </a>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #placeHolder>
    <div class="card-body">
      <span class="card-title placeholder-glow">
        <span class="placeholder col-6"></span>
      </span>
      <p class="card-text placeholder-glow">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-8"></span>
      </p>
    </div>
  </ng-template>
</div>
