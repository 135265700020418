import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import {
  CustomerDTO,
  DistrictDTO,
  DistrictPayloadDTO,
  DistrictSimpleDto,
} from 'src/app/common/dtos/district.dto';

import { APICoreService } from '../../../../common/services/api-core/api-core.service';
import {
  customerListDTOFromAPIResponse,
  districtListDTOFromAPIResponse,
  districtListSimpleDTOFromAPIResponse,
} from '../../helpers/translators/districts.translators';
import {
  CustomerAPIResponse,
  DistrictListAPIResponse,
  DistrictListSimpleAPIResponse,
} from '../../types/responses/district-list.responses';

interface RequestParams {
  per_page?: number;
  search?: string | number;
  omit_school?: number;
}

@Injectable({ providedIn: 'root' })
export class DistrictListService {
  constructor(private apiService: APICoreService) {}

  fetchDistricts(
    requestOptions: RequestParams
  ): Observable<DistrictDTO[] | null> {
    return this.apiService
      .getRequest('districts', requestOptions)
      .pipe(
        map((res) =>
          districtListDTOFromAPIResponse(res as DistrictListAPIResponse)
        )
      );
  }

  fetchDistrictsSimple(
    requestOptions: RequestParams
  ): Observable<DistrictSimpleDto[] | null> {
    return this.apiService
      .getRequest('districts/simple', requestOptions)
      .pipe(
        map((res) =>
          districtListSimpleDTOFromAPIResponse(
            res as DistrictListSimpleAPIResponse
          )
        )
      );
  }

  fetchNewDistricts(): Observable<CustomerDTO[] | null> {
    return this.apiService
      .getRequest('data-warehouse/customers?onlyNew=1')
      .pipe(
        map((res) =>
          customerListDTOFromAPIResponse(
            res as { items: CustomerAPIResponse[] }
          )
        )
      );
  }

  addDistrict(data: DistrictPayloadDTO) {
    return this.apiService.postRequest('districts', data).pipe(
      catchError((err) => {
        throw err;
      })
    );
  }

  updateDistrict(districtId: string, payload: DistrictPayloadDTO) {
    return this.apiService.putRequest(`districts/${districtId}`, payload).pipe(
      catchError((err) => {
        throw err;
      })
    );
  }

  archiveDistrict(districtId: string) {
    return this.apiService.deleteRequest(`districts/${districtId}`).pipe(
      catchError((err) => {
        throw err;
      })
    );
  }
}
