<div class="container">
  <div class="d-flex mb-3 align-items-center justify-content-between">
    <h1 class="fs-3 fw-bold">Coaching Session</h1>
    <div
      [autoClose]="'outside'"
      [ngbPopover]="celebrationsPopover"
      popoverClass="popover-lg"
      placement="bottom-end"
      class="icon"
      [ngStyle]="{
        'background-image': 'url(/assets/celebrations.svg)'
      }"></div>
    <ng-template #celebrationsPopover>
      <app-card-celebrations-feed [celebrations]="celebrations" [user]="user" />
    </ng-template>
  </div>

  <div class="card border-0 p-3 p-lg-5 min-vh-75">
    <ng-container *ngIf="sessionData; else loadingTemplate">
      <h2 class="fs-1 fw-bold text-center my-3">
        {{ sessionData.title }}
      </h2>
      <div
        class="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <ng-template
          [ngIf]="
            coachingService.logPusherService.attendeeRosters[sessionId].length >
            0
          ">
          <div class="d-flex flex-row align-items-center">
            <div class="me-1 text-muted">Attendees</div>
            <app-avatar
              style="margin-left: 5px"
              avatarSize="small"
              *ngFor="
                let attendee of coachingService.logPusherService
                  .attendeeRosters[sessionId]
              "
              [avatarData]="attendee"
              data-testid="attendee-avatar"></app-avatar>
          </div>
        </ng-template>
        <div class="col text-end">
          <button
            routerLink="/general/log/{{ sessionId }}/notes"
            class="btn btn-primary btn-next"
            style="pointer-events: auto">
            Let's Begin <i class="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
      <div class="container mt-3">
        <form>
          <div class="row">
            <div class="col-lg-4 p-3">
              <h3>Session Coach</h3>
              <div class="d-flex flex-nowrap align-items-center my-3">
                <app-avatar
                  [profile]="sessionData.user.profile"
                  [avatarSize]="'medium'">
                </app-avatar>
                <div class="username ms-2">
                  {{ sessionData.user.profile.first_name }}
                  {{ sessionData.user.profile.last_name }}
                </div>
              </div>
              <h3 class="mt-4">Coachee(s)</h3>
              <ng-container
                *ngIf="sessionData.attendees.length > 0; else noCoachees">
                <app-coaching-attendee
                  *ngFor="let attendee of sessionData.attendees"
                  [attendee]="attendee"
                  [isCoach]="isCoach"
                  [absentReasonsList]="absentReasonsList"
                  (removedAttendee)="removeCoachee($event)" />
              </ng-container>
              <ng-template #noCoachees>
                <p class="text-muted fw-light">
                  No coachees have been added to this log.
                </p>
              </ng-template>
              <div *ngIf="isCoach" class="mt-4">
                <label class="form-label">Add Coachee</label>
                <app-user-select
                  [clearAfterSelection]="true"
                  (selectedUser)="addCoachee($event)" />
              </div>
            </div>
            <div class="col-lg-4 p-3">
              <h3>Session Info</h3>
              <ng-container *ngIf="isCoach">
                <label class="w-100 form-label" for="title">Title</label>
                <input
                  class="form-control"
                  type="text"
                  id="title"
                  aria-label="Title"
                  appAutosave
                  [(ngModel)]="sessionData.title"
                  [ngModelOptions]="{ standalone: true }"
                  (autosave)="saveTitle()"
                  class="form-control" />
              </ng-container>
              <label class="form-label">Type</label>
              <div class="mb-3">{{ sessionData.type.title }}</div>

              <ng-container *ngIf="isCoach">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    (change)="onCheckboxChange($event)"
                    [(ngModel)]="sessionData.isPrivate"
                    [ngModelOptions]="{ standalone: true }"
                    type="checkbox"
                    id="is-private" />
                  <label class="form-check-label" for="is-private">
                    Is Private
                    <div class="form-text">
                      Private logs can only be viewed by the coach, coachee(s),
                      and shadower of the log.
                    </div></label
                  >
                </div>
              </ng-container>
              <ng-container *ngIf="sessionData.currentUsersRole === 'coachee'">
                <div class="form-check">
                  <input
                    disabled
                    class="form-check-input"
                    (change)="onCheckboxChange($event)"
                    [(ngModel)]="sessionData.isPrivate"
                    [ngModelOptions]="{ standalone: true }"
                    type="checkbox"
                    id="is-private" />
                  <label class="form-check-label" for="is-private">
                    Is Private
                    <div class="form-text">
                      Private logs can only be viewed by the coach, coachee(s),
                      and shadower of the log.
                    </div></label
                  >
                </div>
              </ng-container>

              <ng-container
                *ngIf="
                  sessionData.currentUsersRole === 'shadower' &&
                  sessionData.isPrivate
                ">
                <div class="mt-1 mb-3">
                  <label class="w-100 form-label">Private Log</label>

                  <div class="form-text">
                    Private logs can only be viewed by the coach, coachee(s),
                    and shadower of the log.
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="isCoach; else coacheeDatetimeView">
                <label class="w-100 form-label">Date & Time</label>
                <app-datepicker
                  #datePicker
                  (dateSelectedUnixTimestamp)="updateDateTime($event)"
                  [initialDateUnixTimestamp]="sessionData.startDatetime"
                  [timezone]="sessionData.timezone"
                  [timepicker]="true"></app-datepicker>
                <label class="form-label" for="session-duration"
                  >Duration</label
                >
                <ng-select
                  id="session-duration"
                  [items]="durationList"
                  [ngModel]="logDuration"
                  (ngModelChange)="updateDuration($event)"
                  [ngModelOptions]="{ standalone: true }"
                  [clearable]="false"
                  [searchable]="false"
                  [closeOnSelect]="true"
                  [appendTo]="'body'"
                  [virtualScroll]="true"
                  [bindLabel]="'label'"
                  [bindValue]="'value'" />
              </ng-container>
              <ng-template #coacheeDatetimeView>
                <div class="form-label">Date & Time</div>
                {{ sessionData.startDatetime * 1000 | date : "MMM d, y" }},
                {{ sessionData.startDatetime * 1000 | date : "h:mm a" }}<br />
                {{ sessionData.timezone }}
              </ng-template>
            </div>
            <div class="col-lg-4 p-3">
              <h3>Shadower</h3>
              <p
                class="fw-light text-muted"
                *ngIf="sessionData.shadowers.length == 0">
                No shadower has been added to this log.
              </p>
              <ng-container *ngFor="let shadower of sessionData.shadowers">
                <div class="my-3">
                  <app-coaching-shadower
                    [shadower]="shadower"
                    [logId]="sessionData.id"
                    [isCoach]="isCoach" />
                </div>
              </ng-container>
              <div *ngIf="isCoach">
                <label class="form-label">Add Shadower</label>
                <app-user-select
                  [clearAfterSelection]="true"
                  (selectedUser)="addShadower($event)" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
    <ng-template #loadingTemplate>
      <div class="w-100" style="height: 400px">
        <app-loader />
      </div>
    </ng-template>
  </div>
</div>
