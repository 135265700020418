import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { map, Subject, Subscription } from 'rxjs';
import { CoachlogSearchPayloadDTO } from 'src/app/common/dtos/coachlog-search-payload.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { v4 as uuidv4 } from 'uuid';

import { LogTypeDTO } from '../../../../common/dtos/log-type.dto';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { LogTypeSearchService } from '../../services/log-type-search/log-type-search.service';
import {
  LogTypeSelectDto,
  LogTypeSelectDTOFromLogTypeDTO,
} from './log-type-select.dto';

@Component({
  selector: 'app-log-type-select',
  templateUrl: './log-type-select.component.html',
  styleUrls: ['./log-type-select.component.scss'],
})
export class LogTypeSelectComponent implements OnInit, OnDestroy, OnChanges {
  logTypeList: LogTypeSelectDto[];

  logTypeLibrary: LogTypeDTO[] = [];

  searchInput$ = new Subject<string>();

  searchSubscription: Subscription | null;

  searchLoading = false;

  searchOptions: CoachlogSearchPayloadDTO;

  labelId = uuidv4();

  selectedLogType: LogTypeSelectDto | null = null;

  selectedLogTypeList: LogTypeSelectDto[] = [];

  @ViewChild('select') select: NgSelectComponent;

  @Input() clearAfterSelection = false;

  @Input() label = 'Search for and select a log type';

  @Input() placeholder = 'Search for a log type...';

  @Input() isMulti = false;

  @Input() chosenLogType: LogTypeSelectDto | null;

  @Input() fieldInvalid = false;

  @Output() selectedChosenLogType: EventEmitter<LogTypeSelectDto> =
    new EventEmitter();

  user$ = select(UserState.getUser);

  user: User | null = null;

  constructor(
    private logTypeSearch: LogTypeSearchService,
    private coachinglogService: CoachingLogService
  ) {}

  ngOnInit(): void {
    this.user$
      .pipe(
        map((user) => {
          this.user = user;
          this.loadLogTypes();
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((propName) => {
      switch (propName) {
        case 'chosenLogType':
          this.updateChosenLog();
          break;
        default:
      }
    });
  }

  updateChosenLog() {
    if (this.chosenLogType) {
      let foundLogType = false;
      this.logTypeLibrary.forEach((logTypeItem) => {
        if (this.chosenLogType) {
          if (this.chosenLogType.id === logTypeItem.id) {
            foundLogType = true;
            this.selectedLogType = LogTypeSelectDTOFromLogTypeDTO(logTypeItem);
          }
        }
      });
      if (!foundLogType) {
        this.selectedLogType = null;
      }
    } else {
      this.selectedLogType = null;
    }
  }

  loadLogTypes() {
    let districtId = 0;
    if (this.user?.district?.id) {
      districtId = this.user?.district?.id;
    }

    this.logTypeSearch
      .getLogTypes(districtId)
      .pipe(
        map((logTypes) => {
          if (logTypes) {
            this.logTypeList = [];
            logTypes.forEach((logType) => {
              this.logTypeList.push(LogTypeSelectDTOFromLogTypeDTO(logType));
              this.logTypeLibrary.push(logType);
            });
          }
          this.searchLoading = false;
          this.updateChosenLog();
        })
      )
      .subscribe();
  }

  logTypeSelected(logType: LogTypeSelectDto) {
    if (logType) {
      if (!this.isMulti) {
        this.selectedChosenLogType.emit(logType);
      }
      if (this.clearAfterSelection) {
        // Without a timeout you get infinite recursion
        setTimeout(() => {
          this.select.unselect(this.select.selectedItems[0]);
        });
      }
    }
  }

  remove() {
    this.selectedLogType = null;

    this.selectedChosenLogType.emit();
  }

  removeLogType(logType: LogTypeSelectDto) {
    this.selectedLogTypeList = this.selectedLogTypeList.filter(
      (item) => item.id !== logType.id
    );

    // this.selectedChosenLogType.emit(this.selectedLogTypeList);
  }

  ngOnDestroy() {
    this.selectedLogType = null;
    this.selectedLogTypeList = [];
  }
}
