import { UserDTO } from 'src/app/common/state/user/user.dto';
import { UserAPIResponse } from '../../types/responses/user.responses';

export const userDTOFromAPIResponse = (item: UserAPIResponse): UserDTO => ({
  id: item.id,
  username: item.username,
  status: item.status,
  email: item.email,
  is_deleted: item.is_deleted,
  years_coached: item.years_coached,
  smartCoach: null,
  is_smart: item.is_smart,
  is_smart_default: Boolean(item.is_smart_default),
  profile: item.profile,
  coachee_type: item.coachee_type,
});
