<div class="d-flex justify-content-between mb-3">
  <h1>Resources</h1>
  <button
    *ngIf="createResourceBtn && canCreate"
    type="button"
    (click)="resourceCreateParentModal.openModal()"
    class="btn btn-link align-self-center">
    <i class="bi bi-plus-circle me-1"></i>Create a Resource
  </button>
</div>

<app-resource-create-modal
  #resourceCreateParentModal></app-resource-create-modal>
