import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompetencyListItemDTO } from '../../../dtos/attendee-rubric.dto';
import { CoachingLogService } from '../../../services/coaching-log/coaching-log.service';
import { AddCompetencyPayload } from '../../../types/payloads/coaching-log.payloads';

@Component({
  selector: 'app-select-competencies-screen',
  templateUrl: './select-competencies-screen.component.html',
  styleUrls: ['./select-competencies-screen.component.scss'],
})
export class SelectCompetenciesScreenComponent {
  @Input() logId: number;

  @Input() letsGroweActive: boolean;

  @Output() nextScreenEvent = new EventEmitter<string>();

  @Output() updateCompetencyEvent = new EventEmitter();

  @Input() competencyList: CompetencyListItemDTO[];

  @Input() attendeeIds: number[];

  constructor(private coachingService: CoachingLogService) {}

  selectCompetency(eventData: [AddCompetencyPayload, boolean]): void {
    this.attendeeIds.forEach((attendeeId) => {
      if (eventData[1]) {
        this.coachingService
          .deleteCompetency(eventData[0].egrowe_standard_id, this.logId)
          .subscribe();
      } else {
        eventData[0].user_id = attendeeId;
        this.coachingService
          .addCompetency(eventData[0], this.logId)
          .subscribe();
      }
    });
  }

  navigateNextScreen() {
    this.nextScreenEvent.emit('leveledCompetencies');
  }
}
