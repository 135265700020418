/* eslint-disable @typescript-eslint/no-explicit-any, max-classes-per-file */
import { Injectable, OnDestroy } from '@angular/core';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';

declare class Codox {}

export interface CodoxUser {
  name: string;
}

export interface CodoxConfig {
  username: string;
  docId: string;
  editor: any;
}

export interface CodoxCollaborationConfig extends CodoxConfig {
  app: string;
  apiKey?: string;
  showUI?: boolean;
}

const defaultConfig: CodoxCollaborationConfig = {
  app: 'froala',
  apiKey: EnvironmentService.codoxApiKey(),
  username: '',
  showUI: false,
  docId: '',
  editor: null,
};

@Injectable()
export class CodoxService implements OnDestroy {
  config: CodoxCollaborationConfig;

  editor: any;

  instance: any;

  users: CodoxUser[];

  setDetails(config: CodoxConfig) {
    this.config = {
      ...defaultConfig,
      ...config,
    };
    this.editor = config.editor;
  }

  start() {
    if (!this.instance) {
      this.instance = new Codox();
      this.instance.init(this.config);
      this.instance.on('error', (data: any) => {
        /* eslint-disable-next-line no-console */
        console.log(`Codox error message: ${data.message}`);
      });
      this.instance.on('users_update', (users: any) => {
        this.users = users;
      });
    }
  }

  stop() {
    if (this.instance) {
      this.instance.stop();
    }
  }

  ngOnDestroy(): void {
    if (this.instance) {
      this.stop();
    }
  }
}
