<div class="container">
  <div class="d-flex justify-content-between mb-3">
    <h1>
      Manage
      {{ (district && district.title) || "District" }}
    </h1>
    <div *ngIf="district">
      <button class="btn btn-link" (click)="showCreateUserModal = true">
        <i class="bi bi-plus-circle me-1"></i>Create New User
      </button>
      <app-create-user-modal
        [(showModal)]="showCreateUserModal"
        [districtId]="district.id"></app-create-user-modal>
    </div>
  </div>
  <div class="card border-0 p-3 roster-container">
    <app-settings-nav [districtCode]="districtCode" />
    <div class="d-flex justify-content-between my-3">
      <div class="table-search">
        <ng-container *ngIf="activeSection == 'users'">
          <input
            #searchText
            type="search"
            class="form-control ms-1"
            placeholder="Search Users"
            aria-label="Search"
            (input)="searchUsers($event)" />
        </ng-container>
        <ng-container *ngIf="activeSection == 'schools'">
          <input
            #searchText
            type="search"
            class="form-control ms-1"
            placeholder="Search Schools"
            aria-label="Search Schools"
            (input)="searchSchools($event)" />
        </ng-container>
      </div>
      <div
        class="btn-group btn-group-md d-block text-center"
        role="group"
        aria-label="Basic radio toggle button group">
        <input
          type="radio"
          class="btn-check"
          name="btnradio"
          id="currentView"
          autocomplete="off"
          [checked]="activeSection == 'schools'"
          (click)="changeSection('schools')" />
        <label class="btn btn-outline-primary" for="currentView">Schools</label>
        <input
          type="radio"
          class="btn-check"
          name="btnradio"
          id="archivedView"
          autocomplete="off"
          [checked]="activeSection == 'users'"
          (click)="changeSection('users')" />
        <label class="btn btn-outline-primary" for="archivedView">Users</label>
      </div>
      <div>
        <button class="btn btn-primary" (click)="uploadModal.open()">
          Upload {{ activeSection | titlecase }}
        </button>
      </div>
    </div>
    <ng-container *ngIf="district">
      <ng-container *ngIf="activeSection == 'schools' && schoolData">
        <app-roster-schools [schoolData]="schoolData" />
      </ng-container>
      <ng-container *ngIf="activeSection == 'users'">
        <app-roster-users [district]="district" />
      </ng-container>
    </ng-container>
  </div>
</div>

<app-modal
  #uploadModal
  [modalConfig]="{
    titleText: 'Upload CSV Data',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: false
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="uploadModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div>
    <div class="fs-18 my-4">
      Upload your {{ activeSection | titlecase }} CSV file.
    </div>
    <app-csv-upload
      *ngIf="uploadModal.open"
      [districtId]="districtId"
      [type]="activeSection"></app-csv-upload>
  </div>
</app-modal>
