<ul class="nav nav-tabs justify-content-center">
  <li class="nav-item">
    <a
      class="nav-link"
      [class.active]="isRootResoucePage"
      title="Discover Resources"
      routerLink="/resources/search"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Discover Resources
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      title="My Resources"
      routerLink="/resources/my"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
      aria-current="page">
      My Resources
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      title="Online Courses"
      routerLink="/resources/courses"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Online Courses
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      title="Video Library"
      routerLink="/resources/video-library"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Video Library
    </a>
  </li>
  <!-- <li class="nav-item">
    <a
      class="nav-link"
      title="Community"
      routerLink="/resources/communities"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Community
    </a>
  </li> -->
</ul>
