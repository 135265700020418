<div #container class="container competency-card shadow p-3 mb-2">
  <div class="d-flex justify-content-between align-items-center">
    <h3 class="fs-6 fw-bold mt-2">
      <i class="bi bi-lightbulb"></i>
      Option
    </h3>
    <button aria-label="Delete option" class="btn-delete gl-tertiary">
      <i
        *ngIf="!this.will"
        (click)="deleteOption()"
        ngbTooltip="Delete option"
        class="bi bi-trash fs-6"></i>
    </button>
  </div>
  <app-codox-textarea
    [initialContent]="initialOptionContent"
    [isSaving]="optionSaving"
    (autosave)="updateOptionContent($event)"
    [editId]="'growelabOption' + details.id" />
  <div class="row datePickerRow" *ngIf="showDatePicker">
    <ngb-datepicker
      [(ngModel)]="selectedDate"
      (dateSelect)="onDateSelect($event)">
    </ngb-datepicker>
  </div>
  <button
    class="btn btn-primary btn-sm ms-1 mt-1"
    (click)="showDatePicker = !showDatePicker">
    <ng-container *ngIf="this.will; else noDate">
      {{ this.will.dueDate | formatDate }}
    </ng-container>
    <ng-template #noDate> Commit </ng-template>
    <i class="bi bi-calendar3"></i>
  </button>
  <!--EVIDENCE-->
  <ng-container *ngIf="this.will">
    <div class="d-flex justify-content-between align-items-center mt-3">
      <h3 class="fs-6 fw-bold mt-2">
        <i class="bi bi-pencil-square"></i>
        Evidence Plan
      </h3>
      <button
        aria-label="Delete planned evidence"
        class="btn-delete gl-tertiary">
        <i
          (click)="deleteWill()"
          ngbTooltip="Delete planned evidence"
          class="bi bi-trash fs-6"></i>
      </button>
    </div>
    <app-codox-textarea
      [initialContent]="initialWillContent"
      [isSaving]="willSaving"
      (autosave)="updateWillContent($event)"
      [editId]="'growelabOption' + this.will.id" />
  </ng-container>
  <!--RESOURCES-->
  <div class="d-flex justify-content-between align-items-center mt-3">
    <h3 class="fs-6 fw-bold mt-2">
      <i class="bi bi-paperclip"></i>
      Resources
    </h3>
    <button
      class="icon-container circle"
      aria-label="Search for resources"
      ngbTooltip="Search for resources"
      (click)="resourceSearch()">
      <i class="bi bi-search color-primary"></i>
    </button>
  </div>
  <ul *ngIf="resources">
    <li class="d-flex justify-content-start" *ngFor="let resource of resources">
      <a target="_blank" href="/resources/{{ resource.slug }}">{{
        resource.title
      }}</a>
      <button
        attr.aria-label="Remove {{ resource.title }}"
        class="btn-delete gl-tertiary">
        <i
          ngbTooltip="Remove resource"
          class="bi bi-x-circle-fill"
          (click)="removeResource(resource.id)"></i>
      </button>
    </li>
  </ul>
</div>
<ng-container *ngIf="lastCard">
  <button
    aria-label="Add Another Option"
    class="btn btn-link me-1"
    (click)="addAnotherOption()">
    <i class="bi bi-plus-circle me-1"></i>Add Another Option
  </button>
</ng-container>
