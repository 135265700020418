<div class="container">
  <div class="d-flex justify-content-between mb-3">
    <h1>Coaching Sessions</h1>
    <div class="align-self-center"></div>
  </div>
  <div class="card border-0 p-3 min-vh-100">
    <ng-container *ngIf="sessionData; else loadingTemplate">
      <h2 class="text-center mt-3">{{ sessionData.title }}</h2>
      <div
        class="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <ng-template
          [ngIf]="
            coachingService.logPusherService.attendeeRosters[sessionId].length >
            0
          ">
          <div class="d-flex flex-row align-items-center">
            <div class="me-3 text-muted">Attendees</div>
            <app-avatar
              style="margin-left: -5px"
              avatarSize="small"
              *ngFor="
                let attendee of coachingService.logPusherService
                  .attendeeRosters[sessionId]
              "
              [avatarData]="attendee"
              data-testid="attendee-avatar"></app-avatar>
          </div>
        </ng-template>
        <div class="col text-end">
          <button
            (click)="navigateNextScreen()"
            class="btn btn-primary btn-next"
            [class.disabled]="!hasPresentAttendee"
            ngbTooltip="At least 1 coachee must be marked present to begin."
            [disableTooltip]="hasPresentAttendee"
            style="pointer-events: auto">
            Let's Begin <i class="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
      <div class="container mt-3">
        <form>
          <div class="row">
            <div class="col-lg-4 p-3">
              <h3>Session Coach</h3>
              <div class="d-flex flex-nowrap align-items-center my-3">
                <app-avatar
                  [profile]="sessionData.user.profile"
                  [avatarSize]="'medium'">
                </app-avatar>
                <div class="username ms-2">
                  {{ sessionData.user.profile.first_name }}
                  {{ sessionData.user.profile.last_name }}
                </div>
              </div>
              <h3 class="mt-4">Coachee(s)</h3>
              <ng-container
                *ngIf="sessionData.attendees.length > 0; else noCoachees">
                <app-coaching-attendee
                  *ngFor="let attendee of sessionData.attendees"
                  [attendee]="attendee"
                  [isCoach]="isCoach"
                  [absentReasonsList]="absentReasonsList"
                  (removedAttendee)="removeCoachee($event)" />
              </ng-container>
              <ng-template #noCoachees>
                <p class="text-muted fw-light">
                  No coachees have been added to this log.
                </p>
              </ng-template>
              <div
                *ngIf="isCoach && sessionData.attendees.length < 1"
                class="mt-4">
                <label class="form-label">Add Coachee</label>
                <app-user-select
                  [clearAfterSelection]="true"
                  (selectedUser)="addCoachee($event)" />
              </div>
            </div>
            <div class="col-lg-4 p-3">
              <h3>Session Info</h3>
              <ng-container *ngIf="isCoach">
                <label class="w-100 form-label" for="title">Title</label>
                <input
                  class="form-control"
                  type="text"
                  id="title"
                  aria-label="Title"
                  appAutosave
                  [(ngModel)]="sessionData.title"
                  [ngModelOptions]="{ standalone: true }"
                  (autosave)="saveTitle()"
                  class="form-control" />
              </ng-container>
              <label class="form-label">Type</label>
              <div class="mb-3">{{ sessionData.type.title }}</div>
              <ng-container *ngIf="isCoach; else coacheeDatetimeView">
                <label class="w-100 form-label">Date & Time</label>
                <app-datepicker
                  #datePicker
                  (dateSelectedUnixTimestamp)="updateDateTime($event)"
                  [initialDateUnixTimestamp]="sessionData.startDatetime"
                  [timezone]="sessionData.timezone"
                  [timepicker]="true"></app-datepicker>
                <label class="form-label" for="session-duration"
                  >Duration</label
                >
                <ng-select
                  id="session-duration"
                  [items]="durationList"
                  [ngModel]="logDuration"
                  (ngModelChange)="updateDuration($event)"
                  [ngModelOptions]="{ standalone: true }"
                  [clearable]="false"
                  [searchable]="false"
                  [closeOnSelect]="true"
                  [appendTo]="'body'"
                  [virtualScroll]="true"
                  [bindLabel]="'label'"
                  [bindValue]="'value'" />
              </ng-container>
              <ng-template #coacheeDatetimeView>
                <div class="form-label">Date & Time</div>
                {{ sessionData.startDatetime * 1000 | date : "MMM d, y" }},
                {{ sessionData.startDatetime * 1000 | date : "h:mm a" }}<br />
                {{ sessionData.timezone }}
              </ng-template>
            </div>
            <div class="col-lg-4 p-3">
              <h3>Shadower</h3>
              <p
                class="fw-light text-muted"
                *ngIf="sessionData.shadowers.length == 0">
                No shadower has been added to this log.
              </p>
              <ng-container *ngFor="let shadower of sessionData.shadowers">
                <div class="my-3">
                  <app-coaching-shadower
                    [shadower]="shadower"
                    [logId]="sessionData.id"
                    [isCoach]="isCoach" />
                </div>
              </ng-container>
              <div *ngIf="isCoach">
                <label class="form-label">Add Shadower</label>
                <app-user-select
                  [clearAfterSelection]="true"
                  (selectedUser)="addShadower($event)" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
    <ng-template #loadingTemplate>
      <app-loader />
    </ng-template>
  </div>
</div>
