import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { BehaviorSubject, map, Observable, of, Subject, tap } from 'rxjs';
import { CompetencyDTO } from 'src/app/common/dtos/competency.dto';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  Form,
  FormCompetencyDTO,
  FormCreateDTO,
  FormLearnerBehaviorCreateDTO,
  FormStatus,
  ObservationFormSubmission,
  ObservationFormSubmissionRawData,
} from 'src/app/private/shared/dtos/forms.dto';

import { AssessmentDTO } from '../../dtos/attendee-rubric.dto';
import { assessmentsDTOFromAPIResponse } from '../../helpers/translators/attendee-rubrics-dto.translator';
import {
  formLearnerBehaviorCreatePayloadFromDTO,
  formPayloadFromDTO,
  FormSubmissionPayload,
  formSubmissionPayloadFromDTO,
  formSubmissionPayloadFromRawDataDTO,
} from '../../types/payloads/forms.payloads';
import { AssessmentAPIResponse } from '../../types/responses/coaching-log.responses';
import {
  FormResponse,
  LearnerBehaviorAPIResponse,
  LearnerBehaviorListApiResponse,
  ObservationFormSubmissionResponse,
  translateFormResponseToFormDTO,
  translateObservationFormSubmissionDTOFromResponse,
} from '../../types/responses/forms.responses';

export const indicatorFormDTOFromApiResponse = (
  response: LearnerBehaviorAPIResponse
): FormCompetencyDTO => ({
  competencyId: response.item.egrowe_standard_id,
  rubricId: response.item.egrowe_rubric_id,
  indicatorSet: {
    id: response.item.indicatorSet.id,
    groups: response.item.indicatorSet.indicatorGroups.map((group) => ({
      id: group.id,
      sampleQuestions: response.item.sample_questions || undefined,
      indicators: group.indicators.map((indicator) => ({
        id: indicator.id,
        title: indicator.indicator.content,
        level: indicator.level,
      })),
      title: group.title,
    })),
  },
});

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  assessedUser: UserDTO;

  coachlogId: number;

  forms: Form[] = [];

  templateForms: Form[] = [];

  district: DistrictDTO;

  formsDistrictId: number;

  isLoading = false;

  isLiveFormSubmission = false;

  scrollPosition: number;

  repositionFormOnSave = false;

  promptSave = new Subject<boolean>();

  competencyList = new BehaviorSubject<CompetencyDTO[]>([]);

  indicatorsList = new BehaviorSubject<FormCompetencyDTO[]>([]);

  assessmentsList = new BehaviorSubject<AssessmentDTO[]>([]);

  user: User;

  // constructor(private apiService: APICoreService) {}
  // This is only necessary in the localStorage phase, so we can get the user to filter by district client-side
  constructor(private apiService: APICoreService, private store: Store) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    if (this.user.district) {
      this.formsDistrictId = this.user.district.id;
    }
  }

  loadForms(districtId?: number): Observable<Form[]> {
    this.isLoading = true;
    const url = districtId
      ? `growelab/observation-forms?district_id=${districtId}`
      : 'growelab/observation-forms';
    return this.apiService.getRequest(url).pipe(
      map((response) => {
        this.forms = response.items.map((form: FormResponse) =>
          translateFormResponseToFormDTO(form)
        );
        this.isLoading = false;
        return this.forms;
      })
    );
  }

  loadForm(id: number): Observable<Form | null> {
    this.isLoading = true;
    return this.apiService.getRequest(`growelab/observation-forms/${id}`).pipe(
      map((response) => {
        const form = translateFormResponseToFormDTO(response.item);
        const idx = this.forms.findIndex((f) => f.id === form.id);
        if (idx > -1) {
          this.forms[idx] = form;
        } else {
          this.forms.push(form);
        }
        this.isLoading = false;
        return form;
      })
    );
  }

  getForm(id: number): Observable<Form | null> {
    const form = this.forms.find((f) => f.id === id);
    if (form) {
      return of(form);
    }
    return this.loadForm(id);
  }

  createForm(form: FormCreateDTO): Observable<Form> {
    const payload = formPayloadFromDTO(form);
    return this.apiService
      .postRequest('growelab/observation-forms', payload)
      .pipe(
        map((response: { item: FormResponse }) => {
          const newForm = translateFormResponseToFormDTO(response.item);
          this.forms.push(newForm);
          return newForm;
        })
      );
  }

  deleteForm(id: number): Observable<number | null> {
    return this.apiService
      .deleteRequest(`growelab/observation-forms/${id}`)
      .pipe(
        tap((response) => {
          if (response) {
            this.forms = this.forms.filter((form) => form.id !== id);
          }
        })
      );
  }

  hideForm(formId: number, districtId: number): Observable<Form | null> {
    const form = this.forms.find((f) => f.id === formId);
    if (form) {
      if (form.districtsSharedWith?.includes(districtId)) {
        form.districtsSharedWith = form.districtsSharedWith.filter(
          (d) => d !== districtId
        );
        if (form.districtsSharedWith.length === 0) {
          if (!form.districtsSharedExclusion) {
            form.districtsSharedExclusion = [];
          }
          form.districtsSharedExclusion.push(districtId);
        }
      } else {
        if (!form.districtsSharedExclusion) {
          form.districtsSharedExclusion = [];
        }
        form.districtsSharedExclusion.push(districtId);
      }
      return this.updateForm(form).pipe(
        tap((updatedForm) => {
          this.forms = this.forms.filter((f) => f.id !== updatedForm.id);
        })
      );
    }
    return of(null);
  }

  updateForm(form: Form): Observable<Form> {
    const payload = formPayloadFromDTO(form);
    return this.apiService
      .putRequest(`growelab/observation-forms/${payload.id}`, payload)
      .pipe(
        map((response: { item: FormResponse }) => {
          this.isLoading = false;
          return translateFormResponseToFormDTO(response.item);
        })
      );
  }

  generateDuplicateFormTitle(title: string): string {
    const newTitle = `${title} copy`;
    if (this.forms.filter((form) => form.title === newTitle).length === 0) {
      return newTitle;
    }
    return this.generateDuplicateFormTitle(newTitle);
  }

  duplicateForm(formToDuplicate: Form, districtId: number): Observable<Form> {
    const newFormTitle = this.generateDuplicateFormTitle(formToDuplicate.title);
    const newFormData: FormCreateDTO = {
      title: newFormTitle,
      description: formToDuplicate.description,
      status: FormStatus.DRAFT,
      districtId,
      type: formToDuplicate.type,
      fields: formToDuplicate.fields,
      isTemplate: false,
    };
    return this.createForm(newFormData);
  }

  archiveForm(formId: number): Observable<boolean> {
    this.loadForms();
    const unixNow = Math.round(DateTime.now().toSeconds());
    this.forms = this.forms.map((form) => {
      if (form.id === formId) {
        return {
          ...form,
          modifiedAt: unixNow,
          status: FormStatus.ARCHIVED,
        };
      }
      return form;
    });
    StorageService.storeItem('formDefinitions', this.forms);
    return of(true);
  }

  createFormSubmission(
    rawData: ObservationFormSubmissionRawData
  ): Observable<ObservationFormSubmission> {
    const payload: FormSubmissionPayload =
      formSubmissionPayloadFromRawDataDTO(rawData);

    return this.apiService
      .postRequest('growelab/observation-form-submissions', payload)
      .pipe(
        map((response: { item: ObservationFormSubmissionResponse }) =>
          translateObservationFormSubmissionDTOFromResponse(response.item)
        )
      );
  }

  updateFormSubmission(
    rawData: ObservationFormSubmission
  ): Observable<ObservationFormSubmission> {
    const payload: FormSubmissionPayload =
      formSubmissionPayloadFromDTO(rawData);

    return this.apiService.putRequest(
      `growelab/observation-form-submissions/${payload.id}`,
      payload
    );
  }

  getFormSubmission(id: number) {
    return this.apiService.getRequest(
      `growelab/observation-form-submissions/${id}`
    );
  }

  getFormSubmissionsFromCoachingLog(
    logId: number
  ): Observable<ObservationFormSubmission[]> {
    return this.apiService
      .getRequest(`growelab/observation-form-submissions/coachlog/${logId}`)
      .pipe(
        map((response: { items: ObservationFormSubmissionResponse[] }) => {
          if (response.items.length > 0) {
            return response.items.map((item) =>
              translateObservationFormSubmissionDTOFromResponse(item)
            );
          }
          return [];
        })
      );
  }

  getCompetencies(forceUpdate = false) {
    if (this.competencyList.value.length === 0 || forceUpdate === true) {
      const learnerBehaviorRequest = this.apiService
        .getRequest('growelab/competencies/learner-behaviors')
        .subscribe((behaviors: LearnerBehaviorListApiResponse) => {
          const competencyIdList = behaviors.items
            .filter((b) => b.rubricLearnerBehaviorStrands.length > 0)
            .map((b) => b.rubricLearnerBehaviorStrands[0].egrowe_standard_id);
          const request = this.apiService
            .getRequest('egrowe/standards?per-page=10000')
            .subscribe((competencies) => {
              this.competencyList.next(
                competencies.items
                  .filter((compCheck: CompetencyDTO) =>
                    competencyIdList.includes(compCheck.id)
                  )
                  .map((comp: CompetencyDTO) => ({
                    ...comp,
                    label: `${comp.title} (${comp.rubric_name})`,
                  }))
              );
              request.unsubscribe();
              learnerBehaviorRequest.unsubscribe();
            });
        });
    }
  }

  fetchIndicators(competencyId: number) {
    return this.apiService
      .getRequest(`growelab/competencies/${competencyId}/learner-behaviors`)
      .pipe(
        tap((data) => {
          const localList = this.indicatorsList.value;
          const indicatorIdx = localList.findIndex(
            (item) => item.competencyId === data.item.egrowe_standard_id
          );
          if (indicatorIdx > -1) {
            localList[indicatorIdx] = indicatorFormDTOFromApiResponse(data);
          } else {
            localList.push(indicatorFormDTOFromApiResponse(data));
          }
          this.indicatorsList.next(localList);
        })
      );
  }

  setCoachlog(coachlogId: number) {
    this.coachlogId = coachlogId;
    this.apiService
      .getRequest('growelab/indicator-assessments', {
        egrowe_coachlog_id: coachlogId,
      })
      .subscribe((data) => {
        if (data.items) {
          this.assessmentsList.next(
            data.items.map((item: AssessmentAPIResponse) =>
              assessmentsDTOFromAPIResponse(item)
            )
          );
        }
      });
  }

  addAssessment(formAssessment: FormLearnerBehaviorCreateDTO): void {
    this.apiService
      .postRequest(
        'egrowe/indicator-assessments',
        formLearnerBehaviorCreatePayloadFromDTO({
          ...formAssessment,
          coachlogId: this.coachlogId,
        })
      )
      .subscribe((response) => {
        this.assessmentsList.next([
          ...this.assessmentsList.value,
          assessmentsDTOFromAPIResponse(response),
        ]);
      });
  }

  removeAssesment(assessment: AssessmentDTO): void {
    this.apiService
      .deleteRequest(`egrowe/indicator-assessments/${assessment.id}`)
      .subscribe((response) => {
        if (response) {
          this.assessmentsList.next(
            this.assessmentsList.value.filter(
              (item) => item.id !== assessment.id
            )
          );
        }
      });
  }
}
