import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { map } from 'rxjs';
import {
  CompetencyFiltersDTO,
  CompetencyListItemDTO,
  CompetencyUserDTO,
} from '../../dtos/competencies.dto';

@Component({
  selector: 'app-competencies-summary',
  templateUrl: './competencies-summary.component.html',
  styleUrls: ['./competencies-summary.component.scss'],
})
export class CompetenciesSummaryComponent implements OnInit {
  @Input() competencyId = 0;

  @Input() userId = 0;

  @Input() isSelectable = false;

  @Input() isSelected = false;

  @Output() isSelectedEvent = new EventEmitter<boolean>();

  isLoading = false;

  competency: CompetencyListItemDTO | null;

  user: CompetencyUserDTO | undefined;

  competencyTitle: string | undefined;

  iconUrl: string;

  icons: string[] = [
    'assessment',
    'collaboration',
    'communication',
    'criticalanalysis',
    'cultureenvironment',
    'differentiation',
    'digitallearning',
    'goalsetting',
    'problemsolving',
    'reflectiongrowthmindset',
    'relevanceauthenticity',
    'smallgroupinstruction',
    'standardsalignment',
  ];

  constructor(private apicoreService: APICoreService) {}

  ngOnInit(): void {
    this.getCompetencies();
  }

  toggleSelectIcon() {
    this.isSelected = !this.isSelected;
    this.isSelectedEvent.emit(this.isSelected);
  }

  // This function will change to be passed in by the parent at a later date
  // A more general sulution must be found when the project is furether specified
  getCompetencies() {
    this.isLoading = true;

    this.getCompetencyOverview().subscribe(
      (response: CompetencyListItemDTO[]) => {
        const competencyFound = response.find(
          (item) => item?.id === this.competencyId
        );
        if (competencyFound) {
          this.competency = competencyFound;
          this.competencyTitle = this.competency?.title;
          this.user = this.competency?.users.find(
            (user) => user.id === this.userId
          );

          this.iconUrl = `/assets/competency_icons/${this.getIcon(
            this.competency.icon
          )}.svg`;
        }
        this.isLoading = false;
      }
    );
  }

  getIcon(competencyTitle: string) {
    if (competencyTitle) {
      for (let i = 0; i < this.icons.length; i += 1) {
        competencyTitle = competencyTitle.toLowerCase();
        const currentIcon = this.icons[i];
        if (competencyTitle.includes(currentIcon)) {
          return this.icons[i];
        }
      }
    }
    return 'assessment';
  }

  getCompetencyOverview() {
    const filterFreeze: CompetencyFiltersDTO = {
      competency_ids: [this.competencyId],
      user_ids: [this.userId],
    };

    return this.apicoreService
      .getRequest('growelab/competencies/overview', filterFreeze)
      .pipe(
        map((results) => {
          let competencyList: [];
          if (results.items.competencies) {
            competencyList = results.items.competencies;
          } else {
            competencyList = [];
          }
          return competencyList;
        })
      );
  }
}
