import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import {
  FroalaEnterOutput,
  WysiwygEditorConfig,
} from '../wysiwyg-editor/wysiwyg-editor.component';

@Component({
  selector: 'app-codox-textarea',
  templateUrl: './codox-textarea.component.html',
  styleUrls: ['./codox-textarea.component.scss'],
})
export class CodoxTextareaComponent implements OnInit {
  @Input() editId: string = uuidv4();

  @Input() initialContent = '';

  @Input() isSaving = false;

  @Input() disableAutosave = false;

  @Output() autosave: EventEmitter<string> = new EventEmitter<string>();

  config: WysiwygEditorConfig;

  content: string;

  ngOnInit() {
    // froala doesn't like newlines in content, it wants breaks
    this.initialContent = this.initialContent.replace(
      /(?:\r\n|\r|\n)/g,
      '<br>'
    );
    this.content = this.initialContent;
    this.config = {
      useCodox: true,
      editId: this.editId,
      pastePlain: true,
      enter: FroalaEnterOutput.INSERT_BR,
    };
  }

  emitAutosave(value: string) {
    value = value
      // froala autoappends a span to end of content sometimes
      .replace(/<\/?span[^>]*>/g, '')
      // remove bold and italic tags (from keyboard shortcuts)
      .replace(/<\/?strong[^>]*>/g, '')
      .replace(/<\/?em[^>]*>/g, '')
      // replace br tags with newlines for saving to plaintext
      .replace(/<\/?br[^>]*>/g, '\n');
    if (!this.disableAutosave) {
      this.autosave.emit(value);
    }
  }
}
