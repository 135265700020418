import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subscription, map } from 'rxjs';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { User } from 'src/app/common/state/user/user.model';
import { ModalComponent, ModalConfig } from '../modals/modal/modal.component';
import { ConfettiService } from '../../services/confetti/confetti.service';
import { BadgeEarnedDTO } from '../../dtos/badge.dto';
import { UserPusherService } from '../../services/pusher/user.pusher.service';

@Component({
  selector: 'app-badge-earned-modal',
  templateUrl: './badge-earned-modal.component.html',
  styleUrls: ['./badge-earned-modal.component.scss'],
})
export class BadgeEarnedModalComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  badgeData: BadgeEarnedDTO;

  @ViewChild('internalModal') internalModal: ModalComponent;

  @ViewChild('inner') inner: ElementRef<HTMLDivElement>;

  subs: Subscription[] = [];

  badgeEarnedNotifierSub: Subscription;

  private isOpen = false;

  public modalShowing: BehaviorSubject<boolean> = new BehaviorSubject(false);

  user$ = select(UserState.getUser);

  user: User | null;

  modalConfig: ModalConfig = {
    headerClass: ['visually-hidden'],
    hideDismissButton: true,
    hideCloseButton: true,
    options: {
      size: 'lg',
    },
  };

  constructor(
    private confettiService: ConfettiService,
    private userPusherService: UserPusherService
  ) {}

  ngOnInit(): void {
    this.user$
      .pipe(
        map((user) => {
          this.user = user;
        })
      )
      .subscribe();

    if (this.badgeEarnedNotifierSub) this.badgeEarnedNotifierSub.unsubscribe();
    if (this.userPusherService.badgeEarnedNotifier) {
      this.badgeEarnedNotifierSub =
        this.userPusherService.badgeEarnedNotifier.subscribe((messageData) => {
          if (messageData) {
            this.badgeData = messageData;
            this.modalShowing.next(true);
          }
        });
    }

    this.subs.push(this.badgeEarnedNotifierSub);
  }

  ngAfterViewInit() {
    this.internalModal.isDismissing.subscribe(() => {
      this.modalShowing.next(false);
    });

    this.modalShowing.subscribe((shown) => {
      if (shown) {
        if (!this.isOpen) {
          this.internalModal.open();
          this.isOpen = true;
        }
      } else if (this.isOpen) {
        this.internalModal.close();
        this.isOpen = false;
      }
    });
  }

  shareOnTwitter(badgeUser: string, badgeTitle: string) {
    if (this.user) {
      let shareText = '';
      if (this.user.id === parseInt(this.badgeData.userId)) {
        shareText = `I just earned the ${badgeTitle} badge”, ask me how!`;
      } else {
        shareText = `${badgeUser} just earned the ${badgeTitle} badge, way to grow!`;
      }
      window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`
      );
    }
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
