import { User } from 'src/app/common/state/user/user.model';
import { UserRole } from '../state/user/role/user-role';

export const checkIfE2L = (userData: User) => {
  if (userData.roles) {
    // eslint-disable-next-line no-restricted-syntax
    for (const role of userData.roles) {
      if (role === UserRole.E2L_EMPLOYEE) {
        return true;
      }
    }
  }
  return false;
};
