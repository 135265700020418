import { DistrictDTO } from '../../dtos/district.dto';
import { ProfileDTO } from '../../dtos/profile.dto';
import { SchoolDTO } from '../../dtos/school.dto';
import { BooleanNumber } from '../../utilities/enums/boolean-number.enum';
import { UserRole } from './role/user-role';
import { UserType } from './type/user-type';
import { UserDTO } from './user.dto';

export class User {
  isCoach: boolean;

  fullName = '';

  constructor(
    public id: number,
    public username: string,
    public profile: ProfileDTO,
    public type: UserType,
    public status: number,
    public email: string,
    public district: DistrictDTO | undefined,
    public school: SchoolDTO | undefined,
    public roles: UserRole[],
    public isDeleted: BooleanNumber,
    public isSmart: BooleanNumber,
    public isSmartDefault: boolean | null,
    public smartCoach: string | null,
    public yearsCoached: number,
    public coach: UserDTO | undefined,
    public createdAt: number,
    public coacheeType: string | null
  ) {
    this.isCoach = this.roles.includes(UserRole.COACH);
    this.fullName = `${this.profile.first_name} ${this.profile.last_name}`;
  }
}
