<button
  (click)="assignCoacheeModal.open()"
  type="button"
  class="btn btn-link align-self-center">
  <i _ngcontent-cut-c44="" class="bi bi-plus-circle me-1"></i>Add Coachee
</button>

<app-modal
  #assignCoacheeModal
  [modalConfig]="{
    titleText: 'Add a Coachee',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="cancel()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <div>
    <div class="fw-800 fs-21 mb-2">Assign a Coachee to Yourself</div>
    <p>
      Search for a user to become your coachee. By assigning someone as your
      coachee, you'll have the opportunity to provide them with support, nurture
      their growth, and monitor their progress. Additionally, this will result
      in unassigning them from their current coach, if they have one. To confirm
      the assignment, simply click on 'Assign' below, and they will appear on
      your Coachees List.
    </p>
    <div class="my-3">
      <app-user-select
        (selectedUser)="chooseUser($event)"
        [clearAfterSelection]="true"
        [isMulti]="false"></app-user-select>

      <div *ngIf="selectedUser" class="pt-5 pb-3 fs-21 text-center">
        Selected User:&nbsp;
        <strong
          >{{ selectedUser.profile.first_name }}
          {{ selectedUser.profile.last_name }}</strong
        >
      </div>
    </div>
  </div>

  <div footerContent>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSubmit()"
      [disabled]="!selectedUser || submitting">
      <span
        *ngIf="submitting"
        class="spinner-border spinner-border-sm mr-1"></span>
      Assign
    </button>
  </div>
</app-modal>
