/* eslint-disable array-callback-return */
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { MOCK_USER_DTO } from 'src/app/common/state/user/user.mocks';
import { DateTime } from 'luxon';
import { EvidenceAssessedDTO } from 'src/app/common/types/responses/responses';
import {
  SortableHeader,
  SortEvent,
} from '../../shared/directives/sortable-header.directive';
import { EvidenceService } from '../../shared/services/evidence/evidence.service';
import { CoacheeEvidenceDTO } from '../../shared/dtos/coachee-evidence';

function compare(v1: string | number, v2: string | number) {
  if (typeof v1 === 'string' && typeof v2 === 'string') {
    v1 = v1.toUpperCase();
    v2 = v2.toUpperCase();
  }
  // eslint-disable-next-line no-nested-ternary
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

const columnDataMap = (
  evidence: EvidenceRow,
  column: string
): string | number => {
  switch (column) {
    case 'coachee':
      return (
        `${evidence.coachee.profile.first_name} ${evidence.coachee.profile.last_name}` ||
        ''
      );
    case 'evidence':
      return evidence.evidence || '';
    case 'competency':
      return evidence.competency || '';
    case 'level':
      return evidence.level || '';
    case 'reviewed':
      return Number(evidence.reviewed) || 0;
    case 'dateUploaded':
    default:
      return evidence.dateUploaded || '';
  }
};

interface EvidenceRow {
  coachee: UserDTO;
  evidence: string;
  competency: string;
  level: number;
  dateUploaded: number;
  reviewed: boolean;
  id: number;
}

@Component({
  selector: 'app-coachee-evidence-page',
  templateUrl: './coachee-evidence-page.component.html',
  styleUrls: ['./coachee-evidence-page.component.scss'],
})
export class CoacheeEvidencePageComponent implements OnInit {
  user$ = select(UserState.getUser);

  dayOfWeek = new Date().toLocaleDateString('en-US', { weekday: 'long' });

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  tableData: EvidenceRow[];

  coacheeEvidence: EvidenceRow[] = [];

  isLoading = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortIconTemp: any = {
    coachee: 'gray',
    evidence: 'gray',
    competency: 'gray',
    level: 'gray',
    dateUploaded: 'gray',
    reviewed: 'gray',
  };

  constructor(private evidenceService: EvidenceService) {}

  async ngOnInit(): Promise<void> {
    this.getCoacheeEvidenceList();
  }

  search(event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.tableData = this.coacheeEvidence.filter((row) => {
      const term = searchTerm.toLowerCase();
      return (
        row.coachee.profile.first_name.toLowerCase().includes(term) ||
        row.evidence.toLowerCase().includes(term) ||
        row.competency.toLowerCase().includes(term) ||
        row.level.toString().includes(term) ||
        DateTime.fromSeconds(row.dateUploaded).toFormat('MM/dd').includes(term)
      );
    });

    this.headers.forEach((header) => {
      header.direction = '';
      this.sortIconTemp[header.sortable] = 'gray';
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
        this.sortIconTemp[header.sortable] = 'gray';
      } else {
        this.sortIconTemp[header.sortable] = 'none';
      }
    });
    if (direction === '' || column === '') {
      this.tableData.sort((a, b) => compare(a.dateUploaded, b.dateUploaded));
      this.sortIconTemp[column] = 'gray';
    } else {
      this.tableData = [...this.tableData].sort((a, b) => {
        const res = compare(
          columnDataMap(a, column as string),
          columnDataMap(b, column as string)
        );
        return direction === 'asc' ? -res : res;
      });
    }
  }

  getCoacheeEvidenceList(): void {
    this.isLoading = true;
    this.evidenceService
      .getCoacheeEvidenceList()
      .subscribe((res: CoacheeEvidenceDTO[]) => {
        if (res) {
          res.map((item) => {
            item.wills.map((will) => {
              will.evidence.map((evidence) => {
                const row: EvidenceRow = {
                  coachee: MOCK_USER_DTO,
                  evidence: '',
                  competency: '',
                  level: 0,
                  dateUploaded: 0,
                  reviewed: false,
                  id: evidence.id,
                };
                row.coachee = item.user;
                row.competency = will.standard;
                row.evidence = evidence.title;
                row.dateUploaded = evidence.createdAt;
                row.level = evidence.level;
                row.reviewed = evidence.assessments.length > 0;
                this.coacheeEvidence.push(row);
              });
            });
          });
          this.tableData = this.coacheeEvidence;
          this.tableData.sort((a, b) =>
            compare(a.dateUploaded, b.dateUploaded)
          );
          this.isLoading = false;
        }
      });
  }

  updateReviewedStatus(response: EvidenceAssessedDTO) {
    const foundIndex = this.tableData.findIndex(
      (x) => x.id === response.egrowe_evidence_id
    );
    this.tableData[foundIndex].reviewed = true;
  }
}
