<div class="container">
  <div class="card border-0 p-3" [class.edit]="editMode">
    <div class="card-body">
      <ng-container *ngIf="currentResource; else loadingTemplate">
        <div class="row gx-5">
          <div class="col-lg-8 mb-4">
            <ng-container
              *ngIf="isOwnerOrEditor && editMode; else viewTitleTemplate">
              <input
                placeholder="Resource Title"
                class="form-control title-input"
                id="title"
                [(ngModel)]="currentResource.title"
                appAutosave
                (autosave)="saveTitle()" />
            </ng-container>
            <ng-template #viewTitleTemplate>
              <h1>{{ currentResource.title }}</h1>
            </ng-template>
            <hr class="border border-2 opacity-50" />
            <app-resource-content
              [docId]="currentResource.id"
              [isAllowed]="isOwnerOrEditor"
              [editMode]="editMode"></app-resource-content>
          </div>
          <div class="col-lg-4">
            <section *ngIf="isOwnerOrEditor">
              <div
                class="btn-group btn-group-sm d-block text-center mb-2"
                role="group"
                aria-label="Basic radio toggle button group">
                <input
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="editMode"
                  autocomplete="off"
                  [checked]="editMode"
                  (click)="editMode ? '' : toggleEditMode()" />
                <label class="btn btn-outline-primary" for="editMode"
                  ><i
                    *ngIf="
                      editMode &&
                      isLocked &&
                      currentUser &&
                      currentResource.lock_user_id != currentUser.id
                    "
                    class="bi bi-lock me-1"></i
                  >Edit Mode</label
                >
                <input
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="viewMode"
                  autocomplete="off"
                  [checked]="!editMode"
                  (click)="!editMode ? '' : toggleEditMode()" />
                <label class="btn btn-outline-primary" for="viewMode"
                  >View Mode</label
                >
              </div>
              <div
                *ngIf="isLocked"
                class="lock-notification small m-3 text-center">
                Locked by
                <app-users-name [userId]="currentResource.lock_user_id" />
                <span *ngIf="currentResource.lock_expires">
                  (expires at
                  {{
                    currentResource.lock_expires * 1000 | date : "shortTime"
                  }})
                </span>
              </div>
            </section>

            <h2 class="fs-25 fw-800 mt-4">Description</h2>
            <ng-container
              *ngIf="isOwnerOrEditor && editMode; else viewDescriptionTemplate">
              <textarea
                placeholder="Description of Resource"
                class="form-control"
                id="description"
                rows="3"
                [(ngModel)]="currentResource.description"
                appAutosave
                (autosave)="saveDescription()"></textarea>
            </ng-container>
            <ng-template #viewDescriptionTemplate>
              <p>{{ currentResource.description }}</p>
            </ng-template>

            <h2 class="fs-25 fw-800 mt-4">Competencies</h2>
            <ng-container *ngIf="isOwnerOrEditor && editMode">
              <ng-container
                *ngIf="
                  currentResource.has_elearn_manage_resource_standards_perm
                ">
                <app-competency-select
                  [clearAfterSelection]="true"
                  [isMulti]="false"
                  [hideTags]="true"
                  [placeholder]="'Search Competencies'"
                  (selectedCompetency)="updateCompetenciesToAdd($event)" />
                <div style="clear: both; height: 8px"></div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="invalidSelection">
              <div class="validation-text">
                <ng-container *ngIf="selectionNotAllowed">
                  You do not have permission to add this competency.
                </ng-container>
                <ng-container *ngIf="selectionAlreadyAdded">
                  This competency has already been added.
                </ng-container>
              </div>
            </ng-container>
            <div style="clear: both; height: 4px"></div>

            <app-leveled-resource-competencies
              [resourceId]="currentResource.id"
              [competenciesToAdd]="competenciesToAdd"
              [resourceCompetencies]="resourceCompetencies"
              [hasEditPermissions]="
                isOwnerOrEditor &&
                editMode &&
                currentResource.has_elearn_manage_resource_standards_perm
              "
              (sendCompetenciesToAdd)="refreshCompetenciesToAdd($event)"
              (sendResourceCompetencies)="
                refreshResourceCompetencies($event)
              " />

            <section>
              <h2 class="fs-25 fw-800 mt-4">Type</h2>
              <ng-container
                *ngIf="isOwnerOrEditor && editMode; else viewTypeTemplate">
                <section class="resource-type">
                  <div name="type" class="radio-group">
                    <div class="radio-item">
                      <input
                        type="radio"
                        [(ngModel)]="currentResource.type"
                        (ngModelChange)="saveType()"
                        id="test1"
                        name="type"
                        value="research" />
                      <label for="test1">
                        <i class="type-ico bi bi-lightbulb"></i>RESEARCH</label
                      >
                    </div>
                    <div class="radio-item">
                      <input
                        type="radio"
                        [(ngModel)]="currentResource.type"
                        (ngModelChange)="saveType()"
                        id="test2"
                        name="type"
                        value="diy" />
                      <label for="test2"
                        ><i class="type-ico bi bi-tools"></i>DIY ACTIVITY</label
                      >
                    </div>
                    <div class="radio-item">
                      <input
                        type="radio"
                        [(ngModel)]="currentResource.type"
                        (ngModelChange)="saveType()"
                        id="test3"
                        name="type"
                        value="workshop" />
                      <label for="test3"
                        ><i class="type-ico bi bi-people"></i>WORKSHOP</label
                      >
                    </div>
                    <div class="radio-item">
                      <input
                        type="radio"
                        [(ngModel)]="currentResource.type"
                        (ngModelChange)="saveType()"
                        id="test4"
                        name="type"
                        value="template" />
                      <label for="test4"
                        ><i class="type-ico bi bi-briefcase"></i>TEMPLATE</label
                      >
                    </div>
                    <div class="radio-item">
                      <input
                        type="radio"
                        [(ngModel)]="currentResource.type"
                        (ngModelChange)="saveType()"
                        id="test5"
                        name="type"
                        value="video" />
                      <label for="test5"
                        ><i class="type-ico bi bi-camera-reels"></i>VIDEO</label
                      >
                    </div>
                  </div>
                  <div>&nbsp;</div>
                </section>
              </ng-container>
              <ng-template #viewTypeTemplate>
                <p>{{ currentResource.type | titlecase }} Resource</p>
              </ng-template>

              <h2 class="fs-25 fw-800 mt-4">Created By</h2>
              <div *ngIf="currentResource?.creator_user_id">
                <app-users-name
                  [userId]="currentResource.creator_user_id"></app-users-name>
              </div>

              <h2 class="fs-25 fw-800 mt-4">Last updated</h2>
              <div>
                {{
                  currentResource.updated_at
                    | showDateTimezone : "MMM d, yyyy" : undefined : false
                }}
              </div>

              <ng-container *ngIf="currentResource.tags?.length">
                <h2 class="fs-25 fw-800 mt-4">Tags</h2>
                <app-resource-tags
                  [readOnly]="!isOwnerOrEditor || !editMode"
                  [tagList]="currentResource.tags"
                  [refTableId]="currentResource.id">
                </app-resource-tags>
              </ng-container>

              <ng-container *ngIf="isOwnerOrEditor && editMode">
                <button
                  (click)="permissions.open()"
                  class="btn btn-primary btn-next mt-4">
                  Publish
                </button>
              </ng-container>
            </section>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="loading-wrapper">
    <div class="w-100" style="background-color: #fff; height: 700px">
      <app-loader></app-loader>
    </div>
  </div>
</ng-template>

<app-modal
  #permissions
  [modalConfig]="{
    titleText: 'Share Settings',
    headerClass: [
      'modal-header',
      'bg-primary',
      'text-white',
      'share-settings-header'
    ],
    windowClass: 'resource-share-modal',
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="permissions.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <ng-container *ngIf="isOwnerOrEditor && editMode">
    <div class="sharing-container">
      <resource-access-rules [canManage]="true"></resource-access-rules>
    </div>
  </ng-container>
  <div footerContent>
    <button
      (click)="permissions.close()"
      class="sharing-done-button btn btn-primary">
      Done
    </button>
  </div>
</app-modal>
