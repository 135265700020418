import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuid } from 'uuid';

export interface ModalConfig {
  dismissButton?: ButtonConfig;
  closeButton?: ButtonConfig;
  customFooter?: boolean;
  headerClass?: string[];
  windowClass?: string;
  closeButtonDisabled?: boolean;
  hideDismissButton?: boolean;
  hideCloseButton?: boolean;
  options?: NgbModalOptions;
  titleText?: string;
  modalLabel?: string;
}

interface InternalModalConfig extends ModalConfig {
  closeButton: ButtonConfig;
  headerClass: string[];
  titleText: string;
}

export interface ButtonConfig {
  label?: string;
  classes?: string[];
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  configLoaded = false;

  @Input() public modalConfig: ModalConfig = {};

  @Output()
  public modalRef: EventEmitter<NgbModalRef> = new EventEmitter();

  @Output()
  public isOpening: EventEmitter<NgbModalRef> = new EventEmitter();

  @Output()
  public isClosing: EventEmitter<NgbModalRef> = new EventEmitter();

  @Output()
  public isDismissing: EventEmitter<NgbModalRef> = new EventEmitter();

  @ViewChild('modal')
  public modalContent: TemplateRef<ModalComponent>;

  public modalReference: NgbModalRef;

  modalLabel: string;

  modalLabelId = uuid();

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    this.loadConfig();
  }

  public config: InternalModalConfig;

  @Input() disableSubmit = false;

  defaultConfig: InternalModalConfig = {
    closeButton: {
      classes: ['btn', 'btn-primary', 'btn-md'],
      label: 'OK',
    },
    closeButtonDisabled: false,
    headerClass: ['modal-header'],
    customFooter: false,
    hideCloseButton: false,
    options: {},
    titleText: '',
    modalLabel: '',
  };

  loadConfig() {
    this.config = {
      ...this.defaultConfig,
      ...this.modalConfig,
    };
    if (this.modalConfig.headerClass) {
      this.defaultConfig.headerClass = [
        ...this.defaultConfig.headerClass,
        ...(this.modalConfig as InternalModalConfig).headerClass,
      ];
    }
    if (this.config.closeButton.classes?.length === 0) {
      this.config.closeButton.classes = this.defaultConfig.closeButton.classes;
    }
    this.config.options = {
      ...this.config.options,
      ariaLabelledBy: this.modalLabelId,
    };
    if (this.modalConfig.windowClass) {
      this.config.options.windowClass = this.modalConfig.windowClass;
    }
    this.modalLabel =
      this.config.modalLabel || this.config.titleText || 'modal';
    this.configLoaded = true;
  }

  open() {
    this.modalReference = this.modalService.open(
      this.modalContent,
      this.config.options
    );
    this.modalRef.emit(this.modalReference);
    this.isOpening.emit(this.modalReference);
    this.modalReference.closed.subscribe(() =>
      this.isClosing.emit(this.modalReference)
    );
    this.modalReference.dismissed.subscribe(() =>
      this.isDismissing.emit(this.modalReference)
    );
  }

  close() {
    this.modalReference.close();
    return true;
  }

  dismiss() {
    this.modalReference.dismiss();
  }
}
