import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PresenceChannel } from 'pusher-js';
import { BehaviorSubject, map } from 'rxjs';
import { UserType } from 'src/app/common/state/user/type/user-type';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';

import {
  AttendeeRubricDTO,
  RubricDTO,
  StrandDTO,
} from '../../dtos/attendee-rubric.dto';
import { BadgeUserDTO } from '../../dtos/badge.dto';
import {
  CoachingSessionDTO,
  NewCoachingSessionItemsPayloadDTO,
  NextSessionDTO,
} from '../../dtos/coaching-session.dto';
import { FeedbackDTO } from '../../dtos/feedback.dto';
import { feedbackDTOFromAPIResponse } from '../../helpers/translators/coaching-session-dto.translator';
import { PusherFeedback } from '../../services/coaching-log/coaching-log-service.dto';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { FeedbackPayload } from '../../types/payloads/coaching-log.payloads';
import { FeedbackAPIResponse } from '../../types/responses/coaching-log.responses';
import { LogTypeSelectDto } from '../log-type-select/log-type-select.dto';

@Component({
  selector: 'app-coaching-session-summary',
  templateUrl: './coaching-session-summary.component.html',
  styleUrls: ['./coaching-session-summary.component.scss'],
})
export class CoachingSessionSummaryComponent implements OnInit {
  @Input() session: CoachingSessionDTO;

  @Input() rubricData: AttendeeRubricDTO[];

  user$ = select(UserState.getUser);

  user: User | null = null;

  badgeUsers: BadgeUserDTO[] = [];

  feedback: FeedbackDTO[] = [];

  feedbackForm: FormGroup;

  feedbackFormSubmitted = false;

  feedbackFormSubmitLoading = false;

  isEditingFeedback = false;

  editFeedbackForm: FormGroup;

  editFeedbackFormSubmitted = false;

  editFeedbackFormSubmitLoading = false;

  nextSessionForm: FormGroup;

  nextSessionFormSubmitted = false;

  nextSessionFormSubmitLoading = false;

  currentDate = new Date();

  currentDateTime = new Date().getTime() / 1000;

  selectedDate = new BehaviorSubject<number>(this.currentDateTime);

  selectedDate$ = this.selectedDate.asObservable();

  selectedTimezone = new BehaviorSubject<TimezoneLinkBack>(
    TimezoneLinkBack.Central
  );

  selectedTimezone$ = this.selectedTimezone.asObservable();

  currentIcon: string;

  pusherChannel: PresenceChannel;

  icons: string[] = [
    'assessment',
    'collaboration',
    'communication',
    'criticalanalysis',
    'cultureenvironment',
    'differentiation',
    'digitallearning',
    'goalsetting',
    'problemsolving',
    'reflectiongrowthmindset',
    'relevanceauthenticity',
    'smallgroupinstruction',
    'standardsalignment',
  ];

  coachlogItemsPayload: NewCoachingSessionItemsPayloadDTO = {
    title: '',
    logType: null,
    startDatetime: 0,
    endDatetime: 0,
    timezone: TimezoneLinkBack.Central,
    coachUser: null,
    creatorUserId: 0,
    coachees: [],
    shadowers: [],
  };

  duration = 60;

  defaultLogType: LogTypeSelectDto;

  formValid = true;

  scheduledSession: NextSessionDTO;

  constructor(
    private coachingLogService: CoachingLogService,
    private formBuilder: FormBuilder
  ) {
    this.user$
      .pipe(
        map((user) => {
          this.user = user;
        })
      )
      .subscribe();
  }

  getIcon(competencyTitle: string) {
    for (let i = 0; i < this.icons.length; i += 1) {
      competencyTitle = competencyTitle.toLowerCase();
      const currentIcon = this.icons[i];
      if (competencyTitle.includes(currentIcon)) {
        return this.icons[i];
      }
    }
    return 'assessment';
  }

  getAttendeeBadgeUser(attendeeUser: UserDTO): BadgeUserDTO | undefined {
    if (this.badgeUsers) {
      return this.badgeUsers.filter(
        (badgeUser) => badgeUser.id === attendeeUser.id
      )[0];
    }
    return undefined;
  }

  getAttendeeRubrics(userId: number): RubricDTO[] | undefined {
    if (this.rubricData) {
      return this.rubricData.filter(
        (rubricData) => rubricData.userId === userId
      )[0].rubrics;
    }
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getStrandProgress(strand: StrandDTO): number {
    let levelsCompleted = 0;
    if (strand.level1.levelCompleted === true) {
      levelsCompleted = 1;
    }
    if (strand.level2.levelCompleted === true) {
      levelsCompleted = 2;
    }
    if (strand.level3.levelCompleted === true) {
      levelsCompleted = 3;
    }
    if (strand.level4.levelCompleted === true) {
      levelsCompleted = 4;
    }
    return levelsCompleted;
  }

  // eslint-disable-next-line class-methods-use-this
  getLevels(strand: StrandDTO) {
    return [strand.level1, strand.level2, strand.level3, strand.level4];
  }

  ngOnInit() {
    this.getBadgeUsers();
    this.getFeedback();
    this.getNextSession();

    this.pusherChannel = this.coachingLogService.getChannel(
      this.session.id
    ) as PresenceChannel;
    this.pusherChannel.bind(
      'coachlog:feedback_created',
      (message: PusherFeedback) => {
        this.feedback.push(
          feedbackDTOFromAPIResponse(message.item as FeedbackAPIResponse)
        );
      }
    );
    this.pusherChannel.bind(
      'coachlog:feedback_updated',
      (message: PusherFeedback) => {
        const index = this.feedback.findIndex(
          (item) => item.id === message.item.id
        );
        this.feedback[index] = feedbackDTOFromAPIResponse(
          message.item as FeedbackAPIResponse
        );
      }
    );
    this.pusherChannel.bind(
      'coachlog:feedback_deleted',
      (message: PusherFeedback) => {
        this.feedback = this.feedback.filter(
          (feedbackItem) => feedbackItem.id !== message.item.id
        );
      }
    );

    this.feedbackForm = this.formBuilder.group({
      feedback: ['', Validators.required],
    });
    this.nextSessionForm = this.formBuilder.group({
      title: [this.session.title, Validators.required],
    });

    this.defaultLogType = {
      name: this.session.type.title,
      id: this.session.type.id,
    };
    this.coachlogItemsPayload.logType = this.defaultLogType;

    this.selectedDate.subscribe((timestamp) => {
      this.coachlogItemsPayload.startDatetime = timestamp;
      if (this.duration) {
        this.coachlogItemsPayload.endDatetime =
          this.coachlogItemsPayload.startDatetime + 60 * this.duration;
      }
    });
    this.selectedTimezone.subscribe((timezone) => {
      this.coachlogItemsPayload.timezone = timezone;
    });
  }

  updateSessionLogType(logType: LogTypeSelectDto) {
    this.coachlogItemsPayload.logType = logType;
  }

  updateDateTime(params: { time: number; timezone: TimezoneLinkBack }) {
    this.selectedDate.next(params.time / 1000);
    this.selectedTimezone.next(params.timezone);
  }

  getFeedback() {
    this.coachingLogService.getFeedback(this.session.id).subscribe((res) => {
      this.feedback = res;
    });
  }

  getBadgeUsers() {
    this.coachingLogService.getBadgeUsers(this.session.id).subscribe((res) => {
      this.badgeUsers = res;
    });
  }

  getNextSession() {
    this.coachingLogService
      .getAttendeesNeedNextLog(this.session.id)
      .subscribe((res) => {
        if (res.attendeesScheduled[0]) {
          this.scheduledSession = res.attendeesScheduled[0].nextLog;
        }
      });
  }

  submitFeedback() {
    this.feedbackFormSubmitted = true;

    this.isEditingFeedback = false;

    if (this.feedbackForm.invalid) {
      return;
    }

    this.feedbackFormSubmitLoading = true;

    const payload: FeedbackPayload = {
      content: this.feedbackForm.controls['feedback'].value,
      egrowe_coachlog_id: this.session.id,
      user_id: this.user?.id || 0,
    };

    this.coachingLogService.submitFeedback(payload).subscribe((res) => {
      if (res) {
        this.getFeedback();
        this.feedbackFormSubmitLoading = false;
      }
    });
  }

  editFeedback(itemId: number) {
    this.editFeedbackFormSubmitted = true;

    if (this.editFeedbackForm.invalid) {
      return;
    }

    this.editFeedbackFormSubmitLoading = true;

    this.coachingLogService
      .editFeedback(itemId, this.editFeedbackForm.controls['feedback'].value)
      .subscribe((res) => {
        if (res) {
          this.getFeedback();
          setTimeout(() => {
            this.editFeedbackFormSubmitLoading = false;
            this.isEditingFeedback = false;
          }, 250);
        }
      });
  }

  deleteFeedback(itemId: number) {
    this.coachingLogService.deleteFeedback(itemId).subscribe((res) => {
      if (res) {
        this.getFeedback();
        this.feedbackForm = this.formBuilder.group({
          feedback: ['', Validators.required],
        });
        this.feedbackFormSubmitted = false;
      }
    });
  }

  toggleEditMode(content: string) {
    this.isEditingFeedback = !this.isEditingFeedback;
    if (this.isEditingFeedback) {
      this.editFeedbackForm = this.formBuilder.group({
        feedback: [content, Validators.required],
      });
    }
  }

  scheduleNextSession() {
    const userModelFromDTO = (item: UserDTO): User => ({
      coach: undefined,
      isCoach: true,
      fullName: `${item.profile.first_name} ${item.profile.last_name}`,
      id: item.id,
      username: item.username,
      profile: item.profile,
      type: UserType.COACH,
      status: item.status,
      coacheeType: 'coachee',
      email: item.email,
      district: undefined,
      school: item.school,
      roles: [],
      isDeleted: item.is_deleted,
      isSmart: item.is_smart,
      isSmartDefault: item.is_smart_default,
      smartCoach: item.smartCoach,
      yearsCoached: item.years_coached,
      createdAt: item.profile.created_at,
    });

    this.coachlogItemsPayload = {
      ...this.coachlogItemsPayload,
      title: this.nextSessionForm.controls['title'].value,
      coachUser: userModelFromDTO(this.session.user),
      creatorUserId: this.user?.id ? this.user.id : 0,
      coachees: this.session.attendees.map((attendee) => ({
        id: attendee.user.id,
        profile: attendee.user.profile,
      })),
      shadowers: this.session.shadowers.map((shadower) => ({
        id: shadower.user.id,
        profile: shadower.user.profile,
      })),
    };

    this.formValid = true;

    if (!this.coachlogItemsPayload.logType) {
      this.formValid = false;
    }

    this.nextSessionFormSubmitted = true;
    this.nextSessionFormSubmitLoading = true;

    if (this.nextSessionForm.invalid || !this.formValid) {
      this.nextSessionFormSubmitLoading = false;
    } else {
      this.coachingLogService.createCoachinglog(this.coachlogItemsPayload);
      this.coachingLogService.coachlogCreated.subscribe(() => {
        this.getNextSession();
        this.nextSessionFormSubmitted = true;
        this.nextSessionFormSubmitLoading = false;
      });
    }
  }
}
