/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { APILoadingStatus } from 'src/app/common/types/types';
import {
  apiLoadingStatusComplete,
  defaultAPILoadingStatus,
} from 'src/app/common/utilities/api-status.helpers';

import {
  AttendeeRubricDTO,
  CompetencyListItemDTO,
  DecomposedRubricDictionary,
  EvidenceDTO,
  IndicatorDTO,
  OptionDTO,
} from '../../dtos/attendee-rubric.dto';
import { CoachingSessionDTO } from '../../dtos/coaching-session.dto';
import { updateStrandWithNewGoal } from '../../helpers/coachee-log.utilities';
import {
  checkIndicatorForCoachAssessments,
  setGoalAndCompletedLevelsFromStandDTO,
} from '../../helpers/translators/attendee-rubrics-dto.translator';
import {
  coachingSessionAttendeDTOFromAPIResponse,
  coachingSessionShadowerDTOFromAPIResponse,
} from '../../helpers/translators/coaching-session-dto.translator';
import { evidenceAssessmentDTOFromPusherEvidenceAssessmentResponse } from '../../helpers/translators/evidence.translators';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import {
  AddCompetencies,
  AddEvidence,
  AddIndicatorAssessment,
  AddOption,
  AddResourceToOption,
  AddWill,
  DeleteAttendee,
  DeleteEvidence,
  DeleteShadower,
  FetchAttendeeRubrics,
  FetchSessionData,
  RemoveCompetency,
  RemoveIndicatorAssessment,
  RemoveOption,
  RemoveResourceFromOption,
  RemoveWill,
  ResetLogDefaults,
  UpdateAssesment,
  UpdateAttendee,
  UpdateCompetency,
  UpdateEvidence,
  UpdateGoal,
  UpdateLog,
  AddLogAttachments,
  DeleteLogAttachments,
  UpdateNotes,
  UpdateOption,
  UpdateShadower,
  UpdateWill,
} from './coaching-log.actions';

export interface CompetencyStateObject {
  competencies: CompetencyListItemDTO[];
  competencySelected: boolean;
}

export interface AttendeeRubricStateObject {
  lastUpdated: number;
  rubricsByLog: { [logId: string]: AttendeeRubricDTO[] };
}

export interface CoachingLogModel {
  coachLogStatus: APILoadingStatus;
  rubricStatus: APILoadingStatus;
  sessionData: { [sessionId: number]: CoachingSessionDTO };
  attendeeRubrics: AttendeeRubricStateObject;
  lastUpdated: number;
  decomposedByUser: DecomposedRubricDictionary;
  options: OptionDTO[];
  competencyData: CompetencyStateObject;
  evidence: EvidenceDTO[];
}

const logDefaults = {
  coachLogStatus: defaultAPILoadingStatus,
  rubricStatus: defaultAPILoadingStatus,
  sessionData: {},
  attendeeRubrics: { lastUpdated: 0, rubricsByLog: {} },
  lastUpdated: 0,
  decomposedByUser: {},
  options: [],
  competencyData: { competencies: [], competencySelected: false },
  evidence: [],
};

@State<CoachingLogModel>({
  name: 'coachingLog',
  defaults: logDefaults,
})
@Injectable()
export class CoachingLogState {
  constructor(private coachingLogService: CoachingLogService) {}

  @Selector()
  static getRubric(state: CoachingLogModel) {
    return (userId: number, rubricId: number, logId: number) =>
      state.decomposedByUser[userId].rubrics.filter(
        (rubric) => rubric.id === rubricId && rubric.coachlogId === logId
      )[0];
  }

  @Selector()
  static getStrand(state: CoachingLogModel) {
    return (userId: number, strandId: number) =>
      state.decomposedByUser[userId].strands.filter(
        (strand) => strand.id === strandId
      )[0];
  }

  @Selector()
  static getIndicator(state: CoachingLogModel) {
    return (userId: number, indicatorId: number) =>
      state.decomposedByUser[userId].indicators.filter(
        (indicator) => indicator.id === indicatorId
      )[0];
  }

  @Selector()
  static getCompetency(state: CoachingLogModel) {
    return (userId: number, competencyId: number, logId: number) =>
      state.decomposedByUser[userId].competencies.filter(
        (competency) =>
          competency.id === competencyId && competency.coachlogId === logId
      )[0];
  }

  @Selector()
  static getCompetenciesByLog(state: CoachingLogModel) {
    return (coachlogId: number, userId: number) =>
      state.decomposedByUser[userId]?.competencies.filter(
        (rubric) => rubric.coachlogId === coachlogId
      );
  }

  @Selector()
  static getOptions(state: CoachingLogModel) {
    return (strandId: number, level: number, logId: number) =>
      state.options.filter(
        (option) =>
          strandId === option.strandId &&
          level === option.level &&
          logId === option.coachlogId
      );
  }

  @Selector()
  static getEvidence(state: CoachingLogModel) {
    return (userId: number, strandId: number, level: number, logId: number) =>
      state.evidence.filter(
        (evidence) =>
          userId === evidence.userId &&
          logId === evidence.coachlogId &&
          strandId === evidence.strandId &&
          level === evidence.level
      );
  }

  @Selector()
  static getOption(state: CoachingLogModel) {
    return (optionId: number) =>
      state.options.filter((option) => option.id === optionId)[0];
  }

  @Selector()
  static getWill(state: CoachingLogModel) {
    return (userId: number, optionId: number, logId: number) => {
      const results = state.decomposedByUser[userId].wills.filter(
        (will) => will.optionId === optionId && will.coachlogId === logId
      );
      if (results.length > 0) {
        return results[0];
      }
      return null;
    };
  }

  @Selector()
  static getWillsByCoachlog(state: CoachingLogModel) {
    return (userId: number, logId: number) =>
      state.decomposedByUser[userId]?.wills.filter(
        (will) => will.coachlogId === logId
      );
  }

  @Selector()
  static getAttendeeRubricsData(state: CoachingLogModel) {
    return (logId: number) =>
      state.attendeeRubrics.rubricsByLog[logId.toString()];
  }

  @Selector()
  static getSessionData(state: CoachingLogModel) {
    return (logId: number) => state.sessionData[logId];
  }

  @Selector()
  static getCompetencyData(state: CoachingLogModel) {
    return state.competencyData;
  }

  @Action(FetchSessionData)
  addSessionData(
    ctx: StateContext<CoachingLogModel>,
    { payload }: FetchSessionData
  ) {
    return this.coachingLogService.getCoachingSession(payload).pipe(
      tap((response) => {
        const currentState = ctx.getState();
        if (response) {
          ctx.patchState({
            sessionData: {
              ...currentState.sessionData,
              [response.id]: response,
            },
            coachLogStatus: apiLoadingStatusComplete,
          });
        } else {
          ctx.patchState({
            coachLogStatus: {
              ...apiLoadingStatusComplete,
              errors: ['No session data found'],
            },
          });
        }
      })
    );
  }

  @Action(FetchAttendeeRubrics)
  addAttendeeRubrics(
    ctx: StateContext<CoachingLogModel>,
    { payload }: FetchAttendeeRubrics
  ) {
    return this.coachingLogService.getAttendeeRubrics(payload).pipe(
      tap((response) => {
        const currentState = ctx.getState();
        if (response && response.attendeeRubrics.length > 0) {
          const updateObject = {
            attendeeRubrics: {
              lastUpdated: Date.now(),
              rubricsByLog: {
                ...currentState.attendeeRubrics.rubricsByLog,
                [response.attendeeRubrics[0].egroweCoachlogId.toString()]:
                  response.attendeeRubrics,
              },
            },
            decomposedByUser: currentState.decomposedByUser,
            rubricStatus: apiLoadingStatusComplete,
            options: response.options,
            evidence: response.evidence,
          };
          if (response.decomposedByUser) {
            Object.entries(response.decomposedByUser).forEach((item) => {
              const [key, value] = item;
              updateObject.decomposedByUser[key] = {
                ...updateObject.decomposedByUser[key],
                ...value,
              };
            });
          }
          ctx.patchState(updateObject);
        } else {
          ctx.patchState({
            attendeeRubrics: {
              lastUpdated: Date.now(),
              rubricsByLog: currentState.attendeeRubrics.rubricsByLog,
            },
            rubricStatus: {
              ...apiLoadingStatusComplete,
              errors: ['No rubric data found'],
            },
          });
        }
      })
    );
  }

  @Action(AddOption)
  addOption(ctx: StateContext<CoachingLogModel>, { payload }: AddOption) {
    const currentState = ctx.getState();
    currentState.options.push(payload);
    ctx.patchState({
      lastUpdated: Date.now(),
      options: currentState.options,
    });
  }

  @Action(RemoveOption)
  removeOption(ctx: StateContext<CoachingLogModel>, { payload }: RemoveOption) {
    const currentState = ctx.getState();
    currentState.options = currentState.options.filter(
      (option) => option.id !== payload
    );
    ctx.patchState({
      lastUpdated: Date.now(),
      options: currentState.options,
    });
  }

  @Action(UpdateOption)
  updateOption(ctx: StateContext<CoachingLogModel>, { payload }: UpdateOption) {
    const currentState = ctx.getState();
    const id = currentState.options.findIndex(
      (option) => option.id === payload.id
    );
    currentState.options[id].content = payload.content;
    ctx.patchState({
      lastUpdated: Date.now(),
      options: currentState.options,
    });
  }

  @Action(UpdateGoal)
  updateGoal(ctx: StateContext<CoachingLogModel>, { payload }: UpdateGoal) {
    const currentState = ctx.getState();
    const currentLogRubrics = currentState.attendeeRubrics?.rubricsByLog[
      payload.egroweCoachlogId
    ].map((logRubric) => {
      if (logRubric.userId === payload.userId) {
        logRubric.rubrics?.map((rubric) => {
          rubric.competencyGroups.map((group) => {
            group.competencies.map((competency) => {
              if (competency.id === payload.egroweStandardId) {
                competency.indicatorSet.strands.map((strand) => {
                  if (strand.id === payload.egroweIndicatorGroupId) {
                    strand = updateStrandWithNewGoal(strand, payload);
                  }
                  return strand;
                });
              }
              return competency;
            });
            return group;
          });
          return rubric;
        });
      }
      return logRubric;
    });
    const userRubrics = currentState.decomposedByUser[payload.userId];
    userRubrics.strands.map((strand) => {
      if (
        strand.competencyId === payload.egroweStandardId &&
        strand.indicatorSetId === payload.egroweIndicatorGroupId
      ) {
        strand = updateStrandWithNewGoal(strand, payload);
      }
      return strand;
    });
    userRubrics.goals = [
      ...userRubrics.goals.filter(
        (goal) =>
          goal.egroweStandardId !== payload.egroweStandardId &&
          goal.egroweIndicatorGroupId !== payload.egroweIndicatorGroupId &&
          goal.userId !== payload.userId
      ),
      payload,
    ];
    const updateObject = {
      attendeeRubrics: {
        lastUpdated: Date.now(),
        rubricsByLog: {
          ...currentState.attendeeRubrics.rubricsByLog,
          [payload.egroweCoachlogId]: currentLogRubrics,
        },
      },
      decomposedByuser: currentState.decomposedByUser,
    };
    ctx.patchState(updateObject);
  }

  @Action(AddIndicatorAssessment)
  addIndicatorAssessment(
    ctx: StateContext<CoachingLogModel>,
    { payload }: AddIndicatorAssessment
  ) {
    const currentState = ctx.getState();
    const newIndicators: IndicatorDTO[] = [];
    if (
      currentState &&
      currentState.attendeeRubrics.rubricsByLog[payload.egroweCoachlogId]
    ) {
      currentState.attendeeRubrics.rubricsByLog[
        payload.egroweCoachlogId
      ].forEach((attendeeRubrics) => {
        if (attendeeRubrics.userId === payload.assesseeUserId) {
          attendeeRubrics.rubrics.forEach((attendeeRubric) => {
            if (attendeeRubric.id === payload.egroweRubricId) {
              attendeeRubric.competencyGroups.forEach((competencyGroup) => {
                competencyGroup.competencies.forEach((competency) => {
                  competency.indicatorSet.strands.forEach((strand) => {
                    strand.level1.indicators.forEach((indicator) => {
                      if (indicator.id === payload.egroweIndicatorId) {
                        indicator.assessments.push(payload);
                        indicator =
                          checkIndicatorForCoachAssessments(indicator);
                      }
                    });
                    strand.level2.indicators.forEach((indicator) => {
                      if (indicator.id === payload.egroweIndicatorId) {
                        indicator.assessments.push(payload);
                        indicator =
                          checkIndicatorForCoachAssessments(indicator);
                      }
                    });
                    strand.level3.indicators.forEach((indicator) => {
                      if (indicator.id === payload.egroweIndicatorId) {
                        indicator.assessments.push(payload);
                        indicator =
                          checkIndicatorForCoachAssessments(indicator);
                      }
                    });
                    strand.level4.indicators.forEach((indicator) => {
                      if (indicator.id === payload.egroweIndicatorId) {
                        indicator.assessments.push(payload);
                        indicator =
                          checkIndicatorForCoachAssessments(indicator);
                      }
                    });
                    const { indicators } =
                      setGoalAndCompletedLevelsFromStandDTO(strand);
                    newIndicators.push(...indicators);
                  });
                });
              });
            }
          });
        }
      });
    }
    return ctx.patchState({
      lastUpdated: new Date().getTime(),
      attendeeRubrics: {
        lastUpdated: new Date().getTime(),
        rubricsByLog: currentState.attendeeRubrics.rubricsByLog,
      },
      decomposedByUser: {
        ...currentState.decomposedByUser,
        [payload.assesseeUserId]: {
          ...currentState.decomposedByUser[payload.assesseeUserId],
          indicators: newIndicators,
        },
      },
    });
  }

  @Action(RemoveIndicatorAssessment)
  removeIndicatorAssessment(
    ctx: StateContext<CoachingLogModel>,
    { assessmentId }: RemoveIndicatorAssessment
  ) {
    const currentState = ctx.getState();
    const newIndicators: { [id: string]: IndicatorDTO[] } = {};
    let userId = 0;
    if (currentState) {
      const logIds = Object.keys(currentState.attendeeRubrics.rubricsByLog);
      logIds.forEach((coachlogId) => {
        currentState.attendeeRubrics.rubricsByLog[coachlogId].forEach(
          (attendeeRubrics) => {
            if (
              !Object.keys(newIndicators).includes(
                attendeeRubrics.userId.toString()
              )
            ) {
              newIndicators[attendeeRubrics.userId.toString()] = [];
            }
            attendeeRubrics.rubrics.forEach((attendeeRubric) => {
              attendeeRubric.competencyGroups.forEach((competencyGroup) => {
                competencyGroup.competencies.forEach((competency) => {
                  competency.indicatorSet.strands.forEach((strand) => {
                    strand.level1.indicators.forEach((indicator) => {
                      const beforeLength = indicator.assessments.length;
                      indicator.assessments = indicator.assessments.filter(
                        (assessment) => assessment.id !== assessmentId
                      );
                      if (indicator.assessments.length !== beforeLength) {
                        userId = attendeeRubrics.userId;
                      }
                      indicator = checkIndicatorForCoachAssessments(indicator);
                    });
                    strand.level2.indicators.forEach((indicator) => {
                      const beforeLength = indicator.assessments.length;
                      indicator.assessments = indicator.assessments.filter(
                        (assessment) => assessment.id !== assessmentId
                      );
                      if (indicator.assessments.length !== beforeLength) {
                        userId = attendeeRubrics.userId;
                      }
                      indicator = checkIndicatorForCoachAssessments(indicator);
                    });
                    strand.level3.indicators.forEach((indicator) => {
                      const beforeLength = indicator.assessments.length;
                      indicator.assessments = indicator.assessments.filter(
                        (assessment) => assessment.id !== assessmentId
                      );
                      if (indicator.assessments.length !== beforeLength) {
                        userId = attendeeRubrics.userId;
                      }
                      indicator = checkIndicatorForCoachAssessments(indicator);
                    });
                    strand.level4.indicators.forEach((indicator) => {
                      const beforeLength = indicator.assessments.length;
                      indicator.assessments = indicator.assessments.filter(
                        (assessment) => assessment.id !== assessmentId
                      );
                      if (indicator.assessments.length !== beforeLength) {
                        userId = attendeeRubrics.userId;
                      }
                      indicator = checkIndicatorForCoachAssessments(indicator);
                    });

                    const { indicators } =
                      setGoalAndCompletedLevelsFromStandDTO(strand);
                    newIndicators[attendeeRubrics.userId.toString()].push(
                      ...indicators
                    );
                  });
                });
              });
            });
          }
        );
      });
    }

    return ctx.patchState({
      lastUpdated: new Date().getTime(),
      attendeeRubrics: {
        lastUpdated: new Date().getTime(),
        rubricsByLog: currentState.attendeeRubrics.rubricsByLog,
      },
      decomposedByUser: {
        ...currentState.decomposedByUser,
        [userId]: {
          ...currentState.decomposedByUser[userId],
          indicators: newIndicators[userId],
        },
      },
    });
  }

  @Action(UpdateNotes)
  updateNotes(ctx: StateContext<CoachingLogModel>, { payload }: UpdateNotes) {
    const currentState = ctx.getState();
    if (currentState.sessionData[payload.coachlogId]) {
      currentState.sessionData[payload.coachlogId] = {
        ...currentState.sessionData[payload.coachlogId],
        sessionNotes: payload.notes,
      };
      ctx.patchState({
        lastUpdated: new Date().getTime(),
        sessionData: currentState.sessionData,
      });
    }
  }

  @Action(AddResourceToOption)
  addResourceToOption(
    ctx: StateContext<CoachingLogModel>,
    { payload }: AddResourceToOption
  ) {
    const currentState = ctx.getState();
    const id = currentState.options.findIndex(
      (option) => option.id === payload.optionId
    );
    if (
      !currentState.options[id].resources.some(
        (resource) => resource.id === payload.resource.id
      )
    ) {
      currentState.options[id].resources.push(payload.resource);
      ctx.patchState({
        lastUpdated: new Date().getTime(),
        options: currentState.options,
      });
    }
  }

  @Action(RemoveResourceFromOption)
  removeResourceFromOption(
    ctx: StateContext<CoachingLogModel>,
    { payload }: RemoveResourceFromOption
  ) {
    const currentState = ctx.getState();
    const id = currentState.options.findIndex(
      (option) => option.id === payload.optionId
    );
    currentState.options[id].resources = currentState.options[
      id
    ].resources.filter((resource) => resource.id !== payload.resourceId);
    ctx.patchState({
      lastUpdated: new Date().getTime(),
      options: currentState.options,
    });
  }

  @Action(AddWill)
  addWill(ctx: StateContext<CoachingLogModel>, { payload }: AddWill) {
    const currentState = ctx.getState();
    currentState.decomposedByUser[payload.userId].wills.push(payload);

    const optionIdx = currentState.options.findIndex(
      (option) => option.id === payload.optionId
    );
    if (optionIdx > -1) {
      currentState.options[optionIdx].will = payload;
    }
    return ctx.patchState({
      lastUpdated: new Date().getTime(),
      options: currentState.options,
      decomposedByUser: {
        ...currentState.decomposedByUser,
        [payload.userId]: {
          ...currentState.decomposedByUser[payload.userId],
          wills: currentState.decomposedByUser[payload.userId].wills,
        },
      },
    });
  }

  @Action(RemoveWill)
  removeWill(ctx: StateContext<CoachingLogModel>, { payload }: RemoveWill) {
    const currentState = ctx.getState();
    const willToDelete = currentState.decomposedByUser[
      payload.userId
    ].wills.find((will) => will.id === payload.willId);
    if (willToDelete) {
      currentState.decomposedByUser[payload.userId].wills =
        currentState.decomposedByUser[payload.userId].wills.filter(
          (will) => will.id !== payload.willId
        );
      const optionIdx = currentState.options.findIndex(
        (option) => option.id === willToDelete.optionId
      );
      if (optionIdx > -1) {
        currentState.options[optionIdx].will = null;
      }
    }
    return ctx.patchState({
      lastUpdated: new Date().getTime(),
      options: currentState.options,
      decomposedByUser: {
        ...currentState.decomposedByUser,
        [payload.userId]: {
          ...currentState.decomposedByUser[payload.userId],
          wills: currentState.decomposedByUser[payload.userId].wills,
        },
      },
    });
  }

  @Action(UpdateWill)
  updateWill(ctx: StateContext<CoachingLogModel>, { payload }: UpdateWill) {
    const currentState = ctx.getState();
    const { wills } = currentState.decomposedByUser[payload.userId];
    const willIndex = wills.findIndex((will) => will.id === payload.id);
    wills[willIndex] = payload;
    const optionIdx = currentState.options.findIndex(
      (option) => option.id === payload.optionId
    );
    if (optionIdx > -1) {
      currentState.options[optionIdx].will = payload;
    }
    return ctx.patchState({
      lastUpdated: new Date().getTime(),
      decomposedByUser: {
        ...currentState.decomposedByUser,
        [payload.userId]: {
          ...currentState.decomposedByUser[payload.userId],
          wills,
        },
      },
      options: currentState.options,
    });
  }

  @Action(AddCompetencies)
  addCompetencies(
    ctx: StateContext<CoachingLogModel>,
    { payload }: AddCompetencies
  ) {
    const currentState = ctx.getState();
    [
      currentState.competencyData.competencies,
      currentState.competencyData.competencySelected,
    ] = payload;
    ctx.patchState(currentState);
  }

  // Update competency is always called as a result of adding a competency, thus we can set selected = true
  @Action(UpdateCompetency)
  updateCompetency(
    ctx: StateContext<CoachingLogModel>,
    { payload }: UpdateCompetency
  ) {
    const currentState = ctx.getState();
    currentState.competencyData.competencySelected = false;
    currentState.competencyData.competencies.forEach((competency) => {
      if (competency.id === parseInt(payload.standard_id)) {
        competency.selected = true;
      }
      if (competency.selected) {
        currentState.competencyData.competencySelected = true;
      }
    });
    ctx.patchState(currentState);
  }

  @Action(RemoveCompetency)
  removeCompetency(
    ctx: StateContext<CoachingLogModel>,
    { payload }: RemoveCompetency
  ) {
    const currentState = ctx.getState();
    currentState.competencyData.competencySelected = false;
    currentState.competencyData.competencies.forEach((competency) => {
      if (competency.id === parseInt(payload.item)) {
        competency.selected = false;
      }
      if (competency.selected) {
        currentState.competencyData.competencySelected = true;
      }
    });
    ctx.patchState(currentState);
  }

  @Action(UpdateLog)
  updateLog(ctx: StateContext<CoachingLogModel>, { payload }: UpdateLog) {
    const currentState = ctx.getState();
    if (payload.title) {
      if (currentState.sessionData[payload.coachlogId]) {
        currentState.sessionData[payload.coachlogId].title = payload.title;
      }
      ctx.patchState({
        sessionData: currentState.sessionData,
      });
    } else if (payload.content) {
      if (currentState.sessionData[payload.coachlogId]) {
        currentState.sessionData[payload.coachlogId].content = payload.content;
      }
      ctx.patchState({
        sessionData: currentState.sessionData,
      });
    } else if (payload.start_datetime) {
      if (currentState.sessionData[payload.coachlogId]) {
        currentState.sessionData[payload.coachlogId].startDatetime =
          payload.start_datetime;
      }
      ctx.patchState({
        sessionData: currentState.sessionData,
      });
    } else if (payload.is_private === 0 || payload.is_private === 1) {
      if (currentState.sessionData[payload.coachlogId]) {
        currentState.sessionData[payload.coachlogId].isPrivate =
          payload.is_private;
      }
      ctx.patchState({
        sessionData: currentState.sessionData,
      });
    }
  }

  @Action(DeleteAttendee)
  deleteAttendee(
    ctx: StateContext<CoachingLogModel>,
    { payload }: DeleteAttendee
  ) {
    const currentState = ctx.getState();

    if (currentState.sessionData[payload.coachlogId]?.attendees) {
      currentState.sessionData[payload.coachlogId].attendees =
        currentState.sessionData[payload.coachlogId]?.attendees.filter(
          (attendee) => attendee.id !== payload.id
        );
    }

    ctx.patchState({
      lastUpdated: new Date().getTime(),
      sessionData: currentState.sessionData,
    });
  }

  @Action(UpdateAttendee)
  updateAttendee(
    ctx: StateContext<CoachingLogModel>,
    { payload }: UpdateAttendee
  ) {
    const currentState = ctx.getState();
    const userIndex = currentState.sessionData[
      payload.egrowe_coachlog_id
    ]?.attendees.findIndex((item) => item.id === payload.id);

    if (userIndex < 0) {
      currentState.sessionData[payload.egrowe_coachlog_id]?.attendees.push(
        coachingSessionAttendeDTOFromAPIResponse(payload)
      );
    } else {
      currentState.sessionData[payload.egrowe_coachlog_id].attendees[
        userIndex
      ] = coachingSessionAttendeDTOFromAPIResponse(payload);
    }

    ctx.patchState({
      lastUpdated: new Date().getTime(),
      sessionData: currentState.sessionData,
    });
  }

  @Action(UpdateShadower)
  updateShadower(
    ctx: StateContext<CoachingLogModel>,
    { payload }: UpdateShadower
  ) {
    const currentState = ctx.getState();

    const userExists = currentState.sessionData[
      payload.egrowe_coachlog_id
    ]?.shadowers.find((item) => item.userId === payload.user_id);

    if (userExists) {
      if (currentState.sessionData[payload.egrowe_coachlog_id]?.shadowers) {
        currentState.sessionData[payload.egrowe_coachlog_id].shadowers[
          currentState.sessionData[
            payload.egrowe_coachlog_id
          ]?.shadowers.findIndex((item) => item.userId === payload.user_id)
        ].present = payload.present;
      }
    } else {
      currentState.sessionData[payload.egrowe_coachlog_id]?.shadowers.push(
        coachingSessionShadowerDTOFromAPIResponse(payload)
      );
    }

    ctx.patchState({
      lastUpdated: new Date().getTime(),
      sessionData: currentState.sessionData,
    });
  }

  @Action(DeleteShadower)
  deleteShadower(
    ctx: StateContext<CoachingLogModel>,
    { payload }: DeleteShadower
  ) {
    const currentState = ctx.getState();

    if (currentState.sessionData[payload.egrowe_coachlog_id]?.shadowers) {
      currentState.sessionData[payload.egrowe_coachlog_id].shadowers =
        currentState.sessionData[payload.egrowe_coachlog_id]?.shadowers.filter(
          (shadower) => shadower.id !== payload.id
        );
    }

    ctx.patchState({
      sessionData: currentState.sessionData,
    });
  }

  @Action(ResetLogDefaults)
  resetLogDefaults(ctx: StateContext<CoachingLogModel>) {
    ctx.patchState(logDefaults);
  }

  @Action(AddEvidence)
  addEvidence(ctx: StateContext<CoachingLogModel>, { payload }: AddEvidence) {
    const currentState = ctx.getState();
    currentState.evidence.push(payload);
    ctx.patchState({
      lastUpdated: new Date().getTime(),
      evidence: currentState.evidence,
    });
  }

  @Action(UpdateEvidence)
  updateEvidence(
    ctx: StateContext<CoachingLogModel>,
    { payload }: UpdateEvidence
  ) {
    const currentState = ctx.getState();
    const index = currentState.evidence.findIndex(
      (haystackItem) => haystackItem.id === payload.id
    );
    currentState.evidence[index] = payload;
    ctx.patchState({
      lastUpdated: new Date().getTime(),
      evidence: currentState.evidence,
    });
  }

  @Action(DeleteEvidence)
  deleteEvidence(
    ctx: StateContext<CoachingLogModel>,
    { payload }: DeleteEvidence
  ) {
    const currentState = ctx.getState();
    currentState.evidence = currentState.evidence.filter(
      (evidence) => evidence.id !== payload.id
    );

    ctx.patchState({
      lastUpdated: new Date().getTime(),
      evidence: currentState.evidence,
    });
  }

  @Action(UpdateAssesment)
  updateAssessment(
    ctx: StateContext<CoachingLogModel>,
    { payload }: UpdateAssesment
  ) {
    const currentState = ctx.getState();
    const index = currentState.evidence.findIndex(
      (haystackItem) => haystackItem.id === payload.egrowe_evidence_id
    );
    currentState.evidence[index].assessments.push(
      evidenceAssessmentDTOFromPusherEvidenceAssessmentResponse(payload)
    );

    ctx.patchState({
      lastUpdated: new Date().getTime(),
      evidence: currentState.evidence,
    });
  }

  @Action(DeleteLogAttachments)
  deleteLogAttachments(
    ctx: StateContext<CoachingLogModel>,
    { payload }: DeleteLogAttachments
  ) {
    const currentState = ctx.getState();

    if (currentState.sessionData[payload.coachlogId]) {
      currentState.sessionData[payload.coachlogId].attachments =
        currentState.sessionData[payload.coachlogId].attachments?.filter(
          (attachment) => attachment.id !== payload.message.id
        );
      ctx.patchState({
        sessionData: currentState.sessionData,
      });
    }
  }

  @Action(AddLogAttachments)
  addLogAttachments(
    ctx: StateContext<CoachingLogModel>,
    { payload }: AddLogAttachments
  ) {
    const currentState = ctx.getState();

    if (currentState.sessionData[payload.relationalId]) {
      currentState.sessionData[payload.relationalId].attachments?.push(payload);
      ctx.patchState({
        sessionData: currentState.sessionData,
      });
    }
  }
}
