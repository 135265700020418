import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { VideoCategoryDTO, VideoDTO } from 'src/app/common/dtos/video.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';

import { VideoLibraryFiltersDTO } from '../../dtos/video-library.dto';
import {
  videoCategoryDTOFromAPIResponse,
  videoDTOFromAPIResponse,
  videoModelsFromAPIResponse,
} from '../../helpers/translators/video.translators';
import {
  VideoAPIResponse,
  VideoCategoryResponse,
} from '../../types/responses/video.responses';

@Injectable({
  providedIn: 'root',
})
export class VideoLibraryService {
  // search filters
  private searchFiltersData: VideoLibraryFiltersDTO | null = null;

  private searchFiltersSource = new BehaviorSubject(this.searchFiltersData);

  public searchFiltersObs = this.searchFiltersSource.asObservable();

  // search results
  private searchResultsData: VideoDTO[] | null = null;

  private searchResultsSource = new BehaviorSubject(this.searchResultsData);

  public searchResultsObs = this.searchResultsSource.asObservable();

  // search results meta
  private searchResultsMetaData: PaginationDTO | null = null;

  private searchResultsMetaSource = new BehaviorSubject(
    this.searchResultsMetaData
  );

  public searchResultsMetaObs = this.searchResultsMetaSource.asObservable();

  constructor(private apiService: APICoreService) {}

  setFilters(filters: VideoLibraryFiltersDTO) {
    this.searchFiltersSource.next(filters);
  }

  fetchVideos(): void {
    let filterFreeze: VideoLibraryFiltersDTO = {};

    if (this.searchFiltersSource && this.searchFiltersSource.value) {
      filterFreeze = JSON.parse(JSON.stringify(this.searchFiltersSource.value));
    }

    this.searchResultsSource.next(null);

    this.apiService
      .getRequest('video-library', filterFreeze)
      .pipe(
        map((res) => {
          let videoList: VideoDTO[] = [];
          if (res.items) {
            videoList = videoModelsFromAPIResponse(
              res as { items: VideoAPIResponse[] }
            );
          } else {
            videoList = [];
          }
          // we populate observables, as well as return the videoList.
          this.searchResultsSource.next(videoList);
          this.searchResultsMetaSource.next(res._meta);
          return videoList;
        })
      )
      .subscribe();
  }

  getVideo(videoId: number) {
    return this.apiService
      .getRequest(`video-library/${videoId}`)
      .pipe(map((res) => videoDTOFromAPIResponse(res)));
  }

  getCategories() {
    return this.apiService.getRequest('video-categories').pipe(
      map((res) => {
        const categoriesList: VideoCategoryDTO[] = [];
        if (res.items) {
          res.items.forEach((category: VideoCategoryResponse) => {
            categoriesList.push(videoCategoryDTOFromAPIResponse(category));
          });
        }
        return categoriesList;
      })
    );
  }

  clearFilters() {
    this.setFilters({});
  }
}
