<div class="container">
  <div class="d-flex justify-content-between mb-3">
    <h1>Coaching Sessions</h1>
    <div class="align-self-center"></div>
  </div>
  <div class="card border-0 p-3 min-vh-100">
    <ng-container *ngIf="sessionData; else loadingTemplate">
      <h2 class="text-center mt-3">{{ sessionData.title }}</h2>
      <div
        class="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <ng-template
          [ngIf]="
            coachingService.logPusherService.attendeeRosters[sessionId].length >
            0
          ">
          <div class="d-flex flex-row align-items-center">
            <div class="me-3 text-muted">Attendees</div>
            <app-avatar
              style="margin-left: -5px"
              avatarSize="small"
              *ngFor="
                let attendee of coachingService.logPusherService
                  .attendeeRosters[sessionId]
              "
              [avatarData]="attendee"
              data-testid="attendee-avatar"></app-avatar>
          </div>
        </ng-template>
        <div class="col text-end">
          <button
            (click)="navigateNextScreen()"
            class="btn btn-primary btn-next"
            [class.disabled]="!hasPresentAttendee"
            ngbTooltip="At least 1 coachee must be marked present to begin."
            [disableTooltip]="hasPresentAttendee"
            style="pointer-events: auto">
            Let's Begin <i class="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
      <div class="container mt-3">
        <form>
          <div class="row">
            <div class="col-lg-4 p-3">
              <h3>Session Coach</h3>
              <div class="d-flex flex-nowrap align-items-center my-3">
                <app-avatar
                  [profile]="sessionData.user.profile"
                  [avatarSize]="'medium'">
                </app-avatar>
                <div class="username ms-2">
                  {{ sessionData.user.profile.first_name }}
                  {{ sessionData.user.profile.last_name }}
                </div>
              </div>
              <h3 class="mt-4">Coachee(s)</h3>
              <ng-container
                *ngIf="sessionData.attendees.length > 0; else noCoachees">
                <app-coaching-attendee
                  *ngFor="let attendee of sessionData.attendees"
                  [attendee]="attendee"
                  [isCoach]="isCoach"
                  [absentReasonsList]="absentReasonsList"
                  (removedAttendee)="removeCoachee($event)" />
              </ng-container>
              <ng-template #noCoachees>
                <p class="text-muted fw-light">
                  No coachees have been added to this log.
                </p>
              </ng-template>
              <div *ngIf="isCoach" class="mt-4">
                <label class="form-label">Add Coachee</label>
                <app-user-select
                  [clearAfterSelection]="true"
                  (selectedUser)="addCoachee($event)" />
              </div>
            </div>
            <div class="col-lg-4 p-3">
              <h3>Session Info</h3>
              <ng-container *ngIf="isCoach">
                <label class="w-100 form-label" for="title">Title</label>
                <input
                  class="form-control"
                  type="text"
                  id="title"
                  aria-label="Title"
                  appAutosave
                  [(ngModel)]="sessionData.title"
                  [ngModelOptions]="{ standalone: true }"
                  (autosave)="saveTitle()"
                  class="form-control" />
              </ng-container>
              <label class="form-label">Type</label>
              <div class="mb-3">{{ sessionData.type.title }}</div>

              <ng-container *ngIf="isCoach">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    (change)="onCheckboxChange($event)"
                    [(ngModel)]="sessionData.isPrivate"
                    [ngModelOptions]="{ standalone: true }"
                    type="checkbox"
                    id="is-private" />
                  <label class="form-check-label" for="is-private">
                    Is Private
                    <div class="form-text">
                      Private logs can only be viewed by the coach, coachee(s),
                      and shadower of the log.
                    </div></label
                  >
                </div>
              </ng-container>
              <ng-container *ngIf="sessionData.currentUsersRole === 'coachee'">
                <div class="form-check">
                  <input
                    disabled
                    class="form-check-input"
                    (change)="onCheckboxChange($event)"
                    [(ngModel)]="sessionData.isPrivate"
                    [ngModelOptions]="{ standalone: true }"
                    type="checkbox"
                    id="is-private" />
                  <label class="form-check-label" for="is-private">
                    Is Private
                    <div class="form-text">
                      Private logs can only be viewed by the coach, coachee(s),
                      and shadower of the log.
                    </div></label
                  >
                </div>
              </ng-container>

              <ng-container
                *ngIf="
                  sessionData.currentUsersRole === 'shadower' &&
                  sessionData.isPrivate
                ">
                <div class="mt-1 mb-3">
                  <label class="w-100 form-label">Private Log</label>

                  <div class="form-text">
                    Private logs can only be viewed by the coach, coachee(s),
                    and shadower of the log.
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="isCoach; else coacheeDatetimeView">
                <label class="w-100 form-label">Date & Time</label>
                <app-datepicker
                  #datePicker
                  (dateSelectedUnixTimestamp)="updateDateTime($event)"
                  [initialDateUnixTimestamp]="sessionData.startDatetime"
                  [timezone]="sessionData.timezone"
                  [timepicker]="true"></app-datepicker>
                <label class="form-label" for="session-duration"
                  >Duration</label
                >
                <ng-select
                  id="session-duration"
                  [items]="durationList"
                  [ngModel]="logDuration"
                  (ngModelChange)="updateDuration($event)"
                  [ngModelOptions]="{ standalone: true }"
                  [clearable]="false"
                  [searchable]="false"
                  [closeOnSelect]="true"
                  [appendTo]="'body'"
                  [virtualScroll]="true"
                  [bindLabel]="'label'"
                  [bindValue]="'value'" />
              </ng-container>
              <ng-template #coacheeDatetimeView>
                <div class="form-label">Date & Time</div>
                {{ sessionData.startDatetime * 1000 | date : "MMM d, y" }},
                {{ sessionData.startDatetime * 1000 | date : "h:mm a" }}<br />
                {{ sessionData.timezone }}
              </ng-template>
            </div>
            <div class="col-lg-4 p-3">
              <h3>Shadower</h3>
              <p
                class="fw-light text-muted"
                *ngIf="sessionData.shadowers.length == 0">
                No shadower has been added to this log.
              </p>
              <ng-container *ngFor="let shadower of sessionData.shadowers">
                <div class="my-3">
                  <app-coaching-shadower
                    [shadower]="shadower"
                    [logId]="sessionData.id"
                    [isCoach]="isCoach" />
                </div>
              </ng-container>
              <div *ngIf="isCoach">
                <label class="form-label">Add Shadower</label>
                <app-user-select
                  [clearAfterSelection]="true"
                  (selectedUser)="addShadower($event)" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="row d-flex flex-column align-items-center mt-3">
        <ng-container *ngIf="selectedAttendee">
          <div class="row text-center header-row w-50">
            <h3 class="header-row-header">Last Session</h3>
            <ng-container *ngIf="isCoach || isShadower">
              <ng-select
                [labelForId]="'log-top-attendee-select'"
                [items]="sessionAttendees"
                bindLabel="id"
                [placeholder]="'Select Coachee'"
                [searchable]="false"
                [clearable]="false"
                [(ngModel)]="selectedAttendee"
                (ngModelChange)="onItemChange($event)"
                #selectCoachee
                ><ng-template ng-label-tmp let-item="item"
                  ><span class="bold-text">Coachee: </span>
                  &nbsp;<app-users-name [userProfile]="item.profile" />
                </ng-template>
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="term">
                  <app-users-name [userProfile]="item.profile" />
                </ng-template>
              </ng-select>
            </ng-container>
          </div>
          <ng-container
            *ngIf="
              lastSessionCompetencies[0]?.competencies &&
                lastSessionCompetencies[0].competencies.length > 0;
              else noCompetenciesFound
            ">
            <div class="row row-cols-lg-3 d-flex align-items-stretch">
              <ng-container
                *ngFor="let userCompetencyList of lastSessionCompetencies">
                <div *ngFor="let competency of userCompetencyList.competencies">
                  <div class="col-lg mt-3">
                    <div
                      class="card cursor-pointer"
                      (click)="
                        toggleCardModal(
                          userCompetencyList.userId,
                          competency.id
                        )
                      ">
                      <app-competencies-summary
                        [isSelectable]="false"
                        [userId]="userCompetencyList.userId"
                        [competencyId]="
                          competency.id
                        "></app-competencies-summary>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <app-leveled-competency-modal #competencyModal />
        <ng-template #noCompetenciesFound>
          <div class="row my-5 d-flex">
            <p
              class="text-center fw-300 fs-4"
              *ngIf="
                this.lastSessionCompetencies[0]?.competencies &&
                  this.lastSessionCompetencies[0].competencies.length === 0;
                else noPreviousSession
              ">
              No Competencies from Previous Session
            </p>
            <ng-template #noPreviousSession>
              <p class="text-center fw-300 fs-4">No Previous Session Found</p>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #loadingTemplate>
      <app-loader />
    </ng-template>
  </div>
</div>
