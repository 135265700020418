<div #target></div>
<div ngbDropdown>
  <a href="javascript:void(0)" id="share-dropdown" ngbDropdownToggle>
    <i class="bi bi-three-dots-vertical fs-5"></i>
  </a>
  <ul
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-end"
    aria-labelledby="share-dropdown">
    <li
      ngbDropdownItem
      ngbTooltip="Link copied to clipboard!"
      container="body"
      [positionTarget]="target"
      #t="ngbTooltip"
      triggers="manual"
      (click)="shareVideo(); t.open()">
      <i class="bi bi-share me-2"></i>Share Video
    </li>
    <li
      *ngFor="let type of types"
      ngbDropdownItem
      (click)="copyEmbedCode(type.type)">
      <i class="bi bi-link-45deg me-2"></i>{{ type.text }}
    </li>
  </ul>
</div>
