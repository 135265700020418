import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';

import { CommentModelDTO } from '../../components/evidence-comments/evidence-comments.dto';
import { EvidenceDTO, EvidenceType } from '../../dtos/attendee-rubric.dto';
import { CoacheeEvidenceDTO } from '../../dtos/coachee-evidence';
import { FileDTO } from '../../dtos/file.dto';
import { VideoReferenceObjectType } from '../../enums/video-reference-object-type.enum';
import {
  coacheeEvidenceListDTOFromAPIResponse,
  createEvidencePayloadFromDTO,
  evidenceDTOFromAPIResponse,
  evidencePayloadFromDTO,
} from '../../helpers/translators/evidence.translators';
import { CoacheeEvidenceAPIResponse } from '../../types/responses/coachee-evidence';
import {
  AttachVideoPayload,
  VideoFileService,
} from '../file/video.file.service';

@Injectable({ providedIn: 'root' })
export class EvidenceService {
  constructor(
    private apiService: APICoreService,
    private videoFileService: VideoFileService
  ) {}

  addEvidence(evidence: EvidenceDTO, attachments: FileDTO[]) {
    this.apiService
      .postRequest('egrowe/evidences/many', {
        ...createEvidencePayloadFromDTO(evidence, attachments),
        attachment_file_ids: attachments.map((attachment) => attachment.id),
      })
      .subscribe((data) => {
        const newEvidence = evidenceDTOFromAPIResponse(data.items[0]);
        if (newEvidence.type === EvidenceType.VIDEO) {
          this.addVideo(
            {
              objectId: newEvidence.id,
              objectType: VideoReferenceObjectType.EVIDENCE,
              videoId: attachments[0].id,
            },
            newEvidence
          );
        }
      });
  }

  updateEvidence(evidence: EvidenceDTO) {
    this.apiService
      .putRequest(
        `egrowe/evidences/${evidence.id}/v2`,
        evidencePayloadFromDTO(evidence)
      )
      .subscribe();
  }

  getCoacheeEvidenceList(): Observable<CoacheeEvidenceDTO[]> {
    return this.apiService
      .getRequest('egrowe/dashboard/evidence-to-review?your_coachees=1')
      .pipe(
        map((res) =>
          coacheeEvidenceListDTOFromAPIResponse(
            res as { items: CoacheeEvidenceAPIResponse[] }
          )
        )
      );
  }

  deleteEvidence(evidenceId: number) {
    return this.apiService.deleteRequest(`egrowe/evidences/${evidenceId}`);
  }

  getEvidenceComments(evidenceId: number) {
    return this.apiService.getRequest('comments', {
      ref_table: 'egrowe_evidence',
      ref_table_id: evidenceId,
    });
  }

  saveComment(newComment: CommentModelDTO) {
    return this.apiService.postRequest('comments', newComment);
  }

  deleteComment(comment: CommentModelDTO) {
    return this.apiService.deleteRequest(`comments/${comment.id}`);
  }

  getEvidenceSummary(evidenceId: number) {
    return this.apiService.getRequest(`growelab/evidence/${evidenceId}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  approveEvidence(evidenceToApprove: any) {
    return this.apiService.postFormRequest(
      `egrowe/evidences/${evidenceToApprove.id}/assessments`,
      evidenceToApprove
    );
  }

  addVideo(payload: AttachVideoPayload, evidence: EvidenceDTO): void {
    this.videoFileService.attachVideo(payload).subscribe(() => {
      // Unattaching and attaching currently do not trigger a push notification, so we fake an update
      this.updateEvidence(evidence);
    });
  }

  removeVideo(evidence: EvidenceDTO): void {
    if (evidence.videos)
      this.videoFileService
        .unattachVideo(evidence.videos[0].id)
        .subscribe(() => {
          // Unattaching and attaching currently do not trigger a push notification, so we fake an update
          this.updateEvidence(evidence);
        });
  }
}
