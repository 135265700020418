import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { DistrictListService } from '../../shared/services/district-list/district-list-service';
import {
  SortableHeader,
  SortEvent,
} from '../../shared/directives/sortable-header.directive';

function compare(v1: string | number, v2: string | number) {
  if (typeof v1 === 'string' && typeof v2 === 'string') {
    v1 = v1.toUpperCase();
    v2 = v2.toUpperCase();
  }
  // eslint-disable-next-line no-nested-ternary
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

const columnDataMap = (
  district: DistrictSimpleDto,
  column: string
): string | number => {
  switch (column) {
    case 'id':
      return district.id || 0;
    case 'districtCode':
      return district.districtCode || '';
    case 'city':
      return district.city || '';
    case 'state':
      return district.state || '';
    case 'zip':
      return district.zip || '';
    case 'timezone':
      return district.timezone || '';
    case 'domain':
      return district.domain || '';
    case 'hubspotCompanyId':
      return district.hubspotCompanyId || '';
    case 'isB2b':
      return district.isB2b || 0;
    case 'isInternal':
      return district.isInternal || 0;
    case 'title':
    default:
      return district.title || '';
  }
};

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  tableData: DistrictSimpleDto[];

  districts: DistrictSimpleDto[];

  isLoading = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortIconTemp: any = {
    id: 'gray',
    title: 'gray',
    districtCode: 'gray',
    city: 'gray',
    state: 'gray',
    zip: 'gray',
    timezone: 'gray',
    domain: 'gray',
    hubspotCompanyId: 'gray',
    isB2b: 'gray',
    isInternal: 'gray',
  };

  constructor(private districtListService: DistrictListService) {}

  async ngOnInit(): Promise<void> {
    this.getDistricts();
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
        this.sortIconTemp[header.sortable] = 'gray';
      } else {
        this.sortIconTemp[header.sortable] = 'none';
      }
    });

    if (direction === '' || column === '') {
      this.tableData = this.districts;
      this.sortIconTemp[column] = 'gray';
    } else {
      this.tableData = [...this.tableData].sort((a, b) => {
        const res = compare(
          columnDataMap(a, column as string),
          columnDataMap(b, column as string)
        );
        return direction === 'asc' ? -res : res;
      });
    }
  }

  getDistricts(): void {
    this.isLoading = true;
    this.districtListService
      .fetchDistrictsSimple({ per_page: 10000 })
      .subscribe(
        (districts) => {
          if (districts) {
            this.districts = districts as DistrictSimpleDto[];
            this.tableData = this.districts;
            this.isLoading = false;
          }
        },
        () => {
          this.isLoading = false;
        }
      );
  }
}
