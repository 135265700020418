<div class="card border-0">
  <ng-container *ngIf="assignedUsersEvidence; else placeHolder">
    <div class="card-header pt-3">
      <h2
        id="evidence-title"
        class="card-title float-start"
        (click)="triggerCollapse()">
        Evidence for Review
        <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
      </h2>
      <div class="card-tools float-end">
        <a
          routerLink="/dashboard/coachee-evidence"
          ngbTooltip="See all evidence"
          class="text-primary">
          See All
          <i class="ps-1 bi bi-box-arrow-up-right"></i>
        </a>
      </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div
        class="card-body pt-0 overflow-auto"
        tabindex="0"
        role="group"
        style="height: 18rem">
        <ng-container *ngIf="assignedUsersEvidence.length > 0; else noEvidence">
          <ul
            class="evidence-review-container ps-0"
            style="list-style-type: none">
            <ng-container *ngFor="let item of assignedUsersEvidence">
              <ng-container *ngIf="item.evidence.createdAt">
                <li>
                  <app-card-evidence-for-review-item
                    [dateAdded]="item.evidence.createdAt"
                    [profile]="item.profile"
                    [evidence]="item.evidence"
                    (evidenceAssesed)="
                      removeEvidence($event)
                    "></app-card-evidence-for-review-item>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </ng-container>
      </div>
    </div>
    <ng-template #noEvidence>
      <div
        class="text-muted d-flex h-100 w-100 justify-content-center align-items-center">
        No evidence for review
      </div>
    </ng-template>
  </ng-container>

  <ng-template #placeHolder>
    <div class="card-header pt-3">
      <div class="card-title placeholder-glow">
        <span class="placeholder col-5"></span>
      </div>
    </div>
    <div class="card-body pt-0" style="height: 18rem">
      <div class="card-text placeholder-glow">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
      <div class="card-text placeholder-glow mt-2">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
    </div>
  </ng-template>
</div>
