import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToDoDTO } from '../card-todos/todo.dto';

@Component({
  selector: 'app-todo-item',
  templateUrl: './todo-item.component.html',
  styleUrls: ['./todo-item.component.scss'],
})
export class TodoItemComponent {
  @Input() todo: ToDoDTO;

  @Output() checkboxClicked: EventEmitter<unknown> = new EventEmitter();

  @Output() editClicked: EventEmitter<unknown> = new EventEmitter();

  handleCheck() {
    this.checkboxClicked.emit();
  }

  handleEdit() {
    this.editClicked.emit();
  }
}
