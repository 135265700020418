import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/common/state/user/user.model';
import { map } from 'rxjs';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { UserState } from 'src/app/common/state/user/user.state';
import { BooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import { AssessmentDTO, IndicatorDTO } from '../../dtos/attendee-rubric.dto';
import { IndicatorAssessmentPayload } from '../../services/coaching-log/coaching-log-service.dto';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { CoachingLogState } from '../../state/coaching-log/coaching-log.state';

@Component({
  selector: 'app-growth-indicator',
  templateUrl: './growth-indicator.component.html',
  styleUrls: ['./growth-indicator.component.scss'],
})
export class GrowthIndicatorComponent implements OnInit {
  @Input() indicatorId: number;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() logId: number;

  indicator: IndicatorDTO;

  isChecked = false;

  isLoading = false;

  user$ = select(UserState.getUser);

  indicator$ = select(CoachingLogState.getIndicator);

  user: User | null = null;

  assessment: AssessmentDTO | null;

  constructor(private coachingService: CoachingLogService) {
    this.user$
      .pipe(
        map((user) => {
          this.user = user;
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.indicator$
      .pipe(map((filterFn) => filterFn(this.userId, this.indicatorId)))
      .subscribe((data) => {
        this.indicator = data;
        this.checkForAssessment();
      });
  }

  checkForAssessment() {
    this.isChecked = false;
    this.assessment = null;

    if (
      this.indicator &&
      this.indicator.assessments &&
      this.indicator.assessments.length > 0
    ) {
      this.indicator.assessments.forEach((assessment) => {
        if (assessment.assessmentType === 'coach:standard') {
          this.assessment = assessment;
          this.isChecked = true;
        }
      });
    }
  }

  toggleGI() {
    // only coach can do this, and not for themselves
    if (this.user?.isCoach && this.user.id !== this.userId) {
      this.isLoading = true;
      if (this.isChecked) {
        if (this.assessment && this.assessment.id) {
          // uncheck
          this.coachingService
            .deleteAssessment(this.assessment.id, this.logId)
            .subscribe(() => {
              this.isLoading = false;
            });
        } else {
          // nothing to delete
          this.isLoading = false;
        }
      } else {
        // check
        const payload: IndicatorAssessmentPayload = {
          egrowe_indicator_id: this.indicator.id,
          assessment_type: 'coach:standard',
          egrowe_rubric_id: this.rubricId,
          assessee_user_id: this.userId,
          egrowe_coachlog_id: this.logId,
          from_egrowe_portfolio: BooleanNumber.FALSE,
        };

        this.coachingService.assessIndicator(payload).subscribe(() => {
          this.isLoading = false;
        });
      }
    }
  }
}
