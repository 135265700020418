import { Component, Input, OnInit } from '@angular/core';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { ReportRequestParams } from 'src/app/common/dtos/reports.dto';
import { User } from 'src/app/common/state/user/user.model';
import { ReportService } from '../../../services/report/report.service';
import { CURRENT_SCHOOL_YEAR } from '../../../enums/reports.enum';

@Component({
  selector: 'app-card-leader-glance',
  templateUrl: './card-leader-glance.component.html',
  styleUrls: ['./card-leader-glance.component.scss'],
})
export class CardLeaderGlanceComponent implements OnInit {
  @Input() user: User | null;

  @Input() schoolYearId: number; // default to current school year if not provided

  @Input() badgeParams: ReportRequestParams; // overwrites traditional kpi logic (backwards compatible)

  @Input() sessionParams: ReportRequestParams;

  @Input() obvservationParams: ReportRequestParams;

  @Input() coachedParams: ReportRequestParams;

  @Input() campusParams: ReportRequestParams;

  @Input() giParams: ReportRequestParams;

  isCollapsed: boolean;

  cardTitle = 'Year at a Glance';

  requestParams: ReportRequestParams;

  leaderGlanceDataList: GlanceData[] = [];

  categories: string[] = [
    'Campuses Coached',
    'Staff Coached',
    'Badges Earned',
    'Coaching Conversations',
    'Observations',
    'GIs Earned',
  ];

  constructor(
    private cardStorageService: CardStorageService,
    private reportService: ReportService
  ) {}

  ngOnInit() {
    this.isCollapsed = this.cardStorageService.getCollapsedState(
      this.cardTitle.replace(/\s/g, '').toLowerCase()
    );

    if (!this.schoolYearId) {
      this.schoolYearId = parseInt(CURRENT_SCHOOL_YEAR.toString(), 10);
    }

    this.categories.forEach((category) => {
      this.getLeaderGlanceData(category);
    });
  }

  getLeaderGlanceData(request: string) {
    if (this.user && this.user.district) {
      if (request === 'Badges Earned') {
        this.requestParams = this.badgeParams;
      } else if (request === 'Coaching Conversations') {
        this.requestParams = this.sessionParams;
      } else if (request === 'Observations') {
        this.requestParams = this.obvservationParams;
      } else if (request === 'Staff Coached') {
        this.requestParams = this.coachedParams;
      } else if (request === 'Campuses Coached') {
        this.requestParams = this.campusParams;
      } else if (request === 'GIs Earned') {
        this.requestParams = this.giParams;
      }

      this.reportService
        .getLeaderGlanceData(
          this.requestParams,
          this.user.district.id,
          this.schoolYearId
        )
        .subscribe((res) => {
          const glanceData = {} as GlanceData;
          glanceData.title = request;
          glanceData.count = res.datasets[0].data[0];
          this.leaderGlanceDataList.push(glanceData);
        });
    }
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      this.cardTitle.replace(/\s/g, '').toLowerCase(),
      this.isCollapsed
    );
  }
}

interface GlanceData {
  title: string;
  count: number;
}
