<div class="card border-0">
  <ng-container *ngIf="isLoaded; else placeHolder">
    <div class="card-header pt-3">
      <h2 class="card-title float-start" (click)="triggerCollapse()">
        Current Competencies
        <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
      </h2>
      <div class="card-tools float-end"></div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div
        class="card-body overflow-auto"
        [class.empty]="noData"
        tabindex="0"
        role="group"
        aria-labelledby="ideas-title">
        <ng-container
          *ngIf="competenciesList && competenciesList.length > 0; else noData">
          <ng-container *ngFor="let competency of competenciesList">
            <app-current-competencies-list-item [competency]="competency" />
          </ng-container>
        </ng-container>
        <ng-template #noData>
          <div class="noData fw-200 px-3 pb-3">
            <div class="row">
              <div class="col-xl-6 d-flex align-items-center">
                <div class="px-3">
                  Come back here to see your progress on your chosen
                  competencies after your first coaching session. You’ll be
                  amazed by how much you can learn and improve!
                </div>
              </div>
              <div class="col-xl-6">
                <img
                  src="/assets/current_competency_default.png"
                  class="img-fluid"
                  alt="GroweLab Logo" />
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-template #placeHolder>
    <div class="placeholder-wrapper p-3">
      <div class="card-header pt-3">
        <div class="card-title placeholder-glow">
          <span class="placeholder col-5"></span>
        </div>
      </div>
      <div class="card-body pt-0" style="height: 18rem">
        <div class="card-text placeholder-glow">
          <span class="placeholder col-7"></span>
          <span class="placeholder col-4"></span>
          <span class="placeholder col-4"></span>
          <span class="placeholder col-6"></span>
          <span class="placeholder col-8"></span>
          <span class="placeholder col-4"></span>
        </div>
        <div class="card-text placeholder-glow mt-2">
          <span class="placeholder col-7"></span>
          <span class="placeholder col-4"></span>
          <span class="placeholder col-4"></span>
          <span class="placeholder col-6"></span>
          <span class="placeholder col-8"></span>
          <span class="placeholder col-4"></span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
