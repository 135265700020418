import { CompetencyDTO } from 'src/app/common/dtos/competency.dto';
import { User } from 'src/app/common/state/user/user.model';

export interface FormCreateDTO {
  title: string;
  districtId: number;
  description: string;
  fields: Field[];
  status: FormStatus;
  type: FormType;
  isTemplate: boolean;
  districtsSharedWith?: number[];
  districtsSharedExclusion?: number[];
  deletedFields?: Field[];
  id?: number;
}

export interface Form extends FormCreateDTO {
  id: number;
  createdAt: number;
  modifiedAt: number;
  author: User;
  hasSubmission?: boolean;
}

export interface FormDuplicatePayload {
  formId: number;
  districtId: number;
}

export enum FormStatus {
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
  ARCHIVED = 'Archived',
}

export enum FormType {
  VISIT = 'classroom-visit',
  CALIBRATION = 'coaching-calibration',
  OTHER = 'other',
}

export enum FormSubmissionStatus {
  NEW = 'new',
  SUBMITTED = 'submitted',
}

export interface FormLearnerBehaviorCreateDTO {
  rubricId: number;
  indicatorId: number;
  assesseeUserId: number;
  coachlogId: number;
}

export const FormTypesMap: { label: string; key: FormType }[] = [
  {
    label: 'Classroom Visit',
    key: FormType.VISIT,
  },
  {
    label: 'Coaching Calibration',
    key: FormType.CALIBRATION,
  },
  {
    label: 'Other',
    key: FormType.OTHER,
  },
];

export enum FieldType {
  TEXT = 'text',
  TEXTBLOCK = 'textarea',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  COMPETENCY = 'competency',
  SECTION = 'section',
  FREETEXT = 'freetext',
  MEDIA = 'media',
}

export const getFieldTypeKey = (field: FieldType): string =>
  Object.keys(FieldType)[Object.values(FieldType).indexOf(field)];

export const MultipleChoiceFieldMap: { label: string; value: FieldType }[] = [
  {
    label: '⦿ Single Choice',
    value: FieldType.RADIO,
  },
  {
    label: '✓ Multiple Select',
    value: FieldType.CHECKBOX,
  },
  {
    label: '▼ Dropdown',
    value: FieldType.SELECT,
  },
];

export const TextFieldTypeMap: { label: string; value: FieldType }[] = [
  {
    label: 'Single line',
    value: FieldType.TEXT,
  },
  {
    label: 'Multiple lines',
    value: FieldType.TEXTBLOCK,
  },
];

export interface FieldTypeConfig {
  name: string;
  type: FieldType;
  icon: string;
}

export interface Field {
  id: string;
  name: string;
  type: FieldType;
  helperText?: string;
  label: string;
  choices?: FieldChoice[];
  competency?: CompetencyDTO;
  indicatorGroupId?: number;
}

export interface FieldWithChoices extends Field {
  choices: FieldChoice[];
}

export interface FieldChoice {
  label: string;
  value: string;
}

export interface ObservationFormSubmissionRawData {
  formId: number;
  coachingLogId?: number;
  submissionData?: FieldData[];
  submitterUser: User;
  observedUserId?: number;
}

export interface ObservationFormSubmission {
  id: number;
  formId: number;
  coachingLogId: number;
  submissionData: FieldData[];
  submitterDistrictId: number;
  submitterUserId: number;
  observedUserId: number;
  createdAt: number;
  modifiedAt: number;
}

export interface FieldData {
  fieldId: string;
  value: string;
}

export const FieldTypes: FieldTypeConfig[] = [
  {
    type: FieldType.SECTION,
    name: 'Section Header',
    icon: 'file-text-fill',
  },
  {
    type: FieldType.FREETEXT,
    name: 'Text description',
    icon: 'body-text',
  },
  {
    type: FieldType.TEXT,
    name: 'Text field',
    icon: 'input-cursor-text',
  },
  {
    type: FieldType.TEXTBLOCK,
    name: 'Text block',
    icon: 'textarea-resize',
  },
  {
    type: FieldType.RADIO,
    name: 'Single choice',
    icon: 'ui-radios',
  },
  {
    type: FieldType.CHECKBOX,
    name: 'Multiple select',
    icon: 'ui-checks',
  },
  {
    type: FieldType.SELECT,
    name: 'Dropdown',
    icon: 'menu-button-fill',
  },
  {
    type: FieldType.COMPETENCY,
    name: 'Leveled look-fors',
    icon: 'star-fill',
  },
  {
    type: FieldType.MEDIA,
    name: 'Attachment',
    icon: 'paperclip',
  },
];

export interface FormIndicatorDTO {
  id: number;
  title: string;
  level: number;
}

export interface FormIndicatorGroupDTO {
  id: number;

  sampleQuestions?: string;
  title: string;
  indicators: FormIndicatorDTO[];
}

export interface FormIndicatorSetDTO {
  id: number;
  groups: FormIndicatorGroupDTO[];
}

export interface FormCompetencyDTO {
  competencyId: number;
  rubricId: number;
  indicatorSet: FormIndicatorSetDTO;
}
