import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Field, FieldType } from '../../../../dtos/forms.dto';
import { FormEditFieldLabelComponent } from './label/form-edit-field-label.component';

@Component({
  template: '',
})
export abstract class FormFieldAbstractComponent implements AfterViewInit {
  @ViewChild('label') label: FormEditFieldLabelComponent;

  id = uuidv4();

  @Input() data: Field;

  @Output() dataChange = new EventEmitter<Field>();

  fieldTypes = FieldType;

  ngAfterViewInit() {
    this.label.dataChange.subscribe((data) => {
      this.data.label = data;
      this.dataChange.emit(this.data);
    });
  }
}
