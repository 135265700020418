import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { LogTypeDTO } from '../../../../common/dtos/log-type.dto';

@Injectable({ providedIn: 'root' })
export class LogTypeSearchService {
  logTypes: {
    [id: number]: LogTypeDTO[];
  } = {};

  constructor(private apiService: APICoreService) {}

  getLogTypes(districtId: number): Observable<LogTypeDTO[]> {
    if (!this.logTypes[districtId]) {
      return this.apiService
        .getRequest(
          `egrowe/coachlogs/types?per_page=100&district_id=${districtId}`
        )
        .pipe(
          map((response) => {
            this.logTypes[districtId] = response.items;
            return response.items;
          })
        );
    }
    return of(this.logTypes[districtId]);
  }
}
