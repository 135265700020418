import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import {
  CohortDTO,
  CreateCohortPayloadDTO,
  CohortListDTO,
} from 'src/app/common/dtos/cohort.dto';
import {
  cohortDTOFromApiResponse,
  cohortListDTOFromApiResponse,
} from '../../helpers/translators/cohort.translators';
import {
  CohortAPIResponse,
  CohortListAPIResponse,
} from '../../types/responses/cohort.responses';
import { APICoreService } from '../../../../common/services/api-core/api-core.service';

@Injectable({ providedIn: 'root' })
export class CohortService {
  constructor(private apiService: APICoreService) {}

  fetchCohorts(page: number, districtId: number): Observable<CohortListDTO> {
    const pageNumber = page || 1;
    return this.apiService
      .getRequest(
        `cohort/search?page=${pageNumber}&pageSize=12&districtId=${districtId}`
      )
      .pipe(
        map((res) => cohortListDTOFromApiResponse(res as CohortListAPIResponse))
      );
  }

  fetchCohortsSearch(
    districtId: number,
    searchTerm: string
  ): Observable<CohortListDTO> {
    return this.apiService
      .getRequest(
        `cohort/search?search=${searchTerm}&pageSize=12&districtId=${districtId}`
      )
      .pipe(
        map((res) => cohortListDTOFromApiResponse(res as CohortListAPIResponse))
      );
  }

  createCohort(cohort: CreateCohortPayloadDTO): Observable<CohortDTO> {
    return this.apiService.postRequest('cohort', cohort).pipe(
      map((res) => cohortDTOFromApiResponse(res.item as CohortAPIResponse)),
      catchError((err) => {
        throw err;
      })
    );
  }

  addUsersToCohort(tag: string, usersIds: number[]) {
    const params = {
      tag,
      usersIds,
    };
    return this.apiService.putRequest('cohort/add-users', params).pipe(
      map((res) => res),
      catchError((err) => {
        throw err;
      })
    );
  }

  removeUsersFromCohort(tag: string, usersIds: number[]) {
    const params = {
      tag,
      usersIds,
    };
    return this.apiService.putRequest('cohort/remove-users', params).pipe(
      map((res) => res),
      catchError((err) => {
        throw err;
      })
    );
  }
}
