import * as Sentry from '@sentry/angular-ivy';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService } from '../environment/environment.service';

/* eslint-disable @typescript-eslint/no-explicit-any, no-console */

@Injectable()
export class ErrorHandlerService {
  sentry: Sentry.SentryErrorHandler;

  constructor(private router: Router) {
    this.sentry = Sentry.createErrorHandler({
      showDialog: !EnvironmentService.isProduction(),
      logErrors: EnvironmentService.isProduction(),
    });
  }

  handleError(error: any) {
    if (!EnvironmentService.isProduction()) {
      console.error(error);
    } else {
      this.sentry.handleError(error);
    }
  }
}
