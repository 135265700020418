<app-modal #internalModal [modalConfig]="modalConfig">
  <button (click)="this.internalModal.dismiss()" class="btn tertiary fs-19">
    X
  </button>

  <div *ngIf="competencyDictionary.length > 0" class="competencies">
    <ng-container *ngFor="let dictionaryItem of competencyDictionary">
      <app-leveled-competency
        [userId]="dictionaryItem.userId"
        [competency]="dictionaryItem.competency"
        [rubricId]="dictionaryItem.rubricId"
        [logId]="coachlogId"></app-leveled-competency>
    </ng-container>
  </div>
  <app-loader *ngIf="competencyDictionary.length === 0"></app-loader>
</app-modal>
