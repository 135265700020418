import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getIcon } from '../../../helpers/icon.utilities';
import { CompetencyListItemDTO } from '../../../dtos/attendee-rubric.dto';

@Component({
  selector: 'app-select-competency',
  templateUrl: './select-competency.component.html',
  styleUrls: ['./select-competency.component.scss'],
})
export class SelectCompetencyComponent implements OnInit {
  @Input() selected: boolean;

  currentIcon: string;

  @Input() competency: CompetencyListItemDTO;

  @Output() selectedEvent = new EventEmitter();

  updateCompetency() {
    this.selectedEvent.emit([
      {
        egrowe_standard_id: this.competency.id,
        egrowe_rubric_id: this.competency.rubricId,
      },
      this.selected,
    ]);
    this.selected = !this.selected;
  }

  ngOnInit(): void {
    const icon: string = this.competency?.icon || '';
    this.currentIcon = getIcon(icon);
  }
}
