import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PresenceChannel } from 'pusher-js';
import { BehaviorSubject, Subscription, map } from 'rxjs';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import { Store } from '@ngxs/store';
import { ReportRequestParams } from 'src/app/common/dtos/reports.dto';
import { PusherService } from '../../shared/services/pusher/pusher.service';
import { avatarDTOFromPusher } from '../../shared/components/avatar/avatar.translators';
import { AvatarDTO } from '../../shared/components/avatar/avatar.dto';
import { ConfettiService } from '../../shared/services/confetti/confetti.service';
import { ResourcePreviewDTO } from '../../shared/components/resources/resource-preview/resource-preview.dto';
import { MOCK_RESOURCE_LIST } from '../../shared/components/resources/resource-preview/resource-preview.mocks';
import { FileDTO } from '../../shared/dtos/file.dto';
import { MOCK_FILE_LIST } from '../../shared/components/file-management/file-list/file-list.mocks';
import { LeveledCompetencyModalComponent } from '../../shared/components/competency/leved-competency-modal/leveled-competency-modal.component';
import { CoachingLogState } from '../../shared/state/coaching-log/coaching-log.state';
import { FetchAttendeeRubrics } from '../../shared/state/coaching-log/coaching-log.actions';
import { coachingSessionsData } from '../../shared/components/reports/kpi-controller/kpi-card.mock';
import { BadgeEarnedDTO } from '../../shared/dtos/badge.dto';
import {
  MOCK_STRAND_BADGE_EARNED_DTO,
  MOCK_COMPETENCY_BADGE_EARNED_DTO,
} from '../../../common/utilities/mocks/badge.mocks';
import { ChartType } from '../../shared/enums/chart-type.enum';

@Component({
  selector: 'app-style-library-page',
  templateUrl: './style-library.component.html',
  styleUrls: ['./style-library.component.scss'],
})
export class StyleLibraryComponent implements OnInit, OnDestroy {
  coachingSessionsData = coachingSessionsData;

  files: FileDTO[] = [];

  subs: Subscription[] = [];

  attendees: AvatarDTO[];

  particlesVisible: boolean;

  channel: PresenceChannel;

  chartTypes = ChartType;

  competencyModalLogId = 0;

  competencyModalCompetencyIds: number[] = [];

  competencyModalCompetencyId = 0;

  competencyModalUserIds: number[] = [];

  competencyModalUserId = 0;

  competencyModalLogAttendeeRubrics$ = this.store.select(
    CoachingLogState.getAttendeeRubricsData
  );

  resources: ResourcePreviewDTO[] = MOCK_RESOURCE_LIST;

  mockFiles = MOCK_FILE_LIST;

  confettiLocation = 'top';

  selectedDate = new BehaviorSubject<number>(1620000000);

  selectedDate$ = this.selectedDate.asObservable();

  selectedTimezone = new BehaviorSubject<TimezoneLinkBack>(
    TimezoneLinkBack.Central
  );

  selectedTimezone$ = this.selectedTimezone.asObservable();

  @ViewChild('competencyModal')
  competencyModal: LeveledCompetencyModalComponent;

  @Output() fileUploads = new EventEmitter<FileDTO>();

  strandBadge: BadgeEarnedDTO = MOCK_STRAND_BADGE_EARNED_DTO;

  competencyBadge: BadgeEarnedDTO = MOCK_COMPETENCY_BADGE_EARNED_DTO;

  aprilSessionParams: ReportRequestParams = {
    dataset: 'fct_user_badge',
    measure: {
      type: 'count',
      field: '',
    },
    filters: [
      {
        codename: 'school_year',
        title: 'School Year',
        value: ['6'],
        displayName: 'School Year',
      },
      {
        codename: 'month_of_school_year',
        title: 'Month of School Year',
        value: ['10'],
        displayName: '',
      },
    ],
  };

  primaryAverageParams: ReportRequestParams = {
    dataset: 'fct_coaching_session',
    measure: {
      type: 'count_distinct',
      field: 'log_id',
    },
    filters: [
      {
        codename: 'session_type',
        value: ['2', '13', '43'],
      },
    ],
  };

  secondaryAverageParams: ReportRequestParams = {
    dataset: 'fct_coaching_session',
    measure: {
      type: 'count_distinct',
      field: 'user_id',
    },
    filters: [
      {
        codename: 'session_type',
        value: ['2', '13', '43'],
      },
    ],
  };

  showCreateUserModal = false;

  constructor(
    private store: Store,
    private pusher: PusherService,
    private confettiService: ConfettiService
  ) {}

  updateMembers() {
    this.attendees = Object.keys(this.channel.members.members).map((key) =>
      avatarDTOFromPusher(this.channel.members.members[key])
    );
  }

  handleFileUpload(file: FileDTO) {
    this.fileUploads.emit(file);
  }

  ngOnInit() {
    this.channel = this.pusher.connectToPresenceChannel('page-style-library');
    this.channel.bind('pusher:subscription_succeeded', () => {
      this.updateMembers();
    });
    this.channel.bind('pusher:member_added', () => {
      this.updateMembers();
    });
    this.channel.bind('pusher:member_removed', () => {
      this.updateMembers();
    });

    this.subs.push(
      this.confettiService.showConfettiNotifier.subscribe((value) => {
        this.particlesVisible = value;
      })
    );

    this.competencyModalLogAttendeeRubrics$
      .pipe(map((filterFn) => filterFn(this.competencyModalLogId)))
      .subscribe((attendeeRubrics) => {
        if (attendeeRubrics) {
          this.competencyModalUserIds = attendeeRubrics.map(
            (attendeeRubric) => attendeeRubric.userId
          );
          this.competencyModalCompetencyIds = attendeeRubrics.flatMap(
            (attendeeRubric) =>
              attendeeRubric.rubrics.flatMap((rubric) =>
                rubric.competencyGroups.flatMap((competencyGroup) =>
                  competencyGroup.competencies.map(
                    (competency) => competency.id
                  )
                )
              )
          );
        } else {
          this.competencyModalCompetencyIds = [];
        }
      });
  }

  getCompetencyLogData() {
    this.store.dispatch(new FetchAttendeeRubrics(this.competencyModalLogId));
  }

  setCompetencyModalId(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    this.competencyModalLogId = parseInt(target.value);
  }

  setCompetencyModalCompetencyId(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    this.competencyModalCompetencyId = parseInt(target.value);
  }

  setCompetencyModalUserId(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    this.competencyModalUserId = parseInt(target.value);
  }

  setConfettiLocation(location: string) {
    this.confettiLocation = location;
  }

  cueConfetti() {
    this.confettiService.showConfetti(this.confettiLocation);
  }

  toggleCompetencyModal() {
    this.competencyModal.coachlogId = this.competencyModalLogId;
    this.competencyModal.competencyId = this.competencyModalCompetencyId;
    this.competencyModal.userId = this.competencyModalUserId;
    this.competencyModal.ngOnInit();
    this.competencyModal.modalShowing.next(
      !this.competencyModal.modalShowing.value
    );
  }

  handleCompetencySummaryEvent() {
    // This handle function should be repurposed
    // replace confetti with desired functionality
    this.cueConfetti();
  }

  ngOnDestroy() {
    this.pusher.disconnectFromPresenceChannel('page-style-library');

    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
