<ul *ngIf="districtCode" class="nav nav-tabs justify-content-center">
  <li class="nav-item">
    <a
      class="nav-link"
      title="Properties"
      href="javascript:void(0)"
      [routerLink]="'/settings/districts/' + districtCode"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Properties
    </a>
  </li>
  <li class="nav-item">
    <a
      *ngIf="user.roles.includes(Roles.E2L_EMPLOYEE)"
      class="nav-link"
      title="Districts"
      href="javascript:void(0)"
      [routerLink]="'/settings'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Districts
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      title="Rosters"
      href="javascript:void(0)"
      [routerLink]="'/settings/districts/' + districtCode + '/rosters'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Rosters
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      title="Forms"
      href="javascript:void(0)"
      [routerLink]="'/settings/districts/' + districtCode + '/cohorts'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Cohorts
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      title="Form Templates"
      href="javascript:void(0)"
      [routerLink]="'/settings/districts/' + districtCode + '/forms'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Forms
    </a>
  </li>
</ul>
