import { AuthGuard } from 'src/app/common/guards/auth-guard/auth-guard.service';
import { UserRole } from 'src/app/common/state/user/role/user-role';

import { RouteGuard } from '../guards/route-guard/route-guard.service';
import { PrivateRoute, PublicRoute } from '../types/routes';

export const AUTH_ROUTE_GUARDED_ROUTE: PrivateRoute = {
  canActivate: [AuthGuard, RouteGuard],
};

export const ROUTE_GUARDED_ROUTE: PublicRoute | PrivateRoute = {
  canActivate: [RouteGuard],
};

export const AUTH_GUARDED_ROUTE: PrivateRoute = {
  canActivate: [AuthGuard],
};

export const AUTH_GUARDED_ROUTE_OPERATOR: PrivateRoute = {
  ...AUTH_GUARDED_ROUTE,
  data: { roles: [UserRole.OPERATOR] },
};

export const AUTH_GUARDED_ROUTE_COACH: PrivateRoute = {
  ...AUTH_GUARDED_ROUTE,
  data: { roles: [UserRole.COACH] },
};

export const AUTH_ROUTE_GUARDED_ROUTE_OPERATOR: PrivateRoute = {
  ...AUTH_GUARDED_ROUTE_OPERATOR,
  canActivate: [AuthGuard, RouteGuard],
};

export const AUTH_ROUTE_GUARDED_ROUTE_E2L_EMPLOYEE: PrivateRoute = {
  ...AUTH_GUARDED_ROUTE,
  data: { roles: [UserRole.E2L_EMPLOYEE] },
};

export const AUTH_ROUTE_GUARDED_ROUTE_E2L_EMPLOYEE_COACHES: PrivateRoute = {
  ...AUTH_GUARDED_ROUTE,
  data: { roles: [UserRole.E2L_EMPLOYEE, UserRole.COACH] },
};
