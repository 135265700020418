<div class="container">
  <div class="d-flex justify-content-between mb-3">
    <h1>User Settings</h1>
  </div>
  <div class="card border-0 p-2 table-container">
    <div
      style="max-width: 600px; padding: 30px 0; margin: 0 auto; display: flex">
      <app-avatar
        [avatarSize]="'large'"
        class="mx-3"
        [profile]="user.profile"></app-avatar>
      <div>
        <h2>{{ user.fullName }}</h2>
        <p *ngIf="user.district">{{ user.district.title }}</p>
      </div>
    </div>
    <div class="card-body">
      <ng-container *ngIf="!isLoading; else loadingTemplate">
        <div class="container mb-3" style="max-width: 800px; margin: 0 auto">
          <div class="row">
            <div class="col-lg">
              <app-basic-input
                [disabled]="true"
                label="First Name"
                [value]="user.profile.first_name" />
            </div>
            <div class="col-lg">
              <app-basic-input
                [disabled]="true"
                label="Last Name"
                [value]="user.profile.last_name" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-basic-input
                label="Email Address"
                [disabled]="true"
                [value]="user.email" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-basic-input
                label="Title"
                [disabled]="true"
                [value]="user.profile.title" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-basic-input
                *ngIf="user.district"
                label="District"
                [disabled]="true"
                [value]="user.district.title" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-basic-input
                *ngIf="user.school"
                label="School"
                [disabled]="true"
                [value]="user.school.title" />
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #loadingTemplate>
        <app-loader />
      </ng-template>
    </div>
  </div>
</div>
