import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { APICoreService } from '../../../../common/services/api-core/api-core.service';
import { AssignedCoacheesAPIResponse } from '../../types/responses/coaching-log.responses';

interface RequestParams {
  details: number;
  per_page?: number;
}

@Injectable({ providedIn: 'root' })
export class CoacheeListService {
  constructor(private apiService: APICoreService, private router: Router) {}

  fetchCoachees(
    requestOptions: RequestParams = { details: 1 }
  ): Observable<AssignedCoacheesAPIResponse | null> {
    return this.apiService.getRequest(
      'growelab/coachees/assigned',
      requestOptions
    );
  }

  assignCoachee(userId: number, coachId: number) {
    const requestBody = {
      coach_id: coachId,
    };
    return this.apiService.postRequest(
      `egrowe/user/${userId}/coach`,
      requestBody
    );
  }
}
