<ng-container *ngIf="competenciesToAdd?.length || resourceCompetencies?.length">
  <div class="row gy-1">
    <div class="col col-sm-6 col-lg-6 col-xl-6 competency-title">
      <span class="bold-text">Competency</span>
    </div>
    <div class="col col-sm-6 col-lg-6 col-xl-6 competency-title">
      <span class="bold-text">Level</span>&nbsp; &nbsp;
      <i class="validation-label" *ngIf="hasEditPermissions"
        >At least one selection is required</i
      >
    </div>

    <ng-container
      *ngFor="let competency of resourceCompetencies; let i = index">
      <div class="col col-sm-6 col-lg-6 col-xl-6">
        <span class="badge badge-item">{{
          competency.name | truncatePhrase : 22
        }}</span>
      </div>
      <div class="col col-sm-6 col-lg-6 col-xl-6">
        &nbsp; &nbsp;<span class="levels-label">{{
          competency.levelsFormatted
        }}</span>
        <ng-container *ngIf="competency.can_delete && hasEditPermissions">
          &nbsp;
          <a>
            <i
              [style.color]="'#DFD0F3'"
              class="bi bi-x-circle-fill"
              (click)="deleteComptetency(competency)"></i>
          </a>
        </ng-container>
      </div>
    </ng-container>
    <div style="clear: both; height: 8px"></div>

    <ng-container *ngIf="competenciesToAdd?.length">
      <div class="col col-sm-4 col-lg-6 col-xl-3 competency-title">
        <span class="bold-text">Competency</span>
      </div>
      <div class="col col-sm-8 col-lg-6 col-xl-9 competency-title">
        <span class="bold-text">Level</span>&nbsp; &nbsp;
        <i class="validation-label">At least one selection is required</i>
      </div>

      <ng-container *ngFor="let competency of competenciesToAdd; let i = index">
        <div class="col col-sm-4 col-lg-4 col-xl-3">
          <span class="badge badge-item">{{
            competency.name | truncatePhrase : 22
          }}</span>
        </div>
        <div class="col col-sm-5 col-lg-5 col-xl-7">
          <div class="row">
            <ng-container *ngFor="let eachLevel of levelNums">
              <div class="col col-3 center-text">
                <app-competency-level-checkbox
                  [level]="eachLevel"
                  (sendIsChecked)="checkedLevelBox($event, i, eachLevel)" />
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col col-sm-3 col-lg-3 col-xl-2">
          <button
            class="btn btn-primary check-label"
            [disabled]="!competency.levelsFormatted"
            (click)="addCompetency(competency, i)">
            Done
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
