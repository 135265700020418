import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { LogTypeDTO } from 'src/app/common/dtos/log-type.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { LogTypeSearchService } from '../../services/log-type-search/log-type-search.service';

@Component({
  selector: 'app-log-type-name',
  templateUrl: './log-type-name.component.html',
  styleUrls: ['./log-type-name.component.scss'],
})
export class LogTypeNameComponent implements OnInit {
  @Input() logTypeId = 0;

  selectedLogType: LogTypeDTO;

  user$ = select(UserState.getUser);

  user: User | null = null;

  constructor(private logTypeSearchService: LogTypeSearchService) {}

  ngOnInit(): void {
    this.user$
      .pipe(
        map((user) => {
          this.user = user;
          this.loadLogTypes();
        })
      )
      .subscribe();
  }

  loadLogTypes() {
    if (this.logTypeId) {
      let districtId = 0;
      if (this.user?.district?.id) {
        districtId = this.user.district.id;
      }
      this.logTypeSearchService
        .getLogTypes(districtId)
        .pipe(
          map((logTypes) => {
            if (logTypes) {
              logTypes.forEach((logType) => {
                if (logType.id === this.logTypeId) {
                  this.selectedLogType = logType;
                }
              });
            }
          })
        )
        .subscribe();
    }
  }
}
