import { Injectable } from '@angular/core';
import Pusher, { Channel, PresenceChannel } from 'pusher-js';
import PrivateChannel from 'pusher-js/types/src/core/channels/private_channel';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  instance?: Pusher;

  constructor() {
    if (StorageService.getItem('authToken')) {
      this.initialize();
    }
  }

  initialize() {
    if (!this.instance) {
      this.instance = new Pusher(EnvironmentService.pusherInfo('key'), {
        cluster: EnvironmentService.pusherInfo('cluster'),
        channelAuthorization: {
          transport: 'ajax',
          endpoint: `${EnvironmentService.apiUrl()}/growelab/pusher/auth`,
          headers: {
            Authorization: `Bearer ${
              StorageService.getItem('authToken')?.token
            }`,
          },
        },
      });
    }
  }

  deinitialize() {
    if (this.instance) {
      this.instance.disconnect();
      delete this.instance;
    }
  }

  connectToChannel(channelName: string): Channel {
    return this.instance?.subscribe(channelName) as Channel;
  }

  connectToPresenceChannel(channelName: string): PresenceChannel {
    return this.instance?.subscribe(
      `presence-${channelName}`
    ) as PresenceChannel;
  }

  connectToPrivateChannel(channelName: string): PrivateChannel {
    return this.instance?.subscribe(`private-${channelName}`) as PrivateChannel;
  }

  disconnectFromChannel(channelName: string) {
    return this.instance?.unsubscribe(channelName);
  }

  disconnectFromPresenceChannel(channelName: string) {
    return this.instance?.unsubscribe(`presence-${channelName}`);
  }

  disconnectFromPrivateChannel(channelName: string) {
    return this.instance?.unsubscribe(`private-${channelName}`);
  }
}
