import { Component, Input } from '@angular/core';
import { CompetencyDTO } from '../../../dtos/attendee-rubric.dto';

@Component({
  selector: 'app-leveled-competency',
  templateUrl: './leveled-competency.component.html',
  styleUrls: ['./leveled-competency.component.scss'],
})
export class LeveledCompetencyComponent {
  @Input() competency: CompetencyDTO;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() logId: number;
}
