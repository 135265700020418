import { Component, OnInit } from '@angular/core';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { CompetenciesSearchService } from '../../services/competencies/competencies-search.service';

@Component({
  selector: 'app-growth-snapshot',
  templateUrl: './growth-snapshot.component.html',
  styleUrls: ['./growth-snapshot.component.scss'],
})
export class GrowthSnapshotComponent implements OnInit {
  isLoading = true;

  last5: number[];

  isCollapsed = this.cardStorageService.getCollapsedState('growthsnapshot');

  user$ = select(UserState.getUser);

  constructor(
    private cardStorageService: CardStorageService,
    private competencyService: CompetenciesSearchService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.getLast5Competencies();
  }

  getLast5Competencies() {
    this.competencyService.getLast5().subscribe((res) => {
      if (res) {
        this.last5 = res;
      }
      this.isLoading = false;
    });
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'growthsnapshot',
      this.isCollapsed
    );
  }
}
