import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { Clipboard } from '@angular/cdk/clipboard';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import {
  LibraryShelfModelDTO,
  ResourceListItemDTO,
  LibraryShelfItemModelDTO,
  ResourceFiltersDTO,
} from '../../../dtos/resources.dto';
import { ResourcesBookmarkService } from '../../../services/resources/resources-bookmark.service';

@Component({
  selector: 'app-shelf',
  templateUrl: './shelf.component.html',
  styleUrls: ['./shelf.component.scss'],
})
export class ShelfComponent implements OnInit, OnDestroy {
  shelfId: number;

  createdByMeShelfId: string;

  @Input() shelf = {} as LibraryShelfModelDTO | null;

  @Input() multiShelves: boolean;

  shelfItems: ResourceListItemDTO[] | null = null;

  newShelf = {} as LibraryShelfModelDTO;

  resourceFilters = {} as ResourceFiltersDTO;

  shelfListMeta: PaginationDTO;

  subs: Subscription[] = [];

  newShelfItem = {} as LibraryShelfItemModelDTO;

  selectedResource: ResourceListItemDTO;

  myStarredChildShelves: LibraryShelfModelDTO[] = [];

  myChildShelves: LibraryShelfModelDTO[] = [];

  isCreatedByMe = false;

  hasChildren = false;

  shareUrl: string;

  tempTitle = '';

  tempDescription = '';

  @ViewChild('copiedAlert', { static: false }) copiedAlert: NgbAlert;

  copiedStatus = false;

  showAddButton = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resourceService: ResourcesBookmarkService,
    private clipboard: Clipboard
  ) {}

  ngOnInit() {
    this.newShelf.title = '';
    this.route.params.subscribe((url) => {
      if (url['shelfId'] === 'created-by-you') {
        this.isCreatedByMe = true;
        this.createdByMeShelfId = 'created-by-me';
      } else {
        this.shelfId = parseInt(url['shelfId']);
      }
      this.getShelf();
    });
  }

  getShelf() {
    this.shelfItems = null;
    const payloadId: number | string = this.createdByMeShelfId
      ? this.createdByMeShelfId
      : this.shelfId;
    this.resourceService.getShelf(payloadId).subscribe((response) => {
      if (response.item) {
        this.shelf = response.item;

        if (this.shelf) {
          if (this.shelf.childShelves) {
            this.myStarredChildShelves = this.shelf.childShelves.filter(
              (shelf) => shelf.usersFavorite === 1
            );
            this.myChildShelves = this.shelf.childShelves.filter(
              (shelf) => shelf.usersFavorite === 0
            );
          }

          this.newShelf.parent_shelf_id = this.shelf.id;
          if (this.shelf.childShelves && this.shelf.resources) {
            if (
              this.shelf.childShelves.length > 0 ||
              this.shelf.resources.length > 0
            ) {
              this.hasChildren = true;
            } else {
              this.hasChildren = false;
            }
          }
          if (response.item.id) {
            this.showAddButton = true;
          } else {
            this.showAddButton = false;
          }

          if (this.shelf.title) {
            this.tempTitle = this.shelf.title;
          }

          if (this.shelf.description) {
            this.tempDescription = this.shelf.description;
          }
          this.getShelfItems();
        }
      }
    });
  }

  getShelfItems() {
    this.shelfItems = null;
    const payloadId: number | string = this.createdByMeShelfId
      ? this.createdByMeShelfId
      : this.shelfId;
    this.resourceService
      .getShelfItems(payloadId, this.resourceFilters)
      .subscribe((result) => {
        if (result.items) {
          this.shelfItems = result.items;
          this.shelfListMeta = result._meta;
        } else {
          this.shelfItems = [];
        }
      });
  }

  paginationChange() {
    if (this.resourceFilters) {
      this.resourceFilters.page = this.shelfListMeta.currentPage;
      this.resourceFilters.per_page = this.shelfListMeta.perPage;
      this.getShelfItems();
    }
  }

  saveTitleDescription() {
    this.resourceService
      .updateShelfInfo(this.shelfId, this.tempTitle, this.tempDescription)
      .subscribe(() => {
        this.getShelf();
      });
  }

  deleteShelf() {
    this.resourceService.deleteShelf(this.shelfId).subscribe(() => {
      this.router.navigateByUrl('/resources/my');
    });
  }

  cancelEditShelfItem() {
    if (this.shelf) {
      this.tempTitle = this.shelf.title;
      if (this.shelf.description) this.tempDescription = this.shelf.description;
    }
  }

  resourceSelected(selectedResource: ResourceListItemDTO) {
    this.selectedResource = selectedResource;
    this.newShelfItem.elearn_favorite_shelf_id = this.shelfId;
    this.newShelfItem.ref_table = 'elearn_resource';
    this.newShelfItem.ref_table_id = selectedResource.id;
  }

  copyToClipboard(shelfId: number) {
    if (shelfId) {
      this.clipboard.copy(
        `${window.location.origin}/resources/my/shelf/${shelfId}`
      );
    } else {
      this.clipboard.copy(window.location.href);
    }
    this.copiedStatus = true;
    setTimeout(() => {
      this.copiedStatus = false;
    }, 3000);
  }

  resetShelf() {
    this.shelf = null;
    this.tempDescription = '';
  }

  createShelf() {
    this.resourceService.createShelf(this.newShelf).subscribe((response) => {
      if (response.item) {
        this.myChildShelves.push(response.item);
      }
      this.newShelf.title = '';
      this.newShelf.description = '';
    });
  }

  starSelect(id: number, favorite: number, shelf: LibraryShelfModelDTO[]) {
    if (favorite) {
      const starSelected = shelf.find(
        (item: LibraryShelfModelDTO) => item.id === id
      );
      if (starSelected) starSelected.isLoading = true;
      this.resourceService.deleteStarShelf(id).subscribe((response) => {
        if (response) {
          this.updateLocalShelf(id, 0);
        }
      });
    } else {
      const selected = shelf.find(
        (item: LibraryShelfModelDTO) => item.id === id
      );
      if (selected) selected.isLoading = true;
      this.resourceService.postStarShelf(id).subscribe((response) => {
        if (response) {
          this.updateLocalShelf(id, 1);
        }
      });
    }
  }

  updateLocalShelf(id: number, value: number) {
    if (this.shelf?.childShelves) {
      this.shelf.childShelves.forEach((item) => {
        if (item.id === id) {
          item.usersFavorite = value;
          item.isLoading = false;
        }
      });
    }
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
