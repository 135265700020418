import { Component, Input, OnInit } from '@angular/core';
import { CreateOptionPayload } from 'src/app/private/shared/types/payloads/coaching-log.payloads';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { map } from 'rxjs/operators';
import {
  EvidenceDTO,
  LevelDTO,
  OptionDTO,
  WillDTO,
} from 'src/app/private/shared/dtos/attendee-rubric.dto';
import { CoachingLogService } from 'src/app/private/shared/services/coaching-log/coaching-log.service';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';

@Component({
  selector: 'app-leveled-competency-strand-level',
  templateUrl: './leveled-competency-strand-level.component.html',
  styleUrls: ['./leveled-competency-strand-level.component.scss'],
})
export class LeveledCompetencyStrandLevelComponent implements OnInit {
  @Input() competencyId: number;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() logId: number;

  @Input() level: number;

  @Input() levelName: string;

  @Input() strandId: number;

  isLightBulbDisabled = false;

  strandData$ = select(CoachingLogState.getStrand);

  optionData$ = select(CoachingLogState.getOptions);

  evidenceData$ = select(CoachingLogState.getEvidence);

  will$ = select(CoachingLogState.getWillsByCoachlog);

  levelData: LevelDTO;

  optionsForChildComponents: OptionDTO[] = [];

  options: OptionDTO[] = [];

  optionsHaveWills = false;

  evidence: EvidenceDTO[] = [];

  wills: WillDTO[] = [];

  hasOptionContent = false;

  hasEvidenceContent = false;

  isEvidenceCollapsed = true;

  isDrawerCollapsed = true;

  isOptionCollapsed = true;

  constructor(private coachingLogService: CoachingLogService) {}

  toggleOptions(): void {
    if (this.isOptionCollapsed) {
      this.closeDrawer();
      this.openOptionDrawer();
    } else {
      this.closeOptionDrawer();
    }
  }

  toggleEvidence(): void {
    if (this.isEvidenceCollapsed) {
      this.closeDrawer();
      this.openEvidenceDrawer();
    } else {
      this.closeEvidenceDrawer();
    }
  }

  openEvidenceDrawer(): void {
    const timing = !this.isDrawerCollapsed ? 700 : 0;
    this.closeDrawer();
    setTimeout(() => {
      this.isEvidenceCollapsed = false;
      this.isDrawerCollapsed = false;
    }, timing);
  }

  closeEvidenceDrawer(): void {
    this.isEvidenceCollapsed = true;
    this.isDrawerCollapsed = true;
  }

  closeDrawer(): void {
    if (!this.isOptionCollapsed) {
      this.closeOptionDrawer();
    } else if (!this.isEvidenceCollapsed) {
      this.closeEvidenceDrawer();
    }
  }

  openOptionDrawer(): void {
    const timing = !this.isDrawerCollapsed ? 700 : 0;
    this.closeDrawer();
    setTimeout(() => {
      this.isDrawerCollapsed = false;
      this.isOptionCollapsed = false;
      if (this.options.length === 0) {
        this.isLightBulbDisabled = true;
        this.createOption();
      } else {
        this.isLightBulbDisabled = false;
      }
    }, timing);
  }

  closeOptionDrawer(): void {
    this.isDrawerCollapsed = true;
    this.isOptionCollapsed = true;
    if (this.options.length > 0) {
      this.options.forEach((option) => {
        if (option.content === '') {
          this.isLightBulbDisabled = true;
          this.coachingLogService.deleteOption(option.id);
        }
      });
    }
  }

  createOption(): void {
    const options: CreateOptionPayload = {
      groweCoachlogId: this.logId,
      groweCompetencyId: this.competencyId,
      groweRubricId: this.rubricId,
      groweStrandId: this.strandId,
      content: '',
      level: this.level,
    };
    this.coachingLogService.createOption(this.logId, options);
  }

  updateOptions() {
    this.optionsHaveWills = this.options.some(
      (option) =>
        this.wills.filter((will) => will.optionId === option.id).length > 0
    );
    if (this.options.length > 0) {
      this.hasOptionContent = true;
    } else {
      this.isOptionCollapsed = true;
      this.hasOptionContent = false;
    }
    this.isLightBulbDisabled = false;
  }

  ngOnInit(): void {
    this.optionData$
      .pipe(map((filterFn) => filterFn(this.strandId, this.level, this.logId)))
      .subscribe((newData) => {
        // Deletions
        const newIds = newData.map((item) => item.id);
        this.optionsForChildComponents.forEach((item) => {
          if (!newIds.includes(item.id)) {
            this.optionsForChildComponents =
              this.optionsForChildComponents.filter(
                (itemToCheck) => itemToCheck.id !== item.id
              );
          }
        });
        // Additions
        newData.forEach((newOption) => {
          if (
            !this.optionsForChildComponents.find(
              (option) => option.id === newOption.id
            )
          ) {
            this.optionsForChildComponents.push(newOption);
          }
        });
        this.options = newData;
        this.updateOptions();
      });
    this.strandData$
      .pipe(map((filterFn) => filterFn(this.userId, this.strandId)))
      .subscribe((data) => {
        if (data)
          switch (this.level) {
            case 1:
              this.levelData = data.level1;
              break;
            case 2:
              this.levelData = data.level2;
              break;
            case 3:
              this.levelData = data.level3;
              break;
            default:
              this.levelData = data.level4;
              break;
          }
        this.hasEvidenceContent = this.evidence.length > 0;
      });

    this.will$
      .pipe(map((filterFn) => filterFn(this.userId, this.logId)))
      .subscribe((wills) => {
        this.wills = wills;
        this.updateOptions();
      });

    this.evidenceData$
      .pipe(
        map((filterFn) =>
          filterFn(this.userId, this.strandId, this.level, this.logId)
        )
      )
      .subscribe((data) => {
        this.hasEvidenceContent = data.length > 0;
      });
  }
}
