import { Component, Input } from '@angular/core';
import { LogTypeSelectDto } from '../log-type-select.dto';

@Component({
  selector: 'app-log-type-select-label',
  templateUrl: './log-type-select-label.component.html',
  styleUrls: ['./log-type-select-label.component.scss'],
})
export class LogTypeSelectLabelComponent {
  @Input() logType: LogTypeSelectDto;
}
