<div class="form-group select">
  <label [id]="id" class="form-label">{{ fieldDefinition.label }}</label>
  <ng-select
    [id]="id"
    [className]="'formSelect'"
    [items]="fieldDefinition.choices"
    bindLabel="label"
    bindValue="value"
    [ngModel]="selection"
    (ngModelChange)="this.value.emit($event)"
    [clearable]="false"></ng-select>
</div>
