import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../environment/environment.service';

const API_URL = EnvironmentService.apiUrl();

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({
  providedIn: 'root',
})
export class APICoreService {
  constructor(private http: HttpClient) {}

  getRequest(url: string, parameters?: any): Observable<any> {
    // set params
    // let getParams = new HttpParams({fromObject: parameters});
    // return this.http.get<any>(API_URL+'/'+url,{params:getParams});

    // using our own buildGetParams, because HttParams doesnt support objects with arrays in them
    const getParams = this.buildGetParams(parameters);
    if (getParams.length > 0) {
      return this.http.get<any>(`${API_URL}/${url}?${getParams}`);
    }
    return this.http.get<any>(`${API_URL}/${url}`);
  }

  putRequest(url: string, parameters?: any): Observable<any> {
    // put parameters into body (with Formdata)
    let formData: FormData = new FormData();
    formData = this.appendFormdata(formData, parameters, '');
    return this.http.put<FormData>(`${API_URL}/${url}`, formData);
  }

  putFormRequest(url: string, formdata: FormData): Observable<any> {
    return this.http.put<FormData>(`${API_URL}/${url}`, formdata);
  }

  postRequest(url: string, parameters?: any): Observable<any> {
    // post parameters into body (with Formdata)
    let formData: FormData = new FormData();
    formData = this.appendFormdata(formData, parameters, '');
    return this.http.post<FormData>(`${API_URL}/${url}`, formData);
  }

  postFormRequest(
    url: string,
    formdata: FormData,
    options: any = {}
  ): Observable<any> {
    return this.http.post<FormData>(`${API_URL}/${url}`, formdata, options);
  }

  deleteRequest(url: string, parameters?: any): Observable<any> {
    // set params
    const delParams = new HttpParams({ fromObject: parameters });
    return this.http.delete<any>(`${API_URL}/${url}`, { params: delParams });
  }

  appendFormdata(formData: FormData, data: any, name = '') {
    if (typeof data === 'object' && data !== null) {
      if (data.length === 0) {
        formData.append(`${name}[]`, data);
      }
      Object.keys(data).forEach((key) => {
        if (name === '') {
          this.appendFormdata(formData, data[key], key);
        } else {
          this.appendFormdata(formData, data[key], `${name}[${key}]`);
        }
      });
    } else {
      formData.append(name, data);
    }
    return formData;
  }

  buildGetParams(obj: any) {
    if (typeof obj !== 'object') {
      return obj == null || typeof obj === 'undefined' ? '' : obj.toString();
    }
    let query = '';
    let value: any;
    let fullSubName;
    let subValue;
    let innerObj: any = {};
    Object.keys(obj).forEach((name) => {
      value = obj[name];
      if (value instanceof Array) {
        Object.keys(value).forEach((i) => {
          subValue = value[i];
          fullSubName = `${name}[${i}]`;
          innerObj = {};
          innerObj[fullSubName] = subValue;
          query += `${this.buildGetParams(innerObj)}&`;
        });
      } else if (value instanceof Object) {
        Object.keys(value).forEach((subName) => {
          subValue = value[subName];
          fullSubName = `${name}[${subName}]`;
          innerObj = {};
          innerObj[fullSubName] = subValue;
          query += `${this.buildGetParams(innerObj)}&`;
        });
      } else if (value !== undefined && value !== null)
        query += `${encodeURIComponent(name)}=${encodeURIComponent(value)}&`;
    });

    return query.length ? query.substr(0, query.length - 1) : query;
  }
}
