<div
  *ngIf="competency"
  role="button"
  (click)="updateCompetency()"
  class="competency text-center shadow p-3 {{ selected ? 'selected' : '' }}">
  <ng-container *ngIf="currentIcon; else noIcon">
    <div
      class="competency-icon"
      [ngStyle]="{
        'background-image':
          'url(/assets/competency_icons/' + currentIcon + '.svg)'
      }"></div>
  </ng-container>
  <ng-template #noIcon>
    <div class="competency-icon"></div>
  </ng-template>
  <div class="competency-title">
    {{ competency.title }}
  </div>
  <div class="competency-content" [innerHTML]="competency.content"></div>
</div>
