import { Component, OnInit } from '@angular/core';
import { CompetencyDTO } from 'src/app/common/dtos/competency.dto';
import { FormCompetencyDTO } from 'src/app/private/shared/dtos/forms.dto';
import { FormsService } from 'src/app/private/shared/services/forms/forms.service';

import { FormFieldAbstractComponent } from '../base/edit-field-abstract';

@Component({
  selector: 'app-form-edit-competency',
  templateUrl: './edit-competency.component.html',
  styleUrls: ['../edit-field/form-edit-field.component.scss'],
})
export class FormEditCompetencyComponent
  extends FormFieldAbstractComponent
  implements OnInit
{
  competencyList: CompetencyDTO[] = [];

  competenciesWithIndicators: FormCompetencyDTO[];

  indicatorSetChoices: FormCompetencyDTO;

  selectedCompetency: CompetencyDTO;

  constructor(private formService: FormsService) {
    super();
    this.formService.getCompetencies();
    this.formService.competencyList.subscribe((competencies) => {
      this.competencyList = competencies;
    });

    this.formService.indicatorsList.subscribe((indicatorsList) => {
      this.competenciesWithIndicators = indicatorsList;
    });
  }

  ngOnInit(): void {
    if (this.data.competency) {
      const selectedIndicator = this.data.indicatorGroupId;
      this.generateIndicatorSets();
      if (selectedIndicator) {
        this.data.indicatorGroupId = selectedIndicator;
      }
    }
  }

  generateIndicatorSets() {
    delete this.data.indicatorGroupId;
    const indicatorSet = this.competenciesWithIndicators?.find(
      (indicator) => indicator.competencyId === this.data.competency?.id
    );
    if (indicatorSet) {
      this.indicatorSetChoices = indicatorSet;
    } else {
      const request = this.formService
        .fetchIndicators(this.data.competency?.id as number)
        .subscribe(() => {
          this.indicatorSetChoices = this.competenciesWithIndicators.find(
            (indicator) => indicator.competencyId === this.data.competency?.id
          ) as FormCompetencyDTO;
          request.unsubscribe();
        });
    }
  }
}
