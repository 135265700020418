<div class="container">
  <ngb-alert
    class="copied-success"
    #copiedAlert
    *ngIf="copiedStatus"
    type="success"
    >Copied to Clipboard
  </ngb-alert>
  <div class="mb-3">Resources you have created or saved appear here.</div>
  <ng-container *ngIf="canCreate">
    <ng-container *ngIf="createdByYouShelf">
      <div class="shelf-section mb-3">
        <div class="shelf-item d-flex align-items-center">
          <div
            class="d-flex align-items-center shelf-title ps-2"
            routerLink="/resources/my/shelf/created-by-you">
            <i class="bi bi-folder me-1"></i>
            {{ createdByYouShelf.title }}
          </div>
          <div class="resources">
            <ng-container
              *ngIf="
                createdByYouShelf.numbItems && createdByYouShelf.numbItems > 0
              ">
              {{ createdByYouShelf.numbItems }} Resources
            </ng-container>
          </div>
          <div class="actions"></div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div
    class="resource-header d-flex align-items-bottom justify-content-between mb-2">
    <div class="section-head mt-2">Saved Resources</div>
    <!-- <button
      aria-label="share"
      class="btn btn-link p-1"
      (click)="copyToClipboard(null)">
      <i class="bi bi-share"></i
      ><span class="ps-1 text">Share Link to Folder</span>
    </button> -->
    <button class="btn btn-link p-1" (click)="createShelfModal.open()">
      <i class="bi bi-folder-plus"></i
      ><span class="ps-1 text">Create a New Folder</span>
    </button>
  </div>
  <ng-container
    *ngIf="myStarredShelves?.length === 0 && myShelves?.length === 0">
    No folders have been created
  </ng-container>
  <!-- STARRED SHELVES -->
  <ng-container *ngIf="myStarredShelves">
    <div class="shelves">
      <div *ngFor="let shelf of myStarredShelves">
        <app-shelf-item
          [shelf]="shelf"
          [multiShelves]="myStarredShelves.length > 1"
          (copyClicked)="copyToClipboard(shelf.id)"
          (starClicked)="
            starSelect(shelf.id, shelf.usersFavorite, myStarredShelves)
          "></app-shelf-item>
      </div>
    </div>
    <div
      *ngIf="myStarredShelves.length > 0"
      class="app-hr-thin"
      style="margin: 25px 0"></div>
  </ng-container>

  <!-- NON-STARRED SHELVES -->
  <ng-container *ngIf="myShelves; else loading">
    <div cdkDropList class="shelves">
      <div *ngFor="let shelf of myShelves">
        <app-shelf-item
          [shelf]="shelf"
          [multiShelves]="myShelves.length > 1"
          (copyClicked)="copyToClipboard(shelf.id)"
          (starClicked)="
            starSelect(shelf.id, shelf.usersFavorite, myShelves)
          "></app-shelf-item>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <div class="w-100 text-center" style="height: 60vh">
      <app-loader />
    </div>
  </ng-template>

  <app-modal
    #createShelfModal
    [modalConfig]="{
      titleText: 'Create a New Folder',
      headerClass: ['modal-header', 'bg-primary', 'text-white'],
      customFooter: true
    }">
    <button
      class="bg-transparent border-0"
      title="Close"
      aria-label="close"
      headerContent
      (click)="createShelfModal.close()">
      <i class="bi bi-x-lg text-white"></i>
    </button>
    <label for="folder-title-input" class="form-label">Title</label>
    <input
      class="form-control mb-3"
      aria-label="title"
      id="folder-title-input"
      placeholder="Enter Folder Title"
      [(ngModel)]="newShelf.title"
      required />
    <label for="folder-description-input" class="form-label">Description</label>
    <textarea
      class="form-control"
      id="folder-description-input"
      aria-label="description"
      [(ngModel)]="newShelf.description"
      rows="4"
      placeholder="Enter a description for this folder"></textarea>
    <div footerContent>
      <div class="footer-btn-row">
        <button
          class="btn btn-primary"
          [disabled]="newShelf.title.length < 1"
          (click)="createShelfModal.close()"
          (click)="createShelf()">
          Create
        </button>
      </div>
    </div>
  </app-modal>
</div>
