<div *ngIf="showForm" class="container-fluid form-view">
  <div *ngIf="showTitle" class="row">
    <div class="col-xs-12">
      <h1 class="color-primary text-center border-tertiary">
        {{ form.title }}
      </h1>
    </div>
  </div>
  <div class="row">
    <div *ngFor="let field of form.fields" class="col-xs-12 mb-3">
      <div *ngIf="field.type === fieldTypes.SECTION" class="section-header">
        <h2 class="pt-2 pb-1 color-primary fw-bold h3">{{ field.label }}</h2>

        <p *ngIf="field.helperText">
          {{ field.helperText }}
        </p>
      </div>
      <div *ngIf="field.type == fieldTypes.FREETEXT" class="col-xs-12 my-1">
        {{ field.label }}
      </div>
      <app-form-view-field
        *ngIf="
          field.type !== fieldTypes.SECTION &&
          field.type !== fieldTypes.FREETEXT
        "
        [inputData]="getInputData(field)"
        [inputDisabled]="inputDisabled"
        (data)="updateData($event)"
        [fieldDefinition]="field"></app-form-view-field>
    </div>
  </div>
</div>
