<div class="container container-main">
  <app-dashboard-header :dashboardType="learner" />
  <div class="row mb-3">
    <div class="col-xl-8">
      <div class="row mb-3">
        <div class="col">
          <app-card-current-competencies />
        </div>
      </div>
      <div class="row gy-3">
        <div class="col-md-6">
          <app-card-todos />
        </div>
        <div class="col-md-6">
          <app-card-ideas />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-recent-celebration />
        </div>
      </div>
      <div class="row gy-3">
        <div class="col">
          <app-card-recent-badges />
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <app-growth-snapshot />
    </div>
  </div>
</div>
