import { Injectable } from '@angular/core';
import {
  Subject,
  debounceTime,
  switchMap,
  shareReplay,
  Observable,
  map,
  ReplaySubject,
} from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { DistrictSearchResponse } from 'src/app/common/types/responses/responses';
import { SchoolYear } from 'src/app/common/dtos/school.dto';
import { districtDTOFromAPIResponse } from 'src/app/common/dtos/translators/district.translator';
import { DistrictDTO } from '../../../../common/dtos/district.dto';

@Injectable()
export class DistrictSearchService {
  items: Observable<DistrictDTO[]>;

  currentResponse: DistrictSearchResponse;

  currentSchoolYear: SchoolYear;

  currentSchoolYearIdObs: ReplaySubject<SchoolYear> = new ReplaySubject();

  schoolYear: SchoolYear;

  schoolYearObs: ReplaySubject<SchoolYear> = new ReplaySubject();

  private term: Subject<string> = new Subject<string>();

  constructor(private apiService: APICoreService) {
    this.items = this.term.pipe(
      debounceTime(250),

      switchMap((term: string) => this.findDistricts(term)),
      shareReplay(1)
    );
  }

  search(term: string) {
    /**
     * Set the value which the search stream will work.
     */
    return this.term.next(term);
  }

  complete(): void {
    this.term.complete();
  }

  public findDistricts(searchTerm = ''): Observable<DistrictDTO[]> {
    const searchQuery = searchTerm ? `&search=${searchTerm}` : '';
    return this.apiService.getRequest(`filters/districts?${searchQuery}`).pipe(
      map((response) => {
        this.currentResponse = {
          districts: response.items,
          _meta: response._meta,
          _links: response._links,
        };
        return response.items;
      })
    );
  }

  getDistrict(id: number): Observable<DistrictDTO | null> {
    return this.apiService
      .getRequest(`districts/${id}`)
      .pipe(map((response) => districtDTOFromAPIResponse(response.item)));
  }

  getCurrentSchoolYear() {
    if (this.currentSchoolYear) {
      this.currentSchoolYearIdObs.next(this.currentSchoolYear);
    } else {
      let schoolYearString = '';
      const date2 = new Date();
      const month = date2.getMonth() + 1;
      const curYear = date2.getFullYear();
      if (month >= 7) {
        const year2 = curYear.toString();
        const year3 = (curYear + 1).toString();
        schoolYearString = `${year2}-${year3}`;
      } else {
        const year2 = (curYear - 1).toString();
        const year3 = curYear.toString();
        schoolYearString = `${year2}-${year3}`;
      }
      this.apiService
        .getRequest(`reporting/school-years/by-name/${schoolYearString}`)
        .subscribe((res) => {
          if (res?.item) {
            this.currentSchoolYear = res.item;
            this.currentSchoolYearIdObs.next(this.currentSchoolYear);
          }
        });
    }
  }

  getSchoolYear(id = 0, schoolYearName = '') {
    if (id) {
      this.apiService
        .getRequest(`reporting/school-years/${id}`)
        .subscribe((res) => {
          if (res?.item) {
            this.schoolYear = res.item;
            this.schoolYearObs.next(this.schoolYear);
          }
        });
    } else if (schoolYearName) {
      this.apiService
        .getRequest(`reporting/school-years/by-name/${schoolYearName}`)
        .subscribe((res) => {
          if (res?.item) {
            this.schoolYear = res.item;
            this.schoolYearObs.next(this.schoolYear);
          }
        });
    }
  }
}
