<div class="form-group">
  <label class="form-label" [for]="id">{{ fieldDefinition.label }}</label>
  <input
    *ngIf="fieldDefinition.type === fieldTypes.TEXT"
    type="text"
    class="form-control"
    [(ngModel)]="userInput"
    (input)="value.emit(userInput)"
    [id]="id" />
  <textarea
    *ngIf="fieldDefinition.type === fieldTypes.TEXTBLOCK"
    class="form-control"
    [(ngModel)]="userInput"
    (input)="value.emit(userInput)"
    [id]="id"></textarea>
</div>
